import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../actions';

import ButtonSecondary from './mui/ButtonSecondary';
import { Dialog, DialogContent } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import SectionTitle from "./SectionTitle";
import SelectField from '@material-ui/core/Select';

import Strings from '../constants/strings';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const labelStyle = {
    fontSize: "16px",
    marginRight: "20px",
    marginTop: "10px",
    float: "left"
};
const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

class AddNewValidationList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: "",
            creating: false,
            type: "",
            key: ""
        }
    } 

    handleNewList = () => {
        let configurations = this.props.configurations;
        let listConfiguration = {
            Enabled: true,
            PeriodList: [],
            ValueList: []
        };

        for (const index in configurations.BigIdValidationOptions) {
            const validation = configurations.BigIdValidationOptions[index];
            if (validation.Name === this.state.type) {
                configurations
                    .BigIdValidationOptions[index]
                    .ListsConfiguration[this.state.key] = listConfiguration;
            }
            var enabled = false;
            if(configurations.BigIdValidationOptions[index].ListsConfiguration.CPF) {
                enabled = configurations.BigIdValidationOptions[index].ListsConfiguration.CPF.Enabled
            }
            if(configurations.BigIdValidationOptions[index].ListsConfiguration.CRM && enabled === false) {
                enabled = configurations.BigIdValidationOptions[index].ListsConfiguration.CRM.Enabled
            }
            configurations.BigIdValidationOptions[index].Enabled = enabled;
        }

        this.props.onSave(configurations);
        this.setState({ creating: false });
    };

    onValueChange = (field, event, key, value) => {
        this.setState({ [field]: event.target.value });
    };


    renderForm() {
        return (

            <div>
                <SectionTitle
                    text={Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.NEW_PERMISSION_LIST[language]}
                />

                <p style={labelStyle}>
                    {Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.TYPE[language]}
                </p>

                <SelectField
                    labelText={Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.TYPE[language]}
                    value={this.state.type}
                    onChange={this.onValueChange.bind(null, 'type')}
                    style={{minWidth: '150px'}}
                >
                    <MenuItem style={styleMenuItem} value={"BIGID_BLACK_LIST"} > {Strings.validations["BIGID_BLACK_LIST"]}</MenuItem>
                    <MenuItem style={styleMenuItem} value={"BIGID_WHITE_LIST"} > {Strings.validations["BIGID_WHITE_LIST"]}</MenuItem>
                    <MenuItem style={styleMenuItem} value={"BIGID_WATCH_LIST"} > {Strings.validations["BIGID_WATCH_LIST"]}</MenuItem>
                </SelectField>

                <div style={{ clear: "both" }}></div>

                <p style={labelStyle}>
                    {Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.KEY[language]}
                </p>

                <SelectField
                    labelText={Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.KEY[language]}
                    value={this.state.key}
                    onChange={this.onValueChange.bind(null, 'key')}
                    style={{minWidth: '150px'}}
                >
                    <MenuItem style={styleMenuItem} value={"CPF"}> CPF </MenuItem>
                    <MenuItem style={styleMenuItem} value={"CRM"}>  CRM </MenuItem>
                </SelectField>

                <div style={{ clear: "both" }}></div>

                <div style={{display: "relative"}}>
                    <ButtonSecondary
                        label={Strings.generalConfigurations.CREATE[language]}
                        onClick={this.handleNewList}
                    />
                
                    <ButtonSecondary
                        label={Strings.generalConfigurations.BACK[language]}                        
                        onClick={() => { this.setState({ creating: false }) }}
                    />
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div>
                <ButtonSecondary
                    label={Strings.generalConfigurations.LIST_VALIDATIONS_NEW_LIST_BUTTON[language]}
                    style={{ margin: 50, left: "-5%", textTransform: "none" }}
                    onClick={() => { this.setState({ creating: true }) }}
                />

                <Dialog
                    fullScreen={false}
                    open={this.state.creating}
                    onClose={() => { this.setState({ creating: false }) }}
                    arial-labelledby="dialogTitle"
                >
                    <DialogContent>
                        {this.renderForm()}
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewValidationList);
