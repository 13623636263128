import React from 'react';

import Strings from '../../constants/strings';

// Material UI Imports
import TextField from '../mui/TextField';
import { MenuItem } from '@material-ui/core';
import SelectField from '@material-ui/core/Select';
import { CircularProgress } from '@material-ui/core';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

import API from '../../API';

import Auth from '../../Authentication';

import ButtonSecondary from '../mui/ButtonSecondary';
import Toggle from '../../components/mui/Toggle';

import Alert from 'react-bootstrap/lib/Alert';

import SectionTitle from '../../components/SectionTitle';

import { Card, CardHeader, CardContent } from '@material-ui/core';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Paper from 'material-ui/Paper';

import UserLanguage from '../configurations/UserLanguage.js'

import Utils from '../Utils.js';

import { CountdownCircleTimer } from 'react-countdown-circle-timer'


let language = UserLanguage.getLanguage();


const flexRowStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap"
};

const inputsInterface = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const requiredFieldStyle = {
    width: "120px",
    marginBottom: "12px"
};

const alertStyle = {
    marginTop: "20px"
};

const eventStatusStyle = {
    marginTop: "20px"
};

const invisible = {
    display: 'none'
}

const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

class Demonstration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            personToggle: true,
            companyToggle: false,
            companyKey: "CNPJ",
            key: "CPF",
            value: '',
            companyId: '',
            generatingLoading: false,
            getStatusLoading: false,
            faceRecognition: false,
            documentImage: null,
            faceImage: null,
            currentQuestion: 0, // Used when question is one per time
            messageTimeout: 0,
            startTimer: true
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    handleToggle = (event, toggled) => {
        let newState = this.state;
        newState[event.target.name] = toggled;
        this.setState(newState);
    };

    // -- HANDLERS -- //
    onKeyChange = (field, event, index) => {
        let newState = this.state;
        newState[field] = event.target.value;
        this.setState(newState);
    };

    onValueChange = (field, event) => {
        let newState = this.state;
        newState[field] = event.target.value;
        if(newState[field].length < 15){
            this.setState(newState);
        }

    };

    onCompanyIdChange = (field, event, value) => {
        let newState = this.state;
        newState["companyId"] = event.target.value;
        if(newState["companyId"].length < 19){
            this.setState(newState);
        }
    };

    onGenerate = async () => {
        //Check if anything was typed before pressing the button
        if(!this.state.value && !this.state.companyId){
            alert(Strings.generalConfigurations.demonstration.VALID_DOC_ALERT[language])
            return
        }

        if(this.state.value.length < 11 && this.state.companyId.length < 14){
            alert(Strings.generalConfigurations.demonstration.VALID_DOC_ALERT[language])
            return
        }

        var messageTimeout = 5
        if(this.state.configurations.QuestionDemonstrationTimeoutInSeconds != undefined) {
            messageTimeout = this.state.configurations.QuestionDemonstrationTimeoutInSeconds
        }

        this.setState({ generatingLoading: true, questionsEvent: null, answersError: null, currentQuestion: 0, messageTimeout: messageTimeout });

        let result;

        let params = [];

        if (this.state.value && this.state.personToggle) {
            params.push(`${this.state.key}=${this.state.value}`);
        }

        if (this.state.companyId && this.state.companyToggle) {
            params.push(`${this.state.companyKey}=${this.state.companyId}`);
        }

        result = await API.getQuestions(params, this.props.selectedDomain);

        if (result) {
            this.setState(
                {
                    questionsEvent: result,
                    faceImage: null,
                    documentImage: null,
                    generatingLoading: false,
                    bigIdEvent: null,
                    answers: new Array(result.Questions.length)
                }
            );   
        }
    };

    onGetEventStatus = async () => {
        this.setState({ getStatusLoading: true });
        let events = await API.getEvent(this.state.answersEvent.TicketId, this.props.selectedDomain);
        if (events && events.ResultCode === -605) {
            this.setState({ bigIdEvent: events.Events[0] });
        }
        this.setState({ getStatusLoading: false });
    };

    onAnswered = (index, event, value) => {
        let answers = this.state.answers;
        answers[index] = value;
        this.setState({ answers: answers });
    };

    onSendAnswers = async () => {
        // Check if number of answers different of null
        // is different to number of questions
        // so it cannot be send
        if (this.state.answers.filter((x) => x !== null).length !== this.state.questionsEvent.Questions.length) {
            var answer = Strings.generalConfigurations.demonstration.MISSING_ANSWER[language];
            this.setState({ answersError: answer });
            return;
        }

        this.setState({ generatingLoading: true });

        let result = await API.sendAnswers(this.state.questionsEvent.TicketId, this.state.answers, this.props.selectedDomain);

        this.setState({ answersEvent: result, generatingLoading: false, questionsEvent: null }, () => {
            if (this.state.answersEvent) {
                this.onGetEventStatus();
            }
        });

    };

    // -- RENDER -- //
    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} thickness={3.5} size={35} />
        )
    }

    renderEventStatus() {
        if (!this.state.bigIdEvent) {
            return null;
        }

        const bigIdEvent = this.state.bigIdEvent;

        var approved = false;
        if (bigIdEvent.Status == 1) {
            approved = true;
        }

        return (
            <div>
                <div>
                    <h5>TicketId - {this.state.answersEvent.TicketId}</h5>
                </div>
                <Alert bsStyle={approved ? 'success' : 'danger'} style={eventStatusStyle}>
                    <h3>{this.returnQuestionnaireResultMessage(bigIdEvent)}</h3>
                    <h4>Status: {bigIdEvent.Status}</h4>
                </Alert>

                {this.renderQuestionnaireDetailAnswer(bigIdEvent)}
            </div>
        )
    }

    returnQuestionnaireResultMessage(bigIdEvent) {
        if (bigIdEvent.Status == 1) {
            return Strings.generalConfigurations.demonstration.APPROVED[language]
        } else {
            return Strings.generalConfigurations.demonstration.DISAPPROVED[language]
        }
    }


    renderQuestionnaireDetailAnswer(bigIdEvent) {
        if (!Auth.sessionData.isViewOnlyUser || Auth.sessionData.isQuestionResult) {
            return (
                bigIdEvent.Questions.map((question) => {
                    return (
                        <Paper style={{ marginBottom: 30, padding: 15 }} zDepth={2}>
                            <h4>{question.Text}</h4>
                            <h5>{Strings.generalConfigurations.demonstration.CORRECT_ANSWER[language]} {question.Result ? "Sim" : "Não"}</h5>
                            <div style={this.state.bigIdEvent.IsTelemarketingEvent ? invisible : null}>Resposta Correta: {question.Correct}</div>
                            <div>{Strings.generalConfigurations.demonstration.USER_ANSWER[language]} {question.Answer}</div>
                        </Paper>
                    )
                })
            )
        }
    }

    renderEventStatusInterface() {
        return (
            <div>
                {this.renderLoading(this.state.getStatusLoading)}

                {this.renderEventStatus()}
            </div>
        )
    }


    renderAlert() {
        if (this.state.questionsEvent.ResultCode !== 0 && this.state.questionsEvent.ResultCode !== 70) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <h3> {this.returnWarningCodeText(this.state.questionsEvent.ResultMessage)}</h3>
                    <h4> Status: {this.state.questionsEvent.ResultCode}</h4>
                </Alert>
            )
        }
    }

    returnWarningCodeText(message) {
        var messageTruncated = Utils.internalReplaceAll(message, " ", "_");
        if (Strings.generalConfigurations.demonstration.QUESTIONNAIRE_CODES[messageTruncated] &&
            Strings.generalConfigurations.demonstration.QUESTIONNAIRE_CODES[messageTruncated][language]) {
            return Strings.generalConfigurations.demonstration.QUESTIONNAIRE_CODES[messageTruncated][language]
        } else {
            return message;
        }
    }

    renderQuestion(question, index) {
        let arrayCorrectAnswers = [];

        if (this.state.configurations.TelemarketingGroup == true) {
            arrayCorrectAnswers = question.CorrectAnswers.map((correctAnswer) => {
                return (
                    <h5>Respostas: {correctAnswer}</h5>
                )
            })
        }

        return (
            <div key={question.Question}>
                <h4>{question.Question}</h4>
                {arrayCorrectAnswers}
                <div>
                    <RadioGroup name={question.Question} onChange={this.onAnswered.bind(null, index)}>
                        {question.Answers.map((answer) => {
                            return (
                                <FormControlLabel value={answer} control={<Radio />} label={answer} />
                            )
                        })}
                    </RadioGroup>
                </div>
            </div>
        )
    }

    handleRenderTime = ({ remainingTime }) => {
        this.remainingTime = remainingTime;
        if (remainingTime === 0) {
            //{this.stopLazyLoad()}
            return <div className="timer" style={{ fontSize: '42px' }}>0</div>;
            //return true;
        }

        return (
            <div className="timer" style={{ fontSize: '42px' }}>
                <div className="value">{remainingTime}</div>
            </div>
        );
    };

    timeoutQuestion() {
        if (this.state.answers[this.state.currentQuestion] == undefined) {
            this.state.answers[this.state.currentQuestion] = "TIMEOUT";
        }

        if (this.state.currentQuestion == this.state.answers.length - 1) {
            this.onSendAnswers()
        } else {
            this.nextQuestion()
        }
    }

    nextQuestion() {
        if (this.state.answers[this.state.currentQuestion] == undefined) {
            var answer = Strings.generalConfigurations.demonstration.MISSING_ANSWER[language];
            this.setState({ answersError: answer });
            return;
        }
        var quest = this.state.currentQuestion;
        this.setState({ currentQuestion: quest + 1, answersError: null, startTimer: false })
        setInterval(() => {
            this.setState({ startTimer: true })
        }, 500);
    }

    renderNextButton(questionsEvent) {
        if (this.state.currentQuestion < questionsEvent.Questions.length - 1) {
            return (
                <ButtonSecondary
                    disabled={this.state.questionsEvent.ResultCode !== 0}
                    label={Strings.generalConfigurations.demonstration.NEXT[language]}
                    onClick={this.nextQuestion.bind(this)}
                />
            )
        }
    }

    renderCountdownTimer() {
        if (Auth.getSessionData().isOperatorUserTimer && this.state.startTimer) {
            return (
                <div id="countdown-timer">
                    <CountdownCircleTimer
                        isPlaying
                        duration={this.state.messageTimeout}
                        size={120}
                        colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                        onComplete={this.timeoutQuestion.bind(this)}
                    >
                        {this.handleRenderTime}
                    </CountdownCircleTimer>
                </div>
            )
        }
    }

    renderQuestions(questionsEvent) {
        if (questionsEvent.ResultCode != -101) {
            if (!Auth.getSessionData().isOperatorUserTimer) {
                return (
                    <div>
                        {questionsEvent.Questions.map((question, index) => {
                            return this.renderQuestion(question, index);
                        })}
                    </div>
                )
            } else {
                return (
                    <div>
                        {this.renderCountdownTimer()}
                        {this.renderQuestion(questionsEvent.Questions[this.state.currentQuestion], this.state.currentQuestion)}
                        {this.renderNextButton(questionsEvent)}
                    </div>
                )
            }
        }
    }

    renderAnswersError() {
        if (this.state.answersError) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <h4> {this.state.answersError} </h4>
                </Alert>
            )
        }
    }

    /**
     * Render "SEND ANSWERS" button. 
     * In case of not be a isOperatorUserTime show the SEND ANSWER instantly. If its isOperatorUserTime just in end of the questionnaire. 
     * @param {*} questionsEvent 
     */
    renderSendButton(questionsEvent) {
        if (!Auth.getSessionData().isOperatorUserTimer ||
            Auth.getSessionData().isOperatorUserTimer && this.state.currentQuestion == questionsEvent.Questions.length - 1) {
            return (
                <ButtonSecondary 
                    disabled={this.state.questionsEvent.ResultCode !== 0} 
                    label={Strings.generalConfigurations.demonstration.SEND_ANSWER[language]} 
                    onClick={this.onSendAnswers} 
                />
            )
        }
    }

    renderQuestionnaire() {
        if (this.state.questionsEvent) {
            return (
                <Card initiallyExpanded={true}>
                    <CardHeader
                        title={(
                            <div>
                                <h4>{Strings.generalConfigurations.demonstration.QUESTIONNAIRE[language]}</h4>
                                <h5>TicketId - {this.state.questionsEvent.TicketId}</h5>
                            </div>

                        )}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardContent expandable={true}>
                        {this.renderAlert()}
                        {this.renderAnswersError()}

                        {this.renderQuestions(this.state.questionsEvent)}
                        {this.renderSendButton(this.state.questionsEvent)}
                    </CardContent>
                </Card>
            )
        }

        if (this.state.answersEvent) {
            return (
                <div>
                    <h4>{Strings.generalConfigurations.demonstration.SUCCESFUL_ANSWER_SENT[language]}</h4>

                    {this.renderEventStatusInterface()}
                </div>
            )
        }

        return null;
    }
    renderCnpjRequestInterface() {
        let questConfig = this.props.configurations.QuestionsConfigurations.find((x) => {
            const questProp = this.props.questionsProperties.AllQuestionPorperties.find((y) => { return (y.Id === x.QuestionId && y.IsCompanyOriented)});
            return questProp != undefined;
        });
        if(questConfig)
            return (
                <div style={flexRowStyle}>
                    <Toggle
                        toggled={this.state.companyToggle}
                        onToggle={this.handleToggle}
                        name="companyToggle"
                    />
                    &nbsp;&nbsp;
                    <SelectField
                        disabled={!this.state.companyToggle}
                        style={requiredFieldStyle}
                        value={this.state.companyKey}
                    >
                        <MenuItem value={"CNPJ"}> CNPJ </MenuItem>
                    </SelectField>
                    &nbsp;&nbsp;
                    <TextField
                        disabled={!this.state.companyToggle}
                        type="text"
                        name="companyId"
                        value={this.state.companyId}
                        inputProps={{ maxLength: 18 }}
                        onChange={this.onCompanyIdChange.bind(null, 'companyId')}
                    />
                </div>    
            )
    }

    renderCpfRequestInterface() {
        let items = null
        if (!Auth.sessionData.isTelemarketing || !Auth.sessionData.isViewOnlyUser) {
            items = <MenuItem style={styleMenuItem} value={"CRM"} > CRM </MenuItem>
        }
        return (
            <div>
                <div style={flexRowStyle} >
                    <Toggle
                        toggled={this.state.personToggle}
                        onToggle={this.handleToggle}
                        name="personToggle"
                    />
                    &nbsp;&nbsp;
                    <SelectField
                        disabled={!this.state.personToggle}
                        style={requiredFieldStyle}
                        value={this.state.key}
                        onChange={this.onKeyChange.bind(null, 'key')}
                    >
                        <MenuItem style={styleMenuItem} value={"CPF"}> CPF </MenuItem>
                        {this.checkIfHaveDNIAccess()}
                        {items}
                    </SelectField>
                    &nbsp;&nbsp;
                    <TextField
                        disabled={!this.state.personToggle}
                        hintText={Strings.generalConfigurations.demonstration.ONLY_NUMBERS_HINT[language]}
                        type="text"
                        name="value"
                        value={this.state.value}
                        inputProps={{ maxLength: 14 }}
                        onChange={this.onValueChange.bind(null, 'value')}
                    />
                </div>
                {this.renderCnpjRequestInterface()}                   
                <div style={{ marginLeft: "47%", padding: "-10%" }}>
                    <ButtonSecondary
                        id="quest-demo-gen-button"
                        label={Strings.generalConfigurations.demonstration.GENERATE[language]}
                        style={{ textTransform: "none", marginBottom: "20px"}}
                        onClick={this.onGenerate}
                    />
                </div> 
            </div>
            
        )
    }
    checkIfHaveDNIAccess() {
        var userRegion = JSON.parse(localStorage.getItem(Auth.AUTHENTICATION_OBJECT_KEY)).userRegion;
        if (userRegion == 'ARG') {
            return <MenuItem style={styleMenuItem} value={"DNI"}> DNI </MenuItem>
        }
    }

    render() {
        let demoTitle = (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) ||
            (Auth.sessionData.activeGroupConfiguration.Domain === "WEBMOTORS") ? Strings.generalConfigurations.demonstration.QUESTIONNAIRE[language]
            : Strings.generalConfigurations.demonstration.DEMONSTRATION[language];
        return (
            <div>
                <SectionTitle text={demoTitle} />

                <div style={inputsInterface}>
                    {this.renderCpfRequestInterface()}
                    {this.renderLoading(this.state.generatingLoading)}
                </div>

                <div>
                    {this.renderQuestionnaire()}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.configurations.Domain : null,
        questionsProperties: state ? state.questionsProperties : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Demonstration);
