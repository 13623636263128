import React, { Component } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';


const CLIENT_ID = '705076957724-hsof5htsjiielt860b3806vtpspku62v.apps.googleusercontent.com';


class GoogleBtn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
      accessToken: ''
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  login(response) {
    if (response.accessToken) {
      this.setState(state => ({
        isLogined: true,
        accessToken: response.accessToken
      }));
    }
  }

  handleLoginFailure(response) {
    console.log("Failure :(", response)
    return;
  }

  handleLogoutFailure(response) {
    return;
  }

  render() {
    return (
      <div>
        <GoogleLogin
          clientId={CLIENT_ID}
          buttonText='Entrar com Google         '
          onSuccess={this.props.doGoogleLogin.bind(this)}
          onFailure={this.props.handleLoginFailure}
          scope={[
            "profile",
            "email" 
          ].join(" ")}
          cookiePolicy={'single_host_origin'}
          responseType='code,token'
        />
      </div>
    )
  }
}

export default GoogleBtn;