import React from 'react';
import Strings from '../../constants/strings';
import SectionTitle from '../SectionTitle';
import ValidationList from '../ValidationList';
import WatchList from '../WatchListContacts';
import AddNewValidationList from '../AddNewValidationList';

import Table from 'react-bootstrap/lib/Table';

import { connect } from 'react-redux';
import * as actions from '../../actions';
import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

class ListValidations extends React.Component {

    onKeyChange = (event, index, value) => {
        this.setState({key: value}, this.stateChanged);
    };

    onTextChange = (key, event, value) => {
        this.setState({[key]: value}, this.stateChanged);
    };

    stateChanged = () => {
        let validation = this.state.validation;
        validation.ListsConfiguration = {};
        validation.ListsConfiguration[this.state.key] = {
            Enabled: true,
            ValueList: [this.state.quantity],
            PeriodList: [this.state.time * 60 * 1000]
        }
    };


    renderLists = () => {
        return (
            <div style={{marginBottom: 40}}>
                <SectionTitle text={Strings.generalConfigurations.LIST_VALIDATIONS_LABEL[language]} tooltip={Strings.tooltips.LIST_VALIDATIONS[language]} />

                <Table style={{marginBottom: 0}}>
                    <thead>
                        <tr>
                            <th>{Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.TYPE[language]}</th>
                            <th>{Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.KEY[language]}</th>
                            <th>{Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.ACTIVATION[language]}</th>
                            <th>{Strings.generalConfigurations.LIST_VALIDATIONS_HEADERS.CONTACTS[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.validations.map((validation) => {
                            return (
                                Object.keys(validation.ListsConfiguration).map((key) => {
                                    return (
                                        <ValidationList validationName={validation.Name} myKey={key} listConfiguration={validation.ListsConfiguration[key]} />
                                    )
                                })
                            )
                        })}
                    </tbody>
                </Table>

                <AddNewValidationList />
            </div>
        )
    };

    render(){
        return (
            <div>
                {this.renderLists()}

                <WatchList validationName={"BIGID_WATCH_LIST"}/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        validations: state.configurations.BigIdValidationOptions.filter((x) => {
            return x.Name === "BIGID_WATCH_LIST" || x.Name === "BIGID_BLACK_LIST" || x.Name === "BIGID_WHITE_LIST";
        })
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(ListValidations);
