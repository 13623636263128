import React from 'react';
import { TextField } from '@material-ui/core';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Strings from '../../constants/strings';

import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tooltip';
import UserLanguage from '../configurations/UserLanguage.js';

const defaultTextStyle = {
    width: 170
};

const defaultLabelStyle = {
    fontWeight: 'bold',
    maxWidth: 250
};

const defaultWrapperStyle = {
    marginBottom: 10,
};

const inlineWrapperStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: "wrap",
    alignItems: 'center'
};

const inlineTextStyle = {
    width: 70,
    display: 'inline-block'
};

const inlineLabelStyle = {
    display: 'inline-block'
};

const errorStyle = {
    color: 'red'
};

const labelWrapperStyle = {
    display: 'flex',
    alignItems: "center",
};

let language = UserLanguage.getLanguage();

class BigIdTextField extends React.Component {

    renderError() {
        if (this.props.inline && this.props.errorText) {
            return (
                <p style={errorStyle}>{this.props.inline ? this.props.errorText : ""}</p>
            )
        }

        return null;
    }

    componentDidMount() {
        $(document).tooltip({
            position: { my: "left+15 top-15", at: "right center", collision: "flipfit" }
        });
    }

    renderTooltipIcon() {
        if (this.props.tooltip) {
            return (
                <HelpOutlineIcon style={{ width: 18, height: 18, padding: 0, marginLeft: 10, marginTop: 5 }} />
            )
        }

        return null;
    }

    renderLabel() {
        var textField = this.props.labelText || this.props.floatingLabelText
        if(Strings.textFieldDialog[textField] !== undefined && Strings.textFieldDialog[textField][language] !== undefined) {
            textField = Strings.textFieldDialog[textField][language]
        }
        
        if (this.props.tooltip) {
            return (
                <span style={labelWrapperStyle}>
                    {textField}
                    <span title={this.props.tooltip}>
                        {this.renderTooltipIcon()}
                    </span>
                </span>
            )
        } else {
            return (
                <span>
                    {textField}
                    <span title={this.props.tooltip}>
                        {this.renderTooltipIcon()}
                    </span>
                </span>
            )
        }
    }

    render() {
        let textStyle = Object.assign({}, defaultTextStyle, this.props.style || {});
        let labelStyle = Object.assign({}, defaultLabelStyle, this.props.labelStyle || {});
        let wrapperStyle = Object.assign({}, defaultWrapperStyle, this.props.wrapperStyle || {});

        if (this.props.inline) {
            wrapperStyle = Object.assign({}, wrapperStyle, inlineWrapperStyle, this.props.wrapperStyle || {});
            textStyle = Object.assign({}, textStyle, inlineTextStyle, this.props.style || {});
            labelStyle = Object.assign({}, labelStyle, inlineLabelStyle, this.props.labelStyle || {})
        }

        return (
            <div style={wrapperStyle} className="common-textfield-wrapper">
                <div style={labelStyle}>
                    {this.renderLabel()}
                </div>

                <TextField
                    id={this.props.id}
                    disabled={this.props.disabled}
                    type={this.props.type}
                    style={textStyle}
                    underlinestyle={{ borderBottom: '1px solid #cdcdcd', height: '0.5px' }}
                    value={this.props.value}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    helperText={this.props.inline ? "" : this.props.errorText}
                    error={this.props.error}
                />

                {this.renderError()}
            </div>

        )
    }
}

export default BigIdTextField;
