import React from 'react';
import Strings from '../../constants/strings';
// Material UI Imports
import {CircularProgress} from '@material-ui/core';
import SectionTitle from '../SectionTitle';
import Alert from 'react-bootstrap/lib/Alert';
import Upload from '../../components/FileUpload';
import API from '../../API';
import validate from 'validate.js';

import { Radio, RadioGroup } from '@material-ui/core';
import { FormControl, FormControlLabel} from '@material-ui/core';

import UserLanguage from '../configurations/UserLanguage.js'
import ButtonSecondary from '../mui/ButtonSecondary';
import BigIdButton from '../mui/Button';

const language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";
 
const cardContainerStyle = {
    display: 'block',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxShadow: null
};


const columnStyle = {
    width: '350px'
};



class PocGenerate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            GroupName: "",
            UserName: "",
            type : ["BackgroundCheck PF", "BackgroundCheck PJ", "VerifyID", "VerifyID Forensic", "FaceMatch"],
            actualType: null,
            isValidDocument : true,
            pocDocument: null,
            isUploaded: false,
            fileInfo: null,
            generatingLoading: false,
            urlToGeneratePOC: null,
        }        
        
    }    
    

    // -- HANDLERS -- //
    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const newState = this.state;
        console.log(newState.type);
        newState.type[name] = value;
        console.log(newState.type);


        this.setState(newState);
    };

    onFileLoad = (stateField, actualType, e, file) => {
        const limitImageBytes = 10000000000
        
        if (actualType === null)
        {
            alert(Strings.generatePOC.SELECT_API_ALERT[language])
            return
        }

        if (actualType === "BackgroundCheck")
        {
            if (file.name.endsWith('.csv') || 
            file.name.endsWith('.jpg') ||
            file.name.endsWith('.png') ||
            file.name.endsWith('.jpeg') ||
            file.name.endsWith('.docx'))
            {
                this.setState( {isValidDocument:false})
                alert(Strings.generatePOC.ONLY_TXT_FILES_ALERT[language])
                return 
            }  
            if (file.size > limitImageBytes) {            
                this.setState({ isValidDocument: false })
                alert(Strings.generatePOC.FILE_MAX_SIZE_ALERT[language])
                return
            } 
        }
        if (actualType === "VerifyID" ||
            actualType === "VerifyID Forensic" ||
            actualType === "FaceMatch")
            {
                if (file.name.endsWith('.csv') || 
                file.name.endsWith('.jpg') ||
                file.name.endsWith('.png') ||
                file.name.endsWith('.jpeg') ||
                file.name.endsWith('.docx') ||
                file.name.endsWith('.txt'))
                {
                    this.setState( {isValidDocument:false})
                    alert(Strings.generatePOC.ONLY_ZIP_FILES_ALERT[language])
                    return 
                }  
            if (file.size > limitImageBytes) {            
                this.setState({ isValidDocument: false })
                alert(Strings.generatePOC.FILE_MAX_SIZE_ALERT[language])
                return
            }
        }            

        this.setState({fileInfo : file})
        let newState = this.state;
        newState[stateField] = e.target.result;
        this.setState(newState);
    };

    selectedType = (state, actual) => {   
        this.state.actualType = actual
        this.setState({ actualType: actual });
    }

    onGenerate = async () => {
        this.setState({ generatingLoading: true, event: null, answersError: null });

        let result;
        let PocType = this.state.actualType
        let fileURL = this.state.urlToGeneratePOC
        let Client = this.state.selectedDomain

        if (this.state.pocDocument) {
            
            // Generate POC
            alert(Strings.generatePOC.SUCCESS_ALERT[language]);
            result = await API.GeneratePOC(PocType, fileURL, Client);
            this.setState(
                {
                    event: result,                    
                    generatingLoading: false,
                }        
            );
        };
    }

    PocFileToS3 = async () => {
        this.setState({ generatingLoading: true, event: null});

        let result;

        if (this.state.pocDocument) {            
            // Generate POC
            const base64Prefix = "base64,";
            let pocDocument = this.state.pocDocument;
            pocDocument = pocDocument.substring(pocDocument.indexOf(base64Prefix) + base64Prefix.length);

            result = await API.PocFileToS3(this.state.fileInfo, this.state.actualType, pocDocument);
            this.setState(
                {
                    event: result,                    
                    generatingLoading: false,   
                    isUploaded: true,
                    urlToGeneratePOC : result.UrlPoc            
                }                  
            );
            alert(Strings.generatePOC.ALERT_FILE_SENT[language]);
        };
    }

    renderGenerateButton() {
        let labelStyle = { textTransform: "capitalize" };

        if (this.state.pocDocument != null && this.state.isUploaded === true) {
            return (
                <div>
                    <ButtonSecondary
                        label={Strings.generatePOC.POC_GENERATE[language]}
                        primary
                        labelStyle={labelStyle}
                        secondary
                        onClick={this.onGenerate}
                    />
                </div>
            );
        }
    }

    renderSendToS3Button() {
        let labelStyle = { textTransform: "capitalize" };

        if (this.state.pocDocument != null) {
            return (
                <div>
                    <BigIdButton
                        disabled = {this.state.isUploaded === true}
                        label={Strings.generatePOC.UPLOAD_FILE[language]}
                        labelStyle={labelStyle}
                        secondary
                        onClick={this.PocFileToS3}
                    />
                </div>
            );
        }
    }

    // -- RENDER -- //
    renderLoading(should){
        if(!should){
            return null;
        }

        return (
            <CircularProgress style={{marginLeft: "20px"}} thickness={3.5} size={35}/>
        )
    }

    renderConfirmation(){
        if(this.state.result){
            return (
                <Alert bsStyle="info">                    
                </Alert>
            )
        }
        return null;
    }
    

    render() {   
        return (
            <div style={cardContainerStyle}>
                <div style={columnStyle}>
                    <SectionTitle text={Strings.generatePOC.menu.CREATE_NEW_POC.TITLE}/>

                    <div style={{display: 'block'}} class="poc-radio-button">                                            
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="radioButtonTypes" onChange={this.handleChange} name={"Types"} >
                            {this.state.type.map((type) => {
                                return(
                                <FormControlLabel value={type} control={<Radio/>} label={type} onChange={this.selectedType.bind(null, this.state, type)}/>
                                )})}
                            </RadioGroup>
                        </FormControl>
                        
                        
                        {/* <RadioButtonGroup name={"Types"}>
                            {this.state.type.map((type) => {
                                return (                                    
                                    <RadioButton
                                        key={type}
                                        value={type}
                                        label={type}
                                        onClick={this.selectedType.bind(null, this.state, type)}                                    
                                    />
                                )
                        })}
                        </RadioButtonGroup>                     */}
                    
                    </div>

                    <br />
                    <br />

                    <div>
                        < Upload label={Strings.generatePOC.POC_FILE[language]} uploaded={this.state.pocDocument !== null} onFileLoad={this.onFileLoad.bind(this, "pocDocument", this.state.actualType)} />
                    </div>                    


                    {this.renderLoading(this.state.generatingLoading)}

                    {this.renderSendToS3Button()}  
                    <br></br>
                    <br></br>
                    {this.renderGenerateButton()}                      
                                 
                    {this.renderConfirmation()}
                </div>
            </div>
        )
    }
}


export default PocGenerate;
