// React Imports
import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom'

import Auth from '../Authentication';
import Strings from '../constants/strings';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.sessionData.isReportUser && props.location.pathname != Strings.routes.REPORTS ? 
    (
      <Redirect to={{
        pathname: '/relatorios',
        state: { from: props.location }
      }} />
    ) 
  : 
    Auth.isLogged() ? (<Component {...props}/>) :
    (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

export default PrivateRoute;
