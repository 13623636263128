import React from 'react';
import GoogleBtn from '../GoogleBtn';
import { Card, CardContent, Grow } from '@material-ui/core';
import { Button } from '@material-ui/core';
import BigIdButton from '../components/mui/Button';
import LoginTextField from '../components/mui/LoginTextField';
import SamlErrorsHandle from '../components/SamlErrorsHandle';
import { CircularProgress } from '@material-ui/core';
import { authProvider } from '../authProvider';
import { AzureAD } from 'react-aad-msal';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

// Import Bootstrap
import HeaderLogin from '../components/HeaderLogin';

import {
    Redirect,
} from 'react-router-dom'

import Auth from '../Authentication';
import colors from '../constants/colors';
import Strings from '../constants/strings';
import ApiConstants from '../constants/api';

import { connect } from 'react-redux';
import * as actions from '../actions';


import UserLanguage from '../components/configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gridGap: '25px',
    width: "25%",
    minHeight: '50%',
    maxHeight: '80%',
    margin: "0px auto",
    boxShadow: 'none',
    backgroundColor: "#f0f8fa"
};

const cardContentStyle = {
    justifySelf: 'center'
}

const cardTextContentStyle ={
    display: 'flex',
    flexDirection: 'column'
}

const cardTitleStyle = {
    textAlign: 'center',
    color: '#0069ff',
    fontWeight: 'bold',
    fontFamily: "'Picadilly', sans-serif"
}

const cardSpanStyle = {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
    color: '#474a51',
    fontSize: '16px',
    fontFamily: "'Be Vietnam', sans-serif",
    fontWeight: '500'
}

const cardLogoStyle ={
    width: '50%',
    margin: '0 25%',
    justifySelf: 'end'
}

const wrapperStyle = {
    backgroundColor: '#f0f8fa',
    height: "100%",
    width: "100%",
    position: "absolute",
    paddingBottom: 0,
};

const progressWrapperStyle = {
    display: "flex",
    width: '100px',
    height: '100px',
    justifyContent: "center",
    top: 'calc(50% - 50px)', left: 'calc(50% - 50px)', position: 'absolute'
};

const dialogStyle = {
    maxWidth: '300px'
};


const CLIENT_ID = '705076957724-hsof5htsjiielt860b3806vtpspku62v.apps.googleusercontent.com';

class Login extends React.Component {
    gotToken = false;
    tokenHoldInfo;
    microsoftClicked = false;
    forceLogin = false;

    constructor(props) {
        super(props);

        if(localStorage.getItem("saml-error")) {
            this.samlMessage = localStorage.getItem("saml-error");
            localStorage.removeItem("saml-error")
        }

        this.state = {
            redirectToReferrer: false,
            login: "",
            password: "",
            tokenSSO: null,
            nameSSO: null,
            openDialog: false,
            errors: [],
        }

    }

    login = async (e) => {
        e.preventDefault();
        this.setState({
            openDialog: true,
            loading: true,
            errors: [],
        });

        let result = await Auth.authenticate(this.state.login, this.state.password)

        if (result.status === ApiConstants.status.SUCCESS) {
            this.props.onLogin(result.configurations, result.questionsProperties, result.groupsNames);
            this.setState({ redirectToReferrer: true })
            
            //*************************//
            //Adding call to TrackMe API
            window.run("https://app-dev.bigdatacorp.com.br/TrackMeInformations",
                function(resultado) {
                    console.log('API chamada com sucesso:\n', resultado);
                },
                function(erro) {
                    console.log('Erro ao chamar a API:\n', erro);
                },
                "BigID-Site",
                {"Login": this.state.login} 
            );
        } else {
            this.setState({
                loading: false,
                dialogTitle: Strings.login.AUTHENTICATION_ERROR_TITLE[language],
                errors: result.errors
            });
        }
    };

    loginSSO = async () => {
        this.setState({
            openDialog: true,
            loading: true,
            errors: [],
        });

        var name = null;
        if (this.state.tokenSSO.profileObj != null) {
            name = this.state.tokenSSO.profileObj.email;
        } else {
            var name = this.state.tokenSSO.account.userName;
        }

        let result = await Auth.authenticateSSO(this.state.tokenSSO.accessToken, this.state.nameSSO, name)

        if (result.status === ApiConstants.status.SUCCESS) {
            this.props.onLogin(result.configurations, result.questionsProperties, result.groupsNames);
            this.setState({ redirectToReferrer: true })
        } else {
            this.setState({
                loading: false,
                dialogTitle: Strings.login.AUTHENTICATION_ERROR_TITLE[language],
                errors: result.errors
            });
        }
    }

    onFieldChange = (field, value) => {
        this.setState({
            [field]: value
        });
    };

    microsoftButtonClicked() {
        this.microsoftClicked = true;
    }

    renderLoginForm() {       
        return (
            <Card style={cardStyle}>
                <div style={cardTextContentStyle}>
                    <h1 style={cardTitleStyle}>Seja bem-vindo!</h1>
                    <span style={cardSpanStyle}>Faça o login com as suas credenciais.</span>
                </div>
                <CardContent style={cardContentStyle}>
                    <form onSubmit={this.login}>
                    <LoginTextField
                            id="txtUser"
                            placeholder={Strings.login.USERNAME_HINT[language]}
                            type="text"
                            onChange={(ev) => this.onFieldChange("login", ev.target.value)}
                            fullWidth={true}
                        />

                        <LoginTextField
                            id="txtPassword"
                            placeholder={Strings.login.PASSWORD_HINT[language]}
                            type="password"
                            onChange={(ev) => this.onFieldChange("password", ev.target.value)}
                            fullWidth={true}
                        />

                        <BigIdButton id="btnLogin" key="login" type="submit" onClick={this.login} label={Strings.login.LOGIN_BUTTON_TEXT[language]} />
                    </form>
                    <hr style={{ borderTop: '1px solid #A5ACB0' }}></hr>
                    <div id="sso-wrapper" style={{ marginTop: '20px', textAlign: 'center' }}>

                        <GoogleLogin
                            style={{fontFamily: "'Be Vietnam', sans-serif !important"}}
                            clientId={CLIENT_ID}
                            buttonText='Entrar com Google         '
                            onSuccess={this.doGoogleLogin.bind(this)}
                            onFailure={this.handleLoginFailure.bind(this)}
                            scope={[
                                "profile",
                                "email"
                            ].join(" ")}
                            cookiePolicy={'single_host_origin'}
                            responseType='code,token'
                        />

                        <AzureAD provider={authProvider}>
                            {({ login, logout, authenticationState, error, accountInfo }) => {
                                //ForceLogin
                                if (accountInfo != null && !this.microsoftClicked && !this.forceLogin) {
                                    this.forceLogin = true;
                                    authProvider.acquireTokenPopup({
                                        scopes: ["https://graph.microsoft.com/User.Read"]
                                    }).then((token) => {
                                        this.tokenHoldInfo = token;
                                        this.gotToken = true;
                                        this.doMicrosoftLogin(token);

                                    }).catch((err) => {
                                        //console.log("Catch", err);
                                        //Important to have this catch to avoid to have errors outside the page.
                                        return;
                                    })
                                }
                                if (this.microsoftClicked) {
                                    if (authenticationState == 'InProgress') {
                                    }
                                    if (authenticationState == 'Unauthenticated') {
                                    }
                                    if (authenticationState == 'Authenticated') {
                                        if (this.state.tokenSSO == null) {
                                            authProvider.acquireTokenPopup({
                                                scopes: ["https://graph.microsoft.com/User.Read"]
                                            }).then((token) => {
                                                this.tokenHoldInfo = token;
                                                this.gotToken = true;
                                                this.doMicrosoftLogin(token);

                                            }).catch((err) => {
                                                //Important to have this catch to avoid to have errors outside the page.
                                                return;
                                            })
                                        }
                                    }
                                }
                                return (
                                    <button className="btn-microsoft" onClick={() => { login(); this.microsoftButtonClicked() }}>
                                        <svg className="svg-microsoft" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><title>MS-SymbolLockup</title><rect x="1" y="1" width="9" height="9" fill="#f25022" /><rect x="1" y="11" width="9" height="9" fill="#00a4ef" /><rect x="11" y="1" width="9" height="9" fill="#7fba00" /><rect x="11" y="11" width="9" height="9" fill="#ffb900" /></svg>
                                        <span className="txt-microsoft">Entrar com Microsoft</span>
                                    </button>
                                );
                            }}
                        </AzureAD>
                    </div>

                </CardContent>
                <img style={cardLogoStyle} src='assets/bdc-logo-login.png'></img>
            </Card>
        )
    }

    doMicrosoftLogin(token) {
        this.setState({ tokenSSO: token, nameSSO: "MICROSOFT" })
        this.loginSSO();
    }

    doGoogleLogin(response) {
        this.setState({ tokenSSO: response, nameSSO: "GOOGLE" })
        this.loginSSO();
    }

    handleLoginFailure(response) {
        return;
    }


    renderErrors() {
        return this.state.errors.map((value) => {
            return value;
        });
    }

    renderDialog() {
        const actions = [
            <Button
                label="Ok"
                primary={true}
                onClick={() => {
                    this.setState({ ...this.state, openDialog: false })
                }}
            />,
        ];

        return (
            <div>
                {this.renderLoadingDialog()}
                {this.renderErrorDialog()}
            </div>
        )
    }

    renderLoadingDialog() {
        if (this.state.loading) {
            return (
                <div>
                    <div style={progressWrapperStyle}>
                        {this.state.loading ? <CircularProgress color="primary" size={80} thickness={5} /> : null}
                    </div>
                </div>
            )
        }
    }

    renderErrorDialog() {
        if (this.state.errors.length > 0) {
            return (
                <div>
                    <div className="login-popup-error">
                        {this.renderErrors()}
                    </div>
                </div>
            )
        }

    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer || Auth.isLogged()) {
            this.setState({
                redirectToReferrer: false,
                openDialog: false,
                loading: false
            });

            if (Auth.getSessionData().isReportUser && !Auth.getSessionData().isViewOnlyUser) {
                return (
                    <Redirect to='/relatorios' />
                )
            }
            else {
                return (
                    <Redirect to={from} />
                )
            }

        }

        let rowStyle = {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%'
        };

        return (
            <div style={wrapperStyle}>
                <SamlErrorsHandle message={this.samlMessage}/>
                <div style={rowStyle} className="login-wrapper">
                        {this.renderLoginForm()}
                        <HeaderLogin />
                </div>
                {this.renderDialog()}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (configurations, questionsProperties, groupsNames) => {
            dispatch(actions.selectConfigurations(configurations));
            dispatch(actions.storeQuestionsProperties(questionsProperties));
            dispatch(actions.storeGroupsNames(groupsNames));
        }
    }
};

const mapStateToProps = (state) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
