import { DialogActions, DialogContentText } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';
import Strings from '../constants/strings';
import UserLanguage from './configurations/UserLanguage.js';
let language = UserLanguage.getLanguage();

const progressWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

const dialogStyle = {};

class ProbabilityDialog extends React.Component {
    render() {
        if (!this.props.open) {
            return null
            //this.props.open = false;
        }

        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.open}
                onClose={this.props.handleRequestClose}
                aria-labelledby="questionnaire-dialog"
            >
            <DialogTitle id="questionnaire-dialog">
                {this.props.dialogTitle || String.APP_NAME}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={progressWrapperStyle}>
                        <h3>{Strings.PROBABILITY_DIALOG.RESTRICTIVE_QUESTIONNAIRE_TITLE[language]}</h3>

                        <p>
                            {Strings.PROBABILITY_DIALOG.RESTRICTIVE_QUESTIONNAIRE_TEXT[language]}
                        </p>

                        <strong>{Strings.PROBABILITY_DIALOG.WHY_TITLE[language]}</strong>

                        <p>
                            {Strings.PROBABILITY_DIALOG.WHY_TEXT[language]}
                        </p>

                        <p>
                            {Strings.PROBABILITY_DIALOG.SUGESTIONS_TITLE[language]}
                            <ul>
                                <li>{Strings.PROBABILITY_DIALOG.SUGESTIONS_TEXT.FIRST[language]}</li>
                                <li>{Strings.PROBABILITY_DIALOG.SUGESTIONS_TEXT.SEC[language]}</li>
                                <li>{Strings.PROBABILITY_DIALOG.SUGESTIONS_TEXT.THIRD[language]}</li>
                                <li>{Strings.PROBABILITY_DIALOG.SUGESTIONS_TEXT.FOURTH[language]}</li>
                            </ul>
                        </p>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {this.props.actions}
            </DialogActions>
            </Dialog>
        );
    }
}

export default ProbabilityDialog;