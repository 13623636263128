import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: "https://login.microsoftonline.com/common",
      //url Logon Azure AD https://login.microsoftonline.com/ea49e3fe-d4a8-4df5-bfb4-28b3ad193342/saml2
      //url Identified Azure AD https://sts.windows.net/ea49e3fe-d4a8-4df5-bfb4-28b3ad193342/
      //url Logoff https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0
      clientId: "0d3903ef-3195-4efc-b3fe-672ee6dd351d",
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
      logger: new Logger(
        (logLevel, message, containsPii) => {
          console.log("[MSAL]", message);
        },
        {
          level: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      )
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  },
  {
    scopes: ["profile", "email", "openid", "user.read"]
  },
  {
    loginType: LoginType.Popup,
    // // When a token is refreshed it will be done by loading a page in an iframe.
    // // Rather than reloading the same page, we can point to an empty html file which will prevent
    // // site resources from being loaded twice.
    // tokenRefreshUri: window.location.origin + "/auth.html"
  }
);
