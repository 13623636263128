import React from 'react';
import Webcam from 'react-webcam';

import {CircularProgress} from '@material-ui/core';

import API from '../API';
import ButtonSecondary from './mui/ButtonSecondary';



class WebcamCapture extends React.Component {

  state = {
    disabledPicture : false,
    cpf : '',
    birthdate: '',
    screenShot: '',
    photoTaken: false,
    resultReady: false
  }

   flexRowContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between"
  }

   flexItemstyle = {
    flex: 1,
    alignSelf: 'center'
  }

  webCamStyle = {
    "display": 'flex',
    "flex-direction":'column'
  }

  inputStyle = {
    "border-bottom": "1px solid red",
    "font-size": "20px",
    "margin": "10px"
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    if(this.state.cpf === "" || this.state.birthdate === ""){
      alert("Insira o CPF e a Data de Nascimento.");
      return;
    }
    const screenShot = this.webcam.getScreenshot();
    this.setState({screenShot, photoTaken: true, });
  };

  cleanState = () => {
    this.setState({screenShot: '', photoTaken: false, cpf: '', birthdate: '', resultReady: false})
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  async waitAndShowResult() {
    //Call here our Verify ID API

    let params = [];
    let faceImage = this.state.screenShot;
    let birthdate = this.state.birthdate;

    let cpf = this.state.cpf;

    const base64Prefix = "base64,";

    faceImage = faceImage.substring(faceImage.indexOf(base64Prefix) + base64Prefix.length);

    params.push(`FACE_IMG=${faceImage}`);
    params.push(`CPF=${cpf}`)
    params.push(`BIRTHDATE=${birthdate}`)

    await API.verifyID(params, this.props.selectedDomain);

    setTimeout(() => {
      this.setState({resultReady: true});
    }, 2000)
  }

  renderProgressAndResult() {
    if(!this.state.resultReady){

      //Call our site API and save it to our databases.
      this.waitAndShowResult();
      return (
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
          <CircularProgress style={{flex: 1}} thickness={3.5} size={50}/>
        </div>
      )
    } else {
      return(
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "20px"}}>
          Aprovado - Não consta na base
        </div>
      )
    }
  };

  renderWebcam() {
    const videoConstraints = {
      facingMode: "user"
    };


    if(!this.state.photoTaken){
      return (
        <Webcam
        audio={false}
        height={350}
        ref={this.setRef}
        screenshotFormat="image/jpeg"
        width={350}
        videoConstraints={videoConstraints}
      />
      )
    } else if(this.state.screenShot){
      return (
      <div style={this.flexRowContainerStyle}>
        <div  style={this.flexItemstyle}>
          <img
          display="block"
          height={350}
          width={350}
          margin={"10px"}
          src={this.state.screenShot}
          alt="image"
        >
          </img>
        </div>
        <div  style={this.flexItemstyle}>
          {this.renderProgressAndResult()}
        </div>
      </div>
      )
    };
  };


  render() {
    let style = { margin: "10px" };
    let labelStyle = { textTransform: "capitalize"};
    let lineStyle = {display: "flex",justifyContent: "space-between"};

    return (

      <div style={this.webCamStyle}>

      <div style={this.lineStyle}>
        {this.renderWebcam()}
      </div>
{/* 
      <div style={lineStyle}>
        <MaskedInput
            placeholder="Seu CPF"
            onChange={this.handleChange('cpf')}
            value={this.state.cpf}
            style={this.inputStyle}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/,/\d/, '-', /\d/, /\d/]}
          />

          <MaskedInput
            placeholder="Seu Nascimento"
            value={this.state.birthdate}
            onChange={this.handleChange('birthdate')}
            style={this.inputStyle}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          />
        </div> */}

        <ButtonSecondary
                        label="Tirar Selfie - Realizar Consulta"
                        style={style}
                        labelStyle={labelStyle}
                        disabled={this.state.photoTaken}
                        primary
                        onClick={this.capture}
                    />

        <ButtonSecondary
                        label="Limpar dados"
                        style={style}
                        labelStyle={labelStyle}
                        disabled={!this.state.resultReady || this.state.screenShot === ""}
                        primary
                        onClick={this.cleanState}
                    />
      </div>
    );
  }
}

export default WebcamCapture;