// React Imports
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'

// Import material-ui
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

// Import Views
import Login from './views/Login';
import Configurations from './views/Configurations';
import Reports from './views/Reports';
import Administration from './views/Administration';
import GeneratePOC from './views/GeneratePOC.js';
import Workspace from './views/Workspace';
import FaceValidation from './views/FaceValidation';
import HealthCheck from './views/HealthCheck';

// Import Constants
import Colors from './constants/colors';
import Strings from './constants/strings';

// Styles
import './styles/App.css';

// Private Route -- routes that need authentication
import PrivateRoute from './components/PrivateRoute';
// Redux
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Reducer from './reducers';

import Auth from './Authentication';
import API from './API';

import * as actions from './actions';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';





//This is not necessary anymore in react v16+. I will let this here if its necessary to return to react v15 again.
// Needed for onClick
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

// REDIRECT TO HTTPS
// Check if env is production and redirect to https if needed
// if(process.env.NODE_ENV === 'production'){
//     if (window.location.protocol !== 'https:')
//     {
//         window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
//     }
// }

// Configure Material UI theme
const muiTheme = getMuiTheme({
    fontFamily: 'HKGrotesk',
    palette: {
        primary1Color: Colors.primaryRed,
        accent1Color: Colors.primaryBlue,
    },
});


const wrapperStyle = {
    fontFamily: "HKGrotesk"
};

// If can not find a configuration in the localStorage
// logout the current user
if (!Auth.getCurrentConfigurations()) {
    //Auth.logout();
}


// Create the redux store with previous data
let store = createStore(Reducer, {
    configurations: Auth.getCurrentConfigurations(),
    questionsProperties: Auth.getQuestionsProperties(),
    groupsNames: Auth.getSessionData().groupsNames,
    refConfigurations: Auth.getSessionData().refConfigurations
});

//const sessionData = Auth.getSessionData();

let search = window.location.search;
let params = new URLSearchParams(search);
let token = params.get('token');
let login = params.get('login')
let error = params.get('error')

if (error) {
    localStorage.setItem("saml-error", error);
}

if (token) {
    if (localStorage.getItem('AUTH_OBJECT')) {
        Auth.logout();
    }
    API.loginParamSAML(token, login).then(res => {
        if (res.status !== "ERROR") {
            res.token = token;
            Auth.saveSessionDataInLocalStorage(res, login, res.encryptedPassword);
            setTimeout(function () { window.location.href = "/"; }, 500);
        } else {
            localStorage.setItem("saml-error", "InvalidSaml");
        }
    })
}

// Sync current configuration with server
if (Auth.isLogged()) {
    let configuration = Auth.getCurrentConfigurations()
    let groupNames = Auth.getSessionData().groupsNames;
    if (configuration == null || groupNames == null) {
        Auth.logout();
        window.location.reload(true)
    }
    let result = API.selectGroup(Auth.getCurrentConfigurations().Name, Auth.getCurrentConfigurations().Domain );

    result.then((result) => {
        if (result) {
            Auth.changeCurrentGroup(result);
            store.dispatch(actions.selectConfigurations(result.configurations));
        }
    });
}

class App extends Component {
    render() {
        return (
            <div>
                <NotificationContainer />
                <Provider store={store}>
                    <MuiThemeProvider muiTheme={muiTheme}>
                        <Router>
                            <div style={wrapperStyle}>
                                <Route exact path={Strings.routes.HEALTHCHECK} component={HealthCheck} />
                                <Route exact path={Strings.routes.LOGIN} component={Login} />
                                <PrivateRoute exact path="/" component={Configurations} />
                                <PrivateRoute path={Strings.routes.CONFIGURATIONS} component={Configurations} />
                                <PrivateRoute path={Strings.routes.REPORTS} component={Reports} />
                                <PrivateRoute path={Strings.routes.ADMIN} component={Administration} />
                                <PrivateRoute path={Strings.routes.POC} component={GeneratePOC} />
                                <PrivateRoute path={Strings.routes.WORKSPACE} component={Workspace} />
                                <PrivateRoute path={Strings.routes.SEARCHFACE} component={FaceValidation} />
                            </div>
                        </Router>
                    </MuiThemeProvider>
                </Provider>
            </div>
        );
    }
}

export default App;
