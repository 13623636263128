import React from 'react';

// Material UI Imports
import CircularProgress from 'material-ui/CircularProgress';

import DialogItem from './DialogItem'

import API from '../../API';
import SERPROAPI from '../../SERPROAPI'

import Auth from '../../Authentication';

import ButtonSecondary from '../mui/ButtonSecondary';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import BigIdToggle from '../mui/Toggle';


import Alert from 'react-bootstrap/lib/Alert';

import SectionTitle from '../../components/SectionTitle';
import Strings from '../../constants/strings';


import Upload from '../../components/FileUpload';

import { Card, CardHeader, CardText } from 'material-ui/Card';

// API
import validate from 'validate.js';
import Paper from 'material-ui/Paper';

import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const flexRowStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
};

const alignLeftflexRowStyle = {
    margin: "20px",
    display: "flex",
    justifyContent: "flex-start",
};

const inputsInterface = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const alertStyle = {
    marginTop: "20px"
};

const eventStatusStyle = {
    marginTop: "20px"
};


class DocumentValidationSERPRO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serproResponse: null,
            serproFaceMatchResponse: null,
            generatingSerproLoading: false,
            disableFaceMatch: false,
            disableSerpro: false,
            disableVerifyId: false,
            showModal: false,
            configurations: this.props.configurations,
            isValidaImageSize: true,
            limitImageSizeReached: false,
            key: "CPF",
            value: '',
            generatingLoading: false,
            getStatusLoading: false,
            faceRecognition: false,
            ticketId: "",
            serproConsultError: false,

            documentImage: null,
            faceImage: null,
            serproParams: {
                "CPF": "",
                "NAME": "",
                "BIRTHDATE": "",
                "MOTHERNAME": "",
                "FATHERNAME": "",
                "CNHNUMBER": "",
                "FIRSTQUALIFICATIONDATE": "",
                "VALIDDATE": "",
            }
        }
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.input.current.value);
        event.preventDefault();
    }

    handleOpenModal() {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    onKeyChange = (field, event, index, value) => {
        let newState = this.state;
        newState[field] = value;
        this.setState(newState);
    };

    onValueChange = (field, event, value) => {
        let newState = this.state;
        newState[field] = value;
        this.setState(newState);
    };

    onSERPROConsult = async () => {
        this.setState({ generatingSerproLoading: true, disableSerpro: true })
        let response;

        const { serproParams, ticketId } = this.state

        try {
            response = await SERPROAPI.validatePf(serproParams, ticketId);
        } catch (e) {
            this.setState({ serproConsultError: true, generatingSerproLoading: false, serproResponse: null })
            return
        }


        if (!response.success) {
            this.setState({ serproConsultError: true, generatingSerproLoading: false, serproResponse: null })
            return
        }

        this.setState({ generatingSerproLoading: false, serproResponse: response, serproConsultError: false })
    }

    onSERPROFaceMatchConsult = async () => {
        this.setState({ generatingSerproFaceMatchLoading: true, disableFaceMatch: true })

        const base64Prefix = "base64,";

        const { serproParams, ticketId, faceImage } = this.state

        if (faceImage) {

            let faceImageParameter = faceImage.substring(faceImage.indexOf(base64Prefix) + base64Prefix.length);

            const serproFaceParams = {
                CPF: serproParams.CPF,
                FACEIMAGE: faceImageParameter
            }

            let faceMatchResponse = null;

            try {
                faceMatchResponse = await SERPROAPI.validateFacePf(serproFaceParams, ticketId);
            } catch (e) {
                this.setState({ serproFaceMatchError: true, generatingSerproFaceMatchLoading: false, serproFaceMatchResponse: faceMatchResponse })
                return
            }

            if (!faceMatchResponse.success) {
                this.setState({ serproFaceMatchError: true, generatingSerproFaceMatchLoading: false, serproFaceMatchResponse: null })
                return
            } else {
                this.setState({ generatingSerproFaceMatchLoading: false, serproFaceMatchResponse: faceMatchResponse, serproFaceMatchError: false })
            }

        }



    }

    onGenerate = async () => {
        this.setState({ generatingLoading: true, questionsEvent: null, answersError: null, disableSerpro: false, disableFaceMatch: false });

        let result;

        let params = [];

        if (this.state.value) {
            params.push(`${this.state.key}=${this.state.value}`);
        }

        const base64Prefix = "base64,";

        if (this.state.documentImage) {

            let documentImage = this.state.documentImage;
            documentImage = documentImage.substring(documentImage.indexOf(base64Prefix) + base64Prefix.length);
            params.push(`DOC_IMG=${documentImage}`);

            result = await API.verifyID(params, this.props.selectedDomain);

            if (result.DocInfo) {
                if (result.DocInfo.CPF) {
                    const serproParams = { ...result.DocInfo }
                    this.setState({ value: result.DocInfo.CPF, serproParams });
                }
            }

        } else {
            result = await API.getQuestions(params, this.props.selectedDomain);
        }


        this.setState(
            {
                questionsEvent: result,
                generatingLoading: false,
                bigIdEvent: null,
                answers: new Array(result.Questions.length),
                serproResponse: null,
                ticketId: result.TicketId
            }
        );
    };

    onGetEventStatus = async () => {
        this.setState({ getStatusLoading: true });
        let events = await API.getEvent(this.state.answersEvent.TicketId, this.props.selectedDomain);
        if (events.ResultCode === -605) {
            this.setState({ bigIdEvent: events.Events[0] });
        }
        this.setState({ getStatusLoading: false });
    };

    onAnswered = (index, event, value) => {
        let answers = this.state.answers;
        answers[index] = value;
        this.setState({ answers: answers });
    };

    onSendAnswers = async () => {
        // Check if number of answers different of null
        // is different to number of questions
        // so it cannot be send
        if (this.state.answers.filter((x) => x !== null).length !== this.state.questionsEvent.Questions.length) {
            this.setState({ answersError: "Por favor responda todas as perguntas." });
            return;
        }

        this.setState({ generatingLoading: true });

        let result = await API.sendAnswers(this.state.questionsEvent.TicketId, this.state.answers, this.props.selectedDomain);

        this.setState({ answersEvent: result, generatingLoading: false, questionsEvent: null }, () => {
            if (this.state.answersEvent) {
                this.onGetEventStatus();
            }
        });

    };

    // -- RENDER -- //
    renderLoading(should) {
        if (!should) {
            return null;
        }
        return (
            <CircularProgress style={{ marginLeft: "20px" }} thickness={3.5} size={35} />
        )
    }

    renderEventStatus() {
        if (!this.state.bigIdEvent) {
            return null;
        }

        const bigIdEvent = this.state.bigIdEvent;

        return (
            <div>
                <Alert style={eventStatusStyle}>
                    <h3>Status: {bigIdEvent.Status}</h3>
                    <h4>Message: {bigIdEvent.Message}</h4>
                </Alert>

                {bigIdEvent.Questions.map((question) => {
                    return (
                        <Paper style={{ marginBottom: 30, padding: 15 }} zDepth={2}>
                            <h4>{question.Text}</h4>
                            <h5>Respondida Corretamente: {question.Result ? "Sim" : "Não"}</h5>
                            <div>Resposta Correta: {question.Correct}</div>
                            <div>Resposta dada pelo usuário: {question.Answer}</div>
                        </Paper>
                    )
                })}
            </div>
        )
    }

    renderEventStatusInterface() {
        return (
            <div>
                {this.renderLoading(this.state.getStatusLoading)}

                {this.renderEventStatus()}
            </div>
        )
    }

    renderAlert() {
        if (this.state.questionsEvent) {
            if (this.state.questionsEvent.ResultCode !== 0 && this.state.questionsEvent.ResultCode !== 70) {
                return (
                    <Alert bsStyle="danger" style={alertStyle}>
                        <h4> Código: {this.state.questionsEvent.ResultCode}</h4>
                        <p>  Mensagem: {this.state.questionsEvent.ResultMessage}</p>
                    </Alert>
                )
            }
        }
    }

    renderSerproError() {
        if (this.state.serproConsultError) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <h4> Erro ao consultar a API SERPRO - /validate/pf.</h4>
                    <h5>Verifique o preenchimento dos dados do documento.</h5>
                </Alert>
            )
        }
    }

    renderSerproFaceMatchError() {
        if (this.state.serproFaceMatchError) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <h4> Erro ao consultar a API SERPRO  /validate/pf-face .</h4>
                    <h5>Verifique o preenchimento do CPF e da Imagem do Rosto.</h5>
                </Alert>
            )
        }
    }

    renderImageSizeWarning() {
        if (!this.state.isValidaImageSize && !this.state.limitImageSizeReached) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <p> {Strings.generalConfigurations.PHOTO_SIZE_WARNING[language]}</p>
                </Alert>
            )

        } else if (!this.state.isValidaImageSize && this.state.limitImageSizeReached) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <p> {Strings.generalConfigurations.MAX_PHOTO_SIZE_WARNING[language]}</p>
                </Alert>
            )

        }

    }

    onFileLoad = (stateField, e, file) => {
        const bigAcceptedImageBytes = 1000000//4000000
        const limitImageBytes = 4000000


        if (file.size > bigAcceptedImageBytes && file.size < limitImageBytes) {
            this.setState({ isValidaImageSize: false })
            return
        } else if (file.size > limitImageBytes) {
            this.setState({ isValidaImageSize: false, limitImageSizeReached: true })
            return
        }

        let newState = this.state;
        newState[stateField] = e.target.result;
        this.setState(newState);
    };

    renderFaceImageInput() {
        if (this.state.faceRecognition) {
            return (
                <div>
                    <Upload label="Foto do rosto" uploaded={this.state.faceImage !== null} onFileLoad={this.onFileLoad.bind(this, "faceImage")} />
                </div>
            )
        }

        return null;
    }

    renderOcrRequestInterface() {
        if (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) {
            return null;
        }
        return (
            <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "40%" }}>
                    <div style={flexRowStyle}>
                        <div>
                            <Upload label="Foto do documento" uploaded={this.state.documentImage !== null} onFileLoad={this.onFileLoad.bind(this, "documentImage")} />
                        </div>
                    </div>
                </div>
                <div style={{ float: "right", width: "60%" }}>
                    {this.renderGenerateButton()}
                </div>
            </div>

        )
    }

    renderSerproFaceMatchInterface() {
        return (
            <div style={alignLeftflexRowStyle}>
                <div >
                    <BigIdToggle label="Reconhecimento Facial" toggled={this.state.faceRecognition} 
                        onToggle={(event, toggled) => {
                            this.setState({ faceRecognition: toggled, faceImage: null });
                        }}
                    />
                </div>
                <div style={flexRowStyle}>
                    {this.renderFaceImageInput()}
                </div>
                <div style={flexRowStyle}>
                    {this.renderFaceMatchButton()}
                </div>
            </div>
        )
    }

    renderFaceMatchButton() {
        let style = { margin: "10px" };
        let labelStyle = { textTransform: "capitalize" };

        if (this.state.faceImage != null) {
            return (
                <div>
                    <ButtonSecondary
                        label="Consultar SERPRO Facematch"
                        style={style}
                        disabled={this.state.disableFaceMatch}
                        onClick={this.onSERPROFaceMatchConsult}
                    />

                    {this.renderLoading(this.state.generatingSerproFaceMatchLoading)}
                </div>

            );
        }
    }

    renderGenerateButton() {
        let style = { margin: "10px" };
        let labelStyle = { textTransform: "capitalize" };

        if (this.state.documentImage != null) {
            return (
                <div>
                    <ButtonSecondary
                        label="Extrair Informações"
                        style={style}
                        onClick={this.onGenerate}
                    />
                </div>
            );
        }
    }


    handleChange(event, keyValue) {
        event.preventDefault();

        const serproParams = { ...this.state.serproParams }

        serproParams[keyValue] = event.target.value

        this.setState({ serproParams });
    }

    renderDocumentInformations(docInfo) {
        let fields = Object.keys(docInfo);
        const allowedParams = [
            "CPF",
            "NAME",
            "BIRTHDATE",
            "MOTHERNAME",
            "FATHERNAME",
            "CNHNUMBER",
            "FIRSTQUALIFICATIONDATE",
            "VALIDDATE",
        ];


        //This line filters only the allowed params:
        fields = fields.filter(x => allowedParams.indexOf(x) !== -1);

        //Sort by allowedParams order: Ex: CPF will be the first shown parameter
        let inputStyle = { margin: "5px", width: "500px", alignSelf: "right" };
        let style = { margin: "10px" };
        let labelStyle = { textTransform: "capitalize" };

        return (
            <div>
                {allowedParams.map(field => {
                    return (
                        <div key={field}>
                            <label>
                                {field}:
                            <input type="text" style={inputStyle} value={this.state.serproParams[field] ? this.state.serproParams[field] : ""} onChange={(e) => this.handleChange(e, field)} />
                            </label>

                        </div>
                    )
                })}
                <div>
                    <ButtonSecondary
                        label="Consultar SERPRO"
                        style={style}
                        disabled={this.state.disableSerpro}
                        onClick={this.onSERPROConsult}
                    />

                    {this.renderLoading(this.state.generatingSerproLoading)}
                </div>
            </div>
        )
    }

    renderDocInfo() {
        if (this.state.serproParams) {
            return (
                <Card initiallyExpanded={true}>
                    <CardHeader
                        title={(
                            <div>
                                <h4>Informações do Documento</h4>
                            </div>

                        )}
                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardText expandable={true}>
                        {this.renderDocumentInformations(this.state.serproParams)}
                    </CardText>
                </Card>
            )
        }
    }


    renderSERPROResponse() {

        let containerStyle = { display: "flex", flex: 1, flexDirection: "row", margin: "10px", alignItems: "center", justifyContent: "left" }
        let valueStyle = { margin: "5px", flex: 1 };
        let labelStyle = { textTransform: "capitalize", flex: 1, fontWeight: "bold" };

        if (this.state.serproResponse) {

            let renderSerproFiliation, renderSerproCnh;
            const { serproResponse } = this.state


            if (serproResponse.filiacao) {
                renderSerproFiliation = Object.keys(serproResponse.filiacao).map(property =>
                    <div key={property} style={containerStyle}>
                        <div style={labelStyle}>
                            {property}:
                    </div>
                        <div style={valueStyle}>{serproResponse.filiacao[property].toString()} </div>
                    </div>
                )
            }

            if (serproResponse.cnh) {
                renderSerproCnh = Object.keys(serproResponse.cnh).map(property =>
                    <div key={property} style={containerStyle}>
                        <div style={labelStyle}>
                            {property}:
                    </div>
                        <div style={valueStyle}>{serproResponse.cnh[property].toString()} </div>
                    </div>
                )
            }



            const renderSerproResponse = Object.keys(serproResponse).map(property => {
                if (property === 'filiacao') return null;
                if (property === 'cnh') return null;
                if (typeof (serproResponse[property] === 'boolean')) {
                    return (
                        <div key={property} style={containerStyle}>
                            <div style={labelStyle}>
                                {property}:
                                </div>
                            <div style={valueStyle}>{serproResponse[property].toString()} </div>
                        </div>
                    )
                }

                return null;
            });

            return (
                <Card initiallyExpanded={true}>
                    <CardHeader
                        title={(
                            <div>
                                <h4>Consulta SERPRO - DADOS CNH - RESULTADO</h4>
                            </div>

                        )}

                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardText expandable={true} style={{ display: "flex", flexWrap: "wrap" }}>
                        {renderSerproResponse}
                        {renderSerproFiliation}
                        {renderSerproCnh}
                    </CardText>
                </Card>
            )
        } else {
        return (
        <div style={containerStyle}>
            <div style={labelStyle}>
                Erro ao consultar a API SERPRO:
                </div>
                <div style={valueStyle}>Verifique a situação contratual. </div>
                </div>
        )}
    }

    renderSERPROFaceMatchResponse() {

        let containerStyle = { display: "flex", flex: 1, flexDirection: "row", margin: "10px", alignItems: "center", justifyContent: "left" }
        let valueStyle = { margin: "5px", flex: 1 };
        let labelStyle = { textTransform: "capitalize", flex: 1, fontWeight: "bold" };

        let renderSerproFaceResponse;

        if (this.state.serproFaceMatchResponse) {

            const { serproFaceMatchResponse } = this.state

            if (serproFaceMatchResponse.biometria_face) {
                renderSerproFaceResponse = Object.keys(serproFaceMatchResponse.biometria_face).map(property =>
                    <div key={property} style={containerStyle}>
                        <div style={labelStyle}>
                            {property}:
                    </div>
                        <div style={valueStyle}>{serproFaceMatchResponse.biometria_face[property].toString()} </div>
                    </div>
                )
            }

            return (
                <Card initiallyExpanded={true}>
                    <CardHeader
                        title={(
                            <div>
                                <h4>Consulta SERPRO - FACEMATCH - RESULTADO</h4>
                            </div>

                        )}

                        actAsExpander={true}
                        showExpandableButton={true}
                    />
                    <CardText expandable={true} style={{ display: "flex", flexWrap: "wrap" }}>
                        {renderSerproFaceResponse}
                    </CardText>
                </Card>
            )
        } else {
        return (
            <div style={containerStyle}>
                <div style={labelStyle}>
                    Erro ao consultar a API SERPRO - Facematch:
            </div>
                <div style={valueStyle}>Verifique o preenchiemnto do CPF e da Foto do Rosto. </div>
            </div>
        )}
    }

    render() {
        let demoTitle = (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) ? "Questionário" : "OCR de Documento";

        const { REMOVE_PROTECTION, REMOVE_NOISE, ADJUST_FOLDING, ADJUST_ROTATION, ADJUST_CAMERA_ROTATION, ADJUST_BRIGHTNESS, ADJUST_FOCUS, ADJUST_AREA } = Strings.validatingDocumentsTooltips


        const actions = [
            <ButtonSecondary
                label={Strings.generalConfigurations.BACK[language]}
                onClick={() => this.handleCloseModal()}
            />,
        ];

        return (
            <div>
                <SectionTitle text={demoTitle} handleOpenModal={() => this.handleOpenModal()} />

                <div style={inputsInterface}>
                    {this.renderOcrRequestInterface()}
                    {this.renderLoading(this.state.generatingLoading)}
                </div>

                <div>
                    {this.renderDocInfo()}
                </div>

                <div>
                    {this.renderSERPROResponse()}
                </div>

                <div>
                    {this.renderSerproFaceMatchInterface()}
                </div>

                <div>
                    {this.renderSERPROFaceMatchResponse()}
                </div>

                <div>
                    {this.renderSerproError()}
                </div>

                <div>
                    {this.renderSerproFaceMatchError()}
                </div>

                <div>
                    {this.renderAlert()}
                </div>

                <div>
                    {this.renderImageSizeWarning()}
                </div>
                <div>
                    <Dialog
                        open={this.state.showModal}
                        onClose={() => this.handleCloseModal()}
                        autoScrollBodyContent={true}
                        aria-labelledby="DocumentValidationDialog"
                    >
                        <DialogTitle id="DocumentValidationDialog">
                            {Strings.validatingDocumentsTooltips.TOOLTIP_TITLE[language]}
                            <IconButton aria-label="close" onClick={() => this.handleCloseModal()} style={{left: "15%"}}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <DialogItem title={REMOVE_PROTECTION.title[language]} text={REMOVE_PROTECTION.text[language]} image={REMOVE_PROTECTION.image} order={2} />
                            <DialogItem title={REMOVE_NOISE.title[language]} text={REMOVE_NOISE.text[language]} image={REMOVE_NOISE.image} order={2} />
                            <DialogItem title={ADJUST_FOLDING.title[language]} text={ADJUST_FOLDING.text[language]} image={ADJUST_FOLDING.image} order={2} />
                            <DialogItem title={ADJUST_ROTATION.title[language]} text={ADJUST_ROTATION.text[language]} image={ADJUST_ROTATION.image} order={2} />
                            <DialogItem title={ADJUST_CAMERA_ROTATION.title[language]} text={ADJUST_CAMERA_ROTATION.text[language]} image={ADJUST_CAMERA_ROTATION.image} order={2} />
                            <DialogItem title={ADJUST_BRIGHTNESS.title[language]} text={ADJUST_BRIGHTNESS.text[language]} image={ADJUST_BRIGHTNESS.image} order={2} />
                            <DialogItem title={ADJUST_FOCUS.title[language]} text={ADJUST_FOCUS.text[language]} image={ADJUST_FOCUS.image} order={2} />
                            <DialogItem title={ADJUST_AREA.title[language]} text={ADJUST_AREA.text[language]} image={ADJUST_AREA.image} order={2} />
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default DocumentValidationSERPRO;