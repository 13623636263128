import React from 'react';

// Material UI Imports
import TextField from '../mui/TextField';
import {MenuItem} from '@material-ui/core';
import SelectField from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Card, CardHeader, CardText } from 'material-ui/Card';

import API from '../../API';

import Auth from '../../Authentication';

import Alert from 'react-bootstrap/lib/Alert';

import SectionTitle from '../../components/SectionTitle';



// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import validate from 'validate.js';
import Paper from 'material-ui/Paper';
import Strings from '../../constants/strings';
import UserLanguage from '../configurations/UserLanguage.js';
import ButtonSecondary from '../mui/ButtonSecondary';

let language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const flexRowStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
};

const docInfoTitle = {
    fontSize: "15px",
    color: "rgb(0, 145, 137)",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px"
}

const inputsInterface = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const requiredFieldStyle = {
    width: "100px",
    marginRight: "10px",
    marginBottom: "18px"
};

const alertStyle = {
    marginTop: "20px"
};

const eventStatusStyle = {
    marginTop: "20px"
};
const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}


class DemonstrationBGC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            key: "CPF",
            value: '',
            generatingLoading: false,
            getStatusLoading: false,
            faceImage: null,
            bgcInfo: null,
            interval: false,
            countInterval: 1,
            idInterval: null,
            errorStatus: false,
            infoWithoutBoost: null
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval); // Always clean up before unmounting
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleInfoWithoutBoost = () => {

        this.renderAlertWithoutBoost()
        
    };

    onKeyChange = (field, event, index, value) => {
        let newState = this.state;
        newState[field] = event.target.value;
        this.setState(newState);
    };

    onValueChange = (field, event) => {
        if (!event.target.value.match(/[a-z]/i)) {
            let newState = this.state;
            newState[field] = event.target.value;
            this.setState(newState);
        }
    };

    onGenerate = async () => {
        this.setState({ generatingLoading: true, questionsEvent: null, answersError: null });
        let result;
        this.state.interval = true;
        let params = [];
        let bgcKeys = [];

        if (this.state.value) {
            params.push(`${this.state.key}=${this.state.value}`);
        }

        this.countInterval = 0;
        clearInterval(this.countInterval);

        if (this.state.interval && !this.state.scoreDetails) {
            this.setState({ countInterval: 0 })
            this.countInterval = setInterval(() => {
                if (this.state.interval) {
                    this.countInterval = this.countInterval + 1;
                    this.setState({ countInterval: this.countInterval })
                }
            }, 1000);
        }

        result = await API.getBgcResult(params, this.props.selectedDomain);
 
        // 1 - Check if result returns empty
        if (result === null ||
            result === undefined ||
            result === "")
        {
            this.setState({errorStatus : true, interval: false, generatingLoading: false})
            return;
        }

        if (result.status === "ERROR") {
            this.setState({
                errorStatus: true,
                interval: false,
                generatingLoading: false,
                resultMessage: result.errors
            })
            return;
        }

        // 2 - Check if BGC contains only BigBoost data
        bgcKeys = Object.keys(result)
        if (!bgcKeys.includes("ScoreDetails"))
        {
            this.setState({
                code: result.ResultCode,
                resultMessage: result.ResultMessage,
                score: result.Score,
                limitScore: result.LimitScore,
                generatingLoading: false,
                interval: false,
                infoWithoutBoost: true,
                score: result.Score
            })
            this.handleInfoWithoutBoost()
            return;
        }
        
        // 3 - If we arrive here, BGC has complete information
        for (var related in result.RelatedScoreDetails)
        {
            if("PROCESS_DETAILS" in result.RelatedScoreDetails[related]){
                delete result.RelatedScoreDetails[related]["PROCESS_DETAILS"]
            }
        }    
        
        this.countInterval = 0;
        clearInterval(this.countInterval);

        //removed the process details object to prevent error in bgc demo render
        delete result.ScoreDetails["PROCESS_DETAILS"]
        
        this.setState(
            {
                bgcEvent: result,
                generatingLoading: false,
                bigIdEvent: null,
                ticketId: result.TicketId,
                code: result.ResultCode,
                resultMessage: result.ResultMessage,                
                limitScore: result.LimitScore,             
                relatedScoreDetails: result.RelatedScoreDetails,                
                interval: false,
                infoWithoutBoost: false, 
                score: result.Score,
                scoreDetails: result.ScoreDetails,                
                bgcInfo: result.RawData,   
                answers: new Array(result.RawData.length)         
            }
        );
    };

    // -- RENDER -- //
    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} thickness={3.5} size={35} />
        )
    }

    renderPhrases(countInterval, interval) {
        if (countInterval !== 0 && interval === true) {

            if (countInterval !== undefined) {
                if (countInterval > 50 && countInterval < 80) {
                    return Strings.generalConfigurations.demonstration.BGC_DEMO_LOAD_ALERT.FIRST[language]
                }
                if (countInterval > 81 && countInterval < 120) {
                    return Strings.generalConfigurations.demonstration.BGC_DEMO_LOAD_ALERT.MID[language]
                }
                if (countInterval > 121) {
                    return Strings.generalConfigurations.demonstration.BGC_DEMO_LOAD_ALERT.LAST[language]
                }
            }                 
        }
    }

    renderEventStatus() {
        if (!this.state.bigIdEvent) {
            return null;
        }

        const bigIdEvent = this.state.bigIdEvent;

        if (this.state.bigIdEvent.IsTelemarketingEvent) {
            return (
                <div>
                    <Alert id="alert" style={eventStatusStyle}>
                        <h3>Status: {bigIdEvent.Status}</h3>
                        <h4>Message: {bigIdEvent.Message}</h4>
                    </Alert>

                    {bigIdEvent.Questions.map((question) => {
                        return (
                            <Paper style={{ marginBottom: 30, padding: 15 }} zDepth={2}>
                                <h4>{question.Text}</h4>
                                <h5>Respondida Corretamente: {question.Result ? "Sim" : "Não"}</h5>
                                <div>Resposta dada pelo usuário: {question.Answer}</div>
                            </Paper>
                        )
                    })}
                </div>
            )
        }
        else {
            return (
                <div>
                    <Alert id="alert" style={eventStatusStyle}>
                        <h3>Status: {bigIdEvent.Status}</h3>
                        <h4>Message: {bigIdEvent.Message}</h4>
                    </Alert>

                    {bigIdEvent.Questions.map((question) => {
                        return (
                            <Paper style={{ marginBottom: 30, padding: 15 }} zDepth={2}>
                                <h4>{question.Text}</h4>
                                <h5>Respondida Corretamente: {question.Result ? "Sim" : "Não"}</h5>
                                <div>Resposta Correta: {question.Correct}</div>
                                <div>Resposta dada pelo usuário: {question.Answer}</div>
                            </Paper>
                        )
                    })}
                </div>
            )
        }        
    }

    renderErrorAlert(){    
        if(this.state.errorStatus)
        {
            this.countInterval = 0;
            clearInterval(this.countInterval);            
        
        return(
            <Alert id="alert" bsStyle="danger" style={alertStyle}>
                <h4> {Strings.generalConfigurations.demonstration.BGC_DEMO_NOINFO_ALERT[language]} {this.state.code}</h4>
                <p> {Strings.generalConfigurations.demonstration.BGC_DEMO_NOINFO_ADVICE[language]} {this.state.resultMessage}</p>                
            </Alert>
            )
        }
    }

    renderAlertWithoutBoost(){
        if (this.state.infoWithoutBoost)
        {
            if (this.state.code === -1100 || this.state.code === -1101) {
                return (
                    <Alert id="alert" bsStyle="danger" style={alertStyle}>
                        <h4 id="txtCode"> {Strings.generalConfigurations.demonstration.BGC_DEMO_CODE[language]}: {this.state.code}</h4>
                        <p id="txtMsg"> {Strings.generalConfigurations.demonstration.BGC_DEMO_MSG[language]}: {this.state.resultMessage}</p>
                        <p> Score: {this.state.score}</p>
                        <p> Limit Score: {this.state.limitScore}</p>
                    </Alert>
                )
            } else {
                //sucessful alert when facematch works
                if (this.state.code === 90) {
                    return (
                        <Alert id="alert" bsStyle="success" style={alertStyle}>
                            <h4 id="txtCode"> {Strings.generalConfigurations.demonstration.BGC_DEMO_CODE[language]}: {this.state.code}</h4>
                            <p id="txtMsg"> {Strings.generalConfigurations.demonstration.BGC_DEMO_MSG[language]}: {this.state.resultMessage}</p>
                            <p> Score: {this.state.score}</p>
                            <p> Limit Score: {this.state.limitScore}</p>
                        </Alert>
                    )
                }
            }
        }
    }
    
    renderAlert() {        
        if (this.state.scoreDetails) {    
            clearInterval(this.countInterval);        
            if (this.state.code === -1100 || this.state.code === -1101) {
                return (
                    <Alert id="alert" bsStyle="danger" style={alertStyle}>
                        <h4 id="txtCode"> {Strings.generalConfigurations.demonstration.BGC_DEMO_CODE[language]}: {this.state.code}</h4>
                        <p id="txtMsg"> {Strings.generalConfigurations.demonstration.BGC_DEMO_MSG[language]}: {this.state.resultMessage}</p>
                        <p> Score: {this.state.score}</p>
                        <p> Limit Score: {this.state.limitScore}</p>
                    </Alert>
                )
            } else {
                //sucessful alert when facematch works
                if (this.state.code === 90) {
                    return (
                        <Alert id="alert" bsStyle="success" style={alertStyle}>
                            <h4 id="txtCode"> {Strings.generalConfigurations.demonstration.BGC_DEMO_CODE[language]}: {this.state.code}</h4>
                            <p id="txtMsg"> {Strings.generalConfigurations.demonstration.BGC_DEMO_MSG[language]}: {this.state.resultMessage}</p>
                            <p> Score: {this.state.score}</p>
                            <p> Limit Score: {this.state.limitScore}</p>
                        </Alert>
                    )
                }
            }
        }
    }

    renderCpfRequestInterface(){
        let items = null
        if (!Auth.sessionData.isTelemarketing || !Auth.sessionData.isViewOnlyUser) {
            items = <MenuItem style={styleMenuItem} value={"CNPJ"} > CNPJ </MenuItem>
        }
        return (
            <div style={flexRowStyle}>
                <SelectField
                    style={requiredFieldStyle}
                    value={this.state.key}
                    onChange={this.onKeyChange.bind(null, 'key')}
                >
                    <MenuItem style={styleMenuItem} value={"CPF"}> CPF </MenuItem>
                    {items}
                </SelectField>

                <TextField
                    id="txtCPF"
                    hintText={Strings.generalConfigurations.demonstration.ONLY_NUMBERS_HINT[language]}
                    type="text"
                    name="value"
                    value={this.state.value}
                    onKeyDown={(e) => { console.log(e) }}
                    onChange={this.onValueChange.bind(null, 'value')}
                />

                <div style={{ marginLeft: "47%", padding: "-10%" }}>
                    <ButtonSecondary
                        id="btnSimulate"
                        label={Strings.generalConfigurations.demonstration.GENERATE[language]}
                        style={{ textTransform: "none", marginBottom: "20px"}}
                        onClick={this.onGenerate}
                    />
                </div>
            </div>
        )
    }

    onFileLoad = (stateField, e) => {
        let newState = this.state;
        newState[stateField] = e.target.result;
        this.setState(newState);
    };

    renderDocumentInformations(scoreDetails) {
        console.log('renderDocumentInformations');
        let fields = Object.keys(scoreDetails);

        const allowedParams = [];
        const scoreKeys = Object.keys(scoreDetails);
        const scoreValues = Object.values(scoreDetails);

        for (let i = 0; i < scoreKeys.length; i++) {
            allowedParams.push(scoreKeys[i] + scoreValues[i]);
        }

        //Sort by allowedParams order: Ex: DOCTYPE will be the first shown parameter
        //New params will be showed in last
        const sortedFields = fields.sort(function (a, b) {
            var indexA = allowedParams.indexOf(a)
            var indexB = allowedParams.indexOf(b);
            if (indexA == -1) {
                indexA = 100;
            }
            if (indexB == -1) {
                indexB = 100;
            }
            return indexA - indexB;
        });

        return (
            <div>
                <h3 style={docInfoTitle}>{Strings.generalConfigurations.demonstration.BGC_DEMO_RESULT_TITLE[language]}</h3>
                {sortedFields.map(field => {
                    let value = scoreDetails[field];

                    return (
                        <div style={flexRowStyle}>
                            <label htmlFor="">{field}: </label>
                            <div style={{ marginLeft: '5px' }}> {value}</div>
                        </div>
                    )
                })}
                <hr></hr>
            </div>
        )
    }

    renderDetailedInfo() {

        // Check if CPF or CNPJ is activated with the FLAG before show the information, if not just dont show anything.
        if(this.state.key == "CPF" && !this.state.configurations.BackGroundCheckConfigs.ReturnBoostRawData 
        || this.state.key == "CNPJ" && !this.state.configurations.BackGroundCheckConfigsPJ.ReturnBoostRawData) {
            return <div></div>
        }
        if (this.state.score != null && this.state.scoreDetails) {
            
            let scoreDetails = this.state.scoreDetails;
            let listOfRelatedScore = Object.values(this.state.relatedScoreDetails);
            let listOfCpfs = Object.keys(this.state.relatedScoreDetails);
            let relatedScoreDetails = listOfRelatedScore[0];


            if (Object.keys(scoreDetails).length < 1) {
                return null;
            }

            if (relatedScoreDetails === undefined) {
                return (
                    <Card initiallyExpanded={true}>
                        <CardHeader
                            title={(
                                <div>
                                    <h4>{Strings.generalConfigurations.demonstration.BGC_DEMO_INFO_RESULT[language]}</h4>
                                    <h5>TicketId - {this.state.ticketId}</h5>
                                    <h5>Data de execução - {new Date().toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</h5>
                                </div>
                            )}
                            actAsExpander={true}
                            showExpandableButton={true}
                        />
                        <CardText expandable={true}>
                            {this.renderDocumentInformations(scoreDetails)}

                        </CardText>
                    </Card>
                )
            }
            else {
                return (
                    <div>
                        <Card initiallyExpanded={true}>
                            <CardHeader
                                title={(
                                    <div>
                                        <h4>{Strings.generalConfigurations.demonstration.BGC_DEMO_INFO_RESULT[language]}</h4>
                                        <h5>TicketId - {this.state.ticketId}</h5>
                                        <h5>{Strings.generalConfigurations.demonstration.BGC_DEMO_RESULT_DATE[language]} - {new Date().toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</h5>
                                    </div>
                                )}
                                actAsExpander={true}
                                showExpandableButton={true}
                            />
                            <CardText expandable={true}>
                                {this.renderDocumentInformations(scoreDetails)}

                            </CardText>
                        </Card>

                        <Card initiallyExpanded={true}>
                            <CardHeader
                                title={(
                                    <div>
                                        <h4>{Strings.generalConfigurations.demonstration.BGC_DEMO_RELATED_INFO[language]} : {listOfCpfs[0]}</h4>
                                    </div>
                                )}
                                actAsExpander={true}
                                showExpandableButton={true}
                            />
                            <CardText expandable={true}>
                                {this.renderDocumentInformations(relatedScoreDetails)}
                            </CardText>
                        </Card>
                    </div>
                )
            }
        }
    }

    render() {
        let demoTitle = (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) || (Auth.sessionData.activeGroupConfiguration.Domain === "WEBMOTORS") ? "Background Check" : Strings.generalConfigurations.demonstration.BGC_DEMO_TITLE[language];
        return (
            <div>
                <SectionTitle text={demoTitle} />

                <div style={inputsInterface}>

                    {this.renderCpfRequestInterface()}

                    {this.renderLoading(this.state.generatingLoading)}
                </div>      
                          
                {this.renderPhrases(this.countInterval, this.state.interval)}
                
                <div>
                    {this.renderErrorAlert()}
                    {this.renderAlertWithoutBoost()}
                    {this.renderAlert()}                        
                    {this.renderDetailedInfo()}

                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.configurations.Domain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DemonstrationBGC);
