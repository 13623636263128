import React from 'react';
import { CircularProgress } from '@material-ui/core';

const progressWrapperStyle = {
    display: "flex", justifyContent: "center"
};

const dialogStyle = {
    width: '300px',
    margin: '0 0',
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%)",
};

const overlayStyle = {
    zIndex: 1000,
    left: 0,
    top: 0,
    position: "fixed",
    height: "100%",
    width: "100%",
    background: "rgba(0,0,0,0.7)"
};

export default class LoadingDialog extends React.Component {

    render() {
        if (!this.props.open) {
            return null;
        }

        return (
            <div style={overlayStyle}>
                <div
                    style={dialogStyle}
                    title={this.props.dialogTitle || String.APP_NAME}
                    open={this.props.open}
                    >

                    <div style={progressWrapperStyle}>
                        <CircularProgress size={80} thickness={5}/>
                    </div>
                </div>
            </div>
        );
    }
}
