import React from 'react';
import ReactDOM from 'react-dom';
import Alert from 'react-bootstrap/lib/Alert';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import DateTimePicker from 'react-datetime-picker';

// Constants
import Strings from '../../constants/strings';

// Material UI Imports
import { CircularProgress, Divider, MenuItem } from '@material-ui/core';
import SectionTitle from '../SectionTitle';
import SelectField from '@material-ui/core/Select';
import Auth from '../../Authentication';
import ButtonSecondary from '../mui/ButtonSecondary';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import API from '../../API';

import Chart from 'chart.js';
import 'chart.piecelabel.js';

import moment from 'moment';

import UserLanguage from '../configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const reportFieldStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: '10px 0'
}

const reportFieldLabelStyle = {
    fontSize: "17px",
    fontWeight: "bold"
}

const reportFieldValueStyle = {
    fontSize: "17px",
    color: "#888"
}

const alertStyle = {
    marginTop: "20px"
}

const reportContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
}

const labelMargin = {
    marginTop: "15px"
}

const styleMenuItem = {
    fontSize: "16px",
    fontFamily: "HKGrotesk",
    padding: "16px"
}

class BGCReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            configurations: this.props.configurations,
            endDate: new Date(),
            startDate: new Date(),
            group: Auth.getSessionData().activeGroupConfiguration.Name
        }

        this.setState({ startDate: this.state.startDate.setDate(this.state.startDate.getDate() - 30) })
    }


    /* **** HANDLERS **** */
    handleChange = (field, event) => {
        const newState = {}
        console.log(event);
        if (field === 'group')
            newState[field] = event.target.value;
        else
            newState[field] = event;

        this.setState(newState)
    }

    handleGenerate = async () => {
        this.setState({ loading: true, error: null });

        var momentStartDate = moment(this.state.startDate)
        var momentEndDate = moment(this.state.endDate)

        let diffInDays = momentEndDate.diff(momentStartDate, 'days');

        if (diffInDays > 60) {
            this.setState({ error: Strings.reports.MAX_PERIOD_ALERT[language], loading: false });
            return;
        }

        if (diffInDays < 0) {
            this.setState({ error: Strings.reports.DATE_ALERT[language], loading: false });
            return;
        }

        momentStartDate = momentStartDate.format('YYYY-MM-DDTHH:mm:ss');
        momentEndDate = momentEndDate.format('YYYY-MM-DDTHH:mm:ss');

        // Here the data is collected to bring up the results
        let report;
        if (this.props.repType === "detailed")
            report = await API.getReportData(momentStartDate, momentEndDate, this.state.group, this.props.selectedDomain, 'GenerateDetailedBGCReport');
        else
            report = await API.getReportData(momentStartDate, momentEndDate, this.state.group, this.props.selectedDomain, 'GenerateGeneralBGCReport');

        if (!report) {
            this.setState({ error: Strings.reports.NO_BACKGROUNDCHECK_ALERT[language], loading: false });
            return;
        }

        if (report.length) {
            let arrayHeader = ["_id", "Data", "CPF/CNPJ", "Status", "Message", "Score", "Ip"]

            // Creating Header
            report.forEach(array => {
                for(let key in array.InfoTypeScore) {
                    if(!arrayHeader.includes(key))
                    {
                        if(key == "PROCESS_DETAILS")
                        {
                            let details = array.InfoTypeScore[key];
                            for(let keyDetail in details) {
                                if(!arrayHeader.includes(key+"."+keyDetail))
                                {
                                    arrayHeader.push(key+"."+keyDetail);
                                }     
                            }
                        }else{
                        arrayHeader.push(key);
                        }
                    }
                }
            });
            let csv = arrayHeader.join(",") + "\n";

            console.log("Header" + arrayHeader);

            report.forEach(array => {
                let arrayLine= [array._id, array.Date, array.Params, array.Status, array.Message, array.Score, array.Ip];
                
                for(let key in array.InfoTypeScore) 
                {
                    if(key == "PROCESS_DETAILS")
                    {
                        let details = array.InfoTypeScore[key];
                        for(let keyDetail in details) {                          
                            arrayLine.push(details[keyDetail]);
                        }
                    }else{
                    arrayLine.push(array.InfoTypeScore[key]);
                    }
                }

                if(arrayHeader.length > arrayLine.length)
                {
                    let difRange = arrayHeader.length - arrayLine.length;
                    for (let dif = 0; dif < difRange; dif++) {
                        arrayLine.push("Empty, ");
                    }
                }
                
                csv += arrayLine.join(",") + "\n";

                //csv += `${array._id}, ${array.Date}, ${array.Params}, ${array.Status}, ${array.Message}, ${array.Score}, ${array.Ip},\n`;
            });

            let csvData = new Blob([csv], { type: 'text/csv' });
            let csvUrl = URL.createObjectURL(csvData);

            let hiddenElement = document.createElement('a');
            hiddenElement.href = csvUrl;
            hiddenElement.target = "_blank";
            hiddenElement.download = "BGC_detailed.csv";
            hiddenElement.click();
            this.setState({loading: false});
            return;
        }

        let data = {
            labels: [
                Strings.reports.APROVED[language],
                Strings.reports.DISAPROVED[language],
                Strings.reports.BLOQUED_BY_VALIDATION[language],
                Strings.reports.INVALID_REQUESTS[language],
                Strings.reports.NOT_ENOUGH_INFO[language]
            ],

            datasets: [
                {
                    label: "# de backgroundcheck",
                    data: [
                        report.Approved,
                        report.NotApproved,
                        report.Blocked,
                        report.InvalidParams, 
                        report.NoInfo
                    ],

                    backgroundColor: [
                        '#6EBEA2',
                        '#F6454C',
                        '#FFE968',
                        '#00B2DE',
                        '#FF9F40'
                    ],
                }
            ]
        };

        let canvas = ReactDOM.findDOMNode(this.refs.chart);

        if (this.state.chart)
            this.state.chart.destroy();
        
        let chart = new Chart(canvas, {
            type: 'pie',
            data: data,
            options: {
                legend: {
                    position: "bottom",
                    labels: {
                        fontSize: 10,
                        boxWidth: 20
                    }
                },

                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let allData = data.datasets[tooltipItem.datasetIndex].data;
                            let tooltipLabel = data.labels[tooltipItem.index];
                            let tooltipData = allData[tooltipItem.index];
                            let total = 0;
                            for (let i in allData) {
                                total += allData[i];
                            }
                            let tooltipPercentage = Math.round((tooltipData / total) * 100);
                            return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                        }
                    }
                }
            }
        });
        this.setState({ loading: false, report: report, chart: chart });
    }

    /* ****************** */

    /* **** REPORT RENDER **** */
    renderReportField(label, value) {
        return (
            <div style={reportFieldStyle}>
                <div style={reportFieldLabelStyle}>{label}:</div>

                <div style={reportFieldValueStyle}>
                    {value}
                </div>
            </div>
        )
    }

    renderReportSubField(label, value) {
        return (
            <div style={reportFieldStyle}>
                <div>{label}:</div>

                <div>
                    {value}
                </div>
            </div>
        )
    }

    renderReport() {
        if (this.state.report) {
            let averageAnswerTime = Math.round(this.state.report.AverageAnswerTime * 1000)/1000  + " s";

            return (
                <div style={{ marginTop: "30px" }}>
                    {this.renderReportField("Total", this.state.report.EventsCount)}
                    {this.renderReportField(Strings.reports.APROVED[language], this.state.report.Approved)}
                    {this.renderReportField(Strings.reports.DISAPROVED[language], this.state.report.NotApproved)}
                    {this.renderReportField(Strings.reports.BLOQUED_BY_VALIDATION[language], this.state.report.Blocked)}
                    {this.renderReportField(Strings.reports.INVALID_REQUESTS[language], this.state.report.InvalidParams)}
                    {this.renderReportField(Strings.reports.NOT_ENOUGH_INFO[language], this.state.report.NoInfo)}

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    {this.renderReportSubField(Strings.reports.AVERAGE_ANSWER_TIME[language], averageAnswerTime)}
                </div>
            )
        }
    }
    /* *********************** */

    renderAllDisclaimer() {
        if(this.state.group === "ALLGROUPS" && !this.state.chart) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <p>{Strings.reports.ALL_DISCLAIMERS[language]}</p>
                </Alert>
            )
        }
    }

    renderLoading(load) {
        if (!load)
            return;
        
        return(
            <CircularProgress style={{ marginLeft: "20px" }} />
        ) 
    }

    render() {
        if (this.state.chart && this.props.repType === "detailed") {
            document.getElementById("report").style.visibility = "hidden";
        }else if(document.getElementById("report")){
            document.getElementById("report").style.visibility = "visible";
        }
        return (
            <div>
                <SectionTitle text={this.props.repType === "detailed" ? Strings.reports.BGC_DETAILED_TITLE[language] : Strings.reports.BGC_GENERAL_TITLE[language]} />
                <label htmlFor="" style={labelMargin}>{Strings.reports.INICIAL_DATE[language]}</label>
                <br />
                <DateTimePicker value={this.state.startDate} format="dd/MM/yyyy HH:mm" onChange={this.handleChange.bind(null, "startDate")} />
                <br />

                <label htmlFor="" style={labelMargin}>{Strings.reports.FINAL_DATE[language]}</label>
                <br />
                <DateTimePicker value={this.state.endDate} format="dd/MM/yyyy HH:mm" onChange={this.handleChange.bind(null, "endDate")} />
                <br />

                <label htmlFor="" style={labelMargin}>{Strings.reports.GROUPS[language]}</label>

                <SelectField
                    style={{ display: 'block' }}
                    onChange={this.handleChange.bind(null, "group")}
                    value={this.state.group} >
                    {Auth.sessionData.groupsNames.map((value) => {
                        return (<MenuItem style={styleMenuItem} value={value}>{value}</MenuItem>)
                    })}
                    <MenuItem style={styleMenuItem} value={'ALLGROUPS'}><b>{Strings.reports.ALL[language]}</b></MenuItem>
                </SelectField>

                <br />
                
                {this.state.error ? this.state.error : ""}
                
                {this.renderAllDisclaimer()}

                <ButtonSecondary key="generate" type="submit" onClick={this.handleGenerate} label={Strings.reports.GENERATE_REPORT[language]} />
                
                {this.renderLoading(this.state.loading)}

                <br />
                <div id="report" style={reportContainerStyle}>
                    <div style={{ width: '50%' }}>
                        {this.renderReport()}
                    </div>

                    <div style={{ width: '50%' }}>
                        <canvas ref="chart" width="0" height="0"></canvas>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.selectedDomain : null,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BGCReport);