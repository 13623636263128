import * as types from './actionsTypes';

export default function reduce(state, action){
    // If new state is undefined
    // return null
    if(state === undefined){
        return null;
    }

    let newState = {};

    // Attetion: always use Object.assign so it
    // returns a new object and not just a modified version of it
    switch (action.type) {
        case types.SAVE_CONFIGURATIONS:
            newState = Object.assign({}, state, {configurations: Object.assign({}, action.configurations), modified: true});
            break;
        case types.SELECT_CONFIGURATIONS:
            newState = Object.assign({}, state, {configurations: Object.assign({}, action.configurations), modified: false});
            break;
        case types.STORE_QUESTIONS_PROPERTIES:
            newState = Object.assign({}, state, {questionsProperties: action.questionsProperties});
            break;
        case types.STORE_GROUPS_NAMES:
            newState = Object.assign({}, state, {groupsNames: action.groupsNames});
            break;

        case types.STORE_SELECTED_DOMAIN:
            newState = Object.assign({}, state, {selectedDomain: action.domain});
            break;

        default:
            newState = Object.assign({}, state);
    }

    // Log if the env is development
    if(process.env.NODE_ENV === 'development'){
        console.log("New State", newState);
    }

    return newState;
};
