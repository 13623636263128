import { ImageCenterFocusStrong } from "material-ui/svg-icons";


export default {
    APP_NAME: "BigId",

    commands: {
        CONFIRM: {
            PT: "Confirmar",
            ES: "Confirmar",
            EN: "Confirm",
        },
        CANCEL: {
            PT: "Cancelar",
            ES: "Cancelar",
            EN: "Cancel"
        }
    },

    // Group Configuration component
    groupConfiguration: {
        NEW_GROUP: {
            PT: "Crie um novo grupo",
            EN: "Create a new group",
            ES: "Crea un nuevo grupo"
        },
        GROUP_TITLE: {
            PT: "Grupo",
            EN: "Group",
            ES: "Grupo"
        },
        NEW_GROUP_HINT: {
            PT: "Qual nome do novo grupo?",
            EN: "What is the name of the new group?",
            ES: "¿Cuál es el nombre del nuevo grupo?"
        },
        NEW_GROUP_OK: {
            PT: "Criar",
            EN: "Create",
            ES: "Crear"
        },
        NEW_GROUP_CANCEL: {
            PT: "Voltar",
            EN: "Return",
            ES: "Volver"
        },
        NEW_GROUP_FROM: {
            PT: "A partir de:",
            EN: "From:",
            ES: "Desde:"
        },
        NEW_GROUP_SELECT: {
            PT: "Novo Grupo",
            EN: "New group",
            ES: "Nuevo grupo "
        }
    },

    // PAGES
    login: {
        USERNAME_HINT: {
            PT: "Usuário ou e-mail",
            EN: "Username or e-mail",
            ES: "Usuario o e-mail"
        },
        PASSWORD_HINT: {
            PT: "Senha",
            EN: "Password",
            ES: "Contraseña"
        },
        LOGIN_BUTTON_TEXT: {
            PT: "Login",
            EN: "Login",
            ES: "Login"
        },
        AUTHENTICATION_ERROR_TITLE: {
            PT: "Erro ao autenticar",
            EN: "Error authenticating",
            ES: "Error de autenticación"
        },
        INVALID_EMAIL: {
            PT: "Use um e-mail válido.",
            EN: "Use a valid email address.",
            ES: "Utilice una dirección de correo electrónico válida."
        },
    },

    logout: {
        LOGOUT_TITLE: "Logout"
    },

    home: {
        CONFIGURATIONS: {
            PT: "Configurações",
            EN: "Settings",
            ES: "Ajustes"
        },
        REPORTS: {
            PT: "Relatórios",
            EN: "Reports",
            ES: "Informes"
        },
        DEMONSTRATION: {
            PT: "Visualizar",
            EN: "Visualize",
            ES: "Visualizar"
        },
        // NOTIFICATIONS:{
        //     PT:"Notificações",
        //     EN:"Notifications",
        //     ES:"Notificaciones"
        // },
    },

    configurations: {
        //GENERAL_CONFIGURATIONS: "Configurações Gerais",
        QUESTIONS: {
            PT: "Perguntas",
            EN: "Questions",
            ES: "Preguntas"
        }
    },

    administration: {
        menu: {
            CREATE_NEW_ACCOUNT: {
                TITLE: "Criação de Conta", SUBS: {}
            }
        },
        GROUP_NAME: {
            PT: "Nome do Grupo",
            EN: "Group name",
            ES: "Nombre del grupo"
        },
        USER_NAME: {
            PT: "Nome do Usuário",
            EN: "Username",
            ES: "Nombre de Usuario"
        },
        USER_PASSWORD: {
            PT: "Senha do Usuário",
            EN: "User Password",
            ES: "Contraseña de usuario"
        },
        CREATE_NEW_ACCOUNT_TITLE: {
            PT: "Criação de Conta",
            EN: "Account Creation",
            ES: "Creación de cuenta"
        },
        CREATE_USER: {
            PT: "Criar",
            EN: "Create",
            ES: "Crear"
        },
        NEW_USER_RESULT: {
            TRUE: {
                PT: "Sim",
                EN: "Yes",
                ES: "Si"
            },
            FALSE: {
                PT: "Não",
                EN: "No",
                ES: "No"
            }
        },
        GROUP_CREATED: {
            PT: "Grupo Criado: ",
            EN: "Group Created: ",
            ES: "Grupo creado: "
        },
        USER_CREATED: {
            PT: "Usuário Criado: ",
            EN: "User Created: ",
            ES: "Usuario creado: "
        },
        USER_CREATED_IDMEPRICE: {
            PT: "Usuário já existente (IdMePrice adicionado ao usuário): ",
            EN: "User already exist (IdMePrice added to user): ",
            ES: "El usuario ya existe (IdMePrice agregado al usuario): "
        },
        BIGID_USER_CREATED: {
            PT: "Usuário BigID Criado: ",
            EN: "BigID User Created: ",
            ES: "Usuario de BigID creado:"
        },
        CONFIG_GROUP_CREATED: {
            PT: "Grupo de Configuração Default Criado: ",
            EN: "Default Configuration Group Created: ",
            ES: "Grupo de Configuración Default Creado:"
        },
    },

    generatePOC: {
        menu: {
            CREATE_NEW_POC: { TITLE: "Geração de novo POC", SUBS: {} },
            CREATE_POC_SIDE: { TITLE: "Criação de POC", SUBS: {} },
            HISTORICAL_POC: { TITLE: "Histórico de POCs", SUBS: {} },
            HISTORICAL_POC_TITLE: { TITLE: "POCs em andamento e Download", SUBS: {} }
        },
        poc_labels: {
            DOMAIN: {
                PT: "Domínio",
                EN: "Domain",
                ES: "Domínio"
            },
            USER: {
                PT: "Usuário",
                EN: "User",
                ES: "Usuário"
            },
            DATE: {
                PT: "Data",
                EN: "Date",
                ES: "Data"
            },
            API: {
                PT: "API",
                EN: "API",
                ES: "API"
            },
            STATUS: {
                PT: "Status",
                EN: "Status",
                ES: "Status"
            },
            DELETE: {
                PT: "Excluir",
                EN: "Delete",
                ES: "Excluir"
            },
            DOWNLOAD: {
                PT: "Download",
                EN: "Download",
                ES: "Download"
            },
            TITLE: {
                PT: "Lista de POCs Geradas",
                EN: "List of Generated POC's",
                ES: "Lista de POCs Geradas"
            },
            SUBTITLE: {
                PT: "Abaixo, a lista com todas as POCs que estão executando e resultados de POCs disponívels para download",
                EN: "Below, the list of with the whole POCs that are executing and result of disponible POCs to download",
                ES: "Abaixo, a lista com todas as POCs que estão executando e resultados de POCs disponívels para download"
            }
        },
        toggle: {
            BGC_POC: "BackgroundCheck",
            VERIFYID: "Verify ID (OCR)",
            FACEMATCH: "FaceMatch"
        },
        MSG: {
            PT: "Mensagem",
            EN: "Message",
            ES: "Mensaje"
        },
        DELETE_ALERT: {
            PT: "Deseja realmente deletar essa POC ?",
            EN: "Do you really want to delete this POC ?",
            ES: "¿Realmente desea eliminar este POC?"
        },
        POC_GENERATE: {
            PT: "Gerar POC",
            EN: "Generate POC",
            ES: "Generar POC"
        },
        ALERT_FILE_SENT: {
            PT: "Arquivo enviado com Sucesso! Clique em GERAR POC para realizá-lo.",
            EN: "File uploaded successfully! Click on GENERATE POC to perform it.",
            ES: "¡El archivo ha subido correctamente! Haga clic en GENERAR POC para realizarlo."
        },
        UPLOAD_FILE: {
            PT: "Fazer Upload de Arquivo",
            EN: "Upload File",
            ES: "Subir archivo"
        },
        SELECT_API_ALERT: {
            PT: "Favor selecionar a API para realização da POC.",
            EN: "Please select the API to perform the POC.",
            ES: "Seleccione la API para realizar el POC"
        },
        ONLY_TXT_FILES_ALERT: {
            PT: "Apenas arquivos .txt são permitidos.",
            EN: "Only .txt files are allowed.",
            ES: "Solo se permiten archivos .txt."
        },
        FILE_MAX_SIZE_ALERT: {
            PT: "Arquivo muito grande! Favor inserir um com menos de 10GB.",
            EN: "Very large file! Please insert one less than 10GB.",
            ES: "Archivo muy grande! Inserte uno de menos de 10 GB."
        },
        ONLY_ZIP_FILES_ALERT: {
            PT: "Apenas arquivos Zipados são permitidos.",
            EN: "Only zipped files are allowed.",
            ES: "Solo se permiten archivos comprimidos"
        },
        SUCCESS_ALERT: {
            PT: "Pronto! Agora é só acompanhar o progresso da POC em 'Historico de POCs'",
            EN: "Ready! Now just follow the progress of the POC in the 'Historic of POCs'",
            ES: "¡Listo! Ahora solo sigue el progreso del POC en la 'Histórico de los POCS'"
        },
        POC_FILE: {
            PT: "Arquivo POC",
            EN: "POC file",
            ES: "Archivo POC"
        }
    },

    workspace: {
        menu: {
            GENERATE_QUESTIONAIRE: { TITLE: "Gerar Questionário", SUBS: {} },
            VALIDATE_DOCUMENT: { TITLE: "OCR de Documento", SUBS: {} }
        }
    },

    facevalidation: {
        menu: {
            GENERATE_QUESTIONAIRE: { TITLE: "Gerar Questionário", SUBS: {} },
            VALIDATE_IDENTITY: { TITLE: "Validação de Identidade", SUBS: {} }
        }
    },

    ABTestingLabels: {
        TITLE: {
            PT: "Configuração de novo Teste A/B",
            ES: "Teste A/B",
            EN: "A/B Testing"
        },
        NEW_TEST: {
            PT: "Novo Teste",
            ES: "Nueva Prueba",
            EN: "New Test"
        },
        FIRST_GROUP_NAME: {
            PT: "Grupo A",
            EN: "Group A",
            ES: "Grupo A"
        },
        SECOND_GROUP_NAME: {
            PT: "Grupo B",
            EN: "Group B",
            ES: "Grupo B"
        },
        PERCENT_FIRST_GROUP: {
            ES: "Porcentaje del Primer Grupo",
            EN: "Percentage of requests to be sent to \"A\" group",
            PT: "Porcentagem do Primeiro Grupo"
        },
        PERCENT_SECOND_GROUP: {
            ES: "Porcentaje del Segundo Grupo",
            EN: "Percentage of requests to be sent to \"B\" group",
            PT: "Porcentagem do Segundo Grupo"
        },
        PRODUCTQUESTIONNARE: {
            PT: "Questionário",
            EN: "Questionnaire",
            ES: "Cuestionario"
        },
        DATES: {
            PT: "Escolha uma data final",
            EN: "Set an end date",
            ES: "Elija una fecha de finalización"
        },
        VOLUME: {
            PT: "Escolha o número de Requisições",
            ES: "Elija el número de Requisiciones",
            EN: "Set maximum number of requests"
        },
        PRODUCTBGC: {
            PT: "BackgroundCheck",
            EN: "BackgroundCheck",
            ES: "BackgroundCheck"
        },
        QUANTITY: {
            PT: "N° de Requisições",
            EN: "Maximum Number of Requests",
            ES: "No. de Requisiciones"
        },
        START_DATE: {
            PT: "Data Inicial",
            ES: "Fecha de Inicio",
            EN: "Start Date"
        },
        END_DATE: {
            PT: "Data Final",
            EN: "End Date",
            ES: "Fecha Final"
        },
        REQUEST_LIMIT: {
            EN: "Request Limit",
            ES: "Límite de solicitud",
            PT: "Limite de Requisições"
        },
        PRODUCT: {
            EN: "Product",
            ES: "Producto",
            PT: "Produto"
        },
        REQUISION_NUMBER: {
            PT: "N° de Requisições Atuais",
            ES: "No. de Requisiciones Actuales",
            EN: "Current Number of Requests"
        },
        STOP_PLAY: {
            PT: "Pausar/Continuar",
            EN: "Pause/Resume",
            ES: "Pausar/Reanudar"
        },
        DELETE: {
            PT: "Deletar",
            EN: "Delete",
            ES: "Eliminar"
        },
        PLAY:{
            PT: "Continuar",
            EN: "Resume",
            ES: "Reanudar"
        },
        STOP:{
            PT: "Pausar",
            EN: "Pause",
            ES: "Pausar"
        },
        ACTION_ALERT:{
            PT: "Deseja realmente # esse Teste AB ?",
            EN: "Do you really want to # this AB Test?",
            ES: "¿De verdad quieres # esta prueba AB?"
        },
        DELETE_ALERT:{
            PT:"Deseja realmente deletar esse Teste A/B ?",
            EN:"Do you really want to delete this A/B Test?",
            ES:"¿Realmente desea eliminar esta prueba A/B?"
        },
        EXPLANATION_FIRST: {
            PT: "O Teste A/B permite que você compare estatisticamente dois grupos de configuração diferentes e com os resultados em mãos tome decisões  baseadas em resultados reais e dados confiáveis. Ele pode ser executado para Questionário ou Background Check.",
            ES: "La Teste A/B le permiten comparar estadísticamente dos grupos de configuración diferentes y, con los resultados disponibles, tomar decisiones basadas en resultados reales y datos confiables. Se puede ejecutar para Cuestionario o Verificación de antecedentes.",
            EN: "This feature allows you to run A/B (champion/challenger) tests in order to compare different configuration groups and choose the best settings based on live production data. A/B tests are available for both the Questionnaire and the Background Check features. "
        },
        EXPLANATION_SECOND: {
            PT: "O Teste A/B distribui requisições entre os dois grupos de configuração selecionados, para isso ele se basea em uma porcentagem que você define para cada grupo, que somadas devem totalizar 100%.",
            ES: "El Teste A/B distribuye las requisiciones entre los dos grupos de configuración seleccionados, para ello se basa en un porcentaje que defina para cada grupo, que en conjunto deben sumar el 100%.",
            EN: "BigID will automatically distribute the incoming validation requests between the selected \"A\" and \"B\" configuration groups, based on the percentages you configure for each group."
        },
        WARNING: {
            PT: "Importante",
            EN: "Important",
            ES: "Importante"
        },
        EXPLANATION_MODAL: {
            PT: "Clique aqui para mais informações sobre as regras de distribuição estatística para o teste A/B.",
            ES: "Haga clic aquí para obtener más información sobre las reglas de distribución estadística para la prueba A/B.",
            EN: "Click here for more information on sample sizes and setting the number of requests for you A/B test."
        },
        SELECT_PRODUCT: {
            PT: "Selecione o produto",
            EN: "Select the feature",
            ES: "Seleccione el producto"
        },
        CHOOSE_PRODUCT_TEXT: {
            PT: "Escolha os Grupos do Teste A/B",
            ES: "Elija grupos de prueba A/B",
            EN: "Select the \"A\" and \"B\" test groups",
        },
        CHOOSE_PERCENTUAL_TEXT: {
            PT: "Escolha os percentuais de cada grupo",
            ES: "Elija los porcentajes para cada grupo",
            EN: "Select the percentage of requests that will be sent to each group. Numbers must add to 100%"
        },
        START_END_TEXT: {
            PT: "Escolha as condições de inicio/fim do teste",
            EN: "Set test start / end conditions",
            ES: "Elija las condiciones de inicio/finalización de la prueba"
        },
        UTCDISCLAIMER: {
            PT: "Obs: As datas inseridas serão convertidas para UTC.",
            EN: "Note: You must set a start date for the test. You can optionally set an end date, and a total number of requests for the test. If only the number of requests is set, the test will run until the number of requests is reached or you manually stop it. If only the end date is set, the test will run until the set date, regardless of the number of requests. If both are set, the test will run until the date or the number of requests is reached, whichever comes first. Dates entered will be converted to UTC.",
            ES: "Nota: Las fechas ingresadas se convertirán a UTC."
        }
    },

    generalConfigurations: {
        MENU_GENERAL_CONFIGURATIONS: {
            ES: "Configuración",
            PT: "Configurações",
            EN: "Settings"
        },
        GENERAL_CONFIGURATIONS: {
            ES: "Configuración General",
            PT: "Configurações Gerais",
            EN: "General Settings"
        },
        GROUP_NAME_LABEL: {
            ES: "Nombre del Grupo",
            PT: "Nome do Grupo",
            EN: "Group Name"
        },
        SCORE_WARNING: {
            ES: "La puntuación no puede ser negativa",
            PT: "Pontuação não pode ser negativa",
            EN: "Score cannot be negative"
        },
        SAVE_BUTTON_LABEL: {
            PT: "Salvar",
            EN: "Save",
            ES: "Guardar"
        },
        NUMBER_OF_QUESTIONS_LABEL: {
            PT: "Número de perguntas",
            ES: "Numero de preguntas",
            EN: "Number of questions"
        },
        TIMEOUT_LABEL: {
            EN: "Expiration time",
            ES: "Tiempo de Validez",
            PT: "Tempo de Validade"
        },
        DEFAULT_LABEL: {
            EN: "Default",
            ES: "Default",
            PT: "Default"
        },
        NOA_LABEL: {
            PT: "Permitir NDA como opção",
            EN: "Allow NDA as an option",
            ES: "Permitir NDA como una opción"
        },
        ESSENTIAL_LABEL: {
            PT: "Habilitar perguntas essenciais",
            EN: "Allow NDA as an option",
            ES: "Permitir NDA como una opción"
        },
        NOA_ANSWER_LABEL: "Permitir NDA como resposta",
        CHECK_CPF_STATUS_LABEL: "Verificar o status do CPF",
        ALLOW_MULTIPLE_ANSWERS_ATTEMPTS_LABEL: "Permitir segunda chance",
        MAX_NO_INFO_QUESTION_COUNT: {
            EN: "Maximum number of NDA questions.",
            PT: "Número máximo de perguntas NDA.",
            ES: "Número máximo de preguntas de NDA"
        },
        MIN_ESSENTIAL_QUESTION_COUNT: {
            EN: "Minimum number of essential questions.",
            PT: "Número mínimo de perguntas essenciais.",
            ES: "Número mínimo de preguntas esenciales."
        },
        FLEXIBLE_QUESTIONS_LABEL: {
            EN: "Variable number of questions",
            ES: "Cantidad de Preguntas Flexible",
            PT: "Quantidade de Perguntas Flexível"
        },
        FLEXIBLE_MIN_QUESTIONS: {
            PT: "Número mínimo de perguntas",
            ES: "Número mínimo de preguntas",
            EN: "Minimum number of questions"
        },
        FLEXIBLE_MAX_QUESTIONS: {
            PT: "Número máximo de perguntas",
            ES: "Número máximo de preguntas",
            EN: "Maximum number of questions"
        },
        REUSE_TIME_LABEL: {
            PT: "Tempo de Reuso",
            EN: "Reuse time",
            ES: "Reutilizar el Tiempo"
        },
        MIN_SCORE_LABEL: {
            PT: "Pontuação mínima para validar",
            EN: "Score validation threshold",
            ES: "Puntuación mínima para validar"
        },
        POSITIVE_SCORE_ALERT:{
            PT:"Pontuação positiva é obrigatório",
            EN:"Positive score is required",
            ES:"Se requiere puntuación positiva"
        },
        NEGATIVE_SCORE_ALERT:{
            PT:"Pontuação negativa é obrigatório",
            EN:"Negative score is required",
            ES:"Se requiere una puntuación negativa"
        },
        ENABLED_QUESTIONS_ALERT:{
            PT:"Você não pode ter menos tipos de perguntas que seu número de perguntas.",
            EN:"You can't have fewer types of questions than your number of questions.",
            ES:"No puede tener menos tipos de preguntas que su número de preguntas"
        },
        ESSENTIAL_QUESTIONS_ALERT:{
            PT:"O numero de perguntas marcadas como essenciais não pode ser menor que o número total de perguntas essenciais. Diminua o numero total de perguntas essenciais.",
            EN:"The number of questions marked as essential cannot be less than the total number of essential questions. Decrease the total number of essential questions.",
            ES:"El número de preguntas marcadas como esenciales no puede ser menor que el número total de preguntas esenciales. Disminuya el número total de preguntas esenciales."
        },
        TOTAL_ESSENTIAL_QUESTIONS_ALERT:{
            PT:"O numero total de perguntas essenciais não pode ser maior que o número de perguntas marcadas como essenciais. Marque mais perguntas como essenciais.",
            EN:"The total number of essential questions cannot be greater than the number of questions marked as essential. Mark more questions as essential.",
            ES:"El número total de preguntas esenciales no puede ser mayor que el número de preguntas marcadas como esenciales. Marque más preguntas como esenciales."
        },
        NEXT_CHANCE_GENERATION_MIN_SCORE_LABEL: "Pontuação mínima para segunda chance",
        LIST_VALIDATIONS_LABEL: {
            PT: "Configurar listas de permissões",
            EN: "Current Lists",
            ES: "Permitir listas"
        },
        LIST_VALIDATIONS_HEADERS: {
            NEW_PERMISSION_LIST: {
                PT: "Nova Lista de Permissões",
                EN: "New Allow List",
                ES: "Nueva lista de permitidos"
            },
            TYPE: {
                PT: "Tipo",
                EN: "Type",
                ES: "Tipo"
            },
            KEY: {
                PT: "Chave",
                EN: "Key",
                ES: "Llave"
            },
            ACTIVATION: {
                PT: "Ativada",
                EN: "Enabled",
                ES: "Habilitado"
            },
            CONTACTS: {
                PT: "Contatos",
                EN: "Contacts",
                ES: "Contactos"
            },
            ACTIONS: {
                PT: "Ações",
                EN: "Actions",
                ES: "Comportamiento"
            }
        },
        LIST_VALIDATIONS_NEW_LIST_BUTTON: {
            PT: "Nova lista",
            EN: "New list",
            ES: "Lista nueva"
        },

        PERSON_QUESTIONS_CONFIGURATIONS_LABEL: {
            PT: "Grupos de perguntas para pessoas",
            EN: "Question groups for persons",
            ES: "Grupos de preguntas para personas"

        },
        COMPANIES_QUESTIONS_CONFIGURATIONS_LABEL: {
            PT: "Grupos de Perguntas para empresas",
            EN: "Question Groups for companies",
            ES: "Grupos de preguntas para empresas"
        },
        NUMBER_OF_ANSWERS_LABEL: {
            PT: "Número de respostas por pergunta",
            EN: "Number of alternatives per question",
            ES: "Número de respuestas por pregunta"
        },
        validations: {
            CPF_LABEL: "Validação por CPF",
            NAME_LABEL: "Validar por nome",
            NAME_PORCENTAGE: "Percentual de tolerância",
            OBIT_LABEL: "Validação de óbito",
            validationList: {
                ADD_NEW_VALUE: "Adicionar novo"
            },
            WATCH_LIST_CONTACT_LABEL: {
                PT: "Configurar notificações",
                EN: "WatchList notification settings",
                ES: "Notificaciones de WatchList"
            },
            WATCH_LIST_CONTACT_FLOAT_LABEL: {
                PT: "Notificar para",
                EN: "Notify to",
                ES: "Notificar a"
            },
            WATCH_LIST_CONTACT_BUTTON: {
                PT: "Adicionar",
                EN: "Add",
                ES: "Añadir"
            },
            WATCH_LIST_CONTACT_HEADERS: {
                CONTACTS: {
                    PT: "Contatos",
                    EN: "Contacts",
                    ES: "Contactos"
                },
                TYPE: {
                    PT: "Tipo",
                    EN: "Type",
                    ES: "Tipo"
                },
                ACTIONS: {
                    PT: "Ações",
                    EN: "Actions",
                    ES: "Comportamiento"
                }
            },
            WATCH_LIST_CONTACT_DELETE_ALERT: {
                PT:"Deseja realmente deletar esse contato ?",
                EN:"Do you really want to delete this contact?",
                ES:"¿Realmente quieres eliminar este contacto?"
            },
            LIST_CONTACT_DELETE_ALERT:{
                PT: "Deseja mesmo excluir o contato: # dessa lista ?",
                EN: "Do you really want to delete the contact: # from that list?",
                ES: "¿Realmente desea eliminar el contacto: # de esa lista?"
            }
        },
        WARNING_QUESTION_POINTS: {
            PT: "Pontuação é muito alta para o número de perguntas e as pontuações das perguntas.",
            EN: "Score is very high for the number of questions and the question scores.",
            ES: "La puntuación es muy alta para el número de preguntas y las puntuaciones de las preguntas."
        },
        menu: {
            GENERAL_CONFIGURATIONS: {
                TITLE: "Configurações", 
                SUBS: {}
            },
            BACKGROUN_CHECK: {
                TITLE: "Background Check",
                SUBS: {
                    CONFIGURATIONS_PF: {
                        TITLE: "Configurações PF",
                        PARENT: "Background Check",
                        SUBS: {}
                    },
                    CONFIGURATIONS_PJ: {
                        TITLE: "Configurações PJ",
                        PARENT: "Background Check",
                        SUBS: {}
                    },
                    ABTESTING_BGC: {
                        TITLE: "Teste A/B",
                        PARENT: "Background Check",
                        SUBS: {}
                    },
                    DEMONSTRATION_BGC: {
                        TITLE: "Demonstração BGC",
                        PARENT: "Background Check",
                        SUBS: {}
                    },
                    NOTIFICATIONS_BGC: {
                        TITLE: "Listas e Notificações",
                        PARENT: "Background Check",
                        SUBS: {}
                    }
                    
                }
            },
            QUESTIONNAIRE: {
                TITLE: "Questionário",
                SUBS: {
                    CONFIGURATIONS: {
                        TITLE: "Configurações",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    QUESTIONS_PERSONS: {
                        TITLE: "Perguntas PF",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    QUESTIONS_COMPANIES: {
                        TITLE: "Perguntas PJ",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    ABTESTING: {
                        TITLE: "Teste A/B",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    DEMONSTRATION: {
                        TITLE: "Demonstração",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    VALIDATIONS: {
                        TITLE: "Validações",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    NOTIFICATIONS: {
                        TITLE: "Listas e Notificações",
                        PARENT: "Questionário",
                        SUBS: {}
                    }
                }
            },
            DOCUMENT_VALIDATION: { 
                TITLE: "Documentoscopia e OCR", 
                SUBS: {
                    DEMONSTRATION_OCR: {
                        TITLE: "Demonstração OCR",
                        PARENT: "Documentoscopia e OCR",
                        SUBS: {}
                    },
                } 
            },        
            FACEMATCH: { 
                TITLE: "FaceMatch", 
                SUBS: {
                    DEMONSTRATION_FACEMATCH: {
                        TITLE: "Demonstração FaceMatch",
                        PARENT: "FaceMatch",
                        SUBS: {}
                    }
                }
            },
            SMSCHECK: { 
                TITLE: "Verificação por SMS", 
                SUBS: {
                    SMSCHECK_CONFIGURATIONS: {
                        TITLE: "SMSCHECK_CONFIG",
                        PARENT: "Verificação por SMS",
                        SUBS: {}
                    } ,
                    SMSCHECK_DEMONSTRATION:{
                        TITLE: "SMSCHECK_DEMO",
                        PARENT: "Verificação por SMS",
                        SUBS:{}
                    }
                } 
            },
            NOTIFICATIONS: { TITLE: "Listas e Notificações", SUBS: {} },
        },

        OCR_PHOTO_LABEL: {
            PT: "Foto do documento",
            EN: "Document photo",
            ES: "Foto del documento"
        },
        OCR_PAGE_TITLE: {
            PT: "Demonstração",
            EN: "Demonstration",
            ES: "Demostración"
        },
        OCR_EXTRACT_BUTTON: {
            PT: "Extrair Informações",
            EN: "Extract Information",
            ES: "Extraer información"
        },
        OCR_REPORT_TITLE: {
            PT: "Informações Obtidas do Documento",
            EN: "Information Obtained from the Document",
            ES: "Información obtenida del Documento"
        },
        OCR_REPORT_EXTRACTION_TITLE: {
            PT: "Informações extraídas do documento",
            EN: "Information extracted from the document",
            ES: "Información extraída del documento"
        },
        OCR_REPORT_VALIDATION_TITLE: {
            PT: "Validação com dados públicos",
            EN: "Validation with public data",
            ES: "Validación con datos públicos"
        },

        //User in both OCR and Facematch
        PHOTO_SIZE_WARNING: {
            PT: "Mensagem:  Não é aconselhável o envio de imagens maiores que 1MB em seu formato original (arquivo .jpg por exemplo). Imagens acima deste patamar podem demorar um tempo excessivamente alto para serem processadas.",
            EN: "Message: It is not advisable to send images larger than 1MB in their original format (.jpg file for example). Images above this threshold may take an excessively long time to process.",
            ES: "Mensaje: No es recomendable enviar imágenes de más de 1 MB en su formato original (archivo .jpg por ejemplo). Las imágenes por encima de este umbral pueden tardar demasiado en procesarse."
        },
        MAX_PHOTO_SIZE_WARNING: {
            PT: "Mensagem: Imagens não podem ter mais que 4MB no seu formato original (arquivo .jpg por exemplo).",
            EN: "Message: Images cannot be more than 4MB in their original format (.jpg file for example).",
            ES: "Mensaje: Las imágenes no pueden tener más de 4 MB en su formato original (archivo .jpg por ejemplo)."
        },

        newMenu: {
            GENERAL: {
                TITLE: "Geral"
            },
            TIMEOUT_AND_REUSE: {
                PT: "Validade e Reuso",
                EN: "Expiration and Reuse",
                ES: "Validez y Reutilización",
            },
            NOA: {
                EN: "Configure \"None of the Above\"",
                PT: "Configurar \"Nenhuma das Anteriores\"",
                ES: "Configurar \"Ninguno Anterior\""
            },
            ESSENTIAL: {
                EN: "Configure Essential Questions",
                PT: "Configurar Perguntas Essenciais",
                ES: "Configurar Preguntas Esenciales"
            },
            SECURITY: { TITLE: "Validações" },
            NOTIFICATIONS: { TITLE: "Listas e Notificações" },
            QUESTIONS: { TITLE: "Perguntas" },
            DEMONSTRATION: { TITLE: "Demonstração" }
        },

        demonstration: {
            DEMONSTRATION: {
                PT: "Demonstração",
                EN: "Preview",
                ES: "Demostración"
            },
            QUESTIONNAIRE: {
                PT: "Questionário",
                EN: "Questionnaire",
                ES: "Cuestionario",
            },
            QUESTIONNAIRE_CODES: {
                Not_enough_information: {
                    PT: "Sem Informações Suficiente",
                    EN: "Not Enough Information",
                    ES: "No hay suficiente información"
                },
                An_error_occurred_while_building_the_questions: {
                    PT: "Ocorreu um erro ao gerar as perguntas",
                    EN: "An Error occurred while generating the questions",
                    ES: "Un error ocurrió al generar las preguntas"
                }

            },
            GENERATE: {
                PT: "Simular",
                EN: "Simulate",
                ES: "Simular"
            },
            SEND_ANSWER: {
                PT: "Enviar Respostas",
                EN: "Submit Answers",
                ES: "Enviar Respuestas"
            },
            NEXT: {
                PT: "PRÓXIMO",
                EN: "NEXT",
                ES: "SIGUIENTE"
            },
            VALID_DOC_ALERT:{
                PT: "Digite um documento válido!",
                EN: "Please enter a valid document!",
                ES: "Introduzca un documento válido."
            },
            SUCCESFUL_ANSWER_SENT: {
                PT: "Questionário respondido com sucesso.",
                EN: "Questionnaire successfully answered.",
                ES: "Cuestionario respondido con éxito."
            },
            MISSING_ANSWER: {
                PT: "Por favor responda todas as perguntas.",
                EN: "Please answer all questions.",
                ES: "Responda todas las preguntas."
            },
            CORRECT_ANSWER: {
                PT: "Respondida Corretamente:",
                EN: "Correctly Answered:",
                ES: "Respondido correctamente:"
            },
            USER_ANSWER: {
                PT: "Resposta dada pelo usuário:",
                EN: "User response:",
                ES: "Respuesta del usuario:"
            },
            APPROVED: {
                PT: "Aprovado",
                EN: "Approved",
                ES: "Aprobado"
            },
            DISAPPROVED: {
                PT: "Reprovado",
                EN: "Disapproved",
                ES: "Desaprobado"
            },
            BGC_DEMO_TITLE: {
                PT: "Demonstração",
                EN: "Demonstration",
                ES: "Demostración"
            },
            BGC_DEMO_CODE: {
                PT: "Código",
                EN: "Code",
                ES: "Código"
            },
            BGC_DEMO_MSG: {
                PT: "Mensagem",
                EN: "Message",
                ES: "Mensaje"
            },
            ONLY_NUMBERS_HINT: {
                PT: "Apenas números",
                EN: "Only numbers",
                ES: "Sólo números"
            },
            BGC_DEMO_RESULT_TITLE: {
                PT: "Informações do Background Check",
                EN: "Background Check Information",
                ES: "Información del Background Check"
            },
            BGC_DEMO_INFO_RESULT: {
                PT: "Informações Obtidas do Background Check",
                EN: "Information Obtained from Background Check",
                ES: "Información obtenida del Background Check"
            },
            BGC_DEMO_RESULT_DATE: {
                PT: "Data de execução",
                EN: "Execution date",
                ES: "Fecha de ejecución"
            },
            BGC_DEMO_RELATED_INFO: {
                PT: "Informações - Pessoa/Empresa Relacionada",
                EN: "Information - Related Person / Company",
                ES: "Información - Persona / Empresa relacionada"
            },
            BGC_DEMO_NOINFO_ALERT: {
                PT: "Nenhuma informação encontrada",
                EN: "No information found",
                ES: "No se encontró información"
            },
            BGC_DEMO_NOINFO_ADVICE: {
                PT: "Favor consultar suas configurações",
                EN: "Please consult your settings",
                ES: "Consulte su configuración"
            },
            BGC_DEMO_LOAD_ALERT: {
                FIRST: {
                    PT: "Estamos processando !",
                    EN: "We are processing!",
                    ES: "¡Estamos demandando!"
                },
                MID: {
                    PT: "Aguarde, algumas pessoas ou empresas possuem mais dados que o normal e isso pode demorar.",
                    EN: "Wait, some people or companies have more data than usual and this can take time.",
                    ES: "Espere, algunas personas o empresas tienen más datos de lo habitual y esto puede llevar tiempo."
                },
                LAST: {
                    PT: "Não feche a janela! A Informação está sendo gerada.",
                    EN: "Don't close the window! Information is being generated.",
                    ES: "¡No cierres la ventana! Se está generando información."
                }
            }
        },

        NOA_TEXT: {
            PT: "Permite gerar perguntas com a opção \"nenhuma das anteriores\" como correta quando não houver " +
                "informação suficiente para geração do questionário. Esta opção também gera aleatóriamente respostas NDA como " +
                "opção incorreta em algumas perguntas.",
            ES: "Le permite generar preguntas con la opción \"ninguna de las anteriores\" como correcta cuando ninguna" +
                "suficiente información para generar el cuestionario. Esta opción también genera aleatoriamente respuestas NDA como" +
                "Opción incorrecta en algunas preguntas.",
            EN: "Enables questions that have 'none of the above' as the correct answer. When this option is enabled, other questions will also have 'none of the above' as a possible answer."
        },

        ESSENTIAL_TEXT: {
            PT: "Permite marcar perguntas como essenciais. Dentre as perguntas marcadas como essenciais, o número de perguntas essenciais, selecionado abaixo, obrigatoriamente aparece no questionário, caso contrário o questionário não é gerado.",
            ES: "Le permite marcar preguntas como esenciales. Entre las preguntas marcadas como esenciales, el número de preguntas esenciales seleccionadas a continuación debe aparecer en el cuestionario; de lo contrario, el cuestionario no se genera.",
            EN: "Allows you to mark questions as essential. Among the questions marked as essential, the number of essential questions selected below must reach the questionnaire, otherwise the questionnaire is not generated."
        },

        FLEXIBLE_QUESTIONS_TEXT: {
            PT: "Permite a flexibilidade na geração do Questionário, oferecendo a opção de escolha " +
                "da quantidade mínima e da quantidade máxima de perguntas no Questionário.",
            EN: "Enables the option to set the minimum and maximum number of questions per questionnaire, instead of a fixed number of questions.",
            ES: "Permite flexibilidad en la generación del Cuestionario, ofreciendo la opción de elegir " +
                "el número mínimo y máximo de preguntas del Cuestionario."
        },

        CONFIRM_DEFAULT_ALERT:{
            PT: "Deseja realmente tornar o grupo # como Default ?",
            EN: "Do you really want to make group # as Default?",
            ES:"¿Realmente desea que el grupo # sea el Default?",
        },

        CONFIRM_DEFAULT_TEXT: {
            PT: "Ao tornar esse grupo como Default, este será usado em chamadas que não especificarem qual grupo" +
                " deve ser utilizado.",
            ES: "Cuando establezca este grupo como predeterminado, se utilizará para llamadas que no especifiquen qué grupo" +
                "debe ser usado.",
            EN: "When making this group the Default, it will be used for calls that do not specify which group" +
                "must be used."
        },

        DELETE_GROUP_ALERT:{
            PT:"Deseja realmente apagar o grupo # ?",
            EN:"Do you really want to delete group #?",
            ES:"¿De verdad quieres eliminar el grupo #?"
        },

        BACK: {
            PT: "Voltar",
            EN: "Return",
            ES: "Volver"
        },
        CREATE: {
            PT: "Criar",
            EN: "Create",
            ES: "Crear"
        },
        SMSCHECK: { 
            PT: "Verificação por SMS", 
            EN: "SMS Check", 
            ES: "Verificación por SMS" 
        },
        SMSCHECK_CONFIG_TITLE:{
            PT: "Configurações",
            EN: "Settings",
            ES: "Ajustes"
        },
        NUMBER_OF_DIGITS:{
            PT: "Número de dígitos:",
            EN: "Number of digits:",
            ES: "Número de dígitos:"
        },
        NUMBER_OF_DIGITS_TOOLTIP:{
            EN: "It is the amount of numbers and/or letters that the code sent will have. It can go from 3 to 12 digits.",
            ES: "Es la cantidad de números y/o letras que tendrá el código enviado. Puede ir de 3 a 12 dígitos.",
            PT: "É a quantidade de números e/ou letras que o código enviado vai possuir. Pode ir de 3 a 12 dígitos."
        },
        FACEMATCH_PAGE_TITLE: {
            PT: "FaceMatch",
            EN: "FaceMatch",
            ES: "FaceMatch"
        }
    },

    SMSCHECK: {
        use_Numbers:{
            PT: "Usar números: ",
            EN: "Use numbers: ",
            ES: "Usar números: "
        },
        use_Letters:{
            PT: "Usar letras: ",
            EN: "Use letters: ",
            ES: "Usar letras: "
        },
        SAVE_ERR:{
            PT: "Erro ao salvar!",
            EN: "Error saving!",
            ES: "¡Error al guardar!"
        },
        SAVE_SUCCESS:{
            PT: "Salvo com sucesso!",
            EN: "Saved successfully!",
            ES: "¡Guardado exitosamente!"
        },
        SAVE:{
            PT: "Salvar",
            EN: "Save",
            ES: "Guardar"
        },
        MSG: {
            PT: "Mensagem:",
            EN: "Message:",
            ES: "Mensaje:"
        },
        TIMEOUT_LABEL: {
            EN: "Expiration time (s):",
            ES: "Tiempo de Validez (s):",
            PT: "Tempo de Validade (s):"
        },
        RECHECK_COUNT: {
            PT: "Número de Rechecagens:",
            EN: "Number of Rechecks:",
            ES: "Número de nuevas comprobaciones:"
        },
        RECHECK_COUNT_TOOLTIP: {
            PT: "É o número de vezes que se pode tentar validar um código, é recomendado colocar entre 1 e 2, para não gerar atrito em caso de erro de digitação, porém esse número pode ir de 0 a 5. Caso o numero de tentativas de validação de um mesmo código seja excedido, é retornado o código -1407, solicitando o envio de um novo código.",
            EN: "It is the number of times that you can try to validate a code, it is recommended to put between 1 and 2, so as not to generate friction in case of typing error, however this number can range from 0 to 5. the same code is exceeded, the code -1407 is returned, requesting the sending of a new code.",
            ES: "Es la cantidad de veces que se puede intentar validar un código, se recomienda poner entre 1 y 2, para no generar fricciones en caso de error de digitación, sin embargo este número puede ir de 0 a 5. el mismo código se supera, se devuelve el código -1407, solicitando el envío de un nuevo código."
        },
        SMSCHECK_CODE: {
            PT: "Código",
            EN: "Code",
            ES: "Código"
        },
        SMSCHECK_EXPLAIN_TEXT: {
            PT: "Preencha os dados necessários para enviar o código ao cliente por SMS.",
            EN: "Fill in the necessary data to send the code to the customer via SMS.",
            ES: "Complete los datos necesarios para enviar el Código al cliente por SMS."
        },
        SMSCHECK_SEND_BUTTON: {
            PT: "Enviar Código",
            EN: "Send Code",
            ES: "Enviar Código"
        },
        SMSCHECK_VALIDATE_BUTTON: {
            PT: "Validar Código",
            EN: "Validate Code",
            ES: "Validar Código"
        },
        SMSCHECK_BACK_BUTTON: {
            PT: "Voltar",
            EN: "Back",
            ES: "Regresar"
        },
        SMSCHECK_CODE_EXPLAIN_TEXT: {
            PT: "Preencha o campo abaixo com o token para validação.",
            EN: "Fill the field below to validate the token.",
            ES: "Complete el campo a continuación con el token para la validación."
        },
        SMSCHECK_MESSAGE_MAX_LEN_ERROR: {
            PT: "A mensagem não pode ter mais de 120 caracteres e deve conter o termo '{0}' no lugar onde se deseja que o código apareça.",
            EN: "The message cannot be longer than 120 characters and must contain the term '{0}' in the place where you want the code to appear.",
            ES: "El mensaje no puede tener más de 120 caracteres y debe contener el término '{0}' en el lugar donde desea que aparezca el código."
        }
    },

    facematch: {
        FACEMATCH_PHOTO_INPUT: {
            FIRST: {
                PT: "1ª Foto do rosto",
                EN: "1st Face Photo",
                ES: "1ª Foto de la cara"
            },
            SECOND: {
                PT: "2ª Foto do rosto",
                EN: "2nd Face Photo",
                ES: "2ª Foto de la cara"
            },
        },        

        FACEMATCH_1x1_PAGE_TITLE: {
            PT: "FaceMatch 1x1",
            EN: "1x1 FaceMatching API",
            ES: "FaceMatch 1x1"
        },

        FACEMATCH_UNIFIEDBASE_API_OPTION: {
            PT: "MultiFaces - Base Unificada",
            EN: "MultiFaces - Unified Base",
            ES: "MultiFaces - Base unificada"
        },
        FACEMATCH_UNIFIEDBASE_PHOTO_INPUT: {
            PT: "Foto do rosto - MultiFaces",
            EN: "Face photo - MultiFaces",
            ES: "Foto de la cara - MultiFaces"
        },

        FACEMATCH_UNIFIEDBASE_API_ALERT: {
            CELEBRITY_BASE:{
                PT: "Base de Celebridades",
                EN: "Celebrity Base",
                ES: "Base de Celebridades"
            },
            POLITICIANS_BASE:{
                PT: "Base de Políticos",
                EN: "Politicians Base",
                ES: "Base de Políticos"
            },
            WANTED_BASE:{
                PT: "Base de Procurados",
                EN: "Wanted Base",
                ES: "Base de Buscados"
            },
            CODE: {
                PT: "Código",
                EN: "Code",
                ES: "Código"
            },
            MSG: {
                PT: "Mensagem",
                EN: "Message",
                ES: "Mensaje"
            },
            LOCAL: {
                PT: "Local",
                EN: "Place",
                ES: "Sitio"
            },
            NAME: {
                PT: "Nome",
                EN: "Name",
                ES: "Nombre"
            },
            SIMILARITY: {
                PT: "Similaridade",
                EN: "Similarity",
                ES: "Semejanza"
            },
            ERROR_MSG:{
                PT: "Erro ao fazer a verificação.",
                EN: "Error performing verification.",
                ES: "Error al realizar la verificación."
            }
        }
    },

    backgroundCheckConfigurations: {
        MENU_BACKGROUND_CKECK_CONFIG: {
            PT: "Configurações gerais",
            EN: "General Settings",
            ES: "Configuración general"
        },
        MENU_BASIC_DATA: {
            PT: "Dados Básicos",
            EN: "Basic data",
            ES: "Datos básicos"
        },
        MENU_NAME_VALIDATION: {
            PT: "Validação de nome",
            EN: "Name validation",
            ES: "Validación de nombre"
        },
        MENU_MAIL_VALIDATION: {
            PT: "Validação de e-mail",
            EN: "E-mail validation",
            ES: "Validación de e-mail"
        },
        MENU_MINIMUM_AGE_VALIDATION: {
            PT: "Validação de Idade mínima",
            EN: "Minimum Age Validation",
            ES: "Validación de edad mínima"
        },
        MENU_BLACKLIST: "Blocklist",
        MENU_KNOW_YOUR_CLIENT: "Know-Your-Client (KYC)",

        MENU_PUBLIC_AGENT: {
            PT: "Funcionário Público",
            EN: "Public Service",
            ES: "Funcionario público"
        },
        MENU_DEBIT_PRESENCE: {
            PT: "Presença em cobrança",
            EN: "Records of debt collections",
            ES: "Presencia en colección"
        },
        MENU_INCOME: {
            PT: "Renda",
            EN: "Income",
            ES: "Renta"
        },
        MENU_LAWSUITS_PRESENCE: {
            PT: "Presença em ações judiciais",
            EN: "Lawsuits",
            ES: "Presencia en juicios"
        },
        MENU_CLASSIST: {
            PT: "Classista",
            EN: "Professional entities",
            ES: "Clasista"
        },
        MENU_CELEBRITY: {
            PT: "Celebridade",
            EN: "Celebrity",
            ES: "Celebridad"
        },
        MENU_SOCIAL_BENEFIT: {
            PT: "Benefícios sociais",
            EN: "Government assistance programs",
            ES: "Beneficios sociales"
        },
        MENU_RELATIONSHIP: {
            PT: "Relacionamento (pessoa física)",
            EN: "Personal Relationships",
            ES: "Relación (persona física)"
        },
        MENU_RELATIONSHIP_PJ: {
            PT: "Relacionamento (pessoa jurídica)",
            EN: "Corporate Relationships",
            ES: "Relación (entidad legal)"
        },
        SCORE: "Score",
        SCORE_INDIVIDUAL: {
            PT: "Score Individual",
            EN: "Individual Score",
            ES: "Puntaje individual"
        },
        TOTALSCORE: {
            PT: "Score Total",
            EN: "Total Score",
            ES: "Score Total"
        },
        ORGANIZATION: {
            PT: "Organização",
            EN: "Organization",
            ES: "Organización"
        },
        CATEGORY: {
            PT: "Categoria",
            EN: "Category",
            ES: "Categoría"
        },
        STATUS: "Status",
        BACKGROUND_CHECK: {
            PT: "Background Check - Pessoa Física",
            EN: "Background Check - Individuals",
            ES: "Background Check - Individuos"
        },
        BACKGROUND_CHECK_TEXT: {
            PT: "O Background Check, verifica várias informações sobre uma pessoa a partir do número do seu CPF, todas as pontuações de score são editáveis, ou seja, é possível atribuir uma pontuação para cada uma das informações.",
            EN: "This functionality checks several data points about an individual based on their tax id number. Each individual check can be enabled or disabled, and each check can have a number of points assigned to it. The checks use a rejection threshold model, that is, the individual is rejected by the system if they reach a certain amount of points.",
            ES: "La verificación de antecedentes verifica diversa información sobre una persona en función de su número de seguro social, todos los puntajes de score son editables, es decir, es posible asignar un puntaje a cada una de la información."
        },
        KNOW_YOUR_CLIENT_TEXT:{
            PT:"A correspondência com a maioria das listas é baseada no nome, portanto, a porcentagem de correspondência de nome desejada deve ser configurada.",
            EN:"Matching against most lists is name-based, so the desired name matching percentage must be configured.",
            ES:"La comparación con la mayoría de las listas se basa en el nombre, por lo que se debe configurar el porcentaje de coincidencia de nombres deseado."
        },
        REPROVATION_POINT: {
            PT: "Pontuação para reprovação",
            EN: "Rejection threshold score",
            ES: "Puntuación de desaprobación"
        },
        REPROVATION_POINT_TEXT: {
            PT: "A pontuação para reprovação é o  valor, em pontos, que, se atingido, reprova seu candidato no início de seu processo. A cada uma das configurações do Background Check é atribuído um valor em pontos e, em caso de falha naquela validaçãõ, esses pontos serão acumulados.",
            EN: "The rejection threshold is the value, in points, that a candidate must stay below in order to be approved. Each individual check has a points value, and the sum of the points of all checks that the individual failed is the total score for that individual. If this total score is equal to or above the threshold, the person is rejected.",
            ES: "El puntaje de falla es el valor, en puntos, que, si se alcanza, falla a su candidato al comienzo de su proceso. A cada una de las configuraciones de verificación de antecedentes se le asigna un valor de puntos y, en caso de falla en esa validación, estos puntos se acumularán."
        },
        RECEIVE_BACKGROUND_CHECK_DATA: {
            PT: "Sempre retornar dados utilizados no Background Check",
            EN: "Always return data used in the Background Check",
            ES: "Devolver siempre los datos utilizados en el Background Check"
        },
        RECEIVE_BACKGROUND_CHECK_DATA_TEXT: {
            PT: "A princípio, o Background Check só retorna o sucesso ou falha na validação do seu cliente, para receber os dados específicos dessas validações entre em contato com a equipe comercial e assine os dados do BigBost.",
            EN: "By default, the Background Check functionality only returns the \"approved\" or \"rejected\" status of the individual. By enabling this option, you will also ALWAYS receive the underlying data that was used to perform the validations. ATTENTION: YOU WILL BE CHARGED FOR THIS DATA ACCORDING TO THE BIGBOOST PRICING TABLE. If you have any questions about the pricing, get in touch with your account manager. Alternatively, you can use the options below to receive the data ONLY when the person is \"approved\" or ONLY when they are \"rejected\". ",
            ES: "En principio, Background Check solo devuelve el acierto o fracaso en la validación de su cliente, para recibir los datos específicos de estas validaciones contacte con el equipo de ventas y firme los datos de BigBost."
        },
        RECEIVE_BACKGROUND_CHECK_APPROVED: {
            PT: "Retornar dados utilizados em caso de Aprovação",
            EN: "Return data only on \"approved\" status",
            ES: "Devolver datos utilizados en caso de aprobación"
        },
        RECEIVE_BACKGROUND_CHECK_REPROVED: {
            PT: "Retornar dados utilizados em caso de Reprovação",
            EN: "Return data only on \"rejected\" status",
            ES: "Devolver datos usados ​​en caso de desaprobación"
        },
        KYC_OBIT_STATUS: {
            PT: "Óbito",
            EN: "Deceased Individual",
            ES: "Muerte"
        },
        KYC_OBIT_STATUS_TEXT: {
            PT: "A validação de óbito verifica, junto à Receita Federal, se o CPF fornecido está registrado como óbito. Esta opção impede possíveis fraudadores de utilizarem CPF de pessoas falecidas para abertura de contas, etc.",
            EN: "This check ensures that there is no official record of death registered for the tax id number. This option prevents potential fraudsters from using the CPF of deceased people to open accounts, etc.",
            ES: "La validación de muerte verifica, con el IRS, si el CPF proporcionado está registrado como muerte. Esta opción evita que los posibles estafadores utilicen el CPF de personas fallecidas para abrir cuentas, etc."
        },
        KYC_OBIT_STATUS_VALUE: /*{
            PT:*/"Score"/*,
            EN:"Score",
            ES:"Score"
        }*/,
        IP_LOCATION:{
            PT: "Localização do IP",
            EN: "IP Location",
            ES: "Ubicación IP"
        },
        IP_LOCATION_TEXT:{
            PT: "A validação de localização do IP verifica se o endereço IP da chamada possui o mesmo estado do CPF da consulta.",
            EN: "IP location validation verifies that the IP address of the call has the same state as the CPF of the query.",
            ES: "La validación de ubicación IP verifica que la dirección IP de la llamada tenga el mismo estado que el CPF de la consulta."
        },
        KYC_CPF_STATUS: {
            PT: "Status de CPF",
            EN: "Tax Id Status",
            ES: "Status de CPF"
        },
        KYC_CPF_STATUS_TEXT: {
            PT: "A validação de status de CPF verifica, junto à receita Federal se o CPF fornecido está regular na Receita Federal e bloqueia a requisição caso contrário, um CPF pode estar irregular ou cancelado por diversos motivos, incluindo pendências nas declarações de imposto de renda, cadastro incompleto e até mesmo por decisão judicial.",
            EN: "This check ensures that the tax id number has a \"Regular\" status with the government. A tax id may have a status different from \"Regular\" for several reasons, such as pending income tax returns, incomplete registration, or court decisions.",
            ES: "La validación del status del CPF verifica con la Hacienda Federal que el CPF provisto es regular con la Recaudación Federal y bloquea la solicitud de lo contrario, un CPF puede ser irregular o cancelado debido a varias razones, incluidas las declaraciones de impuestos sobre la renta pendientes, registro incompleto e incluso por decisión judicial. ."
        },
        API_TOKEN_BIGBOOST:{
            PT: "API Token BigBoost: ",
            EN: "BigBoost API Token: ",
            ES: "API Token BigBoost: "
        },
        KYC_PEP: {
            PT: "Pessoa Politicamente exposta",
            EN: "Current Politically Exposed Person (PEP)",
            ES: "Persona Expuesta Políticamente"
        },
        KYC_PAST_PEP: {
            PT: "Pessoa que já foi Politicamente exposta",
            EN: "Previously Politically Exposed Person (PEP)",
            ES: "Persona que ya ha sido expuesta políticamente"
        },
        KYC_PAST_PEP_TEXT: {
            PT: "Esta verificação analisa os dados históricos de pessoas politicamente expostas para ver se a pessoa era anteriormente um PEP, mesmo que não seja mais.",
            EN: "This check looks at the historical data for politically exposed persons to see if the person was previously a PEP, even if they no longer are.",
            ES: "Esta verificación analiza los datos históricos de las personas políticamente expuestas para ver si la persona era anteriormente una PEP, incluso si ya no lo es."
        },
        PF_INCOME_ESTIMATE: {
            PT: "Quantidade de salários mínimos: ",
            EN: "Estimated income range:",
            ES: "Número de salarios mínimos:"
        },
        KYC_PF_SLAVERY: {
            PT: "Lista de trabalho escravo",
            EN: "Slavery sanctions list",
            ES: "Lista de trabajo esclavo"
        },
        KYC_PF_MONEY_LAUNDERING: {
            PT: "Lista de lavagem de dinheiro",
            EN: "National and International Anti-Money Laundering (AML) lists",
            ES: "Lista de blanqueo de capitales"
        },
        KYC_CNEP_PF: {
            PT: "Cadastro Nacional de Empresas Punidas (CNEP)",
            EN: "National Registry of Punished Companies (CNEP)",
            ES: "Registro Nacional de Empresas Sancionadas (CNEP)"
        },
        KYC_CNEP_PF_INACTIVE: {
            PT: "CNEP Inativo",
            EN: "CNEP Inactive",
            ES: "CNEP Inactivo"
        },
        KYC_CVM: {
            PT: "Comissão de Valores Mobiliários (CVM)",
            EN: "Brazilian Securities and Exchange Comission (CVM)",
            ES: "Comisión de Valores Móviles (CVM)"
        },
        PF_COLLECTIONS_ORIGINS: {
            PT: 'Quantidade de empresas cobrando',
            EN: "Number companies collecting debt",
            ES: "Número de empresas que cobran"
        },
        PF_COLLECTIONS_QTY: {
            PT: 'Quantidade total de cobranças',
            EN: "Number of debt collection processes",
            ES: "Cantidad total de cargos"
        },
        KYC_AGE: {
            PT: "Idade",
            EN: "Age",
            ES: "Edad"
        },
        VALUE: {
            PT: 'Valor',
            EN: "Value",
            ES: "Valor"
        },
        OPERAND: {
            PT: "Operador",
            EN: "Operator",
            ES: "Operador"
        },
        QUANTITY: {
            PT: "Quantidade",
            EN: "Quantity",
            ES: "Cantidad"
        },
        RANGE: {
            PT: 'Alcance',
            EN: "Reach",
            ES: "Alcanze"
        },
        SALARY: {
            PT: 'Salário',
            EN: "Salary",
            ES: "Salario"
        },
        SELECT: {
            PT: 'Selecione',
            EN: "Select",
            ES: "Seleccione"
        },
        PERCENT: {
            PT: 'Percentual',
            EN: "Percent",
            ES: "Por ciento"
        },
        KYC_PERCENT:{
            PT:"Percentual",
            EN:"Name match percentage",
            ES:"Por ciento"
        },
        ITEM_ADD: {
            PT: 'adicionar item',
            EN: "add item",
            ES: "agregar elemento"
        },
        POSITION: {
            PT: 'Posição',
            EN: "Position",
            ES: "Posición"
        },
        TYPE_WORD: {
            PT: 'Digite uma palavra:',
            EN: "Type a word:",
            ES: "Escribe una palabra:"
        },
        LAWSUIT_KEYWORD: {
            PT: 'Palavras-Chave do processo',
            EN: "Lawsuit keyword",
            ES: "Palabras clave pleito"
        },
        ADD_WORD: {
            PT: 'Adicionar palavra',
            EN: "Add word",
            ES: "Agregar palabra"
        },
        CLASS_ORG_NAME: {
            PT: "Conselho: ",
            EN: "Professional entity: ",
            ES: "Consejo: "
        },
        CLASS_ORG_CATEGORY: {
            PT: "Categoria: ",
            EN: "Category: ",
            ES: "Categoría: "
        },
        CLASS_ORG_STATUS: {
            PT: 'Status: ',
            EN: 'Status: ',
            ES: 'Status: ',
        },
        CELEBRITY_LEVEL: {
            PT: 'Celebridade',
            EN: "Celebrity",
            ES: "Celebridad"
        },
        SOCIAL_ASSISTANCE: {
            PT: 'Beneficios sociais',
            EN: "Government assistance programs",
            ES: "Beneficios sociales"
        },
        KYC_CEAF: {
            PT: 'Cadastro de Expulsões da Administração Federal (CEAF)',
            EN: "Registry of Individuals Expelled from the Federal Administration (CEAF)",
            ES: "Registro de Expulsiones de la Administración Federal (CEAF)"
        },
        KYC_TERRORIST: {
            PT: 'Lista de terroristas',
            EN: "National and international wanted terrorists lists",
            ES: "Lista de terroristas"
        },
        KYC_COURT_ORDERS: {
            PT: 'Lista de foragidos da justiça',
            EN: "Wanted criminals",
            ES: "Lista de prófugos de la justicia"
        },
        KYC_NAME_MATCH_PERCENT: {
            PT:"Percentual",
            EN:"Name match percentage",
            ES:"Por ciento"
        },
        PUB_EMP_ORG_NAME: {
            PT: 'Funcionário Público',
            EN: "Public Service",
            ES: "Funcionario público"
        },
        PUB_EMP_TYPE: {
            PT: 'Esfera',
            EN: 'Level of government',
            ES: 'Esfera',
        },
        PUB_EMP_STATUS: {
            PT: 'Status',
            EN: 'Status',
            ES: 'Status',
        },
        PROCESS_UF: {
            PT: 'Unidade Federativa',
            EN: "Lawsuit location",
            ES: "Unidad federativa"
        },
        PROCESS_QTY: {
            PT: 'Quantidade de litígio',
            EN: "Total lawsuit value",
            ES: "Cantidad litigios"
        },
        PROCESS_COURT_TYPES: {
            PT: 'Tipo de tribunal',
            EN: "Court type",
            ES: "Tipo de cancha"
        },
        RELATED_PEOPLE: {
            PT: 'Relacionamento',
            EN: "Relationship",
            ES: "Relación"
        },
        KYC_CNEP_REL_CNPJ: {
            PT: 'CNEP de Empresas Relaciondas',
            EN: "CNEP of Related Companies",
            ES: "CNEP de Empresas Relacionadas"
        },
        KYC_CNEP_REL_CNPJ_INACTIVE: {
            PT: 'CNEP Inativo de Empresas Relacionas',
            EN: "Inactive CNEP of Related Companies",
            ES: "CNEP inactivo de empresas relacionadas"
        },
        PF_REL_CNPJ_QTY: {
            PT: 'Quantidade de Empresas Relacionadas',
            EN: "Number of Related Companies",
            ES: "Número de Empresas Relacionadas"
        },
        PF_REL_CNPJ_QTY_TEXT: {
            PT: 'Adiciona o score especiicado abaixo multiplicado pela quantidade de empresas cuja pessoa é proprietária.',
            EN: "Add the score indicated below multiplied by the number of companies owned by the person.",
            ES: "Sume la puntuación que se indica a continuación multiplicada por el número de empresas que posee la persona."
        },
        PF_ACTIVE_COLLECTIONS:{
            PT: 'Cobranças Ativas',
            EN: "Active Debt Collections",
            ES: "Cargos Activos"
        },
        PF_ACTIVE_COLLECTIONS_TEXT:{
            PT: 'Adiciona o score configurado abaixo caso a pessoa possua cobranças ativas na data da consulta.',
            EN: "Adds the score configured below if the person has active debt collections on the date of the consultation.",
            ES: "Agrega el puntaje configurado a continuación si la persona tiene cobros activos a la fecha de la consulta."
        },
        validations: {
        },
    },

    backgroundCheckConfigurations_PJ: {
        PJ_ALL_QSA_OBIT:{
            PT: "Óbito de todos os sócios",
            EN: "Death of all partners",
            ES: "Muerte de todos los socios."
        },
        MENU_BACKGROUND_CKECK_CONFIG: {
            PT: "Configurações gerais",
            EN: "General Settings",
            ES: "Configuración general"
        },
        MENU_BASIC_DATA: {
            PT: "Dados Básicos",
            EN: "Basic data",
            ES: "Datos básicos"
        },
        MENU_NAME_VALIDATION: {
            PT: "Validação de nome",
            EN: "Name validation",
            ES: "Validación de nombre"
        },
        MENU_MAIL_VALIDATION: {
            PT: "Validação de e-mail",
            EN: "E-mail validation",
            ES: "Validación de e-mail"
        },
        MENU_MINIMUM_AGE_VALIDATION: {
            PT: "Validação de Idade mínima",
            EN: "Minimum Age Validation",
            ES: "Validación de edad mínima"
        },
        MENU_BLACKLIST: "Blocklist",
        MENU_KNOW_YOUR_CLIENT: "Know-Your-Client (KYC)",
        MENU_PUBLIC_AGENT: {
            PT: "Funcionário Público",
            EN: "Public Service",
            ES: "Funcionario público"
        },
        MENU_DEBIT_PRESENCE: {
            PT: "Presença em cobrança",
            EN: "Records of debt collections",
            ES: "Presencia en colección"
        },
        MENU_INCOME: {
            PT: "Renda",
            EN: "Income",
            ES: "Renta"
        },
        MENU_LAWSUITS_PRESENCE: {
            PT: "Presença em ações judiciais",
            EN: "Lawsuits",
            ES: "Presencia en juicios"
        },
        MENU_CLASSIST: {
            PT: "Classista",
            EN: "Professional entities",
            ES: "Clasista"
        },
        MENU_SOCIAL_BENEFIT: {
            PT: "Benefícios sociais",
            EN: "Government assistance programs",
            ES: "Beneficios sociales"
        },
        MENU_RELATIONSHIP: {
            PT: "Relacionamento (pessoa física)",
            EN: "Personal Relationships",
            ES: "Relación (persona física)"
        },
        MENU_RELATIONSHIP_PJ: {
            PT: "Relacionamento (pessoa jurídica)",
            EN: "Corporate Relationships",
            ES: "Relación (entidad legal)"
        },
        USE_RELATED_LIMIT: {
            PT: "Usar Quantidade de Relacionamento",
            EN: "Use Relationship Quantity",
            ES: "Usar cantidad de relación"
        },
        USE_RELATED_LIMIT_TEXT: {
            PT: "Habilita ou desabilita o uso da Quantidade de Relacionamentos.",
            EN: "Enables or disables the use of the Quantity of Relationships.",
            ES: "Habilita o deshabilita el uso de la cantidad de relaciones."
        },
        RELATED_LIMIT_QTY: {
            PT: "Quantidade de Relacionamento",
            EN: "Relationship Quantity",
            ES: "Cantidad de relación"
        },
        RELATED_LIMIT_QTY_TEXT: {
            PT: "Valor a ser definido como a Quantidade de Relacionamentos.",
            EN: "Value to be defined as the Number of Relationships.",
            ES: "Valor que se definirá como el número de relaciones."
        },
        SCORE: "Score",
        TOTALSCORE: {
            PT: "Score Total",
            EN: "Total Score",
            ES: "Score Total"
        },
        ORGANIZATION: {
            PT: "Organização",
            EN: "Organization",
            ES: "Organización"
        },
        CATEGORY: {
            PT: "Categoria",
            EN: "Category",
            ES: "Categoría"
        },
        BACKGROUND_CHECK: {
            PT: "Background Check - Pessoa Juridica",
            EN: "Background Check - Company",
            ES: "Background Check - Empresa"
        },
        BACKGROUND_CHECK_TEXT: {
            PT: "O Background Check, verifica várias informações sobre uma pessoa a partir do número do seu CPF, todas as pontuações de score são editáveis, ou seja, é possível atribuir uma pontuação para cada uma das informações.",
            EN: "Background Check verify various information about a person based on his Social Security number, all scores are editable, that is, it is possible to assign a score to each of the information.",
            ES: "La verificación de antecedentes verifica diversa información sobre una persona en función de su número de seguro social, todos los puntajes de score son editables, es decir, es posible asignar un puntaje a cada una de la información."
        },
        REPROVATION_POINT: {
            PT: "Pontuação para reprovação",
            EN: "Disapproval score",
            ES: "Puntuación de desaprobación"
        },
        REPROVATION_POINT_TEXT: {
            PT: "A pontuação para reprovação é o  valor, em pontos, que, se atingido, reprova seu candidato no início de seu processo. A cada uma das configurações do Background Check é atribuído um valor em pontos e, em caso de falha naquela validaçãõ, esses pontos serão acumulados.",
            EN: "The failure score is the value, in points, which, if reached, fails your candidate at the beginning of his process. Each of the Background Check configurations is assigned a point value and, in case of failure in that validation, these points will be accumulated.",
            ES: "El puntaje de falla es el valor, en puntos, que, si se alcanza, falla a su candidato al comienzo de su proceso. A cada una de las configuraciones de verificación de antecedentes se le asigna un valor de puntos y, en caso de falla en esa validación, estos puntos se acumularán."
        },
        RECEIVE_BACKGROUND_CHECK_DATA: {
            PT: "Sempre retornar dados utilizados no Background Check",
            EN: "Always return data used in the Background Check",
            ES: "Devolver siempre los datos utilizados en el Background Check"
        },
        RECEIVE_BACKGROUND_CHECK_DATA_TEXT: {
            PT: "A princípio, o Background Check só retorna o sucesso ou falha na validação do seu cliente, para receber os dados específicos dessas validações entre em contato com a equipe comercial e assine os dados do BigBost.",
            EN: "In principle, Background Check only returns the success or failure in validating your client, to receive the specific data of these validations contact the sales team and sign the BigBost data.",
            ES: "En principio, Background Check solo devuelve el acierto o fracaso en la validación de su cliente, para recibir los datos específicos de estas validaciones contacte con el equipo de ventas y firme los datos de BigBost."
        },
        RECEIVE_BACKGROUND_CHECK_APPROVED: {
            PT: "Retornar dados utilizados em caso de Aprovação",
            EN: "Return data used in case of Approval",
            ES: "Devolver datos utilizados en caso de aprobación"
        },
        RECEIVE_BACKGROUND_CHECK_APPROVED_TEXT: {
            PT: "Quando o BackGround Check retornar Sucesso na validação do seu cliente, irá receber os dados específicos dessas validações.",
            EN: "When BackGround Check returns Success in validating your client, you will receive data specific to those validations.",
            ES: "Cuando BackGround Check devuelva Éxito en la validación de su cliente, recibirá datos específicos para esas validaciones."
        },
        RECEIVE_BACKGROUND_CHECK_REPROVED: {
            PT: "Retornar dados utilizados em caso de Reprovação",
            EN: "Return data used in case of disapproval",
            ES: "Devolver datos usados ​​en caso de desaprobación"
        },
        RECEIVE_BACKGROUND_CHECK_REPROVED_TEXT: {
            PT: "Quando o BackGround Check retornar Falha na validação do seu cliente, irá receber os dados específicos dessas validações.",
            EN: "When BackGround Check returns Failure to validate your client, you will receive data specific to those validations.",
            ES: "Cuando BackGround Check devuelve una falla en la validación de su cliente, recibirá datos específicos para esas validaciones."
        },
        API_TOKEN_BIGBOOST: "API Token BigBoost: ",
        PF_INCOME_ESTIMATE: {
            PT: "Quantidade de salários mínimos: ",
            EN: "Estimated income range:",
            ES: "Número de salarios mínimos:"
        },
        KYC_CNEP: "CNEP",
        VALUE: {
            PT: 'Valor',
            EN: "Value",
            ES: "Valor"
        },
        OPERAND: {
            PT: "Operador",
            EN: "Operator",
            ES: "Operador"
        },
        QUANTITY: {
            PT: "Quantidade",
            EN: "Quantity",
            ES: "Cantidad"
        },
        CLASS_ORG_NAME: {
            PT: "Conselho: ",
            EN: "Professional entity: ",
            ES: "Consejo: "
        },
        CLASS_ORG_CATEGORY: {
            PT: "Categoria: ",
            EN: "Category: ",
            ES: "Categoría: "
        },
        CLASS_ORG_STATUS: {
            PT: 'Status: ',
            EN: 'Status: ',
            ES: 'Status: ',
        },
        CELEBRITY_LEVEL: {
            PT: 'Celebridade',
            EN: "Celebrity",
            ES: "Celebridad"
        },
        SOCIAL_ASSISTANCE: {
            PT: 'Beneficios sociais',
            EN: "Government assistance programs",
            ES: "Beneficios sociales"
        },
        STATUS: {
            PT: "Status da Empresa",
            EN: "Company Status",
            ES: "Status de la Empresa"
        },
        SIMPLES: {
            PT: "Simples Nacional",
            EN: "Simple National",
            ES: "Nacional simple"
        },
        CNAE: {
            PT: "Código Atividade Econômica (CNAE)",
            EN: "Economic Activity Code (CNAE)",
            ES: "Código de Actividad Económica (CNAE)"
        },
        KYC_CEIS: {
            PT: "Cadastro de Empresas Inidôneas e Suspensas (CEIS)",
            EN: "Register of Unfaithful and Suspended Companies (CEIS)",
            ES: "Registro de Empresas Infieles y Suspendidas (CEIS)"
        },
        KYC_CEIS_INACTIVE: {
            PT: "CEIS Inativo",
            EN: "CEIS Inactive",
            ES: "CEIS Inactivo"
        },
        KYC_PJ_MONEY_LAUDERING: {
            PT: "Lista de lavagem de dinheiro",
            EN: "National and International Anti-Money Laundering (AML) lists",
            ES: "Lista de blanqueo de capitales"
        },
        KYC_TERRORIST: {
            PT: 'Lista de terroristas',
            EN: "National and international wanted terrorists lists",
            ES: "Lista de terroristas"
        },
        KYC_COURT_ORDERS: {
            PT: 'Lista de foragidos da justiça',
            EN: "Wanted criminals",
            ES: "Lista de prófugos de la justicia"
        },
        PROCESS_COURT_TYPES_PJ: {
            PT: 'Tipo de tribunal',
            EN: "Court type",
            ES: "Tipo de cancha"
        },
        PROCESS_COURT_TYPES: {
            PT: 'Tipo de tribunal',
            EN: "Court type",
            ES: "Tipo de cancha"
        },
        KYC_PJ_SLAVERY: {
            PT: "Lista de trabalho escravo",
            EN: "Slavery sanctions list",
            ES: "Lista de trabajo esclavo"
        },
        RELATED_PEOPLE: {
            PT: 'Relacionamento',
            EN: "Relationship",
            ES: "Relación"
        },
        KYC_PJ_CNEP: {
            PT: 'CNEP da Empresa',
            EN: "Company CNEP",
            ES: "CNEP de la Empresa"
        },
        KYC_PJ_CNEP_INACTIVE: {
            PT: 'CNEP de Empresas Inativo',
            EN: "CNEP of Inactive Companies",
            ES: "CNEP de Empresas Inactivas"
        },
        KYC_CEPIM: {
            PT: "Cadastro de Entidades Privadas Sem Fins Lucrativos Impedidas (CEPIM)",
            EN: "Registration of Impeded Private Non-Profit Entities (CEPIM)",
            ES: "Registro de Entidades Privadas Sin Fines de Lucro impedidas (CEPIM)"
        },
        KYC_CEPIM_INACTIVE: {
            PT: "CEPIM Inativo",
            EN: "CEPIM Inactive",
            ES: "CEPIM Inactivo"
        },
        COMPANY_AGE: {
            PT: "Idade da Empresa",
            EN: "Company Age",
            ES: "Edad de la Empresa"
        },
        QSA: {
            PT: "QSA da Empresa",
            EN: "Company QSA",
            ES: "QSA de la Empresa"
        },
        KYC_PEP: {
            PT: "Pessoa Politicamente exposta",
            EN: "Current Politically Exposed Person (PEP)",
            ES: "Persona Expuesta Políticamente"
        },
        KYC_PAST_PEP: {
            PT: "Pessoa que já foi Politicamente exposta",
            EN: "Previously Politically Exposed Person (PEP)",
            ES: "Persona que ya ha sido expuesta políticamente"
        },
        KYC_PAST_PEP_TEXT: {
            PT: "Esta verificação analisa os dados históricos de pessoas politicamente expostas para ver se a pessoa era anteriormente um PEP, mesmo que não seja mais.",
            EN: "This check looks at the historical data for politically exposed persons to see if the person was previously a PEP, even if they no longer are.",
            ES: "Esta verificación analiza los datos históricos de las personas políticamente expuestas para ver si la persona era anteriormente una PEP, incluso si ya no lo es."
        },
        KYC_CNEP_PF: {
            PT: "Cadastro Nacional de Empresas Punidas (CNEP)",
            EN: "National Registry of Punished Companies (CNEP)",
            ES: "Registro Nacional de Empresas Sancionadas (CNEP)"
        },
        KYC_CNEP_PF_INACTIVE: {
            PT: "CNEP Inativo",
            EN: "CNEP Inactive",
            ES: "CNEP Inactivo"
        },
        KYC_AGE: {
            PT: "Idade",
            EN: "Age",
            ES: "Edad"
        },
        KYC_CPF_STATUS: {
            PT: "Status de CPF",
            EN: "CPF Status",
            ES: "Status de CPF"
        },
        KYC_PF_SLAVERY: {
            PT: "Lista de trabalho escravo",
            EN: "Slavery sanctions list",
            ES: "Lista de trabajo esclavo"
        },
        KYC_OBIT_STATUS: {
            PT: "Óbito",
            EN: "Death",
            ES: "Muerte"
        },
        KYC_PF_MONEY_LAUNDERING: {
            PT: "Lista de lavagem de dinheiro",
            EN: "National and International Anti-Money Laundering (AML) lists",
            ES: "Lista de blanqueo de capitales"
        },
        PF_COLLECTIONS_ORIGINS: {
            PT: 'Quantidade de empresas cobrando',
            EN: "Number companies collecting debt",
            ES: "Número de empresas que cobran"
        },
        PF_COLLECTIONS_QTY: {
            PT: 'Quantidade total de cobranças',
            EN: "Number of debt collection processes",
            ES: "Cantidad total de cargos"
        },
        PUB_EMP_ORG_NAME: {
            PT: 'Funcionário Público',
            EN: "Public Service",
            ES: "Funcionario público"
        },
        PUB_EMP_TYPE: {
            PT: 'Esfera',
            EN: 'Level of government',
            ES: 'Esfera',
        },
        PUB_EMP_STATUS: {
            PT: 'Status',
            EN: 'Status',
            ES: 'Status',
        },
        KYC_CEAF: {
            PT: 'Cadastro de Expulsões da Administração Federal (CEAF)',
            EN: "Registry of Individuals Expelled from the Federal Administration (CEAF)",
            ES: "Registro de Expulsiones de la Administración Federal (CEAF)"
        },
        CNAE_ADD: {
            PT: "Adicionar CNAE",
            EN: "Add CNAE",
            ES: "Añadir CNAE"

        },
        CNAE_TYPE_IN: {
            PT: "Digite um CNAE:",
            EN: "Enter a CNAE:",
            ES: "Ingrese una CNAE:"

        },

        IP_LOCATION_PJ: {
            PT: "Localização do IP",
            EN: "IP Location",
            ES: "Ubicación IP"
        },

        IP_LOCATION_PJ_TEXT: {
            PT: "A validação de localização do IP verifica se o endereço IP da chamada possui o mesmo estado do CNPJ da consulta.",
            EN: "IP location validation verifies that the IP address of the call has the same state as the company id of the query.",
            ES: "La validación de ubicación IP verifica que la dirección IP de la llamada tenga el mismo estado que el CPF de la consulta."
        },

        validations: {

        },
    },

    reports: {
        menu: {
            ABTESTING: { TITLE: "Teste A/B", SUBS: {} },

            // REPORTS SUB MENUS
            // Colocar os relatórios de novos produtos com os submenus abaixo.
            // Sempre seguindo o padrão de GERAL e DETALHADO
            GENERAL:{
                TITLE: "Geral",
                SUBS: {}
            },
            QUESTIONNAIRE: {
                TITLE: "Questionário",
                SUBS: {
                    GERAL: {
                        TITLE: "Geral",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    QUESTIONS: { 
                        TITLE: "Perguntas",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    ANSWERS: { 
                        TITLE: "Respostas",
                        PARENT: "Questionário",
                        SUBS: {}
                    },
                    ABTESTING: {
                        TITLE: "Teste A/B",
                        PARENT: "Questionário",
                        SUBS: {}
                    }
                }
            },
            BACKGROUND_CHECK: {
                TITLE: "Background Check",
                SUBS: {
                    GERAL: {
                        TITLE: "Geral",
                        PARENT: "Background Check",
                        SUBS: {}
                    },
                    DETALHADO: {
                        TITLE: "Detalhado",
                        PARENT: "Background Check",
                        SUBS: {}
                    },
                    ABTESTING: {
                        TITLE: "Teste A/B",
                        PARENT: "Background Check",
                        SUBS: {}
                    }
                }
            },
            OCR: {
                TITLE: "Documentoscopia e OCR",
                SUBS: {
                    GERAL: {
                        TITLE: "Geral",
                        PARENT: "Documentoscopia e OCR",
                        SUBS: {}
                    },
                    DETALHADO: {
                        TITLE: "Detalhado",
                        PARENT: "Documentoscopia e OCR",
                        SUBS: {}
                    }
                }
            },
            FACEMATCH: {
                TITLE: "FaceMatch",
                SUBS: {
                    GERAL: {
                        TITLE: "Geral",
                        PARENT: "FaceMatch",
                        SUBS: {}
                    },
                    DETALHADO: {
                        TITLE: "Detalhado",
                        PARENT: "FaceMatch",
                        SUBS: {}
                    }
                }
            },
        },
        INVALID_FORMAT:{
            PT: "Formato inválido",
            EN: "Invalid format",
            ES: "Formato inválido"
        },
        DETAILED_TITLE: {
            PT: "Detalhado",
            EN: "Detailed",
            ES: "Detallado"
        },
        GENERAL_TITLE: {
            PT: "Relatório Geral das APIs",
            EN: "APIs General Report",
            ES: "Informe general de API"
        },
        QUEST_GENERAL_TITLE: {
            PT: "Relatório Geral do Questionário",
            EN: "Questionnaire General Report",
            ES: "Informe general del Questionário"
        },
        BGC_DETAILED_TITLE: {
            PT: "Relatório Detalhado do Background Check",
            EN: "Background Check Detailed Report",
            ES: "Informe detallado del Background Check"
        },
        BGC_GENERAL_TITLE: {
            PT: "Relatório Geral do Background Check",
            EN: "Background Check General Report",
            ES: "Informe general del Background Check"
        },
        OCR_DETAILED_TITLE: {
            PT: "Relatório Detalhado do OCR",
            EN: "OCR Detailed Report",
            ES: "Informe detallado del OCR"
        },
        OCR_GENERAL_TITLE: {
            PT: "Relatório Geral do OCR",
            EN: "OCR General Report",
            ES: "Informe general del OCR"
        },
        FACEMATCH_DETAILED_TITLE: {
            PT: "Relatório Detalhado do Facematch",
            EN: "Facematch Detailed Report",
            ES: "Informe detallado del Facematch"
        },
        FACEMATCH_GENERAL_TITLE: {
            PT: "Relatório Geral do Facematch",
            EN: "Facematch General Report",
            ES: "Informe general del Facematch"
        },
        INICIAL_DATE: {
            PT: "Data Inicial: ",
            EN: "Start date: ",
            ES: "Fecha de inicio: "
        },
        FINAL_DATE: {
            PT: "Data Final: ",
            EN: "End date: ",
            ES: "Fecha final: "
        },
        GROUPS: {
            PT: "Grupos: ",
            EN: "Groups: ",
            ES: "Grupos: "
        },
        GENERATE_REPORT: {
            PT: "Gerar Relatório",
            EN: "Generate Report",
            ES: "Generar Informe"
        },
        REPORT: {
            PT: "Relatório",
            EN: "Report",
            ES: "Informe"
        },
        MAX_PERIOD_ALERT: {
            PT: "O relatório só pode ser gerado em um intervalo de no máximo 60 dias.",
            EN: "The report can only be generated within a maximum of 60 days.",
            ES: "El informe solo se puede generar en un plazo máximo de 60 días."
        },
        DATE_ALERT: {
            PT: "Data final deve ser maior que a data inicial.",
            EN: "End date must be greater than the start date.",
            ES: "La fecha de finalización debe ser mayor que la fecha de inicio."
        },
        NO_BACKGROUNDCHECK_ALERT: {
            PT: "Você não possui chamadas suficientes para gerar um relatório no intervalo de tempo selecionado. Mínimo: 1.",
            EN: "You do not have enough calls to generate a report in the selected time frame. Minimum: 1.",
            ES: "No tiene suficientes llamadas para generar un informe en el intervalo de tiempo seleccionado. Mínimo: 1."
        },
        NO_QUESTIONNAIRE_ALERT: {
            PT: "Você não possui questionários suficientes para gerar um relatório no intervalo de tempo selecionado. Mínimo: 1.",
            EN: "You do not have enough questionnaires to generate a report in the selected time frame. Minimum: 1.",
            ES: "No tiene suficientes cuestionarios para generar un informe en el período de tiempo seleccionado. Mínimo: 1."
        },
        AVERAGE_GENERATION_TIME: {
            PT: "Média do tempo de Geração",
            EN: "Average Generation Time",
            ES: "Tiempo promedio de generación"
        },
        AVERAGE_ANSWER_TIME: {
            PT: "Média do tempo de Respostas",
            EN: "Average Response Time",
            ES: "Tiempo promedio de respuesta"
        },
        NOT_ENOUGH_INFO: {
            PT: "Sem informações suficientes",
            EN: "Not enough information",
            ES: "No hay suficiente información"
        },
        INVALID_REQUESTS: {
            PT: "Requisições Inválidas",
            EN: "Invalid requests",
            ES: "Requisiciones no válidas"
        },
        BLOQUED_BY_VALIDATION: {
            PT: "Bloqueados por Validação",
            EN: "Blocked by Validation",
            ES: "Bloqueado por validación"
        },
        UNANSWERED: {
            PT: "Não Respondidos",
            EN: "Unanswered",
            ES: "Sin respuesta"
        },
        DISAPROVED: {
            PT: "Reprovados",
            EN: "Disapproved",
            ES: "Desaprobado"
        },
        APROVED: {
            PT: "Aprovados",
            EN: "Approved",
            ES: "Aprobado"
        },
        INFO_NOT_FOUND: {
            PT: "Informação não encontrada",
            EN: "Information not found",
            ES: "Información no encontrada"
        },
        INFO_FOUND: {
            PT: "Informação encontrada",
            EN: "Information found",
            ES: "Información encontrada"
        },
        FACE_FOUND: {
            PT: "Rosto encontrado",
            EN: "Face found",
            ES: "Rostro encontrado"
        },
        FACE_NOT_FOUND: {
            PT: "Rosto não encontrado",
            EN: "Face not found",
            ES: "Rostro no encontrado"
        },
        ANSWERS_PER_PERSON: {
            PT: "Respostas por CPF por pergunta",
            EN: "Answers per CPF  per question",
            ES: "Respuestas por CPF por pregunta"
        },
        STATUS_PER_CPF: {
            PT: "Status por CPF",
            EN: "Status by CPF",
            ES: "Status por CPF"
        },
        RESULT_PER_QUESTION: {
            PT: "Resultados por pergunta",
            EN: "Results by question",
            ES: "Resultados por pregunta"
        },
        ANSWERS_REPORT_TITLE: {
            PT: "Relatório de Respostas",
            EN: "Responses Report",
            ES: "Informe de Respuestas"
        },
        QUESTS_REPORT_TITLE: {
            PT: "Relatório de Perguntas",
            EN: "Questions Report",
            ES: "Informe de Preguntas"
        },
        GENERATED: {
            PT: "Gerados",
            EN: "Generated",
            ES: "Generado"
        },
        HITS: {
            PT: "Acertos",
            EN: "Hits",
            ES: "Éxitos"
        },
        MISTAKES: {
            PT: "Erros",
            EN: "Mistakes",
            ES: "Errores"
        },
        QUESTION: {
            PT: "Pergunta",
            EN: "Question",
            ES: "Pregunta"
        },
        AB_TEST_REPORT_TITLE: {
            PT: "Relatório Teste A/B",
            EN: "A/B Test Report",
            ES: "Informe de prueba A/B"
        },
        AB_TEST_LIST_TITLE: {
            PT: "Lista de Testes A/B",
            EN: "A/B Test List",
            ES: "Lista de pruebas A/B"
        },
        TOTAL_REQUESTS: {
            PT: "TOTAL de Requisições",
            EN: "TOTAL Requisitions",
            ES: "TOTAL de Requisiciones"
        },
        QTD: {
            PT: "(Qtd)",
            EN: "(Qty)",
            ES: "(Cant)"
        },
        PERCENT_OF_TOTAL: {
            PT: "(% do total)",
            EN: "(% of total)",
            ES: "(% del total)"
        },
        SAME_GROUP_ALERT: {
            PT: "Não é permitido selecionar o mesmo grupo!",
            EN: "It is not allowed to select the same group!",
            ES: "¡No está permitido seleccionar el mismo grupo!"
        },
        THIS_GROUP_REQUEST: {
            PT: "REQUISIÇÕES NESTE GRUPO",
            EN: "REQUIREMENTS IN THIS GROUP",
            ES: "REQUISITOS EN ESTE GRUPO"
        },
        FIRST_GROUP: {
            PT: "1° Grupo",
            EN: "1st Group",
            ES: "1° Grupo"
        },
        SECOND_GROUP: {
            PT: "2° Grupo",
            EN: "2nd Group",
            ES: "2° Grupo"
        },
        PRODUCT: {
            PT: "Produto",
            EN: "Product",
            ES: "Producto"
        },
        BEGIN: {
            PT: "Início",
            EN: "Begin",
            ES: "Inicio"
        },
        END: {
            PT: "Final",
            EN: "End",
            ES: "Final"
        },
        REQUESTS_LIMIT: {
            PT: "Limite de Requisições",
            EN: "Requests Limit",
            ES: "Límite de Requisiciones"
        },
        EXEC_DAYS: {
            PT: "DIAS EM EXECUÇÃO",
            EN: "DAYS IN PROGRESS",
            ES: "DÍAS DE PROGRESO"
        },
        ALL: {
            PT: "Todos",
            EN: "All",
            ES: "Todos"
        },
        ALL_DISCLAIMERS: {
            PT: "Dependendo da volumetria de eventos, um relatório de todos os grupos de configuração pode levar alguns minutos para ficar pronto.",
            EN: "Depending on the number of events, a report of all the configuration groups may take several minutes to complete.",
            ES: "Según la cantidad de eventos, un informe de todos los grupos de configuración puede tardar unos minutos en completarse."

        },
        OCR_TITLE_REPORT:{
            PT: "OCR",
            EN: "OCR",
            ES: "OCR"
        }
    },

    // ROUTES
    routes: {
        HOME: "/",
        LOGIN: "/login",
        CONFIGURATIONS: "/configuracoes",
        GENERAL_CONFIGURATIONS: "/configuracoes-gerais",
        REPORTS: "/relatorios",
        ADMIN: "/admin",
        POC: "/poc-automatizada",
        WORKSPACE: "/workspace",
        SEARCHFACE: "/searchface",
        HEALTHCHECK: "/healthcheck"
    },

    externalRoutes: {
        BIG_DATA_CORP: "bigdatacorp.com.br",
        TWITTER_BDC: "https://twitter.com/BigData_Corp/",
        INSTAGRAM_BDC: "https://www.instagram.com/bigdatacorp/",
        FACEBOOK_BDC: "https://www.facebook.com/bigdatacorp/",
        LINKEDIN_BDC: "https://www.linkedin.com/company/bigdatacorp/",
        YOUTUBE_BDC: "https://www.youtube.com/channel/UC0CHaanZ6rhHfj8gLdJ91og"
    },

    topMenu: {
        CONFIGURATIONS: {
            PT: "Configurações",
            EN: "Settings",
            ES: "Configuración",
        },
        REPORTS: {
            PT: "Relatórios",
            EN: "Reports",
            ES: "Reportes"

        },
        POC: {
            PT: "Gerador de POC",
            EN: "POC Generator",
            ES: "Generador de POC",
        },
        ADMIN: {
            PT: "Administração",
            EN: "Administration",
            ES: "Administración"
        },
        WORKSPACE: "Área de Trabalho",
        SEARCHFACE: "Validação de Identidade",
        HELLO: {
            PT: "Olá",
            EN: "Hello",
            ES: "Hola"
        },
        NO_DEFAULT_ALERT: {
            PT:"O domínio não possui grupo de configuração default!",
            EN:"The domain has no default configuration group!",
            ES:"¡El dominio no tiene un grupo de configuración predeterminado!"
        }
    },

    validations: {
        simpleValidations: {
            BIGID_OBIT: {
                PT: "óbito",
                EN: "deceased status",
                ES: "muerte"
            },
            BIGID_EMAIL_VALIDATION: {
                PT: "email",
                EN: "email address",
                ES: "email"
            },
            BIGID_CPF_STATUS: {
                PT: "status CPF",
                EN: "Tax Id Status",
                ES: "status CPF"
            },
            BIGID_IP_MATCH_CITY: {
                PT: "IP com a cidade",
                EN: "IP geolocation against city",
                ES: "IP con ciudad"
            },
            BIGID_IP_MATCH_PHONE: {
                PT: "IP com o telefone",
                EN: "IP geolocation against phone number",
                ES: "IP con teléfono"
            },

        },

        BIGID_VELOCITY_KEY: {
            LABEL: {
                PT: "Limite de questionários gerados",
                EN: "Velocity checks for input keys",
                ES: "Comprobaciones de velocidad para las teclas de entrada"
            },
            KEY: {
                PT: "Chave",
                EN: "Key",
                ES: "Llave"
            },
            QUANTITY: {
                PT: "Quantidade",
                EN: "Quantity",
                ES: "Cantidad"
            },
            TIME: {
                PT: "Tempo",
                EN: "Time",
                ES: "Tiempo"
            }
        },
        BIGID_IP_VELOCITY: {
            LABEL: {
                PT: "Limite de questionários gerados por IP",
                EN: "Velocity checks for IP Addresses",
                ES: "Límite de cuestionarios generados por IP"
            },
            KEY: {
                PT: "Chave",
                EN: "Key",
                ES: "Llave"
            },
            QUANTITY: {
                PT: "Quantidade",
                EN: "Quantity",
                ES: "Cantidad"
            },
            TIME: {
                PT: "Tempo em minutos",
                EN: "Time in minutes",
                ES: "Tiempo en minutos"
            }
        },

        BIGID_AGE_VALIDATION: {
            PT: "idade mínima",
            EN: "age ",
            ES: "edad mínima",
        },

        BIGID_NAME_MATCH: {
            PT: "nome",
            EN: "name",
            ES: "nombre"
        },
        BIGID_NAME_MATCH_PORCENTAGE: {
            PT: "Percentual de similaridade",
            EN: "Similarity percentage",
            ES: "Porcentaje de similitud"
        },

        listValidations: {
            BIGID_WATCH_LIST: ""
        },
        DELETE_ALERT:{
            PT:"Deseja realmente deletar essa lista ?",
            EN:"Do you really want to delete that list?",
            ES:"¿De verdad quieres eliminar esa lista?"
        },
        FILE_ALERT:{
            PT:"Apenas arquivos .txt ou .csv são permitidos."   ,
            EN:"Only .txt or .csv files are allowed.",
            ES:"Solo se permiten archivos .txt o .csv"
        },

        PREFIX: {
            PT: "Validação de ",
            EN: "Validate ",
            ES: "Validación de "
        },

        OCR_QUESTIONNAIRE_GEN: "Gerar questionário após consulta de OCR",

        BIGID_BLACK_LIST: "Blocklist",
        BIGID_WHITE_LIST: "Allowlist",
        BIGID_WATCH_LIST: "Watchlist",

        LIST_EDIT_TITLE:{
            PT: "Editar ",
            EN: "Edit ",
            ES: "Editar "
        },
        LIST_NEW_VALUE:{
            PT: "Novo Valor",
            EN: "New Value",
            ES: "Nuevo valor"
        }
    },

    infoTypes: {
        CPF: {
            PT: "CPF",
            EN: "CPF",
            ES: "CPF"
        },
        CAR_INFO: {
            PT: "Carro",
            EN: "Car",
            ES: "Coche",
        },
        NAME: {
            PT: "Nome",
            EN: "Name",
            ES: "Nombre"
        },
        BIRTHDAY: {
            PT: "Aniversário",
            EN: "Date of birth",
            ES: "Cumpleaños"
        },
        PHONE: {
            PT: "Telefone",
            ES: "Teléfono",
            EN: "Phone"
        },
        EMAIL: {
            PT: "Email",
            ES: "Email",
            EN: "Email"
        },
        ADDRESS: {
            PT: "Endereço",
            ES: "Dirección",
            EN: "Address"
        },
        VOTING: {
            PT: "Local de Votação",
            ES: "Colegio Electoral",
            EN: "Voting Venue"
        },
        MOTHER_NAME: {
            PT: "Nome Mãe",
            ES: "Nombre Madre",
            EN: "Mother's Name"
        },
        MOTHER_BIRTHDAY: {
            PT: "Mãe Aniversário",
            ES: "Madre Cumpleaños",
            EN: "Mother's Birthday"
        },
        MOTHER_ADDRESS: {
            PT: "Mãe Endereço",
            ES: "Madre Dirección",
            EN: "Mother's Address"
        },
        MOTHER_PHONE: {
            PT: "Mãe Telefone",
            ES: "Madre Teléfono",
            EN: "Mother's Phone"
        },
        MOTHER_EMAIL: {
            PT: "Mãe Email",
            ES: "Madre Email",
            EN: "Mother's Email"
        },
        RELATEDPEOPLE: {
            PT: "Parentesco",
            ES: "Parentesco",
            EN: "Relatives"
        },
        HOUSEHOLD: {
            PT: "Residência",
            ES: "Residencia",
            EN: "Roomates / Housemates"
        },
        PLACES: {
            PT: "Lugares",
            ES: "Lugares",
            EN: "Places"
        },
        SPOUSE: {
            PT: "Cônjuge",
            ES: "Cónyuge",
            EN: "Consort"
        },
        NEIGHBOURS: {
            PT: "Vizinhos",
            ES: "Vecinos",
            EN: "Neighbors"
        },
        PARTNER: {
            PT: "Sócios P.F",
            ES: "Socios",
            EN: "Business Partners"
        },
        JOB: {
            PT: "Trabalho",
            ES: "Trabajo",
            EN: "Job"
        },
        DOMAIN: {
            PT: "Domínio",
            ES: "Dominio",
            EN: "Domain"
        },
        HEALTHCARE: {
            PT: "Plano de Sáude",
            ES: "Plan de salud",
            EN: "Health Plan"
        },
        RESTITUTION: {
            PT: "Restituição do Imposto de Renda",
            ES: "Devolución de Impuestos",
            EN: "Tax Returns"
        },
        VOEGOL_REDEMPTION: {
            PT: "VoeGol Retirada de Milhas",
            ES: "VoeGol Retirada de Milhas",
            EN: "VoeGol Retirada de Milhas"
        },
        VOEGOL_ACCRUAL: {
            PT: "VoeGol Aquisição de Milhas",
            ES: "VoeGol Aquisição de Milhas",
            EN: "VoeGol Aquisição de Milhas"
        },
        RELATEDCOMPANIES: {
            PT: "Sócios P.J.",
            ES: "Socios de la Empresa",
            EN: "Company Ownership"
        },
        EMPLOYMENT: {
            PT: "Vínculo Empregatício",
            ES: "Bono de Empleo",
            EN: "Employment"
        },
        RELATIVE_PHONE: {
            PT: "Telefone de Parentes",
            ES: "Teléfono de Familiares",
            EN: "Relative's Phone"
        },
        RELATIVE_ADDRESS: {
            PT: "Endereço de Parentes",
            ES: "Dirección de Familiares",
            EN: "Relative's Address"
        },
        CLASS_ORGANIZATION: {
            PT: "Conselhos",
            ES: "Consejo de Clase",
            EN: "Professional Entities"
        },
        PROCESS: {
            PT: "Processos",
            ES: "Demanda Judicial",
            EN: "Lawsuit"
        },
        SOCIAL_ASSISTANCE: {
            PT: "Beneficios Sociais",
            ES: "Beneficios Sociales",
            EN: "Government assistance programs"
        },
        GRADUATION_INFO: {
            PT: "Informações Universitárias",
            ES: "Información universitaria",
            EN: "University Information"
        },
        PUBLIC_SERVERS: {
            PT: "Servidores Públicos",
            ES: "Servidores Públicos",
            EN: "Public Servants"
        },
        BUSINESS_INFO: {
            PT: "Sócio (Quantitativo)",
            ES: "Socio (cuantitativo)",
            EN: "Partner (Quantitative)"
        },
        AWARDS: {
            PT: "Certificações",
            ES: "Certificaciones",
            EN: "Certifications"
        },
        ONLINE_ADS: {
            PT: "Anúncios Online",
            ES: "Anuncios online",
            EN: "Online Ads"
        },
        ONLINE_ADS_QTY: {
            PT: "Anúncios Online (Quantitativo)",
            ES: "Anuncios online (cuantitativo)",
            EN: "Online Ads (Quantitative)"
        },
        CLASS_ORGANIZATION_QTY: {
            PT: "Conselhos (Quantitativo)",
            ES: "Consejo de Clase (cuantitativo)",
            EN: "Councils (Quantitative)"
        },
        GRADUATION_QTY: {
            PT: "Informações Universitárias (Quantitativo)",
            ES: "Información universitaria (cuantitativo)",
            EN: "University Information (Quantitative)"
        },
        EMPLOYMENT_QTY: {
            PT: "Vínculo Empregatício (Quantitativo)",
            ES: "Bono de Empleo (cuantitativo)",
            EN: "Employment Bond (Quantitative)"
        },
        ELECTION: {
            PT: "Eleição",
            ES: "Elección",
            EN: "Political Association"
        },
        COMBO_FORMATION: {
            PT: "Combo de Formação",
            ES: "Combo de Formación",
            EN: "Education Combination",
        },
        COMBO_FORMATION_QTY: {
            PT: "Combo de Formação (Quantitativo)",
            ES: "Combo de Formación (Cuantitativo)",
            EN: "Education Combination (Quantitative)",
        },
        COMBO_BIRTHDAY: {
            PT: "Combo de Aniversário",
            ES: "Combo de Cumpleaños",
            EN: "Birthday Combination",
        },
        COMBO_AGE: {
            PT: "Combo de Idade",
            ES: "Combo de Años",
            EN: "Age Combination",
        },
        COMBO_STATE: {
            PT: "Combo de Estado",
            EN: "State Combination",
            ES: "Combo de Estado",
        },
        ADMIN_RELATIONSHIP: {
            PT: "Vínculo Administrativo",
            EN: "Administrative Relationship",
            ES: "Enlace administrativo",
        },
        CNPJ_LEGAL_NATURE: {
            PT: "Natureza legal da Empresa",
            EN: "Company Legal Nature",
            ES: "Naturaleza jurídica de la empresa",
        },
        CNPJ_CNAE: {
            PT: "Atividade Legal da Empresa",
            EN: "Company Legal Activity",
            ES: "Actividad jurídica de la empresa",
        },
        CNPJ_FOUNDATION_YEAR: {
            PT: "Ano de Fundação da Empresa",
            EN: "Company Foundation Year",
            ES: "Año de fundación de la empresa",
        },
        CNPJ_BRANCHES:{
            PT: "Filiais da Empresa",
            EN: "Company Branches",
            ES: "Sucursales de la empresa",
        },
        CNPJ_ADDRESS:{
            PT: "Endereço da empresa",
            EN: "Company's adress",
            ES: "Dirección de la empresa",
        },
        CNPJ_DOMAIN:{
            PT: "Domínios da empresa",
            EN: "Company domains",
            ES: "Dominios de la empresa",
        },
        CNPJ_PHONE:{
            PT: "Telefone da Empresa",
            EN: "Company Phone",
            ES: "Teléfono de la empresa",
        },
        FREELANCER:{
            PT: "Trabalhador autonomo",
            EN: "Freelancer",
            ES: "Persona de libre dedicación",
        },
        CNPJ_RELATED_PEOPLE:{
            PT: "Pessoas Relacionadas",
            EN: "Related People",
            ES: "Personas relacionadas"
        },
        CNPJ_MARKETPLACE:{
            PT: "Marketplace",
            EN: "Marketplace",
            ES: "Marketplace"
        },
        CNPJ_STOCKS:{
            PT: "Mercado Financeiro",
            EN: "Financial Market",
            ES: "Mercado Financiero"
        },
        PATENT:{
            PT: "Patente",
            EN: "Patent",
            ES: "Patent"
        },
        BRAND: {
            PT: "Marca",
            EN: "Brand",
            ES: "Marca"
        },
        RG: {
            PT: "RG",
            EN: "RG",
            ES: "RG"
        }
    },

    selectChoices: {
        RARELY: {
            PT: "Raramente",
            EN: "Rarely",
            ES: "Raramente"
        },
        ALWAYS: {
            PT: "Sempre",
            EN: "Always",
            ES: "Siempre"
        },
        OFTEN: {
            PT: "Frequentemente",
            EN: "Often",
            ES: "A menudo"
        },
        SOMETIMES: {
            PT: "As vezes",
            EN: "Sometimes",
            ES: "A veces"
        }
    },

    questionConfiguration: {
        ESSENTIAL_QUESTION: {
            PT: "Marcar como essencial",
            EN: "Mark as Essential",
            ES: "Marcar como essencial"
        },
        ENABLE_QUESTION: {
            PT: "Habilitar Pergunta",
            EN: "Enable Question",
            ES: "Habilitar Pregunta"
        },
        ALLOW_NDA: {
            PT: "Permitir NDA",
            ES: "Permitir NDA",
            EN: "Allow NDA"
        },
        FREQUENCY: {
            PT: "Frequência",
            EN: "Frequency",
            ES: "Frecuencia"
        },
        HIT_SCORE: {
            PT: "Pontuação Acerto",
            EN: "Points added for correct answer",
            ES: "Punto de Golpe"
        },
        SCORE_ERROR: {
            PT: "Pontuação Erro",
            EN: "Points subtracted for incorrect answer",
            ES: "Puntuación de Error"
        },
        CONFIRM_ALWAYS: {
            PT: "Ao habilitar uma pergunta com frequência 'Sempre' as outras perguntas do mesmo tipo de informação ficarão desabilitadas, tem certeza que deseja fazer isso?",
            ES: "Cuando habilita una pregunta a menudo 'Siempre', las otras preguntas del mismo tipo de información se deshabilitarán, ¿está seguro de que desea hacer esto?",
            EN: "When you enable a question often 'Always' the other questions of the same type of information will be disabled, are you sure you want to do this?"
        }
    },

    PIE_GRAPHIC_TITLE: {
        PT: "Gráficos de Pizza ",
        EN: "Pie Charts ",
        ES: "Gráficos Circulares "
    },
    RADAR_GRAPHIC_TITLE: {
        PT: "Gráfico de Radar ",
        EN: "Radar Chart ",
        ES: "Gráfico de Radar "
    },
    BAR_GRAPHIC_TITLE: {
        PT: "Gráficos de Barra ",
        EN: "Bar Charts ",
        ES: "Gráficos de Barra"
    },
    tooltips: {
        DEFAULT: {
            PT: "Grupo de configuração default do BigID. Quando nenhum grupo de configuração específico for escolhido pelo cliente este será o grupo utilizado.",
            EN: "Default BigID configuration group. When no specific configuration group is chosen by the user this will be the group used.",
            ES: "Grupo de configuración de BigID predeterminado. Cuando el cliente no elija un grupo de configuración específico, este será el grupo utilizado."
        },
        ALLOW_MULTIPLE_ANSWERS_ATTEMPTS_LABEL: "Define se o questionário pode ser reutilizado para a mesma pessoa.",
        NUMBER_OF_QUESTIONS: {
            PT: "Define o número de perguntas do questionário.",
            ES: "Establece el número de preguntas del cuestionario.",
            EN: "Sets the number of questions in the questionnaire."
        },
        REUSE_TIME: {
            PT: "Caso ainda não tenha sido respondido, por quanto tempo um mesmo questionário pode ser reutilizado.",
            EN: "The length of time during which the same set of questions will be displayed for the same individual even if a new questionnaire is requested.",
            ES: "Si aún no se ha respondido, durante cuánto tiempo se puede reutilizar el mismo cuestionario?"
        },
        LIST_VALIDATIONS: {
            PT: "Permite a criação de BlockLists (\"lista negra\", bloqueando consultas com os parâmetros listados); AllowLists (\"lista pré aprovada\", garantindo que questionários sempre serão gerados para os parâmetros listados); e WatchLists (\"lista de observação\" onde, sempre que algum questionário for gerado para os parâmetros listados, uma notificação será enviada via e-mail e/ou sms).",
            EN: "Allows the creation of BlockLists (\"pre-denied\" lists, where any individual in the list is automatically blocked regardless of validation result); AllowLists (\"pre-approved\" lists, where any individual in the list is automatically approved regardless of validation result); and WatchLists (where a notification is sent whenever an individual in the list initiates a validation process). The lists may contain Tax ID numbers, phones, e-mails, names, IP Addresses, or any other input data.",
            ES: "Permite la creación de Listas Negras (\"lista negra\", bloqueando consultas con los parámetros listados); AllowLists (\"lista preaprobada\", que garantiza que siempre se generarán cuestionarios para los parámetros enumerados); y WatchLists (\"lista de seguimiento\" donde, siempre que se genere un cuestionario para los parámetros enumerados, se enviará una notificación por correo electrónico y / o sms)."
        },
        LIST_VALIDATIONS_BATCH_ADD:{
            PT:"Para adição em lote, faça o upload de um arquivo .txt ou .csv separando valor por linha.",
            EN:"For batch addition, upload a .txt or .csv file separating value per line.",
            ES:"Para la adición de lotes, cargue un archivo .txt o .csv separando el valor por línea."
        },

        MIN_SCORE: {
            PT: "Cada pergunta do questionário possui uma pontuação que, quando somadas, constituem a pontuação do questionário. Caso essa pontuação seja maior ou igual à pontuação mínima, o questionário é validado.",
            ES: "Cada pregunta del cuestionario tiene una puntuación que, sumada, constituye la puntuación del cuestionario. Si esta puntuación es mayor o igual que la puntuación mínima, el cuestionario se valida",
            EN: "Each question in the questionnaire has a score that, when added together, makes up the total score. If this total is greater than or equal to the threshold, the questionnaire is validated."
        },

        WATCH_LIST_CONTACTS: {
            PT: "Lista de e-mails e/ou telefones que receberão notificações sempre que um usuário listado na \"Watchlist\" gerar um questionário.",
            EN: "List of contacts to be notified in case an individual that is present on a WatchList tries to initiate a validation process.",
            ES: "Lista de correos electrónicos y/o teléfonos que recibirán notificaciones cada vez que un usuario incluido en la \"Lista de observación\" genere un cuestionario."
        },

        INFOTYPE_FREQUENCY: {
            PT: "Define com qual frequência as perguntas deste grupo aparecerão nos questionários gerados.",
            EN: "Defines how often the questions in this group will appear in the generated questionnaires.",
            ES: "Define con qué frecuencia aparecerán las preguntas de este grupo en los cuestionarios generados",
        },
        INFOTYPE_FREQUENCY_TITLE: {
            PT: "Frequência do Tipo de Informação",
            EN: "Information Type Frequency",
            ES: "Tipo de información Frecuencia"
        },
        QUESTION_FREQUENCY: {
            PT: "Define com qual frequência esta pergunta irá aparecer, dado que o grupo foi sorteado no questionário.",
            EN: "Define how often this question will appear if the question group was selected for the questionnaire.",
            ES: "Defina con qué frecuencia aparecerá esta pregunta, dado que el grupo fue elaborado en el cuestionario"
        },
        QUESTIONS_GROUPS: {
            PT: "Os grupos de perguntas separam as perguntas similares em categorias, impedindo que duas perguntas de um mesmo grupo sejam geradas em um mesmo questionário. Quanto mais grupos estiverem com perguntas ativas, maiores as chances do questionário ser gerado. ",
            ES: "Los grupos de preguntas separan preguntas similares en categorías, evitando que dos preguntas del mismo grupo se generen en el mismo cuestionario. Cuantos más grupos tengan preguntas activas, mayores serán las posibilidades de que se genere el cuestionario",
            EN: "Question groups separate similar questions into categories, preventing two questions from the same group from being generated in the same questionnaire. The more groups that have active questions, the greater the chances of the questionnaire being generated."
        },
        OCR_QUESTIONNAIRE_GEN: "Determina se o tipo de retorno de uma consulta ao OCR deve ser um questionário",
        REPORT_PIE_GRAPHIC: {
            PT: "Neste gráfico é possivel ter a distribuição do total entre os status de cada grupo de configuração.",
            EN: "In this graph it is possible to have the total distribution among the statuses of each configuration group.",
            ES: "En este gráfico es posible tener la distribución total entre los estados de cada grupo de configuración"

        },
        REPORT_RADAR_GRAPHIC: {
            PT: "Com o gráfico de Radar é possivel, de uma forma direta, comparar a performance de ambos os grupos de configuração.",
            EN: "With the Radar graph, it is possible to directly compare the performance of both configuration groups.",
            ES: "Con el gráfico de radar, es posible comparar directamente el rendimiento de ambos grupos de configuración"
        },
        REPORT_BAR_GRAPHIC: {
            PT: "Compare cada status dos grupos de configuração de forma isolada com o gráfico de barras.",
            EN: "Compare each status of the configuration groups in isolation with the bar graph.",
            ES: "Compare cada estado de los grupos de configuración de forma aislada con el gráfico de barras."
        },

        validations: {
            BIGID_OBIT: {
                PT: "Verifica se há algum registro de óbito para o CPF.",
                EN: "Checks if the person is registered as deceased with the government.",
                ES: "Comprueba si existe algún registro de defunción del CPF."
            },
            BIGID_EMAIL_VALIDATION: {
                PT: "Verifica se o e-mail fornecido é um email inválido (temporário, inexistente etc).",
                EN: "Checks if the person's email address is valid or not (temporary email, nonexistent inbox, etc.).",
                ES: "Comprueba si el correo electrónico proporcionado no es válido (temporal, inexistente, etc.)."
            },
            BIGID_CPF_STATUS: {
                PT: "Valida o status (regular, pendente, etc) do CPF consultado junto à Receita Federal.",
                EN: "Checks the if the person's tax id status is regular or not.",
                ES: "Comprueba si el estado de identificación fiscal de la persona es regular o no."
            },
            BIGID_NAME_MATCH: {
                PT: "Verifica se o nome fornecido na entrada é similar ao nome encontrado para o CPF junto à Receita Federal.",
                EN: "Checks if the name provided as input matches the officially registered name for the individual based on the configured similarity threshold.",
                ES: "Comprueba si el nombre proporcionado como entrada coincide con el nombre registrado oficialmente de la persona según el umbral de similitud configurado."
            },
            BIGID_AGE_VALIDATION: {
                PT: "Verifica se a idade é inferior a idade mínima permitida.",
                EN: "Checks if the age of the individual is greater than or equal to the configured minimum age for validation.",
                ES: "Comprueba si la edad es inferior a la edad mínima permitida."
            },
            BIGID_AGE_VALIDATION_MINIMUM_AGE: {
                PT: "Idade mínima permitida para análise.",
                EN: "Minimum age allowed for analysis.",
                ES: "Edad mínima permitida para el análisis."
            },
            BIGID_NAME_MATCH_PORCENTAGE: {
                PT: "Percentual mínimo de similaridade tolerado na validação do nome em comparação ao nome oficial registrado junto a Receita Federal.",
                EN: "Similarity threshold percentage tolerated in name validation compared to the officially registered name for the individual.",
                ES: "Porcentaje de umbral de similitud tolerado en la validación del nombre en comparación con el nombre registrado oficialmente para el individuo. "
            },
            BIGID_VELOCITY_KEY: {
                PT: "Bloqueia múltiplas requisições de geração de questionário para uma mesma chave (CPF, Nome, E-mail, etc), impedindo que um possível fraudador faça múltiplas gerações de questionário.",
                EN: "Allows you to configure velocity checks based on the input information, preventing multiple validations for the same individual.",
                ES: "Le permite configurar verificaciones de velocidad basadas en la información de entrada, evitando múltiples validaciones para el mismo individuo."
            },
            BIGID_IP_VELOCITY: {
                PT: "Bloqueia múltiplas requisições de geração de questionário para um mesmo IP, impedindo que um possível fraudador faça múltiplas gerações de questionário.",
                EN: "Allows you to configure velocity checks based on the IP Address of the end user, preventing multiple validations coming from the same place.",
                ES: "Bloquee múltiples requisiciones de generación de cuestionarios para la misma IP, evitando que un posible estafador realice múltiples generaciones de cuestionarios."
            },
            BIGID_IP_MATCH_CITY: {
                PT: "Verifica se a Cidade do Endereço de IP fornecido corresponde à mesma cidade correspondente ao CPF fornecido.",
                EN: "Checks if the city the IP Address resolves to matches a known city of residence for the person.",
                ES: "Comprueba si la ciudad de la dirección IP proporcionada corresponde a la misma ciudad correspondiente al CPF proporcionado."
            },
            BIGID_IP_MATCH_PHONE: {
                PT: "Verifica se o Estado correspondente ao Endereço de IP fornecido corresponde ao mesmo Estado do telefone (pelo DDD) fornecido.",
                EN: "Checks if the state the IP Address resolves to matches the state of a known phone number for the person.",
                ES: "Comprueba si el estado en el que se resuelve la dirección IP coincide con el estado de un número de teléfono conocido de la persona."
            },
        }
    },

    AB_TEST_ALERTS:{
        CHOOSE_PRODUCT_ALERT:{
            PT:"Escolha pelo menos um produto!",
            EN:"Choose at least one product!",
            ES:"¡Elija al menos un producto!"
        },
        CHOOSE_END_CONDITION_ALERT:{
            PT:"Escolha pelo menos uma condição de encerramento!",
            EN:"Choose at least one termination condition!",
            ES:"¡Elija al menos una condición de apagado!"
        },
        DATES_GRATER_THAN_TODAY_ALERT: {
            PT:"A data de inicio/final tem que ser maior que a data de hoje!",
            EN:"The start/end date must be greater than today's date!",
            ES:"¡La fecha de inicio/finalización debe ser mayor que la fecha de hoy!"
        },
        END_GREATER_THAN_START_ALERT: {
            PT:"A data de inicio não pode ser maior do que a data final!",
            EN:"The start date cannot be greater than the end date!",
            ES:"¡La fecha de inicio no puede ser mayor que la fecha de finalización!"
        },
        AB_TEST_ALREADY_EXISTS_ALERT: {
            PT:"Já existe um Teste A/B para esses grupos nesta data!",
            EN:"There is already an A/B test for these groups on this date!",
            ES:"¡Ya hay una prueba A/B para estos grupos en esta fecha!"
        },
        AB_TEST_ALREADY_ACTIVE_ALERT: {
            PT:"Já existe um Teste A/B ativo!",
            EN:"There is already an active A/B Test!",
            ES:"¡Ya hay una prueba A/B activa!"
        },
        AB_TEST_CREATED_ALERT: {
            PT:"Teste A/B criado com sucesso!",
            EN:"A/B test successfully created!",
            ES:"¡Prueba A/B creada con éxito!"
        },
        SAME_GROUP_SELECT_ALERT: {
            PT:"Não é permitido selecionar o mesmo grupo!",
            EN:"It is not allowed to select the same group!",
            ES:"¡No está permitido seleccionar el mismo grupo!"
        },
        FIELDS_FILL_IN_ALERT:{
            PT:"Preencha todos os campos!",
            EN:"Fill in all the fields!",
            ES:"¡Complete todos los campos!"
        },
        CHOOSE_GROUPS_ALERT:{
            PT:"Escolha os grupos!",
            EN:"Choose groups!",
            ES:"¡Elige los grupos!"
        },
        SAME_GROUPS_ALERT: {
            PT:"Os grupos não podem ser iguais!",
            EN:"Groups cannot be the same!",
            ES:"¡Los grupos no pueden ser iguales!"
        },
        NUMERIC_FIELDS_ALERT:{
            PT:"Preencha todos os campos numéricos!",
            EN:"Fill in all numeric fields!",
            ES:"¡Complete todos los campos numéricos!"
        },
    },

    AB_TEST_SEND:{
        PT:"Enviar",
        EN:"Submit",
        ES:"Enviar"
    },

    AB_TEST_STATUS:{
        EXEC:{
            PT:"Em Execução",
            EN:"Running",
            ES:"En ejecución"
        },
        FINISHED:{
            PT:"Finalizado",
            EN:"Finished",
            ES:"Finalizado"
        },
        WAITING: {
            PT:"Aguardando",
            EN:"Waiting",
            ES:"Aguardando"
        }
    },

    abTestingTooltips: {
        INFO_ABTESTING: {
            header:{
                PT: "Informações sobre o Teste A/B",
                EN: "A/B Test Information",
                ES: "Información de prueba A/B"
            },
            title: {
                PT:"Métricas sobre o Teste A/B",
                EN:"A/B Test Metrics",
                ES:"Métricas de prueba A/B"
            },
            text: {
                PT:"O Teste A/B baseia-se em uma distribuição aleatória estatística das requisições realizadas tanto para o Questionário (KBA) quanto para o Background Check. Isso significa que para que o seu resultado seja estatisticamente relevante, ele deve ser feito dentro de uma volumetria minima que segue uma escala de confiança baseado no cálculo de amostragem abaixo.",
                EN:"The A/B testing system ensures a random distribution of incoming requests between configuration groups based on user-defined percentages. In order to achieve statistical significace on your tests, the test must be run for a sample whose size gives you a high degree of confidence on the results. You can use the sample size calculator below to get a rough idea of how big a sample you need based on your \"population size\" (i.e. the number of requests you make on a monthly basis).",
                ES:"La prueba A/B se basa en una distribución estadística aleatoria de las solicitudes realizadas tanto para el cuestionario (KBA) como para la verificación de antecedentes. Esto significa que para que su resultado sea estadísticamente relevante, debe realizarse dentro de un mínimo de volumetría que sigue una escala de confianza basada en el cálculo de muestreo a continuación "
            },
            calcTitle: {
                PT:"Calcule o tamanho da sua amostra",
                EN:"Calculate your sample size",
                ES:"Calcule el tamaño de su muestra"
            },
            popSize:{
                PT:"Tamanho da população",
                EN:"Population size",
                ES:"Tamaño de la poblacion"
            },
            confDegree: {
                PT:"Grau de Confiança",
                EN:"Degree of Confidence",
                ES:"Grado de confianza"
            },
            errorMargin: {
                PT:"Margem de erro",
                EN:"Margin of error",
                ES:"Margen de error"
            },
            sampleSize:{
                PT:"Tamanho da amostra",
                EN:"Sample size",
                ES:"Tamaño de la muestra"
            }
        
        }
    },
    MENU_LAWSUITS_PRESENCE_MODAL_TITLE: {
        PT: "Informação importante!",
        EN: "Important",
        ES: "Información importante!"
    },
    MENU_LAWSUITS_PRESENCE_MODAL: {
        PT: "É possível adicionar várias palavras chave em uma mesma operação, apenas separando-as por vírgula (,) e sem pôr espaço entre elas.",
        EN: "You can add several keywords at once. Just separate them with commas (,) without any spaces.",
        ES: "Es posible agregar varias palabras clave en una misma operación, simplemente separándolas con una coma (,) y sin poner espacio entre ellas."
    },
    MENU_LAWSUITS_PRESENCE_MODAL_EXAMPLE: {
        PT: "Por exemplo : Furto,BitCoin,Trafico",
        EN: "For example: Theft,BitCoin,Traffic",
        ES: "Por ejemplo: Robo,BitCoin,tráfico"
    },

    validatingDocumentsTooltips: {
        TOOLTIP_TITLE:{
            PT:"OCR de Documentos: Guia de Captura de Imagens",
            EN:"Document OCR: Image Capture Guide",
            ES:"Documento OCR: Guía de captura de imágenes"
        },
        REMOVE_PROTECTION: {
            title: {
                PT: "Remova proteções do documento:",
                EN: "Remove protections from the document:",
                ES: "Quite las protecciones del documento:"
            },
            text: {
                PT: "Remova o plástico do documento ou qualquer outro tipo de proteção que dificulte a visualização do documento.",
                EN: "Remove the plastic from the document or any other type of protection that makes it difficult to see the document.",
                ES: "Retire el plástico del documento o cualquier otro tipo de protección que dificulte la visualización del documento."
            },
            image: "assets/protected-cnh.png"
        },
        REMOVE_NOISE: {
            title: {
                PT: "Evite fundos com interferências:",
                EN: "Avoid backgrounds with interference:",
                ES: "Evite fondos con interferencias:"
            },
            text: {
                PT: "Coloque o documento sobre uma superfície lisa, preferencialmente, de cor única e escura. Quanto maior o contraste em relação oao documento, melhor.",
                EN: "Place the document on a smooth surface, preferably a single dark color. The greater the contrast to the document, the better.",
                ES: "Coloque el documento sobre una superficie lisa, preferiblemente de un solo color oscuro. Cuanto mayor sea el contraste con el documento, mejor."
            },
            image: "assets/noisy-cnh.png"
        },
        ADJUST_FOLDING: {
            title: {
                PT: "Procure deixar o documento o mais plano possível:",
                EN: "Try to keep the document as flat as possible:",
                ES: "Intente mantener el documento lo más plano posible:"
            },
            text: {
                PT: "Mantenha o documento o mais liso possível, evitando áreas curvas, dobras, vincos e regiões amassadas.",
                EN: "Keep the document as smooth as possible, avoiding curved areas, folds, creases and wrinkled regions.",
                ES: "Mantenga el documento lo más suave posible, evitando áreas curvas, pliegues, arrugas y regiones arrugadas."
            },
            image: "assets/folded-cnh.png"
        },
        ADJUST_ROTATION: {
            title: {
                PT: "Posicione o documento verticalmente:",
                EN: "Position the document vertically:",
                ES: "Coloque el documento verticalmente:"
            },
            text: {
                PT: "Evite fazer o upload de fotos que estejam com o documento de lado ou de cabeça para baixo. Documentos assim dificilmente serão reconhecidos pelo software.",
                EN: "Avoid uploading photos with the document on its side or upside down. Such documents are unlikely to be recognized by the software.",
                ES: "Evite cargar fotos con el documento de lado o al revés. Documentos como este difícilmente serán reconocidos por el software."
            },
            image: "assets/rotated-cnh.png"
        },
        ADJUST_CAMERA_ROTATION: {
            title: {
                PT: "Posicione a câmera a 90º:",
                EN: "Position the camera at 90º:",
                ES: "Coloque la cámara a 90º:"
            },
            text: {
                PT: "Sua câmera deve estar posicionada a 90º em relação a foto, evitando que a foto seja deformada.",
                EN: "Your camera must be positioned at 90º in relation to the photo, preventing the photo from being deformed.",
                ES: "Tu cámara debe estar colocada a 90º con respecto a la foto, evitando que la foto se deforme."
            },
            image: "assets/camera-rotated-cnh.png"
        },
        ADJUST_BRIGHTNESS: {
            title: {
                PT: "Atenção à luminosidade:",
                EN: "Attention to brightness:",
                ES: "Atención al brillo:"
            },
            text: {
                PT: "A imagem fotografada deve estar bem iluminada, nem muito escura nem com brilhos vindos de reflexos do flash ou luz.",
                EN: "The photographed image must be well lit, neither too dark nor with glare from flash or light reflections.",
                ES: "La imagen fotografiada debe estar bien iluminada, ni demasiado oscura ni con destellos de flash o reflejos de luz."
            },
            image: "assets/low-brightness-cnh.png"
        },
        ADJUST_FOCUS: {
            title: {
                PT: "Atenção ao foco:",
                EN: "Attention to focus:",
                ES: "Atención al enfoque:"
            },
            text: {
                PT: "Enquadre o documento de forma que ele ocupe a maior área da foto possível sem que a imagem perca seu foco. Atenção para não cortar parte das informações no momento da foto.",
                EN: "Frame the document so that it occupies the largest area of the photo possible without the image losing its focus. Be careful not to cut part of the information at the time of the photo.",
                ES: "Encuadre el documento de modo que ocupe el mayor área posible de la foto sin que la imagen pierda su enfoque. Tenga cuidado de no cortar parte de la información en el momento de la foto."
            },
            image: "assets/low-focus-cnh.png"
        },
        ADJUST_AREA: {
            title: {
                PT: "Ocupe o máximo da área com o documento:",
                EN: "Make the most of the area with the document:",
                ES: "Aprovecha al máximo el área con el documento:"
            },
            text: {
                PT: "Aproxime a câmera do documento para que ele ocupe o máximo da área da imagem. Caso esteja escaneando, recorte a imagem escaneada do documento para evitar que o fundo ocupe excessivamente a foto.",
                EN: "Move the camera closer to the document so that it occupies the maximum area of the image. If you are scanning, crop the scanned image of the document to prevent the background from taking up too much of the photo.",
                ES: "Acerque la cámara al documento para que ocupe el área máxima de la imagen. Si está escaneando, recorte la imagen escaneada del documento para evitar que el fondo ocupe demasiado de la foto."
            },
            image: "assets/low-area-cnh.png"
        },


    },

    faceMatchTooltips: {
        TOOLTIP_TITLE:{
            PT:"FaceMatch: Guia de Inserção de Imagens",
            EN:"FaceMatch: Image Capture Guide",
            ES:"FaceMatch: Guia de Inserção de Imagens"
        },
        DARK_IMAGE: {
            title: {
                PT: "Evite imagens escuras:",
                EN: "Avoid dark images:",
                ES: "Evite las imágenes oscuras:"
            },
            text: {
                PT: "Sempre evite que a imagem esteja escura para que a extração tenha mais chance de sucesso.",
                EN: "Avoid dark images to increase the chances of a successful match",
                ES: "Evite siempre que la imagen sea oscura para que la extracción tenga más posibilidades de éxito."
            },
            image: "assets/dark_image.png"
        },
        LITTLE_IMAGE: {
            title: {
                PT: "Evite imagens muito pequenas:",
                EN: "Avoid very small images:",
                ES: "Evite imágenes muy pequeñas:"
            },
            text: {
                PT: "Com a resolução da imagem bastante limitada, a extração não conseguirá ser efetiva.",
                EN: "Matching is not effective on images with limited resolution.",
                ES: "Con la resolución de la imagen muy limitada, la extracción no será efectiva."
            },
            image: "assets/little_image.png"
        },
        ROTATION_IMAGE: {
            title: {
                PT: "Posicione a imagem verticalmente:",
                EN: "Position the image vertically:",
                ES: "Coloque la imagen verticalmente:"
            },
            text: {
                PT: "Evite fazer o upload de fotos que estejam com o imagem de lado ou de cabeça para baixo. Imagens assim dificilmente serão reconhecidos pelo software.",
                EN: "Upside down or sideways-oriented images reduce the matching accuracy, so try to always upload a properly oriented image.",
                ES: "Evite subir fotos que tengan la imagen de lado o al revés. Es poco probable que el software reconozca estas imágenes."
            },
            image: "assets/rotation_image.png"
        },
        FOCUS_CAMERA: {
            title: {
                PT: "Atenção ao foco:",
                EN: "Attention to focus:",
                ES: "Atención al enfoque:"
            },
            text: {
                PT: "Cuidado com a qualidade da imagem a ser inserida, a imagem precisa estar com uma boa resolução. Enquadre a imagem de forma que seu foco não seja comprometido.",
                EN: "A low quality image reduces the chance of a successful match. Try to upload an image with the best possible resolution and focus.",
                ES: "Tenga cuidado con la calidad de la imagen a insertar, la imagen debe estar en buena resolución. Encuadre la imagen para que su enfoque no se vea comprometido."
            },
            image: "assets/noise_image.png"
        },

    },

    FOOTER: {
        ADDRESS: {
            PT: "Endereços",
            EN: "Address",
            ES: "Dirección"
        },
        LANGUAGE: {
            PT: "Idioma",
            ES: "Lenguaje",
            EN: "Language"
        },
        CHANGE_LANGUAGE: {
            PT: "Deseja alterar o idioma?",
            EN: "Do you want to change the language?",
            ES: "Quieres cambiar el idioma?"
        },
    },

    TIMEPICKER: {
        SECONDS: {
            PT: "Segundos",
            EN: "Seconds",
            ES: "Segundos"
        },
        MINUTES: {
            PT: "Minutos",
            EN: "Minutes",
            ES: "Minutos"
        },
        HOURS: {
            PT: "Horas",
            EN: "Hours",
            ES: "Horas"
        }
    },

    SIDE_MENU_TRANSLATION: {
        CONFIGURAÇÕES: {
            PT: "Configurações",
            EN: "Settings",
            ES: "Ajustes"
        },
        QUESTIONÁRIO: {
            PT: "Questionário",
            ES: "Cuestionarios",
            EN: "Questionnaire"
        },
        DOCUMENTOSCOPIA_E_OCR: {
            PT: "Documentoscopia e OCR",
            EN: "OCR and Document Validation",
            ES: "Documentoscopy y OCR"
        },
        FACEMATCH: {
            PT: "FaceMatch",
            EN: "FaceMatch",
            ES: "FaceMatch"
        },
        BACKGROUND_CHECK: {
            PT: "Background Check",
            EN: "Background Check",
            ES: "Background Check"
        },
        RELATÓRIO_OCR: {
            PT: "OCR",
            EN: "OCR",
            ES: "OCR"
        },
        PERGUNTAS: {
            PT: "Perguntas",
            EN: "Questions",
            ES: "Preguntas"
        },
        PERGUNTAS_PF: {
            PT: "Perguntas PF",
            EN: "Questions for persons",
            ES: "Preguntas"
        },
        PERGUNTAS_PJ: {
            PT: "Perguntas PJ",
            EN: "Questions for companies",
            ES: "Preguntas"
        },
        TESTE_AB: {
            PT: "Teste A/B",
            EN: "A/B Testing",
            ES: "Prueba A/B"
        },
        DEMONSTRAÇÃO: {
            PT: "Demonstração",
            EN: "Preview",
            ES: "Demostración"
        },
        VALIDAÇÕES: {
            PT: "Validações",
            EN: "Validations",
            ES: "Validaciones"
        },
        LISTAS_E_NOTIFICAÇÕES: {
            PT: "Listas e Notificações",
            EN: "Lists and Notifications",
            ES: "Listas y Notificaciones"
        },
        CONFIGURAÇÕES_PF: {
            PT: "Configurações PF",
            EN: "Settings for Individuals",
            ES: "Ajustes PF"
        },
        CONFIGURAÇÕES_PJ: {
            PT: "Configurações PJ",
            EN: "Settings for Companies",
            ES: "Ajustes PJ"
        },
        DEMONSTRAÇÃO_BGC: {
            PT: "Demonstração",
            EN: "Preview",
            ES: "Demostración"
        },
        DEMONSTRAÇÃO_OCR:{
            PT: "Demonstração",
            EN: "Preview",
            ES: "Demostración"
        },
        DEMONSTRAÇÃO_FACEMATCH: {
            PT: "Demonstração",
            EN: "Preview",
            ES: "Demostración"
        },
        SMSCHECK_DEMO:{
            PT: "Demonstração",
            EN: "Preview",
            ES: "Demonstración"
        },
        SMSCHECK_CONFIG:{
            PT: "Configurações",
            EN: "Settings",
            ES: "Ajustes"
        },
        GERAL: {
            PT: "Geral",
            EN: "General",
            ES: "General"
        },
        DETALHADO:{
            PT: "Detalhado",
            EN: "Detail",
            ES: "Detallado"
        },
        RESPOSTAS: {
            PT: "Respostas",
            EN: "Responses",
            ES: "Respuestas"
        },
        DETALHADO:{
            PT: "Detalhado",
            EN: "Detail",
            ES: "Detallado"
        },
        CRIAÇÃO_DE_CONTA: {
            PT: "Criação de Conta",
            ES: "Creación de Cuenta",
            EN: "Account Creation"
        },    
        CRIAÇÃO_DE_POC: {
            PT: "Criação de POC",
            EN: "POC Creation",
            ES: "Creación de POC",
        },
        HISTÓRICO_DE_POCS: {
            PT: "Histórico de POC",
            EN: "POC History",
            ES: "Historia de POC",
        },
        VERIFICAÇÃO_POR_SMS: { 
            PT: "Verificação por SMS", 
            EN: "SMS Check", 
            ES: "Verificación por SMS" 
        },
    },

    textFieldDialog: {
        Posição: {
            PT: "Posição",
            EN: "Position",
            ES: "Posición"
        },
        Palavras: {
            PT: "Palavras",
            EN: "Words",
            ES: "Palabras"
        },
        Tipo: {
            PT: "Tipo",
            EN: "Type",
            ES: "Tipo"
        },
    },

    // Utils
    fields: {
        DOC: "CPF"
    },

    //Saml Handle Errors
    samlErrors: {
        SAML: {
            PT:"Seu dominio não tem acesso ao protocolo SAML.",
            EN:"Your domain does not have access to the SAML protocol.",
            ES:"Su dominio no tiene acceso al protocolo SAML."
        },
        InvalidSaml: {
            PT:"Seu acesso ao protocolo SAML é inválido.",
            EN:"Your access to the SAML protocol is invalid.",
            ES:"Su acceso al protocolo SAML no es válido"
        },
        ProblemToCreateUser: {
            PT:"Ocorreu um erro ao criar seu acesso no portal BigID.",
            EN:"An error occurred while creating your access to the BigID portal.",
            ES:"Se produjo un error al crear su acceso al portal BigID."
        },
        UuidSAML: {
            PT:"Seu dominio não tem acesso ao protocolo SAML.",
            EN:"Your domain does not have access to the SAML protocol.",
            ES:"Su dominio no tiene acceso al protocolo SAML"
        }
    },
    PROBABILITY_DIALOG: {
        RESTRICTIVE_QUESTIONNAIRE_TITLE: {
            PT: "Questionário muito restrito.",
            EN: "Very restricted questionnaire.",
            ES: "Cuestionario muy restringido."
        },
        RESTRICTIVE_QUESTIONNAIRE_TEXT: {
            PT: "Questionário muito restrito.",
            EN: "The probability of generating questionnaires is very low! Please change your settings so that the probability increases.",
            ES: "¡La probabilidad de generar cuestionarios es muy baja! Cambie la configuración para que aumente la probabilidad."
        },
        WHY_TITLE: {
            PT: "Por que isso está acontencendo?",
            EN: "Why is this happening?",
            ES: "¿Por qué está pasando esto?"
        },
        WHY_TEXT: {
            PT: "Quando a variedade de perguntas habilitadas nas configurações é muito baixa nem sempre é possível encontrar informação suficiente para gerar um questionário para um determinado usuário e a probabilidade de gerar questionários pode cair muito. Infelizmente não é possível salvar uma configuração quando essa propabilidade fica baixa demais.",
            EN: "When the variety of questions enabled in the settings is very low, it is not always possible to find enough information to generate a questionnaire for a given user and the probability of generating questionnaires can drop a lot. Unfortunately, it is not possible to save a configuration when this probability becomes too low.",
            ES: "Cuando la variedad de preguntas habilitadas en la configuración es muy baja, no siempre es posible encontrar suficiente información para generar un cuestionario para un usuario determinado y la probabilidad de generar cuestionarios puede disminuir mucho. Desafortunadamente, no es posible guardar una configuración cuando esta probabilidad es demasiado baja."
        },
        SUGESTIONS_TITLE: {
            PT: "Algumas sugestões para resolver isto:",
            EN: "Some suggestions to resolve this:",
            ES: "Algunas sugerencias para resolver esto:"
        },
        SUGESTIONS_TEXT: {
            FIRST: {
                PT: "Aumente o número de perguntas habilitadas em diferentes tipo de informação.",
                EN: "Increase the number of questions enabled in different types of information.",
                ES: "Incrementar el número de preguntas habilitadas en diferentes tipos de información."
            },
            SEC: {
                PT: "Permita NDA(Nenhuma das Anteriores) como opção de resposta e aumente o número máximo de NDAs por questionário.",
                EN: "Allow NDA (None of the above) as an answer option and increase the maximum number of NDAs per questionnaire.",
                ES: "Permita NDA (Ninguno de los anteriores) como una opción de respuesta y aumente el número máximo de NDA por cuestionario."
            },
            THIRD: {
                PT: "Habilite NDA em mais perguntas.",
                EN: "Enable NOA on more questions.",
                ES: "Habilite NDA en más preguntas."
            },
            FOURTH: {
                PT: "Diminua a quantidade de perguntas a serem geradas no questionário.",
                EN: "Decrease the number of questions to be generated in the questionnaire.",
                ES: "Disminuir el número de preguntas que se generarán en el cuestionario."
            },
        },
    },
    BGC_CONFIG_DIALOG: {
        INVALID_CONFIG_TITLE: {
            PT: "Configurações do BackgroundCheck inválidas.",
            EN: "Invalid BackgroundCheck configurations.",
            ES: "Configuración de BackgroundCheck no válida."
        },
        INVALID_CONFIG_TEXT: {
            PT: "Configurações do BackgroundCheck inválidas. Revise os valores inseridos.",
            EN: "Invalid BackgroundCheck configurations. Review the inputted values.",
            ES: "Configuración de BackgroundCheck no válida. Revisar los valores ingresados."
        },
        WHY_TITLE: {
            PT: "Por que isso está acontencendo?",
            EN: "Why is this happening?",
            ES: "¿Por qué está pasando esto?"
        },
        WHY_TEXT: {
            PT: "Algum valor percentual pode estar configurado como menor que um ou maior que cem.",
            EN: "A percentual value may be configured as less than one or more than one hundred.",
            ES: "Un valor porcentual puede configurarse como menor de uno o mayor de cien.."
        }
    }
}
