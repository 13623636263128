import React from 'react';
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import * as actions from '../../actions';
import { MenuItem } from '@material-ui/core';
import Select from '../mui/Select';

// APIs
import API from '../../API';

// Constants
import strings from '../../constants/strings';

// Components
import TextField from '../mui/TextField';
import SectionTitle from '../SectionTitle';
import TimePicker from '../TimePicker';

// Configurations
import UserLanguage from './UserLanguage.js'
import validate from 'validate.js';

import LoadingDialog from "../LoadingDialog";
import ButtonSecondary from '../mui/ButtonSecondary';
import $ from 'jquery';
import Alert from 'react-bootstrap/lib/Alert';
import Toggle from '../../components/mui/Toggle';

let language = UserLanguage.getLanguage();


const flexColumnStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
    maxWidth: '250px'
};
const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

class SMSCheckConfig extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            configurations: this.props.configurations,
            parameters: new Map(),
            visited: false,
            smsConfigs:{},
            loading:false,
            saved:false,
            error:""
        }
        
    }

    handleChange = (event) => {
        var smsConfigs = this.state.smsConfigs;
        smsConfigs[event.target.name] = event.target.value
        this.setState({smsConfigs: smsConfigs})
    }

    handleChangeMsg = (event) => {
        var smsConfigs = this.state.smsConfigs;
        if(event.target.value && event.target.value.length < 120 && event.target.value.includes("{0}")){
            smsConfigs[event.target.name] = event.target.value
            this.setState({smsConfigs: smsConfigs, error: ""})
        }else{
            this.setState({ error: strings.SMSCHECK.SMSCHECK_MESSAGE_MAX_LEN_ERROR[language] });
        }        

    }

    handleUseLettersToggle = (event) => {
        var smsConfigs = this.state.smsConfigs;
        if(!smsConfigs.use_Numbers && smsConfigs.use_Letters){
            smsConfigs.use_Numbers = true;
        }
        smsConfigs.use_Letters = !smsConfigs.use_Letters;
        this.setState({smsConfigs: smsConfigs})
    }

    handleUseNumsToggle = (event) => {
        var smsConfigs = this.state.smsConfigs;
        if (!smsConfigs.use_Letters && smsConfigs.use_Numbers){
            smsConfigs.use_Letters = true;
        }
        smsConfigs.use_Numbers = !smsConfigs.use_Numbers;
        this.setState({smsConfigs: smsConfigs})
    }

    getSMSConfigs = async() =>{
        let language = UserLanguage.getLanguage()
        this.setState({loading: true})
        let smsConfigs = await API.GetSMSConfig(this.props.configurations.Domain);
        if(smsConfigs){
            this.setState({smsConfigs: smsConfigs})
            this.setState({loading: false})
        }
    }

    onSave = async() =>{
        let language = UserLanguage.getLanguage()
        this.setState({loading: true})
        let smsConfigs = this.state.smsConfigs;
        delete smsConfigs["_id"];
        let saved = await API.SaveSMSConfig(smsConfigs);
        if(!saved){
            this.setState({saved: false})
        }else{
            this.setState({saved: true})
        }
        this.setState({loading: false})
        $('#smsConfigAlert').removeClass('displayNone')
    }

    render() {
        let language = UserLanguage.getLanguage();
        if(!this.state.visited){
            this.getSMSConfigs();
            this.setState({visited: true})
        }
        
        return(
            <div style={flexColumnStyle}>
                <SectionTitle text={strings.generalConfigurations.SMSCHECK_CONFIG_TITLE[language]} />
                
                <LoadingDialog open={this.state.loading} />

                <Select
                    labelText={strings.generalConfigurations.NUMBER_OF_DIGITS[language]}
                    name="token_Length"
                    onChange={this.handleChange}
                    value={this.state.smsConfigs.token_Length?this.state.smsConfigs.token_Length:4}
                    tooltip={strings.generalConfigurations.NUMBER_OF_DIGITS_TOOLTIP[language]}
                >
                        <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                        <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                        <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                        <MenuItem style={styleMenuItem} value={6}>6</MenuItem>
                        <MenuItem style={styleMenuItem} value={7}>7</MenuItem>
                        <MenuItem style={styleMenuItem} value={8}>8</MenuItem>
                </Select>
                
                <br />
                <TextField
                    style={{width: 'auto'}}
                    inline
                    floatingLabelText={strings.SMSCHECK.MSG[language]}
                    onChange={this.handleChangeMsg}
                    value={this.state.smsConfigs.message}
                    name="message"
                    errorText={this.state.error}
                />
                <br />
                <TextField
                    inline
                    type="number"
                    floatingLabelText={strings.SMSCHECK.TIMEOUT_LABEL[language]}
                    onChange={this.handleChange}
                    value={this.state.smsConfigs.valid_Time}
                    name="valid_Time"
                />     
                <br />
                <Select
                    labelText={strings.SMSCHECK.RECHECK_COUNT[language]}
                    onChange={this.handleChange}
                    value={this.state.smsConfigs.maxRecheck?this.state.smsConfigs.maxRecheck:2}
                    name="maxRecheck"
                    tooltip={strings.SMSCHECK.RECHECK_COUNT_TOOLTIP[language]}
                >
                    <MenuItem style={styleMenuItem} value={0}>0</MenuItem>
                    <MenuItem style={styleMenuItem} value={1}>1</MenuItem>
                    <MenuItem style={styleMenuItem} value={2}>2</MenuItem>
                    <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                    <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                    <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                </Select>
                
                <br />
                <Toggle
                    label={strings.SMSCHECK.use_Letters[language]}
                    toggled={this.state.smsConfigs.use_Letters ? true : false}
                    onToggle={this.handleUseLettersToggle}
                    name="use_Letters"
                />
                <br />
                <Toggle
                    label={strings.SMSCHECK.use_Numbers[language]}
                    toggled={this.state.smsConfigs.use_Numbers ? true : false }
                    onToggle={this.handleUseNumsToggle}
                    name="use_Numbers"
                />  
                <ButtonSecondary
                    label={strings.SMSCHECK.SAVE[language]}
                    style={{ textTransform: "none", marginBottom: "20px"}}
                    onClick={this.onSave}
                />
                <div id="smsConfigAlert" class="displayNone">
                    <Alert bsStyle={this.state.saved ? 'success' : 'danger'}>
                        {this.state.saved ? strings.SMSCHECK.SAVE_SUCCESS[language] : strings.SMSCHECK.SAVE_ERR[language]}
                    </Alert>
                </div>             
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(SMSCheckConfig);