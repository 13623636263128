import React from 'react';
import { Button } from '@material-ui/core';
import colors from '../../constants/colors';

var styleButton = {
    backgroundColor: colors.primaryRed,
    color: colors.White,
    textTransform: "uppercase",
    fontSize: "14px",
    marginTop: "20px",
    fontFamily: "HKGrotesk",
    marginLeft: 20
};

class ButtonSecondary extends React.Component {
    render(){
        let tempStyleButton = Object.assign({}, styleButton, this.props.style);        
        
        return(
            <Button
                variant="contained"
                id={this.props.id}
                style={tempStyleButton}
                datatype={this.props.type}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                {this.props.label}
            </Button>
        )
    }
}

export default ButtonSecondary;