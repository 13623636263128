import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import LoadingDialog from '../components/LoadingDialog';

import Strings from '../constants/strings';

// Material UI Imports
import { Card, CardContent } from '@material-ui/core';

import SideMenu from '../components/SideMenu';
import ListValidations from '../components/configurations/ListValidations';
import GeneralConfigurations from '../components/configurations/GeneralConfigurations';
import QuestionsConfigurations from '../components/configurations/QuestionsConfigurations';
import BackgroundCheck from '../components/configurations/BackgroundCheck';
import Validations from '../components/configurations/Validations';
import Demonstration from '../components/configurations/Demonstration';
import DocumentValidation from '../components/configurations/DocumentValidation'
import ABTesting from '../components/configurations/ABTesting';
import FaceMatchConfig from '../components/configurations/FaceMatchConfig';
import SMSCheckDemo from '../components/configurations/SMSCheckDemo';
import SMSCheckConfig from '../components/configurations/SMSCheckConfig';
import MenuGeneralConfig from '../components/configurations/MenuGeneralConfig';
import SamlErrorsHandle from '../components/SamlErrorsHandle';

// API
import API from '../API';
import ApiConstants from '../constants/api';
import Auth from '../Authentication';

import { connect } from 'react-redux';
import * as actions from '../actions';
import ProbabilityDialog from "../components/ProbabilityDialog";
import NotAllowedDialog from '../components/NotAllowedDialog';
import BgcConfigsValidationDialog from '../components/BgcConfigsValidation';
import BackgroundCheck_PJ from '../components/configurations/BackgroundCheck_PJ';
import DemonstrationBGC from '../components/configurations/DemonstrationBGC';
import Utils from '../components/Utils';
import { checkBGCConfigValidation } from '../components/Utils';
import { checkBoostTokenAbsentBGC } from '../components/Utils';

const cardStyle = {
    width: "100%",
    maxWidth: "900px",
    minHeight: "900px",
    paddingTop: "15px",
    position: "relative",
    marginLeft: '200px',
    boxShadow: null,
};

const containerStyle = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative"
};

const cardTextStyle = {
    zIndex: 2,
    paddingTop: 0,
};

class Configurations extends React.Component {
    constructor(props) {
        super(props);

        if (localStorage.getItem("saml-error")) {
            this.samlMessage = localStorage.getItem("saml-error");
            localStorage.removeItem("saml-error")
        }

        // Here is the place that we choose the first page from BigID site
        this.state = {
            configurations: this.props.configurations,
            menuActive: (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser || Auth.sessionData.isReportUser)
                ? Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.DEMONSTRATION
                : Strings.generalConfigurations.menu.GENERAL_CONFIGURATIONS,
            modified: this.props.modified,
            displayCreateGroupForm: false,
            probabilityError: false,
            bgcInvalidError: false
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
            modified: newProps.modified,
        });
    }

    // -- HANDLERS -- //

    handleSave = async () => {
        this.setState({ loading: true });
        // Get estimativate of proability of generation
        let probabilityOfGeneration = await API.getGroupProbabilityOfSuccess(this.state.configurations);
        // console.log("probabilityOfGeneration:", probabilityOfGeneration);
        // If this probability is less than 0.70 and is not an admin
        // does not let the user save it
        // if (probabilityOfGeneration < 0.70 && !Auth.sessionData.isAdmin) {
        //     this.setState({ probabilityError: true, loading: false });
        //     return;
        // }
        if (probabilityOfGeneration < 0.70) {
            this.setState({ probabilityError: true, loading: false });
            return;
        }
        let bgcConfigValid = checkBGCConfigValidation(this.state.configurations);
        if (!bgcConfigValid){
            this.setState({ bgcInvalidError: true, loading: false });
            return;
        }
        let bgcBoostTokenAbsent = checkBoostTokenAbsentBGC();
        if (bgcBoostTokenAbsent){
            this.setState({loading: false});
            return;
        }

        let result = await API.saveGroup(this.state.configurations);
        
        if (result) {
            if (result.status === ApiConstants.status.SUCCESS) {
                this.setState({ configurations: result.configurations });
                this.props.onSelectGroup(result.configurations, result.groupsNames);
            } else {
                this.setState({ notAllowedError: true, loading: false })
            }
        }

        this.setState({ loading: false });
    };

    handleMenuChange = (value) => {
        this.setState({ menuActive: value });
    };

    // -- RENDER -- //
    renderContent() { 
        switch (this.state.menuActive.TITLE) {
            case Strings.generalConfigurations.menu.GENERAL_CONFIGURATIONS.TITLE:
                if (this.state.menuActive.PARENT !== undefined) {
                    return (<GeneralConfigurations />);
                } else {
                    return (<MenuGeneralConfig />);
                }

            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.CONFIGURATIONS.TITLE:
                return (<QuestionsConfigurations onInfoTypeSelected={this.handleMenuChange} />);

            case Strings.generalConfigurations.menu.QUESTIONNAIRE.TITLE:
                return (<GeneralConfigurations />);
            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.QUESTIONS_PERSONS.TITLE:
                return (<QuestionsConfigurations onInfoTypeSelected={this.handleMenuChange} kind="PF"/>);
            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.QUESTIONS_COMPANIES.TITLE:
                return (<QuestionsConfigurations onInfoTypeSelected={this.handleMenuChange} kind="PJ"/>);
            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.VALIDATIONS.TITLE:
                return (<Validations />);

            case Strings.generalConfigurations.menu.BACKGROUN_CHECK.SUBS.ABTESTING_BGC.TITLE:
                return (<ABTesting />);

            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.ABTESTING.TITLE:
                return (<ABTesting />);

            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.DEMONSTRATION.TITLE:
                return (<Demonstration />);

            case Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.NOTIFICATIONS.TITLE:
                return (<ListValidations />);

            case Strings.generalConfigurations.menu.DOCUMENT_VALIDATION.TITLE:
            case Strings.generalConfigurations.menu.DOCUMENT_VALIDATION.SUBS.DEMONSTRATION_OCR.TITLE:
                return (<DocumentValidation />);

            case Strings.generalConfigurations.menu.BACKGROUN_CHECK.TITLE:
            case Strings.generalConfigurations.menu.BACKGROUN_CHECK.SUBS.CONFIGURATIONS_PF.TITLE:
                return (<BackgroundCheck />);

            case Strings.generalConfigurations.menu.BACKGROUN_CHECK.SUBS.CONFIGURATIONS_PJ.TITLE:
                return (<BackgroundCheck_PJ />);

            case Strings.generalConfigurations.menu.BACKGROUN_CHECK.SUBS.DEMONSTRATION_BGC.TITLE:
                return (<DemonstrationBGC />);

            case Strings.generalConfigurations.menu.BACKGROUN_CHECK.SUBS.NOTIFICATIONS_BGC.TITLE:
                return (<ListValidations />);

            case Strings.generalConfigurations.menu.FACEMATCH.TITLE:
            case Strings.generalConfigurations.menu.FACEMATCH.SUBS.DEMONSTRATION_FACEMATCH.TITLE:
                return (<FaceMatchConfig />);
            
            case Strings.generalConfigurations.menu.SMSCHECK.TITLE:
            case Strings.generalConfigurations.menu.SMSCHECK.SUBS.SMSCHECK_DEMONSTRATION.TITLE:
                return (<SMSCheckDemo />);
            
            case Strings.generalConfigurations.menu.SMSCHECK.SUBS.SMSCHECK_CONFIGURATIONS.TITLE:
                return (<SMSCheckConfig />);

            default:
                return null;
        }
    }


    render() {
        let isTelemarketing = Auth.sessionData.isTelemarketing;
        let isViewOnlyUser = Auth.sessionData.isViewOnlyUser;
        let isReportUser = Auth.sessionData.isReportUser;
        let menuItems = [];
        if (isTelemarketing || isViewOnlyUser || isReportUser) {
            menuItems.unshift(Strings.generalConfigurations.menu.QUESTIONNAIRE.SUBS.DEMONSTRATION);
        }
        else
        {
            menuItems.unshift(Strings.generalConfigurations.menu.SMSCHECK);
            menuItems.unshift(Strings.generalConfigurations.menu.BACKGROUN_CHECK);
            menuItems.unshift(Strings.generalConfigurations.menu.FACEMATCH);
            menuItems.unshift(Strings.generalConfigurations.menu.DOCUMENT_VALIDATION);
            menuItems.unshift(Strings.generalConfigurations.menu.QUESTIONNAIRE);
            menuItems.unshift(Strings.generalConfigurations.menu.GENERAL_CONFIGURATIONS);
        }

        return (
            <div>
                <SamlErrorsHandle message={this.samlMessage} />
                <Header />
                <div className="content-wrapper" style={containerStyle}>
                    <SideMenu className="side-menu" showSaveButton selected={this.state.menuActive} title={(isTelemarketing || isViewOnlyUser)} menuItems={menuItems} onSelectedChange={this.handleMenuChange} handleSave={this.handleSave} />
                    <div className="select-wrapper">
                    </div>

                    <Card style={cardStyle} class="content">
                        <CardContent style={cardTextStyle}>
                            {this.renderContent()}
                        </CardContent>
                    </Card>

                    <LoadingDialog open={this.state.loading} />

                    <NotAllowedDialog
                        open={this.state.notAllowedError}
                        handleRequestClose={() => this.setState({ notAllowedError: false })} />

                    <ProbabilityDialog
                        open={this.state.probabilityError}
                        handleRequestClose={() => this.setState({ probabilityError: false })} />

                    <BgcConfigsValidationDialog
                        open={this.state.bgcInvalidError}
                        handleRequestClose={() => this.setState({ bgcInvalidError: false })} />
                </div>
                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations, groupsNames) => {
            dispatch(actions.saveConfigurations(configurations));
            dispatch(actions.storeGroupsNames(groupsNames));
        },

        onSelectGroup: (configurations, groupsNames) => {
            dispatch(actions.selectConfigurations(configurations));
            dispatch(actions.storeGroupsNames(groupsNames));
        },
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        modified: state ? state.modified : false,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Configurations);
