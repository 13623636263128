import React from 'react';
import Toggle from './mui/Toggle';
import { MenuItem } from '@material-ui/core';
import SelectField from '@material-ui/core/Select';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ButtonSecondary from '../components/mui/ButtonSecondary';
import Strings from '../constants/strings';
import TextField from './mui/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const defaultToggleStyle = {
    // border: '1px solid #cc0000'
};

const defaultWrapperStyle = {
    marginBottom: 10
};

const textStyle = {
    //   float: 'right'
};

const buttonStyle = {
    backgroundColor: '#fff',
    border: '1px solid #000',
    borderRadius: '100px',
    width: '26px'
}

const selectFieldStyle = {
    fontSize: '18px',
    color: 'black',
    marginBottom: '12px',
    position: 'relative',
    bottom: '5px'
};

const reactscrollablelist = {
    sizing: 'border-box',
    height: '200px',
    width: '200px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    border: '#ddd solid 1px'
};

const imgStyle = {
    width: 18,
    height: 18,
    marginLeft: 10,
    cursor: 'pointer'
}

const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

class CheckboxEnableValue_PJ extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            over: false,
            showModal: false,
            addButton: false
        }
    }

    handleOpenModal = () => {
        this.setState({ showModal: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleSelectKeyMap = (event) => {
        const newState = this.state
        this.props.data.word = event.target.value;
        this.setState({ newState })
    }

    onDelete = (data, mainField, field, index) => {
        const newState = this.state
        data.config[data.name][mainField][0][field].splice(index, 1)
        this.setState({ newState })
    }


    getList(data, name, mainField, field) {
        const words = data.config[name][mainField][0][field]

        let listItems = []
        for (let i = 0; i < words.length; i++) {
            listItems.push({ content: words[i] })
        }

        return listItems;
    }

    render() {
        const actions = [
            <ButtonSecondary
                label={Strings.generalConfigurations.BACK[language]}
                onClick={() => this.handleCloseModal()}
            />,
        ];

        const data = this.props.data;
        const fields = this.props.data.fields || [];
        const fieldsArray = this.props.data.fieldsArray || { fields: [] };
        const fieldArrayToggle = this.props.data.fieldArrayToggle || { fields: [] };
        const fieldItemArray = this.props.data.fieldItemArray || { fields: [] };

        const toggleStyle = Object.assign({}, defaultToggleStyle, this.props.style || {});
        let wrapperStyle = Object.assign({}, defaultWrapperStyle, this.props.wrapperStyle || {});

        if (this.state.over) {
            wrapperStyle.backgroundColor = "rgba(200,200,200,0.25)";
        }
        console.info(` data.config: `, data.config);
        console.info(` data.config[${data.name}] [ ${this.props.key} ]:`, data.config[data.name]);

        const nameMatchTextStyle = {
            display: (data.config[data.name].Enabled ? "block" : "none")
        };

        const toggleRetreiver = () => {
            const accepted_data = [
                "COMPANY_AGE",
                "KYC_PJ_MONEY_LAUDERING",
                "KYC_PJ_SLAVERY",
                "KYC_PF_SLAVERY",
                "KYC_AGE",
                "KYC_COURT_ORDERS",
                "KYC_PF_MONEY_LAUNDERING",
                "KYC_CEAF",
                "KYC_TERRORIST"
            ]
            if (accepted_data.indexOf(data.name) !== -1) 
            {
                let key = data.name + "_" + data.index;
                //Para separar os toggles
                if (this.props.data.displayToggle)
                    return (
                        <Toggle
                            tooltip={typeof Strings.backgroundCheckConfigurations_PJ[data.name + '_TEXT'] === 'object' ? Strings.backgroundCheckConfigurations_PJ[data.name + '_TEXT'][language] : Strings.backgroundCheckConfigurations_PJ[data.name + '_TEXT']}
                            style={toggleStyle}
                            label={(data.incomplete ? ' . . . API . . . - ' + data.incomplete : Strings.backgroundCheckConfigurations_PJ[data.name][language])}
                            toggled={data.config[data.name].Enabled}
                            key={key}
                            name={data.name}
                            onToggle={this.props.handledataToggle.bind(null, data.name)}
                        />
                    )
            }
            else {
                return (
                    <Toggle
                        tooltip={typeof Strings.backgroundCheckConfigurations_PJ[data.name + '_TEXT'] === 'object' ? Strings.backgroundCheckConfigurations_PJ[data.name + '_TEXT'][language] : Strings.backgroundCheckConfigurations_PJ[data.name + '_TEXT']}
                        style={toggleStyle}
                        label={(data.incomplete ? ' . . . API . . . - ' + data.incomplete : Strings.backgroundCheckConfigurations_PJ[data.name][language])}
                        toggled={data.config[data.name].Enabled}
                        key={data.name}
                        name={data.name}
                        onToggle={this.props.handledataToggle.bind(null, data.name)}
                    />)
            }
        };

        return (
            <div
                style={{ ...wrapperStyle, ...data.style }}
                key={data.name}
                onMouseOver={() => { this.setState({ over: true }) }}
                onMouseLeave={() => { this.setState({ over: false }) }}>

                {
                    toggleRetreiver()
                }
                <div style={{ display: 'flex' }} class="bgc-toggle-content-wrapper">

                    <div style={{ marginLeft: '10px' }}>

                        {fields.map((item, index) => {
                            if (item.type === "toggle") {
                                if (this.props.data.name === "PROCESS_COURT_TYPES_PJ" || this.props.data.name === "PROCESS_COURT_TYPES") {
                                    return (
                                        <div>
                                            <div style={{ bottom: '100px', display: 'flex' }}>
                                                <h5
                                                    style={{ ...nameMatchTextStyle, fontWeight: "bold" }}>
                                                    {Strings.backgroundCheckConfigurations.SCORE_INDIVIDUAL[language]}
                                                </h5>

                                                <Toggle
                                                    style={toggleStyle}                                                
                                                    value={data.config[data.name][item.field][0].UseIndividualScore}
                                                    toggled={data.config[data.name][item.field][0].UseIndividualScore}
                                                    onToggle={this.props.handleScoreToggle.bind(null, data.name, item.field)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            }

                            if (item.type === "total_number") {
                                if (this.props.data.name === "PROCESS_COURT_TYPES_PJ") {

                                    const toggleStyleWithText = {
                                        display: (!data.config[data.name][item.mainField][0].UseIndividualScore && data.config[data.name].Enabled ? "block" : "none")
                                    }

                                    return (
                                        <TextField
                                            tooltip={null}
                                            textStyle={{ ...textStyle }}
                                            labelStyle={{ fontWeight: 300 }}
                                            type='number'
                                            wrapperStyle={toggleStyleWithText}
                                            floatingLabelText={(item.incomplete ? ' . . . API . . . - ' + data.incomplete : Strings.backgroundCheckConfigurations_PJ[data.name + '_VALUE'] || item.label) || Strings.backgroundCheckConfigurations.PERCENT[language]}
                                            value={Number(data.config[data.name][item.mainField][0][item.field])}
                                            name={data.name + '_' + item.field}
                                            key={data.name + '_' + item.field}
                                            onChange={this.props.handleValueMatchChange.bind(null, [data.name, item.field])}
                                        />
                                    )
                                }
                            }
                        })}

                        {fields.map((item, index) => {
                            if (item.type === "toggle") {
                                if (this.props.data.name === "QSA") {
                                    return (
                                        <div style={{ bottom: '100px', display: 'flex' }}>
                                            <h5
                                                style={{ ...nameMatchTextStyle, fontWeight: "bold" }}>
                                                {Strings.backgroundCheckConfigurations.SCORE_INDIVIDUAL[language]}
                        </h5>

                                            <Toggle
                                                style={toggleStyle}
                                                value={data.config[data.name].ScoreIndividual}
                                                toggled={data.config[data.name].ScoreIndividual}
                                                onToggle={this.props.handleScoreToggle.bind(null, data.name, item.field)}
                                            />
                                        </div>
                                    )
                                }
                            }
                        })}


                        {(fieldsArray.fields.length === 0)
                            ? null : fieldsArray.fields.map((item, index) => {
                                let renderData = []
                                if (item.basictype === 'dict') {
                                    for (var key in data.config[data.name][fieldsArray.name][0][item.field]) {
                                        renderData.push(data.config[data.name][fieldsArray.name][0][item.field][key])
                                    }
                                } else {
                                    renderData = data.config[data.name][fieldsArray.name][0][item.field]
                                    if (renderData == null || renderData == undefined) {
                                        renderData = []
                                    }
                                }
                                renderData = renderData.map((courtItem, dataIndex) => {
                                    const styleWidth = !item.styleWidth ? {} : { width: item.styleWidth };
                                    const toggleStyleWithText = {
                                        display: (data.config[data.name][fieldsArray.name][0].UseIndividualScore && data.config[data.name].Enabled ? "block" : "none")
                                    }
                                    if (item.type === 'select') {
                                        return (
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <InputLabel
                                                        id="the-label"
                                                        style={{ ...nameMatchTextStyle, marginTop: '23px' }}
                                                    >
                                                        {(item.label || '') + (item.incomplete ? 'FALTA DADO API' : '') || Strings.backgroundCheckConfigurations.SELECT[language]}
                                                    </InputLabel>
                                                    <SelectField
                                                        inline
                                                        labelId="the-label"
                                                        style={{ width: "200px", paddingTop: "2px", ...nameMatchTextStyle, ...styleWidth }}
                                                        onChange={this.props.handleValueMatchChange.bind(null, [data.name, fieldsArray.name, dataIndex, item.field])}
                                                        value={data.config[data.name][fieldsArray.name][0][item.field][dataIndex] || item.default}
                                                        key={data.name + fieldsArray.name + dataIndex + '_' + item.field}
                                                    >
                                                        {item.values.map((value) => {
                                                            return (
                                                                <MenuItem style={styleMenuItem} key={data.name + '_' + item.field + value} value={value} >
                                                                    {value.toString()}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </SelectField>
                                                </div>

                                                {this.renderRemoveButton(data, fieldsArray, nameMatchTextStyle, dataIndex)}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <TextField
                                                    textStyle={{ ...textStyle, ...styleWidth }}
                                                    type={item.type}
                                                    wrapperStyle={toggleStyleWithText}
                                                    labelStyle={{ fontWeight: 'normal' }}
                                                    floatingLabelText={item.values[dataIndex]}
                                                    value={renderData[dataIndex]}
                                                    name={data.name + '_' + item.values[dataIndex]}
                                                    key={data.name + '_' + item.values[dataIndex]}
                                                    onChange={this.props.handleValueMatchChange.bind(null, [data.name, fieldsArray.name, item.translate[item.values[dataIndex]], item.field])}
                                                />
                                            </div>
                                        )
                                    }
                                });

                                return renderData;
                            })
                        }

                        {this.renderButton(data, fieldsArray, nameMatchTextStyle)}

                        {fields.map((item, index) => {
                            if (item.type === 'selectParty') {
                                return (
                                    <div style={{ top: '40px' }}>
                                        <h5
                                            style={{ ...nameMatchTextStyle }}>
                                            {Strings.backgroundCheckConfigurations.POSITION[language]}
                                </h5>
                                        <div>
                                            <InputLabel
                                                id="the-label-2"
                                                style={{ ...nameMatchTextStyle, marginTop: '23px' }}
                                            >
                                                {(item.label || '') + (item.incomplete ? 'FALTA DADO API' : '') || Strings.backgroundCheckConfigurations.SELECT[language]}
                                            </InputLabel>
                                            <SelectField
                                                labelId="the-label-2"
                                                style={{ width: "200px", paddingTop: "2px", ...nameMatchTextStyle }}
                                                onChange={this.props.handleValueMatchChange.bind(null, [data.name, item.mainField, item.field, item.translate])}
                                                value={item.translateVal[data.config[data.name][item.mainField][0][item.field]]}
                                                key={data.name + item.mainField + '_' + item.field}
                                            >

                                                {item.values.map((value) => {
                                                    return (
                                                        <MenuItem style={styleMenuItem} key={data.name + '_' + item.field + value} value={value}>
                                                            {value.toString()}
                                                        </MenuItem>
                                                    )
                                                })}

                                            </SelectField>
                                        </div>
                                    </div>
                                )
                            }
                        })}

                        {(fieldItemArray.fields.length === 0)
                            ? null
                            : data.config[data.name][fieldItemArray.name].map((courtItem, dataIndex) => {
                                const renderData = fieldItemArray.fields.map((item, index) => {
                                    const styleWidth = !item.styleWidth ? {} : { width: item.styleWidth };

                                    return (
                                        <div>
                                            <InputLabel
                                                id="the-label-3"
                                                style={{ ...nameMatchTextStyle, marginTop: '23px' }}
                                            >
                                                {(item.label || '') + (item.incomplete ? 'FALTA DADO API' : '')}
                                            </InputLabel>
                                            <SelectField
                                                labelId="the-label-3"
                                                style={{ width: "200px", ...nameMatchTextStyle, ...styleWidth }}
                                                onChange={this.props.handleValueMatchChange.bind(null, [data.name, fieldItemArray.name, dataIndex])}
                                                value={data.config[data.name][fieldItemArray.name][dataIndex] || item.default}
                                                key={data.name + fieldItemArray.name + dataIndex}
                                            >
                                                {item.values.map((value) => {
                                                    return (
                                                        <MenuItem style={styleMenuItem} key={data.name + value} value={value} >
                                                            {value.toString()}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </SelectField>
                                        </div>
                                    )
                                });

                                renderData.push(this.renderRemoveButton(data, fieldItemArray, nameMatchTextStyle, dataIndex));
                                return renderData;
                            })
                        }

                        {this.renderButton(data, fieldItemArray, nameMatchTextStyle)}
                    </div>

                    <div style={{ display: 'flex', maxWidth: '100%' }}>
                        {fields.map((item, index) => {
                            const styleWidth = !item.styleWidth ? {} : { width: item.styleWidth };
                            const accepted_props = [
                                'COMPANY_AGE',
                                'KYC_COURT_ORDERS',
                                'KYC_TERRORIST',
                                'KYC_CEAF',
                                'KYC_PJ_MONEY_LAUDERING',
                                'KYC_PJ_SLAVERY',
                                'KYC_PF_SLAVERY',
                                'KYC_AGE', 
                                'KYC_PF_MONEY_LAUNDERING'
                            ]
                            if (item.type === 'select') {
                                if (accepted_props.indexOf(this.props.data.name) !== -1) {
                                    if (item.field == "Operand") {
                                        const valueContainer = data.config[data.name][item.field];
                                        const value = Array.isArray(valueContainer) ? valueContainer[data.index] : item.value;
                                        let addButtonObject = ""

                                        if (this.props.addButton) {
                                            addButtonObject = [
                                                <ButtonSecondary 
                                                    label="teste" 
                                                    style={nameMatchTextStyle}
                                                    key={data.name}
                                                />
                                            ];
                                        }

                                        console.info(`COMPANY_AGE :: select :: value: `, value);
                                        return (
                                            <div>
                                                <InputLabel
                                                    id="the-label-4"
                                                    style={{ ...nameMatchTextStyle, marginTop: '23px' }}
                                                >
                                                    {(item.label || '') + (item.incomplete ? 'FALTA DADO API' : '') || Strings.backgroundCheckConfigurations.SELECT[language]}
                                                </InputLabel>
                                                <SelectField
                                                    labelId="the-label-4"
                                                    style={{ ...selectFieldStyle, ...nameMatchTextStyle, ...styleWidth }}
                                                    onChange={this.props.handleValueMatchChange.bind(null, [data.name, item.field, data.index])}
                                                    value={Array.isArray(valueContainer) ? valueContainer[data.index] : item.value}
                                                    key={data.name + '_' + item.field + "_" + data.index}
                                                >
                                                    {item.values.map((value) => {
                                                        return (
                                                            <MenuItem style={styleMenuItem} key={data.name + '_' + item.field + value} value={value}>
                                                                {value.toString()}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </SelectField>
                                            </div>
                                        )
                                    }
                                }
                                return (
                                    <div>
                                        <InputLabel
                                            id="the-label-5"
                                            style={{ ...nameMatchTextStyle, marginTop: '23px' }}
                                        >
                                            {(item.label || '') + (item.incomplete ? 'FALTA DADO API' : '') || Strings.backgroundCheckConfigurations.SELECT[language]}
                                        </InputLabel>
                                        <SelectField
                                            labelId="the-label-5"
                                            style={{ ...selectFieldStyle, ...nameMatchTextStyle, ...styleWidth, paddingTop: '5px' }}
                                            onChange={this.props.handleValueMatchChange.bind(null, [data.name, item.field])}
                                            value={data.config[data.name][item.field] || item.default}
                                            key={data.name + '_' + item.field}
                                        >
                                            {item.values.map((value) => {
                                                return (
                                                    <MenuItem style={styleMenuItem} key={data.name + '_' + item.field + value} value={value}>
                                                        {value.toString()}
                                                    </MenuItem>
                                                )
                                            })}
                                        </SelectField>
                                    </div>
                                )

                            } else if (item.type === "scrollbox") {
                                return (
                                    <div style={{ display: 'block', marginRight: '5px' }}>

                                        <h5
                                            style={{ ...nameMatchTextStyle }}
                                        >{Strings.backgroundCheckConfigurations.LAWSUIT_KEYWORD[language]}</h5>
                                        <List
                                            heightOfItem={20}
                                            style={{ ...reactscrollablelist, ...nameMatchTextStyle }}
                                            maxItemsToRender={30}
                                        >
                                            {
                                                data.config[data.name][item.mainField][0][item.field].map((value, index) => {
                                                    return (
                                                        <ListItem style={{ borderBottom: '1px solid' }}>
                                                            <ListItemText primary={value} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton 
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={this.onDelete.bind(null, data, item.mainField, item.field, index)}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                })}
                                        </List>
                                        <div style={{ marginTop: '50px', ...nameMatchTextStyle }}>
                                            <HelpOutlineIcon style={imgStyle} onClick={this.handleOpenModal} alt="" />
                                            <TextField
                                                type="text"
                                                value={this.props.data.word ? this.props.data.word : ""}
                                                floatingLabelText={Strings.backgroundCheckConfigurations.TYPE_WORD[language]}
                                                onChange={this.handleSelectKeyMap.bind(null)}
                                            />
                                        </div>

                                        <div>
                                            <ButtonSecondary
                                                disabled={this.state.disableButton}
                                                style={{ ...nameMatchTextStyle, textTransform: "none" }}
                                                label={Strings.backgroundCheckConfigurations.ADD_WORD[language]}
                                                onClick={this.props.onGenerate.bind(null, [this.props.data.name, item.field], this.props.data.word)}
                                            />
                                        </div>
                                    </div>
                                )
                            } else {
                                if (this.props.data.name === "COMPANY_AGE"
                                    || this.props.data.name === "KYC_COURT_ORDERS"
                                    || this.props.data.name === "KYC_TERRORIST"
                                    || this.props.data.name === "KYC_PJ_MONEY_LAUDERING"
                                    || this.props.data.name === "KYC_PJ_SLAVERY"
                                    || this.props.data.name === "KYC_PF_SLAVERY"
                                    || this.props.data.name === "KYC_AGE"
                                    || this.props.data.name === "KYC_PF_MONEY_LAUNDERING"
                                    || this.props.data.name === "KYC_CEAF") {
                                    if (item.field == "Score" || item.field == "Value") {
                                        const valueContainer = data.config[data.name][item.field];
                                        var floatLabel = this.treatFloatLabel(item, data)
                                        return (
                                            <TextField
                                                tooltip={null}
                                                textStyle={{ ...textStyle, ...styleWidth }}
                                                labelStyle={{ fontWeight: 300 }}
                                                type={item.type}
                                                wrapperStyle={nameMatchTextStyle}
                                                floatingLabelText={floatLabel}
                                                value={Number(valueContainer ? valueContainer[data.index] : item.value)}
                                                name={data.name + '_' + item.field}
                                                key={data.name + '_' + item.field + "_" + data.index}
                                                onChange={this.props.handleValueMatchChange.bind(null, [data.name, item.field, data.index])}
                                            />)
                                    }
                                }
                                if (item.field == "Score" || item.field == "Value") {
                                    var floatLabel = this.treatFloatLabel(item, data)
                                    return (
                                        <TextField
                                            tooltip={null}
                                            textStyle={{ ...textStyle, ...styleWidth }}
                                            labelStyle={{ fontWeight: 300 }}
                                            type='number'
                                            wrapperStyle={nameMatchTextStyle}
                                            floatingLabelText={floatLabel}
                                            value={Number(data.config[data.name][item.field] || item.default)}
                                            //value= {Number(item.value)}
                                            name={data.name + '_' + item.field}
                                            key={data.name + '_' + item.field}
                                            onChange={this.props.handleValueMatchChange.bind(null, [data.name, item.field])}
                                        />
                                    )
                                }
                            }
                        })}
                    </div>
                    <Dialog
                        open={this.state.showModal}
                        autoScrollBodyContent={true}
                        onClose={() => this.handleCloseModal()}
                        aria-labelledby="CheckboxEnableValuePJDialog"
                    >
                        <DialogTitle id="CheckboxEnableValuePJDialog">
                            {Strings.MENU_LAWSUITS_PRESENCE_MODAL_TITLE[language]}
                            <IconButton aria-label="close" onClick={() => this.handleCloseModal()} style={{left: "55%"}}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{fontFamily: "HKGrotesk"}}>
                                {Strings.MENU_LAWSUITS_PRESENCE_MODAL[language]}.
                                <br /><br />
                                {Strings.MENU_LAWSUITS_PRESENCE_MODAL_EXAMPLE[language]}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }

    renderButton(data, fieldsArray, nameMatchTextStyle) {
        return (fieldsArray.fields.length === 0)
            ? null
            : (
                <div style={{
                    display: 'block',
                    width: '100%'
                }}>
                    <button onClick={this.props.handleValueMatchMake.bind(null, [data.name, fieldsArray.name, null])} style={{ ...nameMatchTextStyle, ...buttonStyle, width: '150px', margin: '15px 0' }}>{Strings.backgroundCheckConfigurations.ITEM_ADD[language]} +</button>
                </div>
            )
    }

    renderRemoveButton(data, fieldsArray, nameMatchTextStyle, index) {
        return (fieldsArray.fields.length === 0)
            ? null
            : (
                <div><button onClick={this.props.handleValueMatchMake.bind(null, [data.name, fieldsArray.name, index])} style={{ marginTop: '30px', marginRight: '100px', ...nameMatchTextStyle, ...buttonStyle }}>-</button></div>
            )
    }
    treatFloatLabel(item, data) {
        if((data.name == "KYC_PJ_MONEY_LAUDERING"
            || data.name == "KYC_PF_MONEY_LAUNDERING"
            || data.name == "KYC_PF_SLAVERY"
            || data.name == "KYC_PJ_SLAVERY")
            && item.field == "Value"){
            return Strings.backgroundCheckConfigurations.KYC_NAME_MATCH_PERCENT[language]
        }        
        return (item.incomplete ? ' . . . API . . . - ' + data.incomplete : Strings.backgroundCheckConfigurations_PJ[data.name + '_VALUE'] || item.label) || Strings.backgroundCheckConfigurations.VALUE[language]
    }
}

export default CheckboxEnableValue_PJ;
