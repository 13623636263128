export default {
    internalReplaceAll(phrase, before, after) {
        // Count how many characters in string
        let letterCount = phrase.replace(before, '').length;

        for(let i = 0; i < letterCount; i++) {
            phrase = phrase.replace(before, after);
        }
        return phrase;
    }
}

function isObject(variable){
    if (
        typeof variable === 'object' &&
        !Array.isArray(variable) &&
        variable !== null
    ) {
        return true;
    }
    return false;
}

function negativeValueInConfig(key, bgcConfigs){
    //Age has value in configs but it's not a percentage
    if(key == "KYC_AGE"){
        return false;
    }
    if(isObject(bgcConfigs[key]) && 'Configs' in bgcConfigs[key]){
        let configs = bgcConfigs[key]['Configs'];
        if(configs === null)
            return false;
        for(let i=0; i < configs.length; i++){
            if(configs[i]['Value'] <= 0 || configs[i]['Value'] > 99){
                console.log(`Key = ${key} and Configs = ${configs[i]}`)
                return true;
            }
        }
    }
    return false;
}

function validateBGCConfig(type, group){
    let bgcConfigs;
    if(type === "PF")
        bgcConfigs = group["BackGroundCheckConfigs"]["DataSetsConfigs"];
    else if(type === "PJ")
        bgcConfigs = group["BackGroundCheckConfigsPJ"]["DataSetsConfigs"];

    let relatedConfigs;
    for(const key in bgcConfigs){
        if(negativeValueInConfig(key, bgcConfigs))
            return false;
        
        if(key === 'RELATED_PEOPLE' || key === 'QSA'){

            relatedConfigs = bgcConfigs[key]['RelatedDataSetsConfigs'];
            for(const related_key in relatedConfigs)
                if(negativeValueInConfig(related_key, relatedConfigs))
                    return false;
        }
    }
    return true;
}

export function checkBGCConfigValidation(group){
    return validateBGCConfig("PF", group) && validateBGCConfig("PJ", group);
}

export function checkBoostTokenAbsentBGC(){
    var visitedBGCPage = localStorage.getItem("visitedBGCPage")
    if (visitedBGCPage == "true")
    {
        alert ("Erro ao salvar: Só é possivel Retornar dos dados utilizados no Background Check com o Token do BigBoost preenchido.\n\nInsira um Token ou Desabilite as opções de retorno de dados.")
        return true;   
    }
    return false;
}