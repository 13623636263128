import React from 'react';

import { Redirect, Link } from 'react-router-dom';

import Auth from '../Authentication';
import API from '../API';

import { connect } from 'react-redux';
import * as actions from '../actions';
import LoadingDialog from "./LoadingDialog";
import { CircularProgress } from "@material-ui/core";
import SelectField from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';

import Strings from '../constants/strings';
import UserLanguage from './configurations/UserLanguage.js'

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import $ from 'jquery';

let language = UserLanguage.getLanguage();

const flag = {
    marginRight: "5px",
    paddingBottom: "5px"
}
  
const styleMenuItem = {
    fontSize: "16px",
    fontFamily: "HKGrotesk",
    padding: "12px"
}

const groupStyle = {
    color: "white",
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridGap: '5px'
};


const reportStyle = {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: '60% 40%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumnGap: '10px'
}

const StyleViewOnlyUser = {
    display: "inline",
    cursor: "pointer",
    color: "white"
};

const welcomeStyle = {
    display: "flex",
    justifySelf: 'center',
    alignSelf: 'center',
    fontSize: '16px',
    gridGap: '8px',
    lineHeight: '24px',
    margin: 'auto 0',
    color: "white",
};

const menuItemStyle = {
    color: "white",
    textDecoration: "none",
    margin: '0px 15px'
};

const reportMenuStyle = {
    color: "white",
    textDecoration: "none",
    gridColumnStart: '1',
    gridColumnEnd: '-1',
    gridRow: '2',
    justifySelf: 'center'
};

const exitIcon = {
    width: "24px",
    height: "24px",
    color: "white"
};

const textStyle = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    cursor: "pointer",
    color: "white",
};

class TopMenu extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            redirectToLogin: false,
            domain: Auth.getCurrentConfigurations().Domain,
            domains: null
        }
    }

    componentWillMount = async () => {
        if (Auth.sessionData.isAdmin) {
            let domains = await API.getDomains();
            if (domains)
                // Ascending sort by name
                domains.sort((a, b) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return 0;
                });

            this.setState({ domains: domains });
        }
    };

    async changeDomain() {
        this.setState({ loading: true });
        let result = await API.changeDomain(this.state.domain);
        this.props.onSelectDomain(result.configurations, result.groupsNames, this.state.domain);
        this.setState({ loading: false });
        //When the domain does not have a default config group it returns the first config group it encounters
        if(!result.configurations.IsDefault){
            alert(Strings.topMenu.NO_DEFAULT_ALERT[language])
        }   
    }

    changeLanguage = (field, event, index, value) => {
        if(window.confirm(Strings.FOOTER.CHANGE_LANGUAGE[language])) {
            var choosenLanguage = event.target.value
            var preferences = JSON.parse(localStorage.getItem("USER_PREFERENCE"))
            if (preferences) {
            console.log("não é indefinido", preferences, event.target.value)
            if (choosenLanguage !== preferences.userLanguage) {
                preferences.userLanguage = choosenLanguage;
                localStorage.setItem("USER_PREFERENCE", JSON.stringify(preferences))
                this.setState({ changed: true })
            }
            } else {
                var newPreferences = Object();
                newPreferences.userLanguage = event.target.value;
                newPreferences.userRegion = event.target.value;
                localStorage.setItem("USER_PREFERENCE", JSON.stringify(newPreferences))
            }
            window.location.reload()
        }
    }

    handleSelectChange = (name, event, key, newValue) => {
        if (key == null || !this.state.domains.includes(key)) {
            key = this.state.domain;
        }
        if (key == this.state.domain) {
            return;
        }
        event.preventDefault();
        const newState = this.state;
        newState[name] = key;

        this.setState(newState, this.changeDomain);

        // setTimeout(function () { window.location.reload(); }, 1500);
    };

    handleClick = () => {
        Auth.logout();
        this.setState({ redirectToLogin: true });
    };

    openMenu = () => {
        if (!$('.side-nav').hasClass("menuActive")) {
            $('.side-nav').addClass("menuActive")
            $('.outside-click').addClass("block")
        } else {
            $('.side-nav').removeClass("menuActive")
            $('.outside-click').removeClass("block")
        }
    }

    renderDomainSelect(mobile) {
        if (!Auth.sessionData.isAdmin) {
            return null;
        }

        if (!this.state.domains) {
            return (
                <CircularProgress style={{ float: "right", marginTop: 10 }} thickness={3.5} size={35} color="primary" />
            )
        }

        if (mobile) {
            return (
                <Autocomplete
                    id="combo-box-demo"
                    options={this.state.domains}
                    getOptionLabel={(option) => option}
                    style={{ width: 300 }}
                    defaultValue={this.state.domain}
                    onChange={this.handleSelectChange.bind(null, "domain")}
                    renderInput={(params) => <TextField id="combo-input" {...params} label="Domain" variant="outlined" />}
                />
            );
        } else {
            return (
                <Autocomplete
                    id="combo-box-demo"
                    options={this.state.domains}
                    getOptionLabel={(option) => option}
                    style={{ width: 300, alignSelf: 'center' }}
                    defaultValue={this.state.domain}
                    onChange={this.handleSelectChange.bind(null, "domain")}
                    renderInput={(params) => <TextField id="combo-input" {...params} label="Domain" variant="outlined" />}
                />
            );
        }
    }

    renderAdminMenu() {
        if (Auth.sessionData.isAdmin) {
            return (
                <div style={{ display: 'inline' }}>
                    <Link to={Strings.routes.ADMIN} style={menuItemStyle}>
                        {Strings.topMenu.ADMIN[language]}
                    </Link>

                    <Link to={Strings.routes.POC} style={menuItemStyle}>
                        {Strings.topMenu.POC[language]}
                    </Link>
                </div>
            )

        } else if (Auth.sessionData.activeGroupConfiguration.Domain === "WEBMOTORS") {
            return (
                <div style={{ display: 'inline' }}>
                    <Link to={Strings.routes.WORKSPACE} style={menuItemStyle}>
                        {Strings.topMenu.WORKSPACE}
                    </Link>
                </div>
            )
        } else if (Auth.sessionData.activeGroupConfiguration.Domain === "NEXTEL") {
            return (
                <div style={{ display: 'inline' }}>
                    <Link to={Strings.routes.SEARCHFACE} style={menuItemStyle}>
                        {Strings.topMenu.SEARCHFACE}
                    </Link>
                </div>
            )
        }

        return null;
    }

    render() {
        if (this.state.redirectToLogin) {
            return (
                <Redirect to='/login' />
            )
        }

        if (!Auth.sessionData.isReportUser && (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser)) {
            return (
                <div style={{height: '120px', width: '90%', display: 'flex', justifyContent: 'space-around', fontFamily: "'Be Vietnam', sans-serif"}}>
                    <div style={groupStyle}>
                        <div style={welcomeStyle}>
                            <img src='assets/user.png'></img>{Auth.getSessionData().login.split('@')[0]}
                        </div>
                        <div style={StyleViewOnlyUser} onClick={this.handleClick}>
                            <img src="assets/logout.png" alt="exit" style={exitIcon} />
                        </div>
                    </div>

                    <LoadingDialog open={this.state.loading} />
                </div>
            );
        }

        if (Auth.getSessionData().login == "admin" && Auth.getCurrentConfigurations().Domain == "ADMIN") {
            return (
                <div className="container" style={{ width: "90%", height: '120px', display: 'grid', gridTemplateColumns: '90% 10%', gridTemplateRows: '70% 30%', alignItems: 'center', fontFamily: "'Be Vietnam', sans-serif"}}>
                    <div className="hello-content" style={{height: '60%', display: 'flex', gridGap: '20px', gridRow: '1', justifySelf: 'flex-end'}}>
                        <div style={{display:'flex', alignItems: 'center'}}>
                            {this.renderDomainSelect(false)}
                        </div>
                        <div style={{display: "flex", gridGap: '10px'}}>
                            <div style={welcomeStyle}>
                                <img src='assets/user.png' style={{height: '24px', width: '24px'}}></img>{Auth.getSessionData().login.split('@')[0]}
                            </div>
                            <div style={textStyle} onClick={this.handleClick}>
                                <img src="assets/logout.png" alt="exit" style={exitIcon} />
                            </div>
                        </div>
                    </div>
                    <div id="select-language" style={{gridRowStart: '1', gridRowEnd: '-1', gridColumn: '2', justifySelf: 'flex-end', alignSelf: 'center'}}>
                        <SelectField style={{ minWidth: '42px', color: 'white' }}
                            onChange={this.changeLanguage.bind(null, 'key')}
                            value={language}
                        >
                        {/* <MenuItem  style={styleMenuItem} value={"EN"}> <img src="assets/english.png" style={flag} width="25px" height="20px" /> EN </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"PT"}> <img src="assets/portuguese.png" style={flag} width="25px" height="20px" /> PT </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"ES"}> <img src="assets/spanish.png" style={flag} width="25px" height="20px" /> ES </MenuItem> */}
                        <MenuItem  style={styleMenuItem} value={"EN"}> EN </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"PT"}> PT </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"ES"}> ES </MenuItem>
                        </SelectField>
                    </div>
                    <div className="sandwich-menu" style={{padding: 0, margin: 0, gridRowStart: '1', gridRowEnd: '-1', alignSelf:'center', justifySelf:'flex-end', marginRight: '30px'}} onClick={this.openMenu}>
                        <svg viewBox="0 0 24 24" width="36" height="36" stroke="#ffffff" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </div>
                    <div className="menu-content" style={{gridRow: '2', justifySelf: 'flex-end'}}>
                        <div style={groupStyle}>
                            <Link to={Strings.routes.CONFIGURATIONS} style={menuItemStyle}>
                                {Strings.topMenu.CONFIGURATIONS[language]}
                            </Link>

                            <Link to={Strings.routes.REPORTS} style={menuItemStyle}>
                                {Strings.topMenu.REPORTS[language]}
                            </Link>

                            {this.renderAdminMenu()}

                        </div>
                    </div>
                    {/* <!-- Mobile Top Menu --> */}
                    <LoadingDialog open={this.state.loading} />
                    <div className="outside-click" onClick={this.openMenu}></div>
                    <nav className="side-nav">
                        <div className="hello-content-mobile">
                            <img src='assets/user.png' style={{height: '24px', width: '24px'}}></img>{Auth.getSessionData().login.split('@')[0]}
                        </div>
                        <div>
                            {this.renderDomainSelect(true)}
                        </div>
                        <ul>
                            <li className="menu-item" style={{ borderTop: '1px solid white' }}>
                                <Link to={Strings.routes.CONFIGURATIONS} style={menuItemStyle}>
                                    {Strings.topMenu.CONFIGURATIONS[language]}
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to={Strings.routes.REPORTS} style={menuItemStyle}>
                                    {Strings.topMenu.REPORTS[language]}
                                </Link>
                            </li>
                            <li className="menu-item">
                                {this.renderAdminMenu()}
                            </li>
                            <li className="menu-item">
                                <Link to={Strings.routes.POC} style={menuItemStyle}>
                                    {Strings.topMenu.POC[language]}
                                </Link>
                            </li>
                            <li className="menu-item" id="no-href" onClick={this.handleClick} style={menuItemStyle}>
                                {Strings.logout.LOGOUT_TITLE}
                            </li>
                        </ul>
                    </nav>
                </div>
            );
        }

        if (Auth.getSessionData().isReportUser) {
            return (
                <div style={{height: '120px', width: '90%', display: 'flex', justifyContent: 'flex-end', fontFamily: "'Be Vietnam', sans-serif"}}>
                    <div style={reportStyle}>
                        <Link to={Strings.routes.REPORTS} style={reportMenuStyle}>
                            {Strings.topMenu.REPORTS[language]}
                        </Link>
                        <div style={{display: 'flex', gridGap: '10px'}}>
                            <div style={welcomeStyle}>
                                <img src='assets/user.png' style={{height: '24px', width: '24px'}}></img>{Auth.getSessionData().login.split('@')[0]}
                            </div>
                            <div style={textStyle} onClick={this.handleClick}>
                                <img src='assets/logout.png' style={{height: '24px', width: '24px'}}></img>
                            </div>
                        </div>

                        <div id="select-language">
                            <SelectField style={{ minWidth: '42px', color: 'white' }}
                                onChange={this.changeLanguage.bind(null, 'key')}
                                value={language}
                            >
                            {/* <MenuItem  style={styleMenuItem} value={"EN"}> <img src="assets/english.png" style={flag} width="25px" height="20px" /> EN </MenuItem>
                            <MenuItem  style={styleMenuItem} value={"PT"}> <img src="assets/portuguese.png" style={flag} width="25px" height="20px" /> PT </MenuItem>
                            <MenuItem  style={styleMenuItem} value={"ES"}> <img src="assets/spanish.png" style={flag} width="25px" height="20px" /> ES </MenuItem> */}
                            <MenuItem  style={styleMenuItem} value={"EN"}> EN </MenuItem>
                            <MenuItem  style={styleMenuItem} value={"PT"}> PT </MenuItem>
                            <MenuItem  style={styleMenuItem} value={"ES"}> ES </MenuItem>
                            </SelectField>
                        </div>
                    </div>

                    <LoadingDialog open={this.state.loading} />
                </div>
            );
        }
        return (
            <div className="container" style={{width: "90%", height: '120px', display: 'grid', gridTemplateColumns: '90% 10%', gridTemplateRows: '70% 30%', alignItems: 'center', fontFamily: "'Be Vietnam', sans-serif"}}>
                <div className="hello-content" style={{height: '60%', display: 'flex', gridGap: '20px', gridRow: '1', justifySelf: 'flex-end'}}>
                    <div style={{display:'flex', alignItems: 'center'}}>
                        {this.renderDomainSelect(false)}
                    </div>
                    <div style={{display: "flex", gridGap: '10px'}}>
                        <div style={welcomeStyle}>
                            <img src='assets/user.png' style={{height: '24px', width: '24px'}}></img>{Auth.getSessionData().login.split('@')[0]}
                        </div>
                        <div style={textStyle} onClick={this.handleClick}>
                            <img src="assets/logout.png" alt="exit" style={exitIcon} />
                        </div>
                    </div>
                </div>
                <div className="sandwich-menu" style={{padding: 0, margin: 0, gridRowStart: '1', gridRowEnd: '-1', alignSelf:'center', justifySelf:'flex-end', marginRight: '30px'}} onClick={this.openMenu}>
                    <svg viewBox="0 0 24 24" width="36" height="36" stroke="#ffffff" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                </div>
                <div className="menu-content" style={{gridRow: '2', justifySelf: 'flex-end'}}>
                    <div style={groupStyle}>
                        <Link to={Strings.routes.CONFIGURATIONS} style={menuItemStyle}>
                            {Strings.topMenu.CONFIGURATIONS[language]}
                        </Link>

                        <Link to={Strings.routes.REPORTS} style={menuItemStyle}>
                            {Strings.topMenu.REPORTS[language]}
                        </Link>
                        {this.renderAdminMenu()}
                    </div>
                </div>
                <div id="select-language" style={{gridRowStart: '1', gridRowEnd: '-1', gridColumn: '2', justifySelf: 'flex-end', alignSelf: 'center'}}>
                        <SelectField style={{ width: '50px', color: 'white', alignSelf: 'center'}}
                            onChange={this.changeLanguage.bind(null, 'key')}
                            value={language}
                        >
                        {/* <MenuItem  style={styleMenuItem} value={"EN"}> <img src="assets/english.png" style={flag} width="25px" height="20px" /> EN </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"PT"}> <img src="assets/portuguese.png" style={flag} width="25px" height="20px" /> PT </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"ES"}> <img src="assets/spanish.png" style={flag} width="25px" height="20px" /> ES </MenuItem> */}
                        <MenuItem  style={styleMenuItem} value={"EN"}> EN </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"PT"}> PT </MenuItem>
                        <MenuItem  style={styleMenuItem} value={"ES"}> ES </MenuItem>
                        </SelectField>
                </div>
                {/* <!-- Mobile Top Menu --> */}
                <LoadingDialog open={this.state.loading} />
                <div className="outside-click" onClick={this.openMenu}></div>
                <nav className="side-nav">
                    <div className="hello-content-mobile" style={{display: 'flex', gridGap: '5px', alignItems: 'center'}}>
                        <img src='assets/user.png' style={{height: '24px', width: '24px'}}></img>{Auth.getSessionData().login.split('@')[0]}
                    </div>
                    <div>
                        {this.renderDomainSelect(true)}
                    </div>
                    <ul>
                        <li className="menu-item" style={{ borderTop: '1px solid white' }}>
                            <Link to={Strings.routes.CONFIGURATIONS} style={menuItemStyle}>
                                {Strings.topMenu.CONFIGURATIONS[language]}
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to={Strings.routes.REPORTS} style={menuItemStyle}>
                                {Strings.topMenu.REPORTS[language]}
                            </Link>
                        </li>
                        <li className="menu-item" style={{ borderTop: '1px solid white' }}>
                            <Link to={Strings.routes.ADMIN} style={menuItemStyle}>
                                {Strings.topMenu.ADMIN[language]}
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to={Strings.routes.POC} style={menuItemStyle}>
                                {Strings.topMenu.POC[language]}
                            </Link>
                        </li>
                        <li className="menu-item" id="no-href" onClick={this.handleClick} style={menuItemStyle}>
                            {Strings.logout.LOGOUT_TITLE}
                        </li>
                        <li>
                            <div id="select-language">
                                <SelectField style={{ minWidth: '42px', color: 'white' }}
                                    onChange={this.changeLanguage.bind(null, 'key')}
                                    value={language}
                                >
                                {/* <MenuItem  style={styleMenuItem} value={"EN"}> <img src="assets/english.png" style={flag} width="25px" height="20px" /> EN </MenuItem>
                                <MenuItem  style={styleMenuItem} value={"PT"}> <img src="assets/portuguese.png" style={flag} width="25px" height="20px" /> PT </MenuItem>
                                <MenuItem  style={styleMenuItem} value={"ES"}> <img src="assets/spanish.png" style={flag} width="25px" height="20px" /> ES </MenuItem> */}
                                <MenuItem  style={styleMenuItem} value={"EN"}> EN </MenuItem>
                                <MenuItem  style={styleMenuItem} value={"PT"}> PT </MenuItem>
                                <MenuItem  style={styleMenuItem} value={"ES"}> ES </MenuItem>
                                </SelectField>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectDomain: (configurations, groupsNames, domain) => {
            dispatch(actions.selectConfigurations(configurations));
            dispatch(actions.storeGroupsNames(groupsNames));
            dispatch(actions.storeSelectedDomain(domain));
        },
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        groupsNames: state ? state.groupsNames : [],
        modified: state ? state.modified : false,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

