// Packages
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Toggle from '../mui/Toggle';
import API from '../../API';
import Auth from '../../Authentication';
import ButtonSecondary from '../mui/ButtonSecondary';
import Alert from 'react-bootstrap/lib/Alert';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import validate from 'validate.js';

import SectionTitle from '../../components/SectionTitle';
import Strings from '../../constants/strings';
import Upload from '../../components/FileUpload2';
import UserLanguage from '../configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const flexRowStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
};

const inputsInterface = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const alertStyle = {
    marginTop: "20px"
};

const docInfoTitle = {
    fontSize: "15px",
    color: "rgb(0, 145, 137)",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px"
}



class FaceMatchConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            configurations: this.props.configurations,
            isValidaImageSize: true,
            limitImageSizeReached: false,
            generatingLoading: false,
            getStatusLoading: false,
            faceRecognition: true,
            imageManipulation: false,
            usedImageManipulation: false,
            documentImage: null,
            documentImage1: null,
            documentImage2: null,
            faceImage: null,
            faceMatch: null,
            unifiedBase: null
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    onKeyChange = (field, event, index, value) => {
        let newState = this.state;
        newState[field] = value;
        this.setState(newState);
    };

    onValueChange = (field, event, value) => {
        let newState = this.state;
        newState[field] = value;
        this.setState(newState);
    };

    onGenerate = async () => {
        this.setState({ generatingLoading: true, event: null, answersError: null });

        let result;
        let documentImage = this.state.documentImage;
        let documentImage1 = this.state.documentImage1;
        let documentImage2 = this.state.documentImage2;
        let params = [];

        if (this.state.value) {
            params.push(`${this.state.key}=${this.state.value}`);
        }

        const base64Prefix = "base64,";

        if (this.state.documentImage1 && this.state.documentImage2 && this.state.faceMatch && this.state.unifiedBase){
            // Process both API if client wants.
            documentImage = null;
            result = [];
            let unifiedBaseParams = [];

            documentImage1 = documentImage1.substring(documentImage1.indexOf(base64Prefix) + base64Prefix.length);
            params.push(`MATCH_IMG=${documentImage1}`);
            unifiedBaseParams.push(`image:${documentImage1}`);

            documentImage2 = documentImage2.substring(documentImage2.indexOf(base64Prefix) + base64Prefix.length);
            params.push(`BASE_FACE_IMG=${documentImage2}`);

            result.push(await API.faceMatch1x1(params, this.props.selectedDomain));
            result.push(await API.UnifiedBase(unifiedBaseParams, this.props.selectedDomain));

            if (result[0].DocInfo) {
                if (result[0].DocInfo.CPF) {
                    this.setState({ value: result[0].DocInfo.CPF });
                }
            }
        } else if (this.state.documentImage1 && this.state.faceMatch)  {
            documentImage = null;
            // FaceMatch API
            // Put both imagens to compare
            documentImage1 = documentImage1.substring(documentImage1.indexOf(base64Prefix) + base64Prefix.length);
            params.push(`MATCH_IMG=${documentImage1}`);

            documentImage2 = documentImage2.substring(documentImage2.indexOf(base64Prefix) + base64Prefix.length);
            params.push(`BASE_FACE_IMG=${documentImage2}`);
            
            result = await API.faceMatch1x1(params, this.props.selectedDomain);

            if (result.DocInfo) {
                if (result.DocInfo.CPF) {
                    this.setState({ value: result.DocInfo.CPF });
                }
            }
            if(result != undefined){
                if(result.ResultCode != "-800"){
                    this.setState({ estimatedInfo: result.EstimatedInfo.Similarity})
                }
                this.setState({ 
                    faceMatchMessage: result.ResultMessage,
                    faceMatchCode: result.ResultCode,
                    bureauWarrantPlace: null,
                    similarity: null,
                    unifiedBaseMessage: null,
                    unifiedBaseCode: null,
                    bigIdEvent: null,
                    answers: new Array(result.Questions.length)})
            }
        } else {
            // Unified Base API
            if (this.state.unifiedBase)
            {
                documentImage = documentImage.substring(documentImage.indexOf(base64Prefix) + base64Prefix.length);
                params.push(`image:${documentImage}`);
            }

            result = await API.UnifiedBase(params, this.props.selectedDomain);

            this.setState({
                faceMatchMessage: null,
                faceMatchCode: null,
                estimatedInfo: null,
                unifiedBaseResult: result
            });
        }
        if(result != undefined){
            if (!result.length)
                result.length = 1;

            if (result.length >  1) {
                /* 
                * Everything that involves FaceMatch is present in [0] element position
                * and everything that involves unifiedBase is present in [1] element position
                * this rule is just in case of both API been executed.
                */
                if(result[0].ResultCode != "-800"){
                    this.setState({ estimatedInfo: result[0].EstimatedInfo.Similarity})
                }

                this.setState({
                    event: result,
                    faceMatch: this.state.faceMatch,
                    unifiedBase: this.state.unifiedBase,
                    generatingLoading: false,
                    success: result.success,
                    unifiedBaseResult: result[1],
                    faceMatchMessage: result[0].ResultMessage,
                    faceMatchCode: result[0].ResultCode,
                    bigIdEvent: null,
                    answers: new Array(result[0].Questions.length)
                });
            } else if (result.length === 1) {
                this.setState ({
                    event: result,
                    faceMatch: this.state.faceMatch,
                    unifiedBase: this.state.unifiedBase,
                    generatingLoading: false,
                    success : result.success
                });
            }        
        } else {
            this.setState ({
                event: false,
                faceMatch: null,
                generatingLoading: false,                
                unifiedBaseMessage : Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.ERROR_MSG[language]
            });
        }
    };

    // -- RENDER -- //
    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} thickness={3.5} size={35} />
        )
    }

    renderUnifiedBaseAlert() {
        if (this.state.unifiedBaseMessage ===  Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.ERROR_MSG[language]) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <h4>{Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.ERROR_MSG[language]}</h4>,
                </Alert>
            )
        }
        
        if (!(this.state.unifiedBaseResult) || !('resultCode' in this.state.unifiedBaseResult)) {
            return
        }

        if (this.state.unifiedBaseResult.resultCode === 93) {
            let resultList = []
            resultList.push(
                <h4>{Strings.facematch.FACEMATCH_UNIFIEDBASE_API_OPTION[language]}</h4>,
                <h5> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.CODE[language]}: {this.state.unifiedBaseResult.resultCode}</h5>,
                <h5>TicketId - {this.state.unifiedBaseResult.ticketId}</h5>
            )
            this.state.unifiedBaseResult.all_Faces.forEach(face => {
                resultList.push(
                )
                face.blackListInfo.forEach( blackListInfo => 
                    resultList.push(
                        <Alert bsStyle="danger" style={alertStyle}>
                            <h4>{Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.WANTED_BASE[language]}</h4>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.LOCAL[language]}: {blackListInfo.source}</p>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.SIMILARITY[language]}: {face.similarity} </p>
                        </Alert>
                    )
                )
                face.celebrityInfo.forEach( celebrityInfo => 
                    resultList.push(
                        <Alert bsStyle="success" style={alertStyle}>
                            <h4>{Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.CELEBRITY_BASE[language]}</h4>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.NAME[language]}: {celebrityInfo.name}</p>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.SIMILARITY[language]}: {face.similarity} </p>
                        </Alert>

                    )
                )                    
                face.politicianInfo.forEach( politicianInfo => 
                    resultList.push(
                        <Alert bsStyle="success" style={alertStyle}>
                            <h4>{Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.POLITICIANS_BASE[language]}</h4>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.NAME[language]}: {politicianInfo.name}</p>
                            <p> CPF: {politicianInfo.doc_number}</p>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.SIMILARITY[language]}: {face.similarity} </p>
                        </Alert>
                    )
                )
            })

            return (
                <Alert bsStyle="success" style={alertStyle}>
                    {resultList}
                </Alert>
            )
        } else {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <h4>{Strings.facematch.FACEMATCH_UNIFIEDBASE_API_OPTION[language]}</h4>
                    <h4> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.CODE[language]}: {this.state.unifiedBaseResult.resultCode}</h4>
                    <h5>TicketId - {this.state.unifiedBaseResult.ticketId}</h5>
                    <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.MSG[language]}: {this.state.unifiedBaseResult.resultMessage}</p>
                </Alert>
            )
        }
    }

    renderFaceMatchAlert() {
        if(this.state.event && this.state.faceMatchMessage) {
            if (this.state.faceMatchCode !== 81 && this.state.faceMatchCode !== 80) {
                return (
                    <Alert bsStyle="danger" style={alertStyle}>
                        <h4>{Strings.facematch.FACEMATCH_1x1_PAGE_TITLE[language]}</h4>
                        <h4> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.CODE[language]}: {this.state.faceMatchCode}</h4>
                        <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.MSG[language]}: {this.state.faceMatchMessage}</p>
                        <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.SIMILARITY[language]}: {this.state.estimatedInfo}</p>
                    </Alert>
                )
            } else {
                //sucessful alert when facematch works
                if (this.state.faceMatchCode === 80 || this.state.faceMatchCode === 81) {
                    return (
                        <Alert bsStyle="success" style={alertStyle}>
                            <h4>{Strings.facematch.FACEMATCH_1x1_PAGE_TITLE[language]}</h4>
                            <h4> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.CODE[language]}: {this.state.faceMatchCode}</h4>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.MSG[language]}: {this.state.faceMatchMessage}</p>
                            <p> {Strings.facematch.FACEMATCH_UNIFIEDBASE_API_ALERT.SIMILARITY[language]}: {this.state.estimatedInfo}</p>
                        </Alert>
                    )
                }
            }
        }
    }

    renderForensincAlert() {
        //Forensic Alerts
        if (this.state.event) {
            let forensicObj = Object(this.state.event.ForensicValidations)
            let forensicKeys = Object.keys(forensicObj)
            if (forensicKeys.length > 0) {
                return (
                    <div>
                        {
                            forensicKeys.map(foren => {
                                return (
                                    <Alert bsStyle="danger" style={alertStyle}>
                                        <h4> {Strings.facematch.FACEMATCH_WANTED_API_ALERT.CODE[language]}: {foren}</h4>
                                        <p> {Strings.facematch.FACEMATCH_WANTED_API_ALERT.MSG[language]}: {forensicObj[foren]}</p>
                                    </Alert>
                                )
                            })
                        }
                    </div>
                )
            }
        }
    }

    renderImageSizeWarning() {
        if (!this.state.isValidaImageSize && !this.state.limitImageSizeReached) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <p> {Strings.generalConfigurations.PHOTO_SIZE_WARNING[language]}</p>
                </Alert>
            )

        } else if (!this.state.isValidaImageSize && this.state.limitImageSizeReached) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <p> {Strings.generalConfigurations.MAX_PHOTO_SIZE_WARNING[language]}</p>
                </Alert>
            )
        }
    }

    onFileLoad = (stateField, e, file) => {
        const bigAcceptedImageBytes = 1000000//4000000
        const limitImageBytes = 4000000

        if (file.size > bigAcceptedImageBytes && file.size < limitImageBytes) {
            this.setState({ isValidaImageSize: false })
            return
        } else if (file.size > limitImageBytes) {
            this.setState({ isValidaImageSize: false, limitImageSizeReached: true })
            return
        }

        let newState = this.state;
        newState[stateField] = e.target.result;
        this.setState(newState);
    };

    renderFaceImageInput(){
        if (this.state.faceMatch) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
                    <div style={{ width: '50%' }}>
                        <Upload
                            index="documentImage1"
                            label={Strings.facematch.FACEMATCH_PHOTO_INPUT.FIRST[language]}
                            tooltip={Strings.facematch.FACEMATCH_PHOTO_INPUT.FIRST[language]}
                            uploaded={this.state.documentImage1 !== null}
                            onFileLoad={this.onFileLoad.bind(this, "documentImage1")}
                        />
                        {this.state.documentImage1 && (
                            <div>
                                <img src={this.state.documentImage1} alt="Preview" style={{ maxWidth: '60%' }} />
                            </div>
                        )}
                    </div>

                    <div style={{ width: '50%' }}>
                        <Upload
                            index="documentImage2"
                            label={Strings.facematch.FACEMATCH_PHOTO_INPUT.SECOND[language]}
                            tooltip={Strings.facematch.FACEMATCH_PHOTO_INPUT.SECOND[language]}
                            uploaded={this.state.documentImage2 !== null}
                            onFileLoad={this.onFileLoad.bind(this, "documentImage2")}
                        />
                        {this.state.documentImage2 && (
                            <div>
                                <img src={this.state.documentImage2} alt="Preview" style={{ maxWidth: '60%' }} />
                            </div>
                        )}
                    </div>
                </div>
            )
        }else if (this.state.unifiedBase) {
            return (
                <div>
                    <Upload
                        index="documentImage"
                        label={Strings.facematch.FACEMATCH_UNIFIEDBASE_PHOTO_INPUT[language]}
                        tooltip={Strings.facematch.FACEMATCH_UNIFIEDBASE_PHOTO_INPUT[language]}
                        uploaded={this.state.documentImage !== null}
                        onFileLoad={this.onFileLoad.bind(this, "documentImage")}
                    />
                    {this.state.documentImage && (
                        <div>
                            <img src={this.state.documentImage} alt="Preview" style={{ maxWidth: '40%' }} />
                        </div>
                    )}
                </div>
            )
        }
    }

    renderOcrRequestInterface() {
        if (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) {
            return null;
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    <div style={{ flex: 1, width: "50%" }}>
                        <Toggle
                            label={Strings.facematch.FACEMATCH_1x1_PAGE_TITLE[language]}
                            toggled={this.state.faceMatch}
                            onToggle={(event, toggled) => {
                                this.setState({ faceMatch: toggled, documentImage1: null, documentImage2: null, documentImage: null, event: null, faceMatchMessage: null, unifiedBaseMessage: null, unifiedBaseResult: null });
                            }}
                        />
                        <Toggle
                            label={Strings.facematch.FACEMATCH_UNIFIEDBASE_API_OPTION[language]}
                            toggled={this.state.unifiedBase}
                            onToggle={(event, toggled) => {
                                this.setState({ unifiedBase: toggled, documentImage1: null, documentImage2: null, documentImage: null, event: null, faceMatchMessage: null, unifiedBaseMessage: null, unifiedBaseResult: null});
                            }}
                        />
                    </div>
                    <div style={{ flex: 1, width: "50%" }}>
                        {this.renderGenerateButton()}
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    {this.renderFaceImageInput()}
                </div>
            </div>
        );
        
        
    }

    renderGenerateButton() {
        let buttonStyle = {margin: "10px"};
        if (this.state.faceMatch === true && this.state.documentImage1 !== null && this.state.documentImage2 !== null) {
            return (
                <div>
                    <ButtonSecondary
                        label="Executar Comparação"
                        style={buttonStyle}
                        onClick={this.onGenerate}
                    />
                </div>
            );
        }
        if (this.state.unifiedBase === true && this.state.documentImage !== null) {
            return (
                <div>
                    <ButtonSecondary
                        label="Executar Comparação"
                        style={buttonStyle}
                        onClick={this.onGenerate}
                    />
                </div>
            );
        }
    }

    renderDocInfo() {
        if (this.state.event) {
            if (this.state.event.DocInfo) {

                let docInfo = this.state.event.DocInfo;
                let forensicInfo = this.state.event.OfficialInfo;

                if (Object.keys(docInfo).length < 1) {
                    return null;
                }

                return (
                    <Card initiallyExpanded={true}>
                        <CardHeader
                            title={(
                                <div>
                                    <h4>Informações Obtidas do Documento</h4>
                                    <h5>TicketId - {this.state.event.TicketId}</h5>
                                </div>

                            )}
                            actAsExpander={true}
                            showExpandableButton={true}
                        />
                        <CardText expandable={true}>
                            {this.renderDocumentInformations(docInfo)}
                            {this.renderForensicInformations(forensicInfo)}
                        </CardText>
                    </Card>
                )
            }
        }
    }

    renderDocumentInformations(docInfo) {
        let fields = Object.keys(docInfo);

        const allowedParams = [
            "Document",
            "Document_Number",
            "Message",
            "ResultCode",
            "Similarity",
            "TicketId",
            "success"
        ];
        

        //Sort by allowedParams order: Ex: DOCTYPE will be the first shown parameter
        //New params will be showed in last
        const sortedFields = fields.sort(function (a, b) {
            var indexA = allowedParams.indexOf(a)
            var indexB = allowedParams.indexOf(b);
            if (indexA === -1) {
                indexA = 100;
            }
            if (indexB === -1) {
                indexB = 100;
            }
            return indexA - indexB;
        });

        return (
            <div>
                <h3 style={docInfoTitle}>Informações extraídas da imagem</h3>
                {sortedFields.map(field => {
                    let value = docInfo[field];

                    return (
                        <div style={flexRowStyle}>
                            <label htmlFor="">{field}: </label>
                            <div style={{ marginLeft: '5px' }}> {value}</div>
                        </div>
                    )
                })}
                <hr></hr>
            </div>
        )
    }

    renderForensicInformations(forensicInfo) {

        //change number to % on view
        forensicInfo.NAMEMATCHPERCENTAGE = forensicInfo.NAMEMATCHPERCENTAGE * 100 + '%';
        forensicInfo.BIRTHDATEMATCH = forensicInfo.BIRTHDATEMATCH * 100 + '%';
        forensicInfo.MOTHERNAMEMATCHPERCENTAGE = forensicInfo.MOTHERNAMEMATCHPERCENTAGE * 100 + '%';

        return (
            <div>
                <h3 style={docInfoTitle}>Validação com dados públicos</h3>
                {Object.keys(forensicInfo).map(field => {
                    let value = forensicInfo[field];

                    return (
                        <div style={flexRowStyle}>
                            <label htmlFor="">{field}: </label>
                            <div style={{marginLeft: '5px'}}> {value}</div>
                        </div>
                    )
                })}
            </div>
        )
    }


    render() {
        return (
            <div>
                <SectionTitle text={Strings.generalConfigurations.FACEMATCH_PAGE_TITLE[language]} />

                <div style={inputsInterface}>

                    {this.renderOcrRequestInterface()}

                    {this.renderLoading(this.state.generatingLoading)}
                </div>

                <div>
                    {this.renderDocInfo()}
                </div>

                <div>
                    {this.renderFaceMatchAlert()}
                    {this.renderUnifiedBaseAlert()}
                    {this.renderForensincAlert()}
                </div>

                <div>
                    {this.renderImageSizeWarning()}
                </div>
                
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.selectedDomain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FaceMatchConfig);