import React from 'react';
import { TextField } from '@material-ui/core';

const style = {
  backgroundColor: "transparent",
  fontFamily: "'Be Vietnam Pro', sans-serif",
  fontSize: "16px",
  borderRadius: "10px",
  color: 'secondary !important',
  border: "1px solid #474a51",
  marginBottom: "10px",
  marginTop: "0px"
};

class LoginTextField extends React.Component {
  render(){
    return (
      <TextField
        id={this.props.id}
        margin="normal"
        variant={"filled"}
        style={Object.assign(style, this.props.style || {})}
        type={this.props.type}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        fullWidth={this.props.fullWidth}
      />
    )
  }
}

export default LoginTextField;
