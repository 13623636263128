import React from 'react';

import { Link } from 'react-router-dom'

// Import Constants
import Strings from '../constants/strings';


const logoStyle = {
  width: '50vw',
  height: '100vh',
  borderRadius: '4% 0 0 4%',
  objectFit: 'cover'
}

const headerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  backgroundColor: "#f0f8fa"
}

export default class HeaderLogin extends React.Component {
  render() {
    return (
      <Link to={Strings.routes.HOME} style={{height: '100%', width: '50%'}}>
        <header style={headerStyle}>
          <img src="assets/big-id-01.svg" alt="BigID" style={logoStyle}/>
        </header>
      </Link>

    );
  }
}
