import React from 'react';
import Toggle from './mui/Toggle';

import Strings from '../constants/strings';
import VelocityValidationConfiguration from './VelocityValidationConfiguration';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const defaultToggleStyle = {

}

const defaultWrapperStyle = {

}
class VelocityValidation extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            over: false,
        }
    }

    render() {
        const validation = this.props.validation;

        const toggleStyle = Object.assign({},defaultToggleStyle, this.props.style || {});
        let wrapperStyle = Object.assign({},defaultWrapperStyle, this.props.wrapperStyle || {});

        if(this.state.over){
            wrapperStyle.backgroundColor = "rgba(200,200,200,0.25)";
        }

        const enabledToggle = (<Toggle
            tooltip={Strings.tooltips.validations[validation.Name][language]}
            style={toggleStyle}
            label={Strings.validations[validation.Name].LABEL[language]}
            toggled={validation.Enabled}
            key={validation.Name}
            name={validation.Name}
            onToggle={this.props.handleValidationToggle.bind(null, validation.Name)}
        />);

        const validationConfiguration = (
            <div>
                <VelocityValidationConfiguration
                    enabled={validation.Enabled}
                    validation={validation}
                />
            </div>
        );


        return (
            <div
                onMouseOver={()=>{this.setState({over: true})}}
                onMouseLeave={()=>{this.setState({over: false})}}
                style={wrapperStyle}
                key={validation.Name}>
                {enabledToggle}
                {validation.Enabled ? validationConfiguration : <div></div> }
            </div>
        )

    }
}

export default VelocityValidation;
