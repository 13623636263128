import React from 'react';

import Strings from '../../constants/strings';

// Material UI Imports
import {CircularProgress} from '@material-ui/core';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Table from 'react-bootstrap/lib/Table'

import API from '../../API';

import validate from 'validate.js';

import UserLanguage from '../configurations/UserLanguage.js'
import BigIdButton from '../mui/Button';
import { IconButton } from 'material-ui';
const language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const cardContainerStyle = {
    display: 'block',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxShadow: null
};

const columnStyle = {
    width: '350px'
};

class HistoricalPoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            account: {
                GroupName: "",
                UserName: ""
            },
            listPocs: [],
            canDownload: false
        }
    }

    async handleSearchPocEvents() {
        let result = await API.GetPocEvents();

        this.setState({
            listPocs: result.listPocs,
            generatingLoading: false
        });

        this.handleResponseSearchPocEvents(result);
    }

    async componentWillMount() {
        this.handleSearchPocEvents();
    };

    handleResponseSearchPocEvents(result) {
        this.setState({
            listPocs: result.listPocs,
            finishSearchListPoc: true
        });
    }

    renderErrors() {
        if (this.state.error) {
            return (
                <div>
                    {this.state.error}
                </div>
            )
        }
    }


    // -- HANDLERS -- //
    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newState = this.state;
        newState.account[name] = value;

        this.setState(newState);
    };

    // -- RENDER -- //
    renderLoading(should){
        if(!should){
            return null;
        }

        return (
            <CircularProgress style={{marginLeft: "20px"}} thickness={3.5} size={35}/>
        )
    }

    
    handleRemoveElement = async (id, element) => {
        this.setState({ generatingLoading: true });
        let result;
        let resultReport;

        result = await API.RemovePocEvent(element.Id);

        this.state.listPocs.splice(id, 1)

        this.setState({
            listPocs: this.state.listPocs,
            generatingLoading: false
        })

    }

    DownloadPocReport()
    {
        let urlToReport = this.ReportUrl
        window.open(urlToReport)
    }
    

    onGenerate = async () => {
        this.setState({ generatingLoading: true, event: null, answersError: null });

        let result;
        let pocDocument = this.state.pocDocument;
        let PocType = this.state.actualType
        let fileURL = this.state.actualType
        let Client = this.state.selectedDomain

        if (this.state.pocDocument) {
            
            // Generate POC
            result = await API.GeneratePOC(PocType, fileURL, Client);
            this.setState(
                {
                    event: result,                    
                    generatingLoading: false,
                }        
            );
        };
    }


    render() {
        return (    
        <div style={{ width: '80%' }}>
        <div>
            <div>
                <div>
                    <div style={{ display: 'flex' }}>
                        <h2 id='title'>
                            {Strings.generatePOC.poc_labels.TITLE[language]}
                        </h2>
                    </div>
                    <div>
                        <h4 style={{ color: "black", fontSize: '16px' }}>
                            {Strings.generatePOC.poc_labels.SUBTITLE[language]}
                            <br />
                        </h4>
                    </div>
                </div>
                
                <div>
                    <br />
                    {this.renderLoading(this.state.generatingLoading)}
                        
                    <Table style={{ width: '100%'}}>                        
                        <thead>
                            <tr>
                                <th> {Strings.generatePOC.poc_labels.DOMAIN[language]} </th>
                                <th> {Strings.generatePOC.poc_labels.USER[language]} </th>
                                <th> {Strings.generatePOC.poc_labels.DATE[language]}    </th>
                                <th> {Strings.generatePOC.poc_labels.API[language]}     </th>
                                <th> {Strings.generatePOC.MSG[language]}  </th>
                                <th> {Strings.generatePOC.poc_labels.DELETE[language]}</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.listPocs.map((element, Id) => {                                                
                                    return (
                                        <tr style={{ width: "100%"}} key={element.Id}>
                                            <td>{element.Client}</td>
                                            <td>{element.Operator}</td>
                                            <td>{element.PocDateTime.replace('T', ' ').replace('Z', '')}</td>
                                            <td>{element.PocType}</td>
                                            <td>{element.Message}</td>                                                        
                                            <td>
                                                <ConfirmationDialog
                                                    onConfirm={this.handleRemoveElement.bind(null, Id, element)}
                                                    text={Strings.generatePOC.DELETE_ALERT[language]}
                                                >    
                                                    <IconButton style={{ minWidth: "50px", cursor: "pointer" }}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </ConfirmationDialog>
                                            </td>
                                            <td>                                        
                                                <BigIdButton disabled={element.Message === "Processing POC." && element.Success === true} label={Strings.reports.REPORT[language]} secondary onClick={this.DownloadPocReport.bind(element)}>  </BigIdButton>                                        
                                            </td>
                                                            
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    </div>    

        )
    }
}

export default HistoricalPoc;
