import React from 'react';

import { Link } from 'react-router-dom'

import TopMenu from '../components/TopMenu';

// Import Constants
import Colors from '../constants/colors';
import Strings from '../constants/strings';

const logoStyle = {
    display: "inline-block",
    width: '161.77px',
    heigth: '50.78px'
};

const logoLinkStyle = {
    width: '20%',
};

const headerStyle = {
    position: 'relative',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primaryBlue,
};

const container = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '85%',
    margin: '0 auto',
    boxSizing: 'border-box',
    backgroundColor: Colors.primaryBlue
}

export default class Header extends React.Component {
    render() {
        return (
            <header style={headerStyle}>
                <div style={container}>
                    <Link to={Strings.routes.HOME} style={logoLinkStyle}>
                        <img src="assets/big-id-logo-01.svg" alt="BigID" style={logoStyle}/>
                    </Link>
                    <TopMenu />
                </div>
            </header>
        );
    }
}
