import React from 'react';
import { Button } from '@material-ui/core';
import colors from '../../constants/colors';

var styleButton = {
    backgroundColor: colors.primaryBlue,
    color: colors.White,
    borderRadius: '10px',
    textTransform: "none",
    position: "relative",
    width: "100%",
    fontFamily: "HKGrotesk",
    height: "36px",
    fontSize: "inherit"
};

class BigIdButton extends React.Component {
    render() {
        let tempStyleButton = Object.assign({}, styleButton, this.props.style)

        return (
            <Button 
                variant="contained"
                id={this.props.id}
                style={tempStyleButton}
                datatype={this.props.type}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
                startIcon={this.props.startIcon}
                endIcon={this.props.endIcon}
            >
                {this.props.label}
            </Button>
        )
    }
}

export default BigIdButton;
