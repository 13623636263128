import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../actions';

import Strings from '../constants/strings';
import SectionTitle from '../components/SectionTitle';

import Table from 'react-bootstrap/lib/Table';

import TextField from '../components/mui/TextField';
import SelectField from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import {Button} from '@material-ui/core';

import { IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import ConfirmationDialog from '../components/ConfirmationDialog';

import UserLanguage from './configurations/UserLanguage.js';
import ButtonSecondary from './mui/ButtonSecondary';

let language = UserLanguage.getLanguage();

const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

class WatchListContacts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: "",
            type: "EMAIL",
            validation: this.props.validation
        }
    }

    onValueChange = (event) => {
        this.setState({ value: event.target.value });
    };

    handleAddNewValue = () => {
        if (!this.state.value || !this.state.type) {
            return;
        }
        
        let configurations = this.props.configurations;

        for (let index in configurations.BigIdValidationOptions) {
            let validation = configurations.BigIdValidationOptions[index];
            if (validation.Name === "BIGID_WATCH_LIST") {
                if (this.state.type === 'EMAIL') {
                    if (configurations.BigIdValidationOptions[index].Configurations.EMAIL_LIST)
                        configurations.BigIdValidationOptions[index].Configurations.EMAIL_LIST += ';' + this.state.value;
                    else
                        configurations.BigIdValidationOptions[index].Configurations.EMAIL_LIST = this.state.value;
                }

                if (this.state.type === 'SMS') {
                    if (configurations.BigIdValidationOptions[index].Configurations.TEL_LIST)
                        configurations.BigIdValidationOptions[index].Configurations.TEL_LIST += ';' + this.state.value;
                    else
                        configurations.BigIdValidationOptions[index].Configurations.TEL_LIST = this.state.value;
                }
                this.setState({ validation: validation });
            }
        }
        this.props.onSave(configurations);
    };

    handleRemoveElement = (index, element) => {
        let configurations = this.props.configurations;
        if (element.type.toUpperCase() === 'EMAIL') {
            let list = configurations.BigIdValidationOptions.find((x) => { return x.Name === this.props.validationName }).Configurations.EMAIL_LIST;
            let splitted = list.split(";");
            splitted.splice(splitted.indexOf(element.value), 1);
            configurations.BigIdValidationOptions.find((x) => { return x.Name === this.props.validationName }).Configurations.EMAIL_LIST = splitted.join(";");
        }

        if (element.type.toUpperCase() === 'SMS') {
            let list = configurations.BigIdValidationOptions.find((x) => { return x.Name === this.props.validationName }).Configurations.TEL_LIST;
            let splitted = list.split(";");
            const index = splitted.indexOf(element.value);
            splitted.splice(index, 1);
            configurations.BigIdValidationOptions.find((x) => { return x.Name === this.props.validationName }).Configurations.TEL_LIST = splitted.join(";");
        }
        
        this.setState({ validation: configurations.BigIdValidationOptions.find((x) => { return x.Name === this.props.validationName }) });
        this.props.onSave(configurations);
    };

    onTypeChange = (event) => {
        this.setState({ type: event.target.value });
    };

    render() {
        let contactList = [];
        if (this.props.validation.Configurations.EMAIL_LIST) {
            contactList = contactList.concat(this.props.validation.Configurations.EMAIL_LIST.split(';').map((value) => {
                return { value: value, type: "Email" }
            }));
        }

        if (this.props.validation.Configurations.TEL_LIST) {
            contactList = contactList.concat(this.props.validation.Configurations.TEL_LIST.split(';').map((value) => {
                return { value: value, type: "SMS" }
            }));
        }

        return (
            <div>
                <SectionTitle text={Strings.generalConfigurations.validations.WATCH_LIST_CONTACT_LABEL[language]} tooltip={Strings.tooltips.WATCH_LIST_CONTACTS[language]} />

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: 40, marginBottom: 40 }}>

                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                            floatingLabelText={Strings.generalConfigurations.validations.WATCH_LIST_CONTACT_FLOAT_LABEL[language]}
                            value={this.props.value}
                            onChange={this.onValueChange}
                        />

                        <SelectField floatingLabelText="Tipo" style={{ width: "100px", marginLeft: "20px", marginRight: "20px", marginBottom: '8px' }}
                            onChange={this.onTypeChange} value={this.state.type}>
                            <MenuItem style={styleMenuItem} value={"EMAIL"}> Email </MenuItem>
                            <MenuItem style={styleMenuItem} value={"SMS"} > SMS </MenuItem>
                        </SelectField>

                        <ButtonSecondary
                            label={Strings.generalConfigurations.validations.WATCH_LIST_CONTACT_BUTTON[language]}
                            onClick={this.handleAddNewValue}
                            style={{ margin: 0 }}
                        />
                    </div>

                </div>


                <Table>
                    <thead >
                        <tr>
                            <th>{Strings.generalConfigurations.validations.WATCH_LIST_CONTACT_HEADERS.CONTACTS[language]}</th>
                            <th>{Strings.generalConfigurations.validations.WATCH_LIST_CONTACT_HEADERS.TYPE[language]}</th>
                        </tr>
                    </thead>
                    <tbody >

                        {
                            contactList.map((element, index) => {
                                return (
                                    <tr style={{ width: "100%" }} key={index}>
                                        <td style={{verticalAlign: "middle"}}>{element.value}</td>
                                        <td style={{verticalAlign: "middle"}}>{element.type}</td>
                                        <td>
                                            <ConfirmationDialog onConfirm={this.handleRemoveElement.bind(null, index, element)}
                                                text={Strings.generalConfigurations.validations.WATCH_LIST_CONTACT_DELETE_ALERT[language]}>
                                                <IconButton>
                                                    <DeleteForeverIcon style={{fontSize: "x-large", color: "#000000"}} />
                                                </IconButton>
                                            </ConfirmationDialog>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </Table>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            for (let index in configurations.BigIdValidationOptions) {
                if (configurations.BigIdValidationOptions[index].Name === "BIGID_WATCH_LIST") {
                    configurations.BigIdValidationOptions[index].Configurations.MAIL_SEND = "TRUE";
                    configurations.BigIdValidationOptions[index].Configurations.SMS_SEND = "TRUE";
                }
            }
            dispatch(actions.saveConfigurations(configurations))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        validation: state.configurations.BigIdValidationOptions.find((x) => x.Name === ownProps.validationName),
        configurations: state.configurations,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchListContacts);
