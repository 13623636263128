import React from 'react';
import {filter} from 'lodash';

import { connect } from 'react-redux';
import * as actions from '../actions';
import Dialog from 'material-ui/Dialog';
import Strings from '../constants/strings';
import isValidCpf from '../Utils';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'

import TextField from '../components/mui/TextField';

import AddIcon from 'material-ui/svg-icons/action/note-add';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import ConfirmationDialog from "./ConfirmationDialog";
import SectionTitle from "./SectionTitle";
import UserLanguage from './configurations/UserLanguage.js';
import ButtonSecondary from './mui/ButtonSecondary';

let language = UserLanguage.getLanguage();

const inputContainerStyle = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",

    width: 150,
    height: 35,

    borderStyle: "dashed",
    borderWidth: 3,
    borderColor: "#AAA"
};


const inputGroupStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",

}

class ListEditingDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: "",
        }
    }

    onValueChange = (event) => {
        this.setState({ value: event.target.value });
    };

    onInputChange = (e) => {
        filter(
            e.target.files,
            (file) => file.type.match(this.props.fileTypeRegex) !== null
        )
            .forEach(
                (file) => {
                    if(!file.name.endsWith('txt') && !file.name.endsWith('csv')){
                        alert(Strings.validations.FILE_ALERT[language])
                        return
                    }


                    let reader = new FileReader();
                    let cpfArray = []
                    reader.onload = (e) => {
                        cpfArray = reader.result.split('\n')
                        const cleanCpfArray = cpfArray.map(cpf => cpf.trim())

                        const validCpfs = cleanCpfArray.filter(isValidCpf);

                        this.handleAddValuesList(validCpfs)
                    };
                    reader.readAsText(file);
                }
            );
    };


    handleAddValuesList = (list) => {
        //Receives the file reader result
        //Father component method that sets a whole list
            let configurations = this.props.configurations;
            let listConfiguration = this.props.listConfiguration;
            listConfiguration.ValueList.unshift(...list);

            for (const index in configurations.BigIdValidationOptions) {
                const validation = configurations.BigIdValidationOptions[index];
                if (validation.Name === this.props.validation.Name) {
                    configurations
                        .BigIdValidationOptions[index]
                        .ListsConfiguration[this.props.myKey] = listConfiguration;
                }
            }

            this.setState({ value: "" });

            this.props.onSave(configurations);
            this.forceUpdate();

    }

    handleAddNewValue = () => {
        if (!this.state.value) {
            return;
        }

        let configurations = this.props.configurations;

        let listConfiguration = this.props.listConfiguration;
        listConfiguration.ValueList.unshift(this.state.value);

        for (const index in configurations.BigIdValidationOptions) {
            const validation = configurations.BigIdValidationOptions[index];
            if (validation.Name === this.props.validation.Name) {
                configurations
                    .BigIdValidationOptions[index]
                    .ListsConfiguration[this.props.myKey] = listConfiguration;
            }
        }

        this.setState({ value: "" });

        this.props.onSave(configurations);
        this.forceUpdate();
    };

    handleRemoveElement = (index) => {
        let configurations = this.props.configurations;
        let listConfiguration = this.props.listConfiguration;
        listConfiguration.ValueList.splice(index, 1);

        for (const index in configurations.BigIdValidationOptions) {
            const validation = configurations.BigIdValidationOptions[index];
            if (validation.Name === this.props.validation.Name) {
                configurations
                    .BigIdValidationOptions[index]
                    .ListsConfiguration[this.props.myKey] = listConfiguration;
            }
        }

        this.props.onSave(configurations);
    };

        renderList() {
        return (
            
            <div>            
                <SectionTitle wrapperStyle={{ marginBottom: 0 }} text={Strings.validations.LIST_EDIT_TITLE[language] + Strings.validations[this.props.validation.Name]} tooltip={Strings.tooltips.LIST_VALIDATIONS_BATCH_ADD[language]}/>

                <div style={inputGroupStyle}>
                
                <TextField
                    type="number"
                    floatingLabelText={Strings.validations.LIST_NEW_VALUE[language]}
                    value={this.state.value}
                    onChange={this.onValueChange}
                />

                <AddIcon
                    style={{ cursor: "pointer", marginLeft: 20 }}
                    onClick={this.handleAddNewValue}
                />

                <input
                    style={inputContainerStyle}
                    type="file"
                    ref="file-input"
                    multiple
                    onChange={this.onInputChange}
                />

                </div>

               <Table>
                   <TableHead displaySelectAll={false} enableSelectAll={false} adjustForCheckbox={false} >
                        <TableRow>
                            <TableCell style={{fontSize: "14px"}}>Valor</TableCell>
                            <TableCell style={{fontSize: "14px"}}>Ações</TableCell>
                        </TableRow>
                   </TableHead>
                   <TableBody displayRowCheckbox={false}>
                   {this.props.listConfiguration.ValueList.map((element, index) => {
                            return (
                                <TableRow style={{ width: "100%" }} key={index}>
                                    <TableCell>{element}</TableCell>
                                    <TableCell>
                                        <ConfirmationDialog
                                            text={Strings.generalConfigurations.validations.LIST_CONTACT_DELETE_ALERT[language].replace("#",element)}
                                            onConfirm={this.handleRemoveElement.bind(null, index)}>
                                            <DeleteIcon style={{ cursor: "pointer" }} />
                                        </ConfirmationDialog>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                   </TableBody>
               </Table>

                <div style={{textAlign: 'right'}}>
                    <ButtonSecondary                        
                        label={Strings.generalConfigurations.BACK[language]}
                        variant="text"
                        onClick={() =>  this.props.handleRequestClose() }
                />
                </div>
            </div>
        )
    }

    render = () => {
        return (

            <div>

                <Dialog
                    open={this.props.editing}
                    onRequestClose={() => this.props.handleRequestClose()}
                    autoScrollBodyContent
                >
                    {this.renderList()}
                </Dialog>

            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEditingDialog);
