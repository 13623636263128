import React from 'react';

import Strings from '../constants/strings';

import { NotificationManager } from 'react-notifications';
import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

class SamlErrorsHandle extends React.Component {

    messageShown = false;
    currentMessage = null;

    render() {
        if (this.props.message && this.props.message !== 'null') {
            if (!this.messageShown && this.oldMessage !== this.props.message) {
                this.currentMessage = this.props.message;
                this.sendNotification(this.currentMessage);
            }
        }

        return (<div></div>);
    }

    sendNotification(message) {
        setTimeout(function () {
            NotificationManager.error(Strings.samlErrors[message][language]);
        }, 1000);
    }
}

export default SamlErrorsHandle;
