import React from 'react';

import SelectField from '@material-ui/core/Select'


import HelpOutline from '@material-ui/icons/HelpOutline';


import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tooltip';

const wrapperStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10
};

const labelStyle = {
    width: "70%",
    fontWeight: '600'
};

const selectFieldStyle = {
    width: 200
};

const errorStyle = {
    color: 'red'
};

const labelWrapperStyle = {
    display: 'flex',
    alignItems: "center",
};

export default class Select extends React.Component {

    renderError(){
        if(this.props.errorText){
            return (
                <p style={errorStyle}>{ this.props.errorText}</p>
            )
        }

        return null;
    }

    componentDidMount(){
        $(document).tooltip({
            position: { my: "left+15 top-15", at: "right center", collision: "flipfit" }
        });
    }

    renderTooltipIcon(){
        if(this.props.tooltip){
            return (
                <HelpOutline style={{width: 18, height: 18, padding: 0, marginLeft: 10, marginTop: 5}}/>
            )
        }

        return null;
    }

    renderLabel(){
        if(this.props.tooltip){
            return (
                <span style={Object.assign({}, labelWrapperStyle, this.props.labelWrapperStyle)}>
                    {this.props.labelText}
                    <span title={this.props.tooltip}>
                        {this.renderTooltipIcon()}
                    </span>
                    
                </span>
            )
        }else{
            return (
                <span>
                    {this.props.labelText}
                    <span title={this.props.tooltip}>
                        {this.renderTooltipIcon()}
                    </span>
                </span>
            )
        }
    }

    render() {
        return (
            <div>
                <div style={Object.assign({}, wrapperStyle, this.props.wrapperStyle)}>
                    <div style={Object.assign({}, labelStyle, this.props.labelStyle)}>
                        {this.renderLabel()}
                    </div>

                    <SelectField
                        disabled={this.props.disabled}
                        style={Object.assign({}, selectFieldStyle, this.props.selectFieldStyle)}
                        value={this.props.value}
                        name={this.props.name}
                        labelId={this.props.name}
                        onChange={this.props.onChange}
                    >
                        {this.props.children}
                    </SelectField>

                </div>

                {this.renderError()}
            </div>
        )
    }
}

/* How to use me?
 *
 * Props to set up in the page:
 * disabled         -> Used to disable the component
 * selectFieldStyle -> Even with a default been held, you can simply redefine all of the selectFieldStyle properties
 * value            -> Value to be returned when selected
 * name, labelId    -> Just a unique identification for the element, can be replaced by id prop
 * onChange()       -> Event that triggers every time you select an element
 * labelText        -> This one you can use to put some label at the side of the component
 * wrapperStyle     -> Same instructions as selectFieldStyle.
 * labelWrapperStyle-> Same instructions as selectFieldStyle.
 * labelStyle       -> Same instructions as selectFieldStyle.
 * children         -> The property that define what are the elements that I need to held, can
 *                     be used like this (children={}) or just putin between <Select>{}</Select>
 */