import React from 'react';

import { Link } from 'react-router-dom'

//import SelectField from '@material-ui/core/Select';

// Import Constants
import colors from '../constants/colors';
import Strings from '../constants/strings';
import UserLanguage from './configurations/UserLanguage.js'

const logoStyle = {
  width: "200px",
}

const iconStyle = {
  width: "20px",
  marginBottom: "35px"
}

const flag = {
  marginRight: "5px",
  paddingBottom: "5px"
}

const styleMenuItem = {
  fontSize: "16px",
  fontFamily: "HKGrotesk",
  padding: "12px"
}

const liStyle = {
  fontSize: "14px",
  fontWeight: "400",
  letterSpacing: "0",
  lineHeight: "20px",
  color: "white"
}

const container = {
  position: 'relative',
  width: '90%',
  margin: '0 auto',
  padding: '0 20px',
  boxSizing: 'border-box',
  display: 'flex',
}

const footerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: '#e1eaef',
  padding: '40px',
  boxSizing: 'content-box',
  alignSelf: "center",
  minHeight: "100px",
  width: "100%",
}

let language = UserLanguage.getLanguage();

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false

    }
  }

  // changeLanguage = (field, event, index, value) => {
  //   if(window.confirm(Strings.FOOTER.CHANGE_LANGUAGE[language])) {
  //     var choosenLanguage = event.target.value
  //     var preferences = JSON.parse(localStorage.getItem("USER_PREFERENCE"))
  //     if (preferences) {
  //       console.log("não é indefinido", preferences, event.target.value)
  //       if (choosenLanguage !== preferences.userLanguage) {
  //         preferences.userLanguage = choosenLanguage;
  //         localStorage.setItem("USER_PREFERENCE", JSON.stringify(preferences))
  //         this.setState({ changed: true })
  //       }
  //     } else {
  //       var newPreferences = Object();
  //       newPreferences.userLanguage = event.target.value;
  //       newPreferences.userRegion = event.target.value;
  //       localStorage.setItem("USER_PREFERENCE", JSON.stringify(newPreferences))
  //     }
  //     window.location.reload()

  //   }
  // }

  render() {
    return (
      <footer style={footerStyle}>
        <div style={container}>
          <div className="footer-wrapper" style={{ display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
            <Link to={Strings.externalRoutes.BIG_DATA_CORP} >
              <img src="assets/bdc-logo-login.png" alt="BigID" style={logoStyle} />
            </Link>
            <p style={{display: 'inline-block', width: '90%', margin: '0 auto', color: '#000066'}}>© 2023 All rights reserved</p>
            {/* <ul style={{ listStyle: "none", padding: "0", marginBottom: "15px", marginLeft: "230px" }}>
              <li style={liStyle}><b>{Strings.FOOTER.ADDRESS[language]}</b></li>
              <br></br>
              <li style={{ color: "#99a3bf" }}><p>Av. das Américas 700m bl 02, sl 129. Barra da Tijuca,</p></li>
              <li style={{ color: "#99a3bf" }}><p>Rio de Janeiro - RJ, Brasil. CEP: 22.640-100</p></li>
              <br></br>
              <li style={{ color: "#99a3bf" }}><p>Av. Angélica 2529, 2° andar. Consolação,</p></li>
              <li style={{ color: "#99a3bf" }}><p>São Paulo - SP, Brasil. CEP 01.227-200</p></li>

            </ul>

            <div style={{ marginLeft: '150px' }}>
              <div style={liStyle}><b>Social</b></div>
              <ul id="social-icons" style={{ listStyle: "none", padding: "0", marginBottom: "15px", display: "flex", paddingTop: '15px' }}>
                <li>
                  <a href={Strings.externalRoutes.LINKEDIN_BDC} target="_blank" rel="noopener noreferrer">
                    <img src="assets/linkedin.png" alt="BigID" style={iconStyle} />
                  </a>
                </li>
                <li>

                  <a href={Strings.externalRoutes.TWITTER_BDC} target="_blank" rel="noopener noreferrer">
                    <img src="assets/twitter.png" alt="BigID" style={iconStyle} />
                  </a>
                </li>

                <li>

                  <a href={Strings.externalRoutes.FACEBOOK_BDC} target="_blank" rel="noopener noreferrer">
                    <img src="assets/facebook.png" alt="BigID" style={iconStyle} />
                  </a>
                </li>

                <li>
                  <a href={Strings.externalRoutes.INSTAGRAM_BDC} target="_blank" rel="noopener noreferrer">
                    <img src="assets/instagram.png" alt="BigID" style={iconStyle} />
                  </a>
                </li>

                <li>
                  <a href={Strings.externalRoutes.YOUTUBE_BDC} target="_blank" rel="noopener noreferrer">
                    <img src="assets/youtube.png" alt="BigID" style={iconStyle} />
                  </a>
                </li>
              </ul> */}
              {/* <div style={liStyle}><b>{Strings.FOOTER.LANGUAGE[language]}</b></div>
              <div id="select-language">
                <SelectField style={{ minWidth: '150px', color: 'white' }}
                  onChange={this.changeLanguage.bind(null, 'key')}
                  value={language}>
                  <MenuItem  style={styleMenuItem} value={"EN"}> <img src="assets/english.png" style={flag} width="25px" height="20px" /> English </MenuItem>
                  <MenuItem  style={styleMenuItem} value={"PT"}> <img src="assets/portuguese.png" style={flag} width="25px" height="20px" /> Português </MenuItem>
                  <MenuItem  style={styleMenuItem} value={"ES"}> <img src="assets/spanish.png" style={flag} width="25px" height="20px" /> Español </MenuItem>
                </SelectField>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </footer>
    );
  }
}
