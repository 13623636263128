import React from 'react';

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
}

const textContainer = {
    display: 'flex',
    flexDirection: 'column'
}


export default class DialogABTest extends React.Component {

    render(){

        const { title, image, text} = this.props

            return (
                <div style={containerStyle}>
                    <div style={textContainer}>
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                </div>
            );
    }
}
