import { DialogActions, DialogContentText } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';
import Strings from '../constants/strings';
import UserLanguage from './configurations/UserLanguage.js';
let language = UserLanguage.getLanguage();

const progressWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

const dialogStyle = {};

class BgcConfigsValidationDialog extends React.Component {
    render() {
        if (!this.props.open) {
            return null
        }

        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.open}
                onClose={this.props.handleRequestClose}
                aria-labelledby="bgc-config-validation-dialog"
            >
            <DialogTitle id="bgc-config-validation-dialog">
                {this.props.dialogTitle || String.APP_NAME}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={progressWrapperStyle}>
                        <h3>{Strings.BGC_CONFIG_DIALOG.INVALID_CONFIG_TITLE[language]}</h3>

                        <p>
                            {Strings.BGC_CONFIG_DIALOG.INVALID_CONFIG_TEXT[language]}
                        </p>

                        <strong>{Strings.BGC_CONFIG_DIALOG.WHY_TITLE[language]}</strong>

                        <p>
                            {Strings.BGC_CONFIG_DIALOG.WHY_TEXT[language]}
                        </p>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {this.props.actions}
            </DialogActions>
            </Dialog>
        );
    }
}

export default BgcConfigsValidationDialog;