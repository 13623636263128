// *-* mode: rjsx -*-
import React from 'react';
import propTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import ButtonSecondary from './mui/ButtonSecondary';
import DialogNew from './DialogNew';
import { Button } from '@material-ui/core';
import { filter } from 'lodash';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Strings from '../constants/strings';

import AddIcon from '@material-ui/icons/CloudUploadOutlined';
import DoneIcon from '@material-ui/icons/CheckCircle';

import UserLanguage from './configurations/UserLanguage';

let language = UserLanguage.getLanguage();

const cloudImage = {
    cursor: 'pointer',
    display: 'inline',
    color: '#7fb4ff'
}

const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
}

const fileInputStyle = {
    display: 'none'
}

const addIconStyle = {
    width: 21,
    height: 21,
    color: "'#7fb4ff",
}

const doneIconStyle = {
    width: 18,
    height: 18,
    color: "#009D00"
}

const helpIcon = {
    width: 18,
    height: 18,
    padding: 0,
    cursor: 'pointer',
    marginLeft: '10'
}

export default class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFaceMatchModal: false,
            showOCRModal: false
        }
    }

    static defaultProps = {
        fileTypeRegex: /.*/,
        onFileLoad: (e) => undefined,
        buttonControl: Button
    };

    static propTypes = {
        fileTypeRegex: propTypes.object,
        onFileLoad: propTypes.func,
        buttonControl: propTypes.func
    };

    exclusiveProps = [
        'fileTypeRegex',
        'onFileLoad',
        'buttonControl'
    ];

    handleCloseModal() {
        if (this.props.label.includes('rosto') || this.props.label.includes('face'))
            this.setState({ showFaceMatchModal: false });
        else
            this.setState({ showOCRModal: false });
    }

    handleOpenModal() {
        if (this.props.label.includes('rosto') || this.props.label.includes('face'))
            this.setState({ showFaceMatchModal: true });
        else
            this.setState({ showOCRModal: true });
    }

    onInputChange = (e) => {
        filter(
            e.target.files,
            (file) => file.type.match(this.props.fileTypeRegex) !== null
        )
            .forEach(
                (file) => {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({uploaded: true});
                        this.props.onFileLoad(e, file);
                    };
                    reader.readAsDataURL(file);
                }
            );
    };

    componentDidMount() {
        findDOMNode(this.refs['file-input'])
            .addEventListener(
                'change',
                this.onInputChange,
                false
            );
    };

    componentWillUnmount() {
        findDOMNode(this.refs['file-input'])
            .removeEventListener(
                'change',
                this.onInputChange,
                false
            );
    };

    componentWillReceiveProps(newProps){
        if(!newProps.uploaded){
            findDOMNode(this.refs['file-input']).value = "";
        }
    }

    getButtonProps() {
        return Object
            .keys(this.props)
            .filter(
                (name) => this.exclusiveProps.indexOf(name) === -1
            )
            .reduce(
                (acc, name) => {
                    acc[name] = this.props[name];
                    return acc;
                },
                {}
            );
    };

    renderTooltipIcon(){
        if(this.props.tooltip){
            return (
                <HelpOutlineIcon style={helpIcon} onClick={() => this.handleOpenModal()} />
            )
        }
        return null;
    }

    renderStatusIcon() {
        if (this.props.uploaded) {
            return (<DoneIcon style={doneIconStyle} />)
        }
    }

    render() {
        const actions = [
            <ButtonSecondary
                label={Strings.generalConfigurations.BACK[language]}
                onClick={() => this.handleCloseModal()}
            />,
        ];
        
        let index = this.props.index || '0';
        return (
            <div style={containerStyle}>
                <div>
                    <div style={{float: 'left'}}>
                        <label>{this.props.label}</label>
                    </div>
                    <div style={{float: 'left', marginRight: '30px'}}>
                        {this.renderTooltipIcon()}
                    </div>
                    <div style={{float: 'left'}}>
                        <label for={"fileInput-" + index} style={cloudImage}>
                            <AddIcon style={addIconStyle} />
                            {this.renderStatusIcon()}
                        </label>
                        <input id={"fileInput-" + index} multiple style={fileInputStyle} type="file" ref="file-input" />
                    </div>
                </div>
                <div>
                    <DialogNew 
                        autoScrollBodyContent={true} 
                        dialogTitleId={"DocumentValidationDialog"} 
                        title={Strings.validatingDocumentsTooltips.TOOLTIP_TITLE[language]}
                        open={this.state.showOCRModal}
                        onClose={() => this.handleCloseModal()}
                        actions={actions}
                        originCall={'OCR'}
                    />
                </div>
                <div>
                    <DialogNew 
                        autoScrollBodyContent={true} 
                        dialogTitleId={"FaceMatchDialog"} 
                        title={Strings.validatingDocumentsTooltips.TOOLTIP_TITLE[language]}
                        open={this.state.showFaceMatchModal}
                        onClose={() => this.handleCloseModal()}
                        actions={actions}
                        originCall={'FaceMatch'}
                    />
                </div>
            </div>
        );
    };
}