import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import BigIdButton from './mui/Button';

import SaveIcon from '@material-ui/icons/Save';

import Collapse from '@material-ui/core/Collapse';
import GroupsSelect from '../components/GroupsSelect';

import $ from 'jquery';


// Import Constants
import Strings from '../constants/strings';

import Auth from '../Authentication';

import UserLanguage from './configurations/UserLanguage.js';

import Utils from './Utils.js';
import colors from '../constants/colors';

let language = UserLanguage.getLanguage();
let titles = ["Questionário", "Background Check", "Documentoscopia e OCR", "FaceMatch", "Verificação por SMS"]

const wrapperStyle = {
    width: "800px",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center"
};

const contentStyle = {
    backgroundColor: '#f2f2f2',
    width: '91%',
    position: 'relative',
    right: '90%'
};

const listStyle = {
    padding: 0,
    display: 'inline-block',
    width: '100%',
    paddingLeft: '530px'
};

const saveButtonStyle = {
    color: colors.primaryBlue,
    boxShadow: 'none',
    marginTop: '10px',
    paddingLeft: '0',
    fontWeight: 'bold',
    left: '-30px',
    backgroundColor: 'transparent !important'
};

const saveIconStyle = {
    marginRight: 5,
    marginBottom: 4,
    fontSize: 25
};

const saveButtonListItemStyle = {
    padding: 0,
    borderRadius: '20px'
};

export default class SideMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected || Strings.generalConfigurations.menu.GENERAL_CONFIGURATIONS.TITLE,
            menu: {
                "Questionário": false,
                "Background Check": false,
                "Documentoscopia e OCR":false,
                "FaceMatch": false,
                "Verificação por SMS": false
            },
            configurations: props.configurations
        };
    }

    handleMenuEvent(value) {
        var visitedBGCPage = localStorage.getItem("visitedBGCPage")
        if (visitedBGCPage === "true") {
            alert("Só é possivel Retornar dos dados utilizados no Background Check com o Token do BigBoost preenchido")
            return;
        }
        
        if ((value.TITLE === "Questionário" && !this.state.menu[value.TITLE]) || value.PARENT === "Questionário") {
            var openQuest = {
                "Questionário": true,
                "Background Check": false,
                "Documentoscopia e OCR": false,
                "FaceMatch": false,
                "Verificação por SMS": false
            }
            this.setState({ menu: openQuest })
            return;
        } else if (value.TITLE === "Documentoscopia e OCR" && !this.state.menu[value.TITLE] || value.PARENT === "Documentoscopia e OCR") {
            var openOCR = {
                "Questionário": false,
                "Background Check": false,
                "Documentoscopia e OCR": true,
                "FaceMatch": false,
                "Verificação por SMS": false
            }
            this.setState({ menu: openOCR })
            return;
        } else if (value.TITLE === "Background Check" && !this.state.menu[value.TITLE] || value.PARENT === "Background Check") {
            var openBGC = {
                "Questionário": false,
                "Background Check": true,
                "Documentoscopia e OCR": false,
                "FaceMatch": false,
                "Verificação por SMS": false
            }
            this.setState({ menu: openBGC })
            return;
        } else if (value.TITLE === "FaceMatch" && !this.state.menu[value.TITLE] || value.PARENT === "FaceMatch") {
            var openFaceMatch = {
                "Questionário": false,
                "Background Check": false,
                "Documentoscopia e OCR": false,
                "FaceMatch": true,
                "Verificação por SMS": false

            }
            this.setState({ menu: openFaceMatch })
            return;
        } else if (value.TITLE === "Verificação por SMS" && !this.state.menu[value.TITLE] || value.PARENT === "Verificação por SMS") {
            var openSMSCheck = {
                "Questionário": false,
                "Background check": false,
                "Documentoscopia e OCR": false,
                "FaceMatch": false,
                "Verificação por SMS": true
            }
            this.setState({ menu: openSMSCheck })
            return;
        }
        var closeAll = {
            "Questionário": false,
            "Background check": false,
            "Documentoscopia e OCR": false,
            "FaceMatch": false,
            "Verificação por SMS": false
        }
        this.setState({ menu: closeAll })
        return;
    }

    changeSelected = (value) => {
        const newState = this.state;
        var visitedBGCPage = localStorage.getItem("visitedBGCPage")
        this.handleMenuEvent(value);
        if (visitedBGCPage == "false") {
            this.setState({ selected: value }, this.props.onSelectedChange.bind(null, value));
        }
        if(!(titles.includes(value.TITLE))){
            this.openSideMenu();
        }
    };

    renderSaveButton() {
        if (this.props.showSaveButton && (!Auth.sessionData.isTelemarketing && !Auth.sessionData.isViewOnlyUser && !Auth.sessionData.isReportUser)) {
            return (
                <ListItem innerdivstyle={saveButtonListItemStyle} className="list-item-save-button" style={{ maxWidth: '200px' }}>
                    <BigIdButton
                        id="save-button"
                        style={saveButtonStyle}
                        onClick={this.props.handleSave}                        
                        label={Strings.generalConfigurations.SAVE_BUTTON_LABEL[language]}
                        startIcon={<SaveIcon style={saveIconStyle} />}
                    />
                </ListItem>
            )
        }
        return null;
    }

    translateSideMenu(menuTitle) {
        menuTitle = menuTitle.toUpperCase();
        menuTitle = Utils.internalReplaceAll(menuTitle, " ", "_");
        menuTitle = Utils.internalReplaceAll(menuTitle, "/", "");
        return Strings.SIDE_MENU_TRANSLATION[menuTitle][language]
    }

    render() {
        return (
            <div style={{ display: 'contents' }}>
                <div style={wrapperStyle} className="side-menu-body">
                    <div style={contentStyle} className="side-menu-wrapper">
                        <div style={{width: "99%"}} className="select-wrapper">
                            <GroupsSelect />
                        </div>
                        <List style={listStyle}>
                            {this.props.menuItems.map((item, index) => {
                                var translatedMenuTitle = this.translateSideMenu(item.TITLE);
                                return (
                                    <div key={'list-item-'+ index}>
                                        <ListItem id={'list-item-' + index}
                                            button
                                            onClick={this.changeSelected.bind(null, item)}
                                            className={"list-item"}
                                        >
                                            <ListItemText id={'list-text-' + index} primary={translatedMenuTitle} key={index} className={"list-text"} />
                                        </ListItem>
                                        {this.renderCollapse(item)}
                                    </div>
                                )
                            })}

                            {this.renderSaveButton()}
                        </List>
                    </div>
                </div>
                {/* <!-- Mobile Top Menu --> */}
                <div className="side-outside-click" onClick={this.openSideMenu}></div>
                <div className="mobile-side-menu">
                    <div className="select-wrapper">
                        <GroupsSelect />
                    </div>
                    <div className="sandwich-menu" onClick={this.openSideMenu}>
                        <svg viewBox="0 0 24 24" width="36" height="36" stroke="#000000" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </div>
                </div>
                <nav className="side-menu-nav">
                    <List style={listStyle}>
                        {this.props.menuItems.map((item, index) => {
                             var translatedMenuTitle = this.translateSideMenu(item.TITLE);
                             return (
                                 <div key={'list-item-' + index}>
                                     <ListItem id={'list-item-' + index}
                                         button
                                         onClick={this.changeSelected.bind(null, item)}
                                         className={"list-item"}
                                     >
                                         <ListItemText id={'list-text-' + index} primary={translatedMenuTitle} key={index} className={"list-text"} />
                                     </ListItem>
                                     {this.renderCollapse(item)}
                                 </div>
                             )
                        })}

                        {this.renderSaveButton()}
                    </List>
                </nav>
            </div>
        );
    }

    openSideMenu() {
        $('.side-menu-nav').toggleClass("menuActive")
        $('.side-outside-click').toggleClass("block")
    }

    renderCollapse(item) {
        if (titles.find(x => x === item.TITLE)) {
            return (
                <Collapse in={this.state.menu[item.TITLE]} timeout="auto" unmountOnExit={true} className={"collapse-wrapper"}>
                    <List>
                        {Object.keys(item.SUBS).map((key, index) => {
                            var translatedMenuTitle = this.translateSideMenu(item.SUBS[key].TITLE)
                            return (
                                <ListItem key={key}
                                    id={'sub-list-item-' + item.TITLE + '-' + index}
                                    button
                                    onClick={this.changeSelected.bind(null, item.SUBS[key])}
                                    className={"sub-list"}
                                >
                                    <ListItemText 
                                        id={'sub-list-text-' + item.TITLE + '-' + index}
                                        primary={(Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) ? "" : translatedMenuTitle}
                                        className={"sub-list-text"}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Collapse>
            )
        }
    }
}
