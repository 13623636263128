import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../../actions';
import $ from 'jquery';

// APIs
import API from '../../API';

// Constants
import strings from '../../constants/strings';

// Components
import ButtonSecondary from '../mui/ButtonSecondary';
import TextField from '../mui/TextField';
import SectionTitle from '../SectionTitle';
import Alert from 'react-bootstrap/lib/Alert';

// Configurations
import UserLanguage from './UserLanguage.js'
import LoadingDialog from "../LoadingDialog";


const goodSendStatus = 1400
const goodCheckStatus = 1401

class SMSCheckDemo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: "",
            ticketId: "",
            statusSendCode: 0,
            statusCheckCode: 0,
            msgSendCode: "",
            msgCheckCode: "",
            code:"",
            loading:false
        }
    }

    changeScreen() {
        $('#inputInfo').toggleClass('displayNone')
        $('#inputCode').toggleClass('displayBlock')
    }

    changeMsg() {
        $('#msgSend').toggleClass('displayNone')
        $('#msgSend').toggleClass('displayBlock')
        $('#msgCheck').toggleClass('displayNone')
        $('#msgCheck').toggleClass('displayBlock')
    }

    handleChange = (event) => {
        this.setState({phone: event.target.value})
    }

    handleCodeChange = (event) => {
        this.setState({code: event.target.value})
    }

    handleClick = async (event) => {
        this.setState({loading: true})
        if (event.currentTarget.id === "SendCode") {
            let sendedObj = await API.sendSMS(this.state.phone)
            
            if (sendedObj) {
                this.setState({msgSendCode: sendedObj.resultMessage, statusSendCode: sendedObj.resultCode, ticketId: sendedObj.ticketId})
                if (sendedObj.resultCode === goodSendStatus)
                    this.changeScreen()
            } else
                this.setState({msgSendCode: "Send object returned undefined", statusSendCode: 0})
            
            $('#msgSend').removeClass('displayNone')
            $('#msgSend').addClass('displayBlock')
        } else if (event.currentTarget.id === "CheckCode") {
            let checkedObj = await API.CheckSMS(this.state.ticketId, this.state.code)
            
            if (checkedObj)
                this.setState({msgCheckCode: checkedObj.resultMessage, statusCheckCode: checkedObj.resultCode})
            else
                this.setState({msgCheckCode: "Check object returned undefined", statusCheckCode: 0})

            $('#msgCheck').removeClass('displayNone')
            $('#msgCheck').addClass('displayBlock')
        } else if (event.currentTarget.id === "Back") {
            this.setState({msgSendCode: "", msgCheckCode: "", statusSendCode: 0, statusCheckCode: 0})
            this.changeScreen()
            this.changeMsg()
        }
        this.setState({loading: false})
    }

    // -- RENDER -- //
    render() {
        let language = UserLanguage.getLanguage()
        return (
            <div>
                <SectionTitle text={strings.generalConfigurations.SMSCHECK[language]} />

                <LoadingDialog open={this.state.loading} />

                <div id="inputInfo">
                    <span style={{fontFamily: "HKGrotesk", fontSize: 16}}>
                        {strings.SMSCHECK.SMSCHECK_EXPLAIN_TEXT[language]}
                    </span>
                    <br />
                    <br />
                    <TextField
                        floatingLabelText={strings.infoTypes.PHONE[language]}
                        onChange={this.handleChange}
                    />
                    <br />
                    <ButtonSecondary
                        disabled={false}
                        id="SendCode"
                        label={strings.SMSCHECK.SMSCHECK_SEND_BUTTON[language]}
                        style={{margin: 0, textTransform: "none"}}
                        onClick={this.handleClick}
                    />
                </div>
                <div id="inputCode" class="displayNone">
                    <span style={{fontFamily: "HKGrotesk", fontSize: 16}}>
                        {strings.SMSCHECK.SMSCHECK_CODE_EXPLAIN_TEXT[language]}
                    </span>
                    <br />
                    <br />
                    <TextField
                        floatingLabelText={strings.SMSCHECK.SMSCHECK_CODE[language]}
                        onChange={this.handleCodeChange}
                    />
                    <br />
                    <ButtonSecondary
                        disabled={false}
                        id="CheckCode"
                        label={strings.SMSCHECK.SMSCHECK_VALIDATE_BUTTON[language]}
                        style={{margin: 0, textTransform: "none"}}
                        onClick={this.handleClick}
                    />
                    &nbsp;
                    <ButtonSecondary
                        disabled={false}
                        id="Back"
                        label={strings.SMSCHECK.SMSCHECK_BACK_BUTTON[language]}
                        style={{margin: 0, textTransform: "none"}}
                        onClick={this.handleClick}
                    />
                </div>
                <br />
                <div id="msgSend" class="displayNone">
                    <Alert bsStyle={this.state.statusSendCode === goodSendStatus ? 'success' : 'danger'}>
                        <h3>Status: {this.state.statusSendCode}</h3>
                        <h4>{this.state.msgSendCode}</h4>
                    </Alert>
                </div>
                <div id="msgCheck" class="displayNone">
                    <Alert bsStyle={this.state.statusCheckCode === goodCheckStatus ? 'success' : 'danger'}>
                        <h3>Status: {this.state.statusCheckCode}</h3>
                        <h4>{this.state.msgCheckCode}</h4>
                    </Alert>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        }
    }
}

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSCheckDemo)
