export default {
    primaryRed: '#F9423A',
    primaryBlue: '#0069ff',
    newBlue: '#213262',
    primaryBlueDark: '#1A2C40',
    lightgrey: '#f2f2f2',
    grey: '#777',
    primaryGreen: '#009189',
    primaryTitleRed: '#fd545b',
    primaryNewBlue: '#01e4ff',
    White: "#FFFFFF",
    black: "#000000"
}
