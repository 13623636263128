import React from 'react';

import Strings from '../../constants/strings';
import Colors from '../../constants/colors';

import SectionTitle from '../SectionTitle';

import { connect } from 'react-redux';
import * as actions from '../../actions';

import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

const cardContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
};

let itemStyle = {
    backgroundColor: Colors.primaryBlue,
    height: "80px",
    marginRight: "10px",
    marginTop: "10px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: "5px",
    borderColor: Colors.primaryBlueDark,
    cursor: "pointer",
    width: 135
};

const textStyle = {
    color: 'white',
    display: "inline-block",
    width: "100px",
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 1,
    lineHeight: 1.5,
    MozOsxFontSmoothing: 'antialiased',
    WebkitFontSmoothing: 'antialiased'
};


class InfoTypeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            configurations: props.configurations,
            displayCreateGroupForm: false,
        }
    }

    checkIfInfoTypeHasQuestions = (infoType) => {
        return this.props.questionsProperties.AllQuestionPorperties.filter((x) => {
            return x.InfoType === infoType.TypeName;
        }).length > 0;
    };

    checkIfInfoTypeEnabled = (infoType) => {
        let questionsFromInfoType = this.props.configurations.QuestionsConfigurations.filter((x) => {
            const property = this.props.questionsProperties.AllQuestionPorperties.find((y) => { return y.Id === x.QuestionId });
            return property.InfoType === infoType.TypeName && x.Enabled;
        });

        return questionsFromInfoType.length > 0;
    };

    IsCompanyOriented = (infoType) => {
        let questionProp = this.props.questionsProperties.AllQuestionPorperties.find((x) => { return x.InfoType === infoType });
        if(questionProp.IsCompanyOriented){
            return true
        }else{
            return false
        }
    };

    handleClick = (element) => {
        this.props.onInfoTypeSelected(element);
    };

    // -- RENDER -- //
    render() {
        let infoTypes = this.props.configurations.InfoTypeConfigurations.filter((x) => { return this.checkIfInfoTypeHasQuestions(x) });

        let sortedFields = infoTypes.sort((a, b) => {
            if (Strings.infoTypes[a.TypeName][language] > Strings.infoTypes[b.TypeName][language]) {
                return 1;
            } else {
                return -1;
            }
        });

        if (this.props.configurations.Domain !== 'SMILES') {
            sortedFields = sortedFields.filter((x) => {
                return x.TypeName !== 'VOEGOL_REDEMPTION' && x.TypeName !== 'VOEGOL_ACCRUAL';
            });
        }

        return (
            <div>{this.props.kind == "PF" ? 
                    <SectionTitle text={Strings.generalConfigurations.PERSON_QUESTIONS_CONFIGURATIONS_LABEL[language]} tooltip={Strings.tooltips.QUESTIONS_GROUPS[language]} />
                    :
                    <SectionTitle text={Strings.generalConfigurations.COMPANIES_QUESTIONS_CONFIGURATIONS_LABEL[language]} tooltip={Strings.tooltips.QUESTIONS_GROUPS[language]} />
                }
                

                <div style={cardContainerStyle}>
                    {sortedFields.map((element, index) => {
                        const enabled = this.checkIfInfoTypeEnabled(element);

                        let newStyle;
                        if (enabled) {
                            newStyle = Object.assign({}, itemStyle);
                        } else {
                            newStyle = Object.assign({}, itemStyle, { backgroundColor: "#777", borderColor: "#555" });
                        }
                        if( (this.IsCompanyOriented(element.TypeName) && this.props.kind == "PJ") || (!this.IsCompanyOriented(element.TypeName) && this.props.kind == "PF")){
                            return (
                                <div style={newStyle} onClick={this.handleClick.bind(null, element)} key={element.TypeName} class="question-card">
                                    <div style={textStyle}>
                                        {Strings.infoTypes[element.TypeName][language] !== undefined ? Strings.infoTypes[element.TypeName][language] : element.TypeName}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTypeList);
