import React from 'react';

import { TextField } from '@material-ui/core';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tooltip';

import Strings from '../constants/strings';

import UserLanguage from './configurations/UserLanguage.js';

import validate from 'validate.js';
validate.validators.presence.message = " é obrigatório.";

let language = UserLanguage.getLanguage();

const textFieldStyle = {
    width: 60,
    marginRight: 10,
    marginBottom: 20
};

const labelStyle = {
    marginBottom: -10,
    fontSize: 14,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
};

export default class TimePicker extends React.Component {

    constrains = {
        hours: {
            presence: true,
            numericality: {
                onlyInteger: true
            }
        },

        minutes: {
            presence: true,
            numericality: {
                onlyInteger: true
            }
        },

        seconds: {
            presence: true,
            numericality: {
                onlyInteger: true
            }
        }
    };

    constructor(props) {
        super(props);
        this.state = this.formatTimeToState(this.props.value);
    }

    componentDidMount(){
        $(document).tooltip({
            position: { my: "left+15 top-15", at: "right center", collision: "flipfit" }
        });
    }

    renderTooltipIcon(){
        if(this.props.tooltip){
            return (
                <HelpOutlineIcon style={{width: 18, height: 18, padding: 0, marginLeft: 10}}/>
            )
        }

        return null;
    }

    addZeroIfNeeded(value){
        if(value < 10)
            return `0${value}`;
        else
            return value;
    }

    onChange = (key, event) => {
        const newState = {};
        // if(value < 0)
        //     return;
        newState[key] = event.target.value;
        this.setState(newState, () => {
            // The initial 00 is added because of the standard format for the TimeSpan in C#
            // Format - days:hours:minutes:seconds
            let hours = this.state.hours % 24;
            let days = Math.floor(this.state.hours / 24);
            let time = `${this.addZeroIfNeeded(days)}:${this.addZeroIfNeeded(hours)}:${this.addZeroIfNeeded(this.state.minutes)}:${this.addZeroIfNeeded(this.state.seconds)}`;
            this.props.onChange(this.props.name, time);
        });
    };

    formatTimeToState(time) {
        let splited = time.split(/[:|.]/);
        splited = splited.map((value) => {
            return parseInt(value, 10)
        });

        let hours = splited[splited.length - 3] || 0;
        let days = splited[splited.length - 4] || 0;

        hours += days * 24;

        return {
            hours: hours,
            minutes: splited[splited.length - 2] || 0,
            seconds: splited[splited.length - 1] || 0
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.formatTimeToState(nextProps.value));
    }

    render() {
        const errors = validate(this.state, this.constrains);
        
        return (
            <div>
                <h4>
                    <span style={labelStyle}>
                        {this.props.floatingLabelText}
                        <span title={this.props.tooltip}>
                            {this.renderTooltipIcon()}
                        </span>
                    </span>
                </h4>
                <br />
                <TextField
                    type="number"
                    id="hourTimePick"
                    label={Strings.TIMEPICKER.HOURS[language]}
                    style={textFieldStyle}
                    value={this.state.hours}
                    name={this.props.name}
                    onChange={this.onChange.bind(null, "hours")}
                    helperText={errors ? errors["hours"] : ""}
                />

                <TextField
                    type="number"
                    id="minuteTimePick"
                    label={Strings.TIMEPICKER.MINUTES[language]}
                    style={textFieldStyle}
                    value={this.state.minutes}
                    name={this.props.name}
                    onChange={this.onChange.bind(null, "minutes")}
                />

                <TextField
                    type="number"
                    id="secondsTimePick"
                    label={Strings.TIMEPICKER.SECONDS[language]}
                    style={textFieldStyle}
                    value={this.state.seconds}
                    name={this.props.name}
                    onChange={this.onChange.bind(null, "seconds")}
                />
            </div>
        );
    }
}
