import React from 'react';

import Header from '../components/Header';
import LoadingDialog from '../components/LoadingDialog';

import Strings from '../constants/strings';

// API
import API from '../API';
import ApiConstants from '../constants/api';

import {connect} from 'react-redux';
import * as actions from '../actions';

// Material UI Imports
import {Card, CardContent} from '@material-ui/core';

import SideMenu from '../components/SideMenu';
import Footer from "../components/Footer";
import WebcamCapture from "../components/WebcamCapture";

const cardStyle = {
    width: "100%",
    maxWidth: "700px",
    minHeight: "500px",
    marginTop: "20px",
    paddingTop: "15px",
    position: "relative",
    top: "-20px",
    left: '90px',
    zIndex: 2,
    boxShadow: null,
};

const containerStyle = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative"
};

const cardTextStyle = {
    zIndex: 2,
    paddingTop: 0,
};

class Configurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            menuActive: Strings.facevalidation.menu.VALIDATE_IDENTITY,
            modified: this.props.modified,
            displayCreateGroupForm: false,
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
            modified: newProps.modified,
        });
    }

    // -- HANDLERS -- //
    handleSave = async () => {
        this.setState({loading: true});
        let result = await API.saveGroup(this.state.configurations);
        if (result.status === ApiConstants.status.SUCCESS) {
            this.setState({configurations: result.configurations});
            this.props.onSelectGroup(result.configurations);
        }

        this.setState({loading: false});
    };

    handleMenuChange = (value) => {
        this.setState({menuActive: value});
    };

    // -- RENDER -- //
    renderContent() {
        return (<WebcamCapture />);
    }


    render() {
        const menuItems = [
            Strings.facevalidation.menu.VALIDATE_IDENTITY,
        ];

        return (
            <div>
                <Header />
                <div style={containerStyle}>
                    <SideMenu selected={this.state.menuActive} title="Área de trabalho" menuItems={menuItems} onSelectedChange={this.handleMenuChange} handleSave={this.handleSave}/>

                    <Card style={cardStyle}>
                        <CardContent style={cardTextStyle}>
                            {this.renderContent()}
                        </CardContent>
                    </Card>

                    <LoadingDialog open={this.state.loading} />
                </div>
                <Footer/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        modified: state? state.modified : false,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Configurations);
