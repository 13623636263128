import React from 'react';

import {CircularProgress} from '@material-ui/core';
import SectionTitle from '../SectionTitle';
import SelectField from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import ButtonSecondary from '../mui/ButtonSecondary';

import Table from 'react-bootstrap/lib/Table';

import { connect } from 'react-redux';
import * as actions from '../../actions';

import API from '../../API';

import moment from 'moment';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import DateTimePicker from 'react-datetime-picker';
import UserLanguage from '../configurations/UserLanguage.js';
import Strings from '../../constants/strings';
import Auth from '../../Authentication';

import Alert from 'react-bootstrap/lib/Alert';

let language = UserLanguage.getLanguage();

const reportContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const labelMargin = {
    marginTop: "15px"
}

const alertStyle = {
    marginTop: "20px"
};

const styleMenuItem = {
    fontSize: "16px",
    fontFamily: "HKGrotesk",
    padding: "16px"
};

class QuestionsReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            endDate: new Date(),
            startDate: new Date(),
            group: Auth.getSessionData().activeGroupConfiguration.Name
        }

        this.setState({ startDate: this.state.startDate.setDate(this.state.startDate.getDate() - 30) })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    getQuestionTextById(id) {
        for (let question of this.props.questionsProperties.AllQuestionPorperties) {
            if (question.Id === id) {
                return question.QuestionText[language];
            }
        }
        return id;
    }

    // -- HANDLERS -- //
    handleChange = (field, event) => {
        const newState = {};
        if (field === 'group') {
            newState[field] = event.target.value;
        } else {
            newState[field] = event;
        }

        this.setState(newState);
    };

    handleGenerate = async () => {
        this.setState({ loading: true, error: null });

        var momentStartDate = moment(this.state.startDate)
        var momentEndDate = moment(this.state.endDate)

        let diffInDays = momentEndDate.diff(momentStartDate, 'days');

        if (diffInDays > 60) {
            this.setState({ error: Strings.reports.MAX_PERIOD_ALERT[language], loading: false });
            return;
        }

        momentStartDate = momentStartDate.format('YYYY-MM-DDTHH:mm:ss');
        momentEndDate = momentEndDate.format('YYYY-MM-DDTHH:mm:ss');

        let report = await API.getReportData(momentStartDate, momentEndDate, this.state.group, this.props.selectedDomain, 'GenerateQuestionsReport');

        report = Object.keys(report.Questions).map((key) => { return report.Questions[key] });
        report.sort((a, b) => {
            if (a.Generated > b.Generated) {
                return -1;
            } else {
                return 1;
            }
        });
        this.setState({ loading: false, report: report });
    };


    // -- RENDER -- //
    renderReport() {
        if (this.state.report) {
            return (
                <div style={{ marginTop: "30px" }}>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: "350px" }}>{Strings.reports.QUESTION[language]}</th>
                                <th>{Strings.reports.GENERATED[language]}</th>
                                <th>{Strings.reports.HITS[language]}</th>
                                <th>{Strings.reports.MISTAKES[language]}</th>
                                <th>{Strings.reports.UNANSWERED[language]}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.state.report.map((value) => {
                                return (
                                    <tr key={value.QuestionId} style={{ textAlign: "right" }}>
                                        <td style={{ width: "350px", textAlign: "left" }}>{this.getQuestionTextById(value.QuestionId)}</td>
                                        <td>{value.Generated} </td>
                                        <td>{Math.round((value.Validated / value.Generated * 100) * 100) / 100} %</td>
                                        <td>{Math.round((value.NotValidated / value.Generated * 100) * 100) / 100} %</td>
                                        <td>{Math.round((value.NotAnswered / value.Generated * 100) * 100) / 100} %</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            )
        }
    }

    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} />
        )
    }

    renderErrors() {
        if (this.state.error) {
            return (
                <div>
                    {this.state.error}
                </div>
            )
        }
    }

    renderAllDisclaimer() {
        if (this.state.group === "ALLGROUPS" && !this.state.report) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <p>{Strings.reports.ALL_DISCLAIMERS[language]}</p>
                </Alert>
            )
        }
    }

render() {

    return (
        <div style={reportContainerStyle}>
            <div style={{ width: '100%' }}>
                <SectionTitle text={Strings.reports.QUESTS_REPORT_TITLE[language]} />

                <label htmlFor="" style={labelMargin}>{Strings.reports.INICIAL_DATE[language]}</label>
                <br />
                <DateTimePicker value={this.state.startDate} format="dd/MM/yyyy HH:mm" onChange={this.handleChange.bind(null, "startDate")} />
                <br />

                <label htmlFor="" style={labelMargin}>{Strings.reports.FINAL_DATE[language]}</label>
                <br />
                <DateTimePicker value={this.state.endDate} format="dd/MM/yyyy HH:mm" onChange={this.handleChange.bind(null, "endDate")} />
                <br />

                <label htmlFor="" style={labelMargin}>{Strings.reports.GROUPS[language]}</label>

                <SelectField
                    style={{ display: 'block' }}
                    onChange={this.handleChange.bind(null, "group")}
                    value={this.state.group} >
                    {Auth.sessionData.groupsNames.map((value) => {
                        return (<MenuItem style={styleMenuItem} value={value}>{value}</MenuItem>)
                    })}
                    <MenuItem style ={styleMenuItem} value={'ALLGROUPS'}><b>{Strings.reports.ALL[language]}</b></MenuItem>
                </SelectField>

                <br />

                {this.renderErrors()}

                {this.renderAllDisclaimer()}

                <ButtonSecondary key="generate" type="submit" onClick={this.handleGenerate} label={Strings.reports.GENERATE_REPORT[language]} />

                {this.renderLoading(this.state.loading)}

                {this.renderReport()}

            </div>
        </div>
    )
}
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {},
        selectedDomain: state ? state.selectedDomain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsReport);
