import React from 'react';

import Select from '../mui/Select';

import QuestionConfiguration from '../QuestionConfiguration';

import { MenuItem } from '@material-ui/core';

import Colors from '../../constants/colors';
import Strings from '../../constants/strings';

import Divider from 'material-ui/Divider';

import { connect } from 'react-redux';
import * as actions from '../../actions';

import UserLanguage from '../configurations/UserLanguage.js'
import ArrowBack from '@material-ui/icons/ArrowBack';

let language = UserLanguage.getLanguage();

const backButtonStyle = {
    width: 30,
    height: 30,
    cursor: "pointer",
    marginRight: "10px"
};
const styleMenuItem = {
    fontSize: "16px",
    padding: "8px",
}

class InfoTypeQuestions extends React.Component {

    constructor(props) {
        super(props);
        const infoType = this.props.configurations.InfoTypeConfigurations.find((x) => { return x.TypeName === this.props.infoTypeName });

        this.state = {
            infoType: infoType
        }
    }

    onFrequencyChange = (event, index, value) => {
        let configurations = this.props.configurations;

        configurations
            .InfoTypeConfigurations
            .find((x) => { return x.TypeName === this.props.infoTypeName; }).Mandatory = event.target.value === 3;

        configurations
            .InfoTypeConfigurations
            .find((x) => { return x.TypeName === this.props.infoTypeName; }).Frequency = event.target.value;

        let choosedInfoType = configurations.InfoTypeConfigurations.find(x => x.TypeName === this.props.infoTypeName);

        this.setState({ infoType: choosedInfoType });
        this.props.onSave(configurations);
    };

    // -- RENDER -- //
    renderTitle(text) {
        const configurationSectionWrapperStyle = {
            display: 'flex',
            alignItems: 'center',
        };

        const configurationSectionTitleStyle = {
            fontSize: 15,
            color: Colors.primaryGreen,
            fontWeight: "bold",
            display: 'inline',
            height: '100%',
            margin: 0
        };

        return (
            <div>
                <div style={configurationSectionWrapperStyle}>
                    <ArrowBack onClick={this.props.onBackButton} color={Colors.primaryBlue} style={backButtonStyle} />
                    <h3 style={configurationSectionTitleStyle}> {text} </h3>
                </div>

                <Divider />
            </div>
        );
    }

    disableAllOtherQuestions = (except) => {
        let configurations = this.props.configurations;

        for (let question of configurations.QuestionsConfigurations) {
            const questionProperty = this.props.questionsProperties.AllQuestionPorperties.find((x) => { return question.QuestionId === x.Id });
            if (questionProperty.InfoType === this.props.infoTypeName && question.QuestionId !== except) {
                question.Enabled = false;
                question.Frequency = question.Frequency === 3 ? 2 : question.Frequency;
            }
        }

        const infoType = this.props.configurations.InfoTypeConfigurations.find((x) => { return x.TypeName === this.props.infoTypeName });
        this.setState({ infoType: infoType });
        this.props.onSave(configurations);
    };


    render() {
        const questionsFromInfoType = this.props.questionsProperties.AllQuestionPorperties.filter((x) => {
            return x.InfoType === this.props.infoTypeName;
        });

        return (
            <div>

                <div style={{ paddingLeft: 10 }}>

                    {this.renderTitle(Strings.infoTypes[this.state.infoType.TypeName][language] || this.state.infoType.TypeName)}

                    <Select
                        tooltip={Strings.tooltips.INFOTYPE_FREQUENCY[language]}
                        labelText={Strings.tooltips.INFOTYPE_FREQUENCY_TITLE[language]}
                        onChange={this.onFrequencyChange}
                        value={this.state.infoType.Frequency}
                        wrapperStyle={{width: "50%", marginTop: "10px",}}
                    >
                        <MenuItem style={styleMenuItem} value={3}> {Strings.selectChoices.ALWAYS[language]} </MenuItem>
                        <MenuItem style={styleMenuItem} value={2}> {Strings.selectChoices.OFTEN[language]} </MenuItem>
                        <MenuItem style={styleMenuItem} value={1}> {Strings.selectChoices.SOMETIMES[language]} </MenuItem>
                        <MenuItem style={styleMenuItem} value={0}> {Strings.selectChoices.RARELY[language]} </MenuItem>
                    </Select>

                </div>

                {questionsFromInfoType.map((question) => {
                    return (
                        <QuestionConfiguration
                            question={question}
                            key={question.Id}
                            disableAllOtherQuestions={this.disableAllOtherQuestions.bind(null, question.Id)} />
                    )
                })}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTypeQuestions);
