import Auth from '../../Authentication.js'

export default {

    checkChoosenLanguage() {
        var preferences = JSON.parse(localStorage.getItem("USER_PREFERENCE"))
        if (preferences != undefined) {
            return true;
        }
    },

    getLanguage() {
        if(this.checkChoosenLanguage()) {
            var preferences = JSON.parse(localStorage.getItem("USER_PREFERENCE"))
            return preferences.userLanguage;
        }

        var lang = navigator.language || navigator.userLanguage;
        lang = lang.split("-");
        if(lang[0] != null && lang[0] != '{}') {
            return lang[0].toUpperCase();
        } else {
            return 'PT';
        }
    },

    getRegion() {
        var lang = navigator.language || navigator.userLanguage;
        lang = lang.split("-");
        if(lang[1] != null && lang[1] != '{}') {
            return lang[1].toUpperCase();
        } else {
            return 'BR';
        }
    }
}