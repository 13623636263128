import React from 'react';

import {connect} from 'react-redux';
import * as actions from '../actions';

import { Button } from '@material-ui/core';
import Toggle from '../components/mui/Toggle';

import ListEditingDialog from './ListEditingDialog';
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import ConfirmationDialog from './ConfirmationDialog';

import Strings from '../constants/strings';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

class ValidationList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false
        }
    }

    handleToggle = (event, toggled) => {
        let configurations = this.props.configurations;

        for (const index in configurations.BigIdValidationOptions) {
            const validation = configurations.BigIdValidationOptions[index];
            if (validation.Name === this.props.validationName) {
                configurations
                    .BigIdValidationOptions[index]
                    .ListsConfiguration[this.props.myKey]
                    .Enabled = toggled;
            }
            var enabled = false;
            if(configurations.BigIdValidationOptions[index].ListsConfiguration.CPF != null) {
                enabled = configurations.BigIdValidationOptions[index].ListsConfiguration.CPF.Enabled
            }
            if(configurations.BigIdValidationOptions[index].ListsConfiguration.CRM != null && enabled === false) {
                enabled = configurations.BigIdValidationOptions[index].ListsConfiguration.CRM.Enabled
            }
            configurations.BigIdValidationOptions[index].Enabled = enabled;
        }

        this.props.onSave(configurations);
    };

    handleEdit = () => {
        this.setState({editing: true});
    };

    handleDelete = () => {
        let configurations = this.props.configurations;

        for (const index in configurations.BigIdValidationOptions) {
            const validation = configurations.BigIdValidationOptions[index];
            if (validation.Name === this.props.validationName) {
                delete configurations
                    .BigIdValidationOptions[index]
                    .ListsConfiguration[this.props.myKey];
            }
        }

        this.props.onSave(configurations);
    };

    renderEnabledToggle = (validation) => {
        return (
            <Toggle
                toggled={validation.Enabled}
                name={validation.Name}
                onToggle={this.handleToggle}
            />
        )
    };

    renderListDialog = () => {
        const validation = this.props.configurations.BigIdValidationOptions.find(x => x.Name === this.props.validationName);
        const listConfiguration = validation.ListsConfiguration[this.props.myKey];

        return (
            <ListEditingDialog
                editing={this.state.editing}
                handleRequestClose={() => {
                    this.setState({editing: false})
                }}
                listConfiguration={listConfiguration}
                validation={validation}
                myKey={this.props.myKey}
            />
        )
    };

    renderListActions = () => {
        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                <Button
                    style={{minWidth: 50}}
                    onClick={this.handleEdit}
                >
                    <EditIcon />
                </Button>

                <div>
                    <ConfirmationDialog 
                        onConfirm={this.handleDelete}
                        text={ Strings.validations.DELETE_ALERT[language] }
                    >
                        <Button style={{minWidth: 50}}>
                            <DeleteIcon />
                        </Button>
                    </ConfirmationDialog>
                </div>

            </div>
        )
    };

    render() {
        const validation = this.props.configurations.BigIdValidationOptions.find(x => x.Name === this.props.validationName);
        const listConfiguration = validation.ListsConfiguration[this.props.myKey];

        return (
            <tr key={validation.Name + this.props.myKey} style={{width: "100%"}}>
                <td>{Strings.validations[validation.Name]}</td>
                <td>{this.props.myKey}</td>
                <td>{this.renderEnabledToggle(listConfiguration)}</td>
                <td>{listConfiguration.ValueList.length }</td>
                <td>
                    {this.renderListActions()}
                    {this.renderListDialog()}
                </td>

            </tr>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        }
    }
};

const mapStateToProps = (state) => {
    if (state) {
        return {
            configurations: state ? state.configurations : {},
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationList);
