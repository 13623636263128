import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './easter/jquery.raptorize.2.0.js';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
