import ApiConstants from './constants/api';
import Auth from './Authentication.js';
import https from 'https';

class API {
    static logoutIfNotAuthenticated(response){
        if(response.status === 401)
            Auth.logout();
    }

    static async login(login, password) {
        try {
            let URL = ApiConstants.URL + "auth/login";

            const response = await fetch(URL,
                {
                    ...ApiConstants.headers.POST,
                    body: JSON.stringify({
                        "login": login,
                        "password": password
                    }),
                });

            const result = await response.json();

            switch (response.status) {
                case 200:
                    return result;
                default:
                    return {
                        status: "ERROR",
                        errors: ["Problemas com a rede de acesso."],
                    };
            }

        } catch (e) {
            return {
                status: "ERROR",
                errors: ["Problemas com a rede de acesso."],
            };
        }
    }

    static async loginSSO(token, nameSSO, name) {
        try {
            let URL = ApiConstants.URL + "auth/loginsso";

            const response = await fetch(URL,
                {
                    ...ApiConstants.headers.POST,
                    body: JSON.stringify({
                        "token": token,
                        "groupReference": nameSSO,
                        "login": name
                    }),
                });

            const result = await response.json();

            switch (response.status) {
                case 200:
                    return result;
                default:
                    return {
                        status: "ERROR",
                        errors: ["Problemas com a rede de acesso."],
                    };
            }

        } catch (e) {
            return {
                status: "ERROR",
                errors: ["Problemas com a rede de acesso."],
            };
        }
    }

    static async loginParamSAML(token, login) {
        try {
            let URL = ApiConstants.URL + "auth/samlparamlogin";

            const response = await fetch(URL,
                {
                    ...ApiConstants.headers.POST,
                    body: JSON.stringify({
                        "token": token,
                        "login": login
                    }),
                });

            const result = await response.json();

            switch (response.status) {
                case 200:
                    return result;
                default:
                    return {
                        status: "ERROR",
                        errors: ["Problemas com a rede de acesso."],
                    };
            }

        } catch (e) {
            return {
                status: "ERROR",
                errors: ["Problemas com a rede de acesso."],
            };
        }

    }

    static async logout(token) {
        try {
            let URL = `${ApiConstants.URL}auth/logout/${token}`;

            const request = {
                ...ApiConstants.headers.DELETE,
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            return await response.json();

        } catch (e) {

        }
    }

    static async createNewGroup(groupName, groupReference, targetDomain) {
        try {
            let URL = ApiConstants.URL + "GroupConfiguration/New";

            groupName = groupName.trim();

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                ...ApiConstants.headers.POST,
                body: JSON.stringify({
                    groupName: groupName,
                    groupReference: groupReference,
                    domain: targetDomain
                }), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();
            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            Auth.changeCurrentGroup(result);
            return result;

        } catch (e) {

        }
    }

    static async createNewAccount(account) {
        try {
            let URL = ApiConstants.URL + "Auth/CreateAccount";

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                ...ApiConstants.headers.POST,
                body: JSON.stringify({
                    Login: account.UserName,
                    Password: account.UserPassword,
                    Group: account.GroupName.trim()
                }), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();
            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async getGroupProbabilityOfSuccess(group){
        try {
            let URL = ApiConstants.URL + "GroupConfiguration/CheckProbability";

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                ...ApiConstants.headers.POST,
                body: JSON.stringify(group), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();
            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async saveGroup(group) {
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/Save`;

            group.Name = group.Name.trim();

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                ...ApiConstants.headers.POST,
                body: JSON.stringify(group), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            console.log("RESPONSE ======> ", response)
            if (response.status !== 200) {
                return {
                    status: response.status,
                    errors: response.errors,
                    response: response
                };
            }

            console.log("RESULT ======> ", result)
            if (result.status == "SUCCESS") {
                Auth.changeCurrentGroup(result);
            } else {
                if (result.errors.length > 0) {
                    return {
                        status: result.status,
                        errors: result.errors,
                        result: result
                    };
                }     
            }

            return result;

        } catch (e) {

        }
    }

    static async selectGroup(groupName, domain) {
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/PostGroupConfig`;

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                ...ApiConstants.headers.POST,
                body: JSON.stringify({
                    groupName: groupName,
                    domain: domain
                }), agent
            };

            request.headers.Authorization = Auth.getToken();
            
            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            Auth.changeCurrentGroup(result);
            return result;
           
        } catch (e) {
            console.error("selectGroup: ", e);
        }
    }

    static async changeDomain(domain) {
        try {

            let URL = `${ApiConstants.URL}Auth/ChangeDomain`;

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                ...ApiConstants.headers.POST,
                body: domain, agent                
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }
            Auth.changeCurrentGroup(result);
            return result;

        } catch (e) {

        }
    }

    static async deleteGroup(groupName, domain = "") {
        try {
            let URL = ApiConstants.URL + "GroupConfiguration/RemoveGroup/" + groupName + "/" + domain;

            const request = {
                ...ApiConstants.headers.POST                
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            Auth.changeCurrentGroup(result);
            return result;

        } catch (e) {

        }
    }

    static async verifyID(params, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/VerifyID`;

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Group": Auth.getCurrentConfigurations().Name,
                "Domain": domain,
                "ForensicValidations": true,
                "ManipulationAnalisys": true,
                "Parameters": params,
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async faceMatch1xN(params, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/faceMatch1xN`;

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Group": Auth.getCurrentConfigurations().Name,                
                "Domain": domain,
                "ForensicValidations": true,
                "ManipulationAnalisys": true,
                "Parameters": params,
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async faceMatch1x1(params, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/faceMatch1x1`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Group": Auth.getCurrentConfigurations().Name,                
                "Domain": domain,
                "ForensicValidations": true,
                "ManipulationAnalisys": true,
                "Parameters": params,
            };

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }


    static async GeneratePOC(PocType, fileURL, Client){
        try {
            let URL = `${ApiConstants.URL}Report/GeneratePOC`;

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const body = {                
                "PocType" : PocType,
                "fileURL" : fileURL,
                "Client": Auth.getCurrentConfigurations().Domain,                
                "ClientLogin": Auth.getSessionData().login,
                "ClientToken" : Auth.getSessionData().token,                           
                "ClientConfigurationGroup" : Auth.getCurrentConfigurations().Name,
                "ClientPassword" : Auth.getSessionData().password            
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async RemovePocEvent(Id){
        try {
            let URL = `${ApiConstants.URL}Report/RemovePocEvent`;

            const body = {
                "Id": Id,
            };

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async PocFileToS3(file, actualType, pocDocument){
        try {
            let URL = `${ApiConstants.URL}Report/SendPocFileToS3`;

            const body = {                
                "Type" : file.type,
                "Name" : file.name,
                "Domain"   : Auth.getCurrentConfigurations().Domain,
                "User"   : Auth.getSessionData().login,
                "API" : actualType,
                "base64" : pocDocument

            };

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }


    static async GetPocEvents(){
        try {
            let URL = ApiConstants.URL + "GroupConfiguration/PocEvents/";

            const body = {                
                "PocType" : "",
                "fileURL" : "",
                "Client" : "",
                "User": Auth.getSessionData().login,                
                "ClientLogin": "",
                "ClientToken" : "",                           
                "ClientConfigurationGroup" : ""
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async UnifiedBase(params, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/FaceMatch1xNV2`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Domain": domain,
                "Parameters": params
            };

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }else if(result == "O servidor remoto retornou um erro: (401) Não Autorizado."){
                return {
                    status: "ERROR",
                    errors: ["Não Autorizado."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async getBgcResult(params, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/BackgroundCheck`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Group": Auth.getCurrentConfigurations().Name,                
                "Domain": domain,
                "ForensicValidations": true,
                "ManipulationAnalisys": true,
                "parameters": params,
            };

            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request); 

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            if (response.status === 200 && result === "The remote server returned an error: (403) Forbidden.") {
                console.log("Entrou");
                return {
                    status: "ERROR",
                    errors: ["Token do Boost Inválido."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async getQuestions(params, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/Questions`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Group": Auth.getCurrentConfigurations().Name,
                "Domain": domain,
                "Parameters": params,
            };
            
            const agent = new https.Agent({
                rejectUnauthorized: false
            });

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body), agent
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async InsertABConfigs(params){
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/SaveAbConfigs`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Domain": params.get("Domain"),
                "StartDate": params.get("StartDate"),
                "EndDate":params.get("EndDate"),
                "GroupA":params.get("GroupA"),
                "GroupB":params.get("GroupB"),
                "QuestionnaireTest": params.get("QuestionnaireTest"),
                "BackgroundCheckTest": params.get("BackgroundCheckTest"),
                "RequestPercentA": params.get("RequestPercentA"),
                "RequestPercentB": params.get("RequestPercentB"),
                "UseDates": params.get("UseDates"),
                "UseRequestQty": params.get("UseRequestQty"),
                "OnPause": false,
                "RequestQty": params.get("RequestQty"),
                "VolumeFinished" : params.get("VolumeFinished")               
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async GenerateAbTestReport(params,resultId){
        try {
            let URL = `${ApiConstants.URL}Report/GenerateAbTestReport`;

            const body = {
                "Domain": params.get("Domain"),
                "TestId": resultId,
                "Product" : params.get("Product"),
                "GroupA": params.get("GroupA"),
                "GroupB": params.get("GroupB")  
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async TakeAbTestReportbyListId(listTestsId){
        try {
            let URL = `${ApiConstants.URL}Report/TakeAbTestReportbyListId`;

            const body = {
                "TotalIds": listTestsId,
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async TakeAbTestReportbyId(testId){
        try {
            let URL = `${ApiConstants.URL}Report/TakeAbTestReportbyId`;

            const body = {
                "TestId": testId,
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async RemoveABReport(Id){
        try {
            let URL = `${ApiConstants.URL}Report/RemoveABReport`;

            const body = {
                "TestId": Id,
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async RemoveABConfigs(element){
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/RemoveABConfig`;

            const body = element

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async UpdatePauseConfig(element){
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/UpdatePauseConfig`;

            const body = element

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async GetABConfigs(domain,actualDate){
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/AbConfigs`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Domain": domain,
                "ActualTime": actualDate             
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async GetReportingABTesting(domain, actualDate){
        try {
            let URL = `${ApiConstants.URL}GroupConfiguration/ActualAndPastAbConfigs`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Domain": domain,
                "ActualTime": actualDate             
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }


    static async sendAnswers(tickedId, answers, domain){
        try {
            let URL = `${ApiConstants.URL}BigId/Answers`;

            const body = {
                "TicketId": tickedId,
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Domain" : domain,
                "Group" : Auth.getCurrentConfigurations().Name,
                "Parameters": answers
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {
            console.error(e);
        }
    }

    static async getDomains(){
        try {
            let URL = `${ApiConstants.URL}Auth/GetDomains`;

            const request = {
                method: "GET",
                headers: {
                    'Content-type' : 'application/json'
                },
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {
        }
    }

    static async generateTestABReport(testId) {
        try {
            let URL = `${ApiConstants.URL}Report/GenerateAbTestReport`;

            const body = {
                "TestId" :  testId
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }

    }

    static async getReportData(startDate, endDate, groupName, domain, endpoint) {
        try {
            let URL = `${ApiConstants.URL}Report/${endpoint}`;

            const body = {
                "StartDate" :  startDate,
                "EndDate"   :  endDate,
                "GroupName" :  groupName,
                "Domain"    :  domain
            }

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            }

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);
            API.logoutIfNotAuthenticated(response);

            const result = await response.json();
            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                }
            }

            return result;

        } catch (e) {
            console.log(e);
        }
    }

    static async generateABTesting(StartDate, EndDate, GroupA, GroupB, Domain){
        try {
            let URL = `${ApiConstants.URL}Report/GenerateABTesting`;

            const body = {
                "StartDate" : StartDate,
                "EndDate"   : EndDate,
                "GroupA"    : GroupA,
                "GroupB"    : GroupB,
                "Domain"    : Domain
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return result;

        } catch (e) {

        }
    }

    static async getEvent(ticketId){
        try {
            let URL = ApiConstants.URL + "BigId/Events";

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "TicketId" : ticketId
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {

        }
    }

    static async sendSMS(phone){
        try {
            let URL = `${ApiConstants.URL}BigId/SendSMS`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Phone" : phone
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {
            console.log(e);
        }
    }

    static async CheckSMS(ticketId, code){
        try {
            let URL = `${ApiConstants.URL}BigId/SMSCheck`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "TicketId" : ticketId,
                "Code" : code
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {
            console.log(e);
        }
    }

    static async GetSMSConfig(domain){
        try {
            let URL = `${ApiConstants.URL}BigId/FetchSMSConfig`;

            const body = {
                "Login": Auth.getSessionData().login,
                "Password" : Auth.getSessionData().password,
                "Domain"    :  domain
            };

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(body),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {
            console.log(e);
        }
    }
    
    static async SaveSMSConfig(smsConfigs){
        try {
            let URL = `${ApiConstants.URL}BigId/SaveSMSConfig`;

            const request = {
                method: "POST",
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify(smsConfigs),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);

            API.logoutIfNotAuthenticated(response);

            const result = await response.json();

            if (response.status !== 200) {
                return {
                    status: "ERROR",
                    errors: ["Problemas com a rede de acesso."],
                };
            }

            return JSON.parse(result);

        } catch (e) {
            console.log(e);
        }
    }
}

export default API;
