import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import Strings from '../constants/strings';
import ButtonSecondary from '../components/mui/ButtonSecondary';
import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

export default class ConfirmationDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            open: false
        }
    }

    componentWillReceiveProps(props){
        this.setState({open: props.open || false});
    }

    handleConfirm = () => {
        this.setState({open: false});
        if(this.props.onConfirm){
            this.props.onConfirm();
        }
    };

    handleCancel = () => {
        this.setState({open: false});
        if(this.props.onCancel) {
            this.props.onCancel();
        }
    };

    getActions(){
        return [
            <ButtonSecondary key={"confirm"}
                label={Strings.commands.CONFIRM[language]}                
                onClick={this.handleConfirm}
            />,
            <ButtonSecondary key={"cancel"}
                label={Strings.commands.CANCEL[language]}
                onClick={this.handleCancel}
            />,
        ];
    }

    onChildrenTouch = () => {
        this.setState({open: true});
    };

    render() {
        const childrenWithProps = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
                onClick: this.onChildrenTouch
            })
        });

        return (
            <div>
                {this.props.disabled ? this.props.children : childrenWithProps}

                <Dialog
                    fullScreen={this.props.fullScreen}
                    open={this.state.open}
                    onClose={this.props.handleRequestClose}
                    aria-labelledby="responseDialogTitle"
                >
                    <DialogTitle id="responseDialogTitle">
                        {this.props.dialogTitle || String.APP_NAME}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="confirmationDialogText">
                            {this.props.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {this.getActions()}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
