import React from 'react';
import ReactDOM from 'react-dom';
import Table from 'react-bootstrap/lib/Table';
import $ from 'jquery';


import Strings from '../../constants/strings';

// Material UI Imports
import {CircularProgress} from '@material-ui/core';
import SectionTitle from '../SectionTitle';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import API from '../../API';
import 'chart.piecelabel.js';
import Chart from 'chart.js';
import {Divider} from '@material-ui/core';

import moment from 'moment';
import ReactTooltip from "react-tooltip";


import KeyboardBackIcon from 'material-ui/svg-icons/hardware/keyboard-backspace';
import InfoIcon from 'material-ui/svg-icons/action/info-outline'

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css'; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import UserLanguage from '../configurations/UserLanguage.js';

import ButtonSecondary from '../mui/ButtonSecondary';

let language = UserLanguage.getLanguage();

const reportContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const reportFieldStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: '10px 0'
};

const reportFieldLabelStyle = {
    fontSize: "17px",
    fontWeight: "bold"
};

const reportFieldValueStyle = {
    fontSize: "17px",
    color: "#888"
};

const backTextButton = {
    textAlign: 'left',
    marginBottom: '20px',
    fontWeight: 'bolder',
    color: '#f5423b',
    fontSize: '16px',
    cursor: 'pointer',
    maxWidth: 'fit-content',
    display: 'flex'
}

const paperStyle = {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), rgba(0, 0, 0, 0.14) 0px 1px 3px 1px',
    padding: '20px',
    borderRadius: '10px'
}

const colorGroupA = '#0d92d2';

const colorGroupB = '#673ab7';

const colorGroupATransparent = '#0d92d28a';

const colorGroupBTransparent = '#673ab787';

const graphTitleStyle = {
    fontSize: '17px',
    color: '#666666',
    fontWeight: '750',
    fontFamily: 'sans-serif',
    textAlign: 'center'
}

const infoIconStyle = {
    width: '18px',
    height: '18px',
    marginBottom: '-3px',

}

class ABTestingReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            actualDate: moment(),
            finishTestAB: false,
            parameters: new Map(),
            productBGC: false,
            productQuestionnare: true,
            testAb: [],
            reportGenerated: false
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleChange = (field, newValue, event) => {
        const newState = {};
        newState[field] = newValue;
        this.setState(newState);
    };

    async handleSearchABTesting() {
        let result;

        result = await API.GetReportingABTesting(this.state.configurations.Domain, this.state.actualDate.format());

        result.testAbs.forEach(element => {

        });

        this.handleResponseSearchAbTesting(result);
    }

    async componentWillMount() {
        this.handleSearchABTesting();
    };



    onGenerate = async (element) => {

        this.setState({ generatingLoading: true, loading: true, error: null });

        let params = this.state.parameters;

        params.set("StartDate", element.StartDate);
        params.set("EndDate", element.EndDate);
        params.set("Domain", this.state.configurations.Domain);

        let testAbChoosen = element;
        let result = await API.TakeAbTestReportbyId(element.Id)
        this.setState({ reportGenerated: true })


        let data = {
            labels: [Strings.reports.APROVED[language]+" A", Strings.reports.DISAPROVED[language]+" A", Strings.reports.UNANSWERED[language]+" A", Strings.reports.BLOQUED_BY_VALIDATION[language]+" A", Strings.reports.INVALID_REQUESTS[language]+" A", Strings.reports.NOT_ENOUGH_INFO[language]+" A"],
            datasets: [
                {
                    label: "# de questionários",
                    data: [result.GroupA.ValidatedCount, result.GroupA.NotValidatedCount,
                    result.GroupA.NotAnsweredCount, result.GroupA.BlockedByValidations, result.GroupA.InvalidRequestCount,
                    result.GroupA.NotEnoughInformationCount
                    ],

                    backgroundColor: [
                        '#6EBEA2',
                        '#F6454C',
                        '#F8C261',
                        '#00B2DE',
                        '#A090C0',
                        'rgba(255, 159, 64, 1)'
                    ],
                }
            ]
        };

        let dataGroupB = {
            labels: [Strings.reports.APROVED[language]+" B", Strings.reports.DISAPROVED[language]+" B", Strings.reports.UNANSWERED[language]+" B", Strings.reports.BLOQUED_BY_VALIDATION[language]+" B", Strings.reports.INVALID_REQUESTS[language]+" B", Strings.reports.NOT_ENOUGH_INFO[language]+" B"],
            datasets: [
                {
                    label: "# de questionários",
                    data: [result.GroupB.ValidatedCount, result.GroupB.NotValidatedCount,
                    result.GroupB.NotAnsweredCount, result.GroupB.BlockedByValidations, result.GroupB.InvalidRequestCount,
                    result.GroupB.NotEnoughInformationCount
                    ],

                    backgroundColor: [
                        '#6EBEA2',
                        '#F6454C',
                        '#F8C261',
                        '#00B2DE',
                        '#A090C0',
                        'rgba(255, 159, 64, 1)'
                    ],
                }
            ]
        };

        let sharedBarData = {
            labels: [Strings.reports.APROVED[language], Strings.reports.DISAPROVED[language], Strings.reports.UNANSWERED[language], Strings.reports.BLOQUED_BY_VALIDATION[language], Strings.reports.INVALID_REQUESTS[language], Strings.reports.NOT_ENOUGH_INFO[language]],
            datasets: [{
                label: element.GroupA,
                data: [((result.GroupA.ValidatedCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.NotValidatedCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.NotAnsweredCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.BlockedByValidations / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.InvalidRequestCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.NotEnoughInformationCount / result.GroupA.TotalCount) * 100).toFixed(2)
                ],

                backgroundColor: colorGroupA
            },
            {
                label: element.GroupB,
                data: [((result.GroupB.ValidatedCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.NotValidatedCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.NotAnsweredCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.BlockedByValidations / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.InvalidRequestCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.NotEnoughInformationCount / result.GroupB.TotalCount) * 100).toFixed(2)
                ],

                backgroundColor: colorGroupB
            }
            ]

        }

        let dataRadar = {
            labels: [Strings.reports.APROVED[language], Strings.reports.DISAPROVED[language], Strings.reports.UNANSWERED[language], Strings.reports.BLOQUED_BY_VALIDATION[language], Strings.reports.INVALID_REQUESTS[language], Strings.reports.NOT_ENOUGH_INFO[language]],
            datasets: [{
                label: element.GroupA,
                data: [((result.GroupA.ValidatedCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.NotValidatedCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.NotAnsweredCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.BlockedByValidations / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.InvalidRequestCount / result.GroupA.TotalCount) * 100).toFixed(2),
                ((result.GroupA.NotEnoughInformationCount / result.GroupA.TotalCount) * 100).toFixed(2)],
                backgroundColor: colorGroupATransparent
            },
            {
                label: element.GroupB,
                data: [((result.GroupB.ValidatedCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.NotValidatedCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.NotAnsweredCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.BlockedByValidations / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.InvalidRequestCount / result.GroupB.TotalCount) * 100).toFixed(2),
                ((result.GroupB.NotEnoughInformationCount / result.GroupB.TotalCount) * 100).toFixed(2)],
                backgroundColor: colorGroupBTransparent

            }]
        }

        let canvasGroupA = ReactDOM.findDOMNode(this.refs.chartGroupA);
        let canvasGroupB = ReactDOM.findDOMNode(this.refs.chartGroupB);
        let canvasStackBar = ReactDOM.findDOMNode(this.refs.chartStackBar);
        let canvasRadar = ReactDOM.findDOMNode(this.refs.chartRadar);

        if (this.state.chartGroupA) {
            this.state.chartGroupA.destroy();
        }

        if (this.state.chartGroupB) {
            this.state.chartGroupB.destroy();
        }

        if (this.state.chartStackBar) {
            this.state.chartStackBar.destroy();
        }

        if (this.state.chartRadar) {
            this.state.chartRadar.destroy();
        }

        let chartGroupA = new Chart(canvasGroupA, {
            type: 'pie',
            data: data,
            options: {
                title: {
                    display: true,
                    text: result.GroupA.GroupName,
                    fontSize: '14'
                },
                legend: {
                    position: "bottom",
                    labels: {
                        fontSize: 15,
                        boxWidth: 20,
                    }
                },

                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let allData = data.datasets[tooltipItem.datasetIndex].data;
                            let tooltipLabel = data.labels[tooltipItem.index];
                            let tooltipData = allData[tooltipItem.index];
                            let total = 0;
                            for (let i in allData) {
                                total += allData[i];
                            }
                            let tooltipPercentage = Math.round((tooltipData / total) * 100);
                            return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                        }
                    }
                }
            }
        });


        let chartGroupB = new Chart(canvasGroupB, {
            type: 'pie',
            data: dataGroupB,
            options: {
                title: {
                    display: true,
                    text: result.GroupB.GroupName,
                    fontSize: '14'
                },
                labels: {
                    render: 'percentage'
                },
                legend: {
                    position: "bottom",
                    labels: {
                        fontSize: 15,
                        boxWidth: 20,
                    }
                },

                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, dataGroupB) {
                            let allData = dataGroupB.datasets[tooltipItem.datasetIndex].data;
                            let tooltipLabel = dataGroupB.labels[tooltipItem.index];
                            let tooltipData = allData[tooltipItem.index];
                            let total = 0;
                            for (let i in allData) {
                                total += allData[i];
                            }
                            let tooltipPercentage = Math.round((tooltipData / total) * 100);
                            return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                        }
                    }
                }
            }
        });

        let chartStackBar = new Chart(canvasStackBar, {
            type: 'bar',
            data: sharedBarData,
            options: {
                tooltips: {
                    mode: 'point'
                },
                legend: {
                    position: "bottom",
                    labels: {
                        fontSize: 15,
                        boxWidth: 20,
                    }
                },
                scales: {
                    pointLabels: {
                        fontSize: 15
                    },
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: 100
                        }
                    }]
                }
            },
        });

        let chartRadar = new Chart(canvasRadar, {

            type: 'radar',
            data: dataRadar,

            options: {
                legend: {
                    position: "bottom",
                    labels: {
                        fontSize: 15,
                        boxWidth: 20,
                    }
                },
                scale: {
                    position: 'bottom',
                    pointLabels: {
                        fontSize: 15
                    }
                }
            }
        });

        //change size dinamically with jQuery
        $('#radar').height("550px");


        this.setState({
            generatingLoading: false, report: result, chartGroupB: chartGroupB,
            chartGroupA: chartGroupA, chartStackBar: chartStackBar, chartRadar: chartRadar, testAbChoosen: testAbChoosen
        })
    };

    backButton() {
        if (this.state) {
            const newState = this.state;
            if (newState.reportGenerated) {
                this.setState({ reportGenerated: false });
            } else {
                this.setState({ reportGenerated: true });
            }
        }
    }

    // -- Handlers to Date --//
    handleChangeendDate = (field, newValue, event) => {
        const newState = this.state;
        if (newValue > newState.startDate)
            newState.endDate = newValue;
        this.setState(newState);
    };
    handleChangestartDate = (field, newValue, event) => {
        const newState = this.state;
        if (newValue <= newState.endDate)
            newState.startDate = newValue;
        this.setState(newState);
    };

    handleLoadingSearchABTesting() {
        this.setState({
            generatingLoading: false,
            finishTestAB: true,
        });
    }

    handleResponseSearchAbTesting(result) {
        this.setState({
            generatingLoading: false,
            testAb: result.testAbs,
            finishTestAB: true,
            disableButton: false
        });
    }




    // -- RENDER -- //
    renderReportField(label, value) {
        return (
            <div style={reportFieldStyle}>
                <div style={reportFieldLabelStyle}>{label}:</div>

                <div style={reportFieldValueStyle}>
                    {value}
                </div>
            </div>
        )
    }

    renderReportSubField(label, value) {
        return (
            <div style={reportFieldStyle}>
                <div>{label}:</div>

                <div>
                    {value}
                </div>
            </div>
        )
    }

    //Render Fields of the pizza report
    renderReportPizza(group) {
        if (this.state.report) {

            let report;
            let letter;
            if (group === 'GroupA') {
                report = this.state.report.GroupA;
                letter = 'A';
            } else {
                report = this.state.report.GroupB;
                letter = 'B';
            }
            // Object.keys(this.state.reportGroupA).forEach(function(item, index){
            //     a.push( item)}
            // ); pra q serve isso?
            return (

                <div style={{ marginTop: "30px" }}>

                    {this.renderReportField(Strings.reports.APROVED[language] + letter, report.ValidatedCount)}

                    {this.renderReportField(Strings.reports.DISAPROVED[language] + letter, report.NotValidatedCount)}

                    {this.renderReportField(Strings.reports.UNANSWERED[language] + letter, report.NotAnsweredCount)}

                    {this.renderReportField(Strings.reports.BLOQUED_BY_VALIDATION[language] + letter, report.BlockedByValidations)}

                    {this.renderReportField(Strings.reports.INVALID_REQUESTS[language] + letter, report.InvalidRequestCount)}

                    {this.renderReportField(Strings.reports.NOT_ENOUGH_INFO[language] + letter, report.NotEnoughInformationCount)}

                    {this.renderReportField(Strings.reports.TOTAL_REQUESTS[language] + letter, report.TotalCount)}

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    {this.renderReportSubField(Strings.reports.AVERAGE_GENERATION_TIME[language] + letter, report.AvgGTime + 's')}

                    {this.renderReportSubField(Strings.reports.AVERAGE_ANSWER_TIME[language] + letter, report.AvgATime + 's')}
                </div>
            )
        }
    }


    renderPercentTable(groupA, groupB) {

        if (this.state.report) {
            let reportA;
            let reportB;
            if (groupA === 'GroupA') {
                reportA = this.state.report.GroupA;
            }
            if (groupB === 'GroupB') {
                reportB = this.state.report.GroupB;
            }
            let aprovedA = ((reportA.ValidatedCount / reportA.TotalCount) * 100).toFixed(2);
            let aprovedB = ((reportB.ValidatedCount / reportB.TotalCount) * 100).toFixed(2);
            let reprovedA = ((reportA.NotValidatedCount / reportA.TotalCount) * 100).toFixed(2);
            let reprovedB = ((reportB.NotValidatedCount / reportB.TotalCount) * 100).toFixed(2);
            let notAnsweredA = ((reportA.NotAnsweredCount / reportA.TotalCount) * 100).toFixed(2);
            let notAnsweredB = ((reportB.NotAnsweredCount / reportB.TotalCount) * 100).toFixed(2);
            let blockedValidationsA = ((reportA.BlockedByValidations / reportA.TotalCount) * 100).toFixed(2);
            let blockedValidationsB = ((reportB.BlockedByValidations / reportB.TotalCount) * 100).toFixed(2);
            let invalidRequestA = ((reportA.InvalidRequestCount / reportA.TotalCount) * 100).toFixed(2);
            let invalidRequestB = ((reportB.InvalidRequestCount / reportB.TotalCount) * 100).toFixed(2);
            let notEnoughA = ((reportA.NotEnoughInformationCount / reportA.TotalCount) * 100).toFixed(2);
            let notEnoughB = ((reportB.NotEnoughInformationCount / reportB.TotalCount) * 100).toFixed(2);
            let totalA = ((reportA.TotalCount / reportA.TotalCount) * 100).toFixed(2);
            let totalB = ((reportB.TotalCount / reportB.TotalCount) * 100).toFixed(2);

            return (
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Status </th>
                            <th>{reportA.GroupName} {Strings.reports.QTD[language]}</th>
                            <th>{reportA.GroupName} {Strings.reports.PERCENT_OF_TOTAL[language]}</th>
                            <th>{reportB.GroupName} {Strings.reports.QTD[language]}</th>
                            <th>{reportB.GroupName} {Strings.reports.PERCENT_OF_TOTAL[language]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td align="left"> {Strings.reports.APROVED[language]} </td>
                        <td > {reportA.ValidatedCount}</td>
                        <td > {aprovedA} %</td>
                        <td > {reportB.ValidatedCount}</td>
                        <td > {aprovedB} %</td>
                    </tbody>

                    <tbody>
                        <td > {Strings.reports.DISAPROVED[language]}</td>
                        <td > {reportA.NotValidatedCount}</td>
                        <td > {reprovedA} %</td>
                        <td > {reportB.NotValidatedCount}</td>
                        <td > {reprovedB} %</td>
                    </tbody>

                    <tbody>
                        <td>{Strings.reports.UNANSWERED[language]}</td>
                        <td > {reportA.NotAnsweredCount}</td>
                        <td > {notAnsweredA} %</td>
                        <td > {reportB.NotAnsweredCount}</td>
                        <td > {notAnsweredB} %</td>
                    </tbody>

                    <tbody>
                        <td>{Strings.reports.BLOQUED_BY_VALIDATION[language]}</td>
                        <td > {reportA.BlockedByValidations}</td>
                        <td > {blockedValidationsA} %</td>
                        <td > {reportB.BlockedByValidations}</td>
                        <td > {blockedValidationsB} %</td>
                    </tbody>

                    <tbody>
                        <td>{Strings.reports.INVALID_REQUESTS[language]}</td>
                        <td > {reportA.InvalidRequestCount}</td>
                        <td > {invalidRequestA} %</td>
                        <td > {reportB.InvalidRequestCount}</td>
                        <td > {invalidRequestB} %</td>
                    </tbody>

                    <tbody>
                        <td>{Strings.reports.NOT_ENOUGH_INFO[language]}</td>
                        <td > {reportA.NotEnoughInformationCount}</td>
                        <td > {notEnoughA} %</td>
                        <td > {reportB.NotEnoughInformationCount}</td>
                        <td > {notEnoughB} %</td>
                    </tbody>

                    <tbody>
                        <td>{Strings.reports.TOTAL_REQUESTS[language]}</td>
                        <td > {reportA.TotalCount}</td>
                        <td > {totalA} %</td>
                        <td > {reportB.TotalCount}</td>
                        <td > {totalB} %</td>
                    </tbody>
                </Table>
            )
        }
    }

    renderReportHeader() {
        if (this.state.report) {
            let testAB = this.state.testAbChoosen;
            let testABReport = this.state.report;
            let today = new Date();
            let startDate = new Date(testAB.StartDate);
            let endDate = new Date(testAB.EndDate);
            let days;
            if (endDate <= today) {
                days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
            } else {
                days = (today.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
            }
            return (
                <div>
                    <div style={backTextButton} onClick={this.backButton.bind(this)}>
                        <KeyboardBackIcon style={{ height: '22px', width: '22px', color: '#f5423b', marginRight: '5px' }} /> {Strings.generalConfigurations.BACK[language]}
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div style={{ ...paperStyle }}>
                            <h3 style={{ color: 'rgb(0, 145, 137)', fontWeight: '900', fontSize: '20px', marginTop: '0px', marginBottom: '20px' }}>
                            {Strings.reports.AB_TEST_REPORT_TITLE[language]}
                    </h3>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '25%' }}>
                                    <h3 style={{ margin: '0px', marginTop: '5px', color: 'black' }}>
                                        {testABReport.GroupA.TotalCount + testABReport.GroupB.TotalCount}
                                    </h3>
                                    <p style={{ margin: '0px', fontSize: '10px', fontWeight: '900' }}>{Strings.reports.TOTAL_REQUESTS[language]}</p>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <h3 style={{ margin: '0px', marginTop: '5px', color: 'black' }}>
                                        {testAB.RequestPercentA}<span style={{ fontSize: '11px' }}>%</span>
                                    </h3>
                                    <p style={{ margin: '0px', fontSize: '10px', fontWeight: '900' }}>{testABReport.GroupA.GroupName}</p>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <h3 style={{ margin: '0px', marginTop: '5px', color: 'black' }}>
                                        {testAB.RequestPercentB}<span style={{ fontSize: '11px' }}>%</span>
                                    </h3>
                                    <p style={{ margin: '0px', fontSize: '10px', fontWeight: '900' }}>{testABReport.GroupB.GroupName}</p>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <h3 style={{ margin: '0px', marginTop: '5px', color: 'black' }}>
                                        {days.toFixed(0)}
                                    </h3>
                                    <p style={{ margin: '0px', fontSize: '10px', fontWeight: '900' }}>{Strings.reports.EXEC_DAYS[language]}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '50px', display: 'flex', width: '100%', color: 'white' }}>
                            <div style={{ ...paperStyle, width: '50%', backgroundColor: colorGroupA }}>
                                <h4 style={{ marginBottom: '20px', fontWeight: 'bolder' }}>{testABReport.GroupA.GroupName}</h4>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '100%' }}>
                                        <h3 style={{ margin: '0px', marginTop: '5px', color: 'white' }}>{testABReport.GroupA.TotalCount}</h3>
                                        <p style={{ margin: '0px', fontSize: '10px', fontWeight: '900' }}>{Strings.reports.THIS_GROUP_REQUEST[language]}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...paperStyle, width: '50%', marginLeft: '10px', backgroundColor: colorGroupB, color: 'white' }}>
                                <div>
                                    <h4 style={{ marginBottom: '20px', fontWeight: 'bolder' }}>{testABReport.GroupB.GroupName}</h4>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '100%' }}>
                                            <h3 style={{ margin: '0px', marginTop: '5px', color: 'white' }}>{testABReport.GroupB.TotalCount}</h3>
                                            <p style={{ margin: '0px', fontSize: '10px', fontWeight: '900' }}>{Strings.reports.THIS_GROUP_REQUEST[language]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }


    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} />
        )
    }

    renderErrors() {
        if (this.state.error) {
            return (
                <div>
                    {this.state.error}
                </div>
            )
        }
    }

    handleSelectChangeMap = (name, name2, event, key, newValue) => {
        event.preventDefault();
        const newState = this.state;
        newState.parameters.set(name, newValue)
        this.setState(newState);

    };

    button_minus(){
        return (
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus">
                                            <line x1="5" y1="12" x2="19" y2="12">
        </line></svg>
        </div>        
        )
    }

    check_final_date(element){
        if (!element.UseDates){
            return true;
        }
        return false;
    }

    check_error = (state) => {
        if (state.parameters === "GroupA" && state.parameters === "GroupB") {
            const groupAValue = state.parameters.get("GroupA");
            const groupBValue = state.parameters.get("GroupB");
            if (groupAValue === groupBValue)
                return Strings.reports.SAME_GROUP_ALERT[language]
        }
    };

    render() {

        let showList = {
            display: (this.state.reportGenerated ? "none" : "block"),
            width: '80%'
        };

        let showReport = {
            display: (this.state.reportGenerated ? "block" : "none")
        };

        //const errors = this.check_error(this.state);
        return (
            <div>
                <div style={showList}>

                    <SectionTitle text={Strings.reports.AB_TEST_REPORT_TITLE[language]}/>

                    <h4 id='title'>{Strings.reports.AB_TEST_LIST_TITLE[language]}</h4>
                    {this.renderLoading(this.state.generatingLoading)}
                    <div>
                        {this.state && this.state.finishTestAB &&
                            <Table>
                                <thead>
                                    <tr>
                                        <th>{Strings.reports.FIRST_GROUP[language]}</th>
                                        <th>{Strings.reports.SECOND_GROUP[language]}</th>
                                        <th>{Strings.reports.PRODUCT[language]}</th>
                                        <th>{Strings.reports.BEGIN[language]}</th>
                                        <th>{Strings.reports.END[language]}</th>
                                        <th>{Strings.reports.REQUESTS_LIMIT[language]}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.testAb.map((element, index) => {
                                            if(this.props.repType === "BGC" && !element.QuestionnaireTest){
                                                let actualDate = moment();
                                                return (
                                                    <tr style={{ width: "100%" }} key={index}>
                                                        <td>{element.GroupA}</td>
                                                        <td>{element.GroupB}</td>
                                                        <td>{this.returnProductName(element)}</td>
                                                        <td>{element.StartDate.replace('T', ' ').replace('Z', '')}</td>
                                                        <td>{this.check_final_date(element) ? this.button_minus() : element.EndDate.replace('T', ' ').replace('Z', '')}</td>
                                                        <td style={{width:'10%' , maxWidth:'10%'}}>{(element.RequestQty === 0) ? this.button_minus() : element.RequestQty}</td>   
                                                        <td> <ButtonSecondary key="generate" type="submit" disabled={element.StartDate > actualDate.format()} onClick={() => { this.onGenerate(element) }} label={Strings.reports.GENERATE_REPORT[language]} /></td>                                               
                                                    </tr>
                                                )
                                            }else if(this.props.repType === "Quest" && element.QuestionnaireTest){
                                                let actualDate = moment();
                                                return (
                                                    <tr style={{ width: "100%" }} key={index}>
                                                        <td>{element.GroupA}</td>
                                                        <td>{element.GroupB}</td>
                                                        <td>{this.returnProductName(element)}</td>
                                                        <td>{element.StartDate.replace('T', ' ').replace('Z', '')}</td>
                                                        <td>{this.check_final_date(element) ? this.button_minus() : element.EndDate.replace('T', ' ').replace('Z', '')}</td>
                                                        <td style={{width:'10%' , maxWidth:'10%'}}>{(element.RequestQty === 0) ? this.button_minus() : element.RequestQty}</td>   
                                                        <td> <ButtonSecondary key="generate" type="submit" disabled={element.StartDate > actualDate.format()} onClick={() => { this.onGenerate(element) }} label={Strings.reports.GENERATE_REPORT[language]} /></td>                                               
                                                    </tr>
                                                )
                                            }
                                            
                                        }
                                        )}

                                </tbody>
                            </Table>
                        }

                    </div>
                </div>


                <div style={Object.assign({}, reportContainerStyle, showReport)}>
                    <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                        {this.renderReportHeader()}
                    </div>
                    <div style={{ width: '90%', alignText: 'center', margin: 'auto', marginTop: '50px', marginBottom: '100px' }}>
                        {this.renderPercentTable('GroupA', 'GroupB')}
                    </div>
                    <p style={graphTitleStyle}>{Strings.PIE_GRAPHIC_TITLE[language]}<InfoIcon style={infoIconStyle} data-tip={Strings.tooltips.REPORT_PIE_GRAPHIC[language]} /></p>
                    <ReactTooltip />
                    <div style={{ display: 'flex', marginTop: '30px' }}>
                        <div style={{ width: '50%' }}>
                            <canvas ref="chartGroupA" width="0" height="450px"></canvas>
                        </div>
                        <div style={{ width: '50%' }}>
                            <canvas ref="chartGroupB" width="0" height="450px"></canvas>
                        </div>
                    </div>
                    <p style={{ ...graphTitleStyle, marginTop: '70px' }}>{Strings.RADAR_GRAPHIC_TITLE[language]}<InfoIcon style={infoIconStyle} data-tip={Strings.tooltips.REPORT_RADAR_GRAPHIC[language]} /></p>
                    <ReactTooltip />
                    <div style={{ width: '100%', marginTop: '10px', marginBottom: '80px' }}>
                        <canvas id="radar" ref="chartRadar" width="0" height="500px"></canvas>
                    </div>
                    <p style={{ ...graphTitleStyle, marginTop: '70px' }}>{Strings.BAR_GRAPHIC_TITLE[language]}<InfoIcon style={infoIconStyle} data-tip={Strings.tooltips.REPORT_BAR_GRAPHIC[language]} /></p>
                    <ReactTooltip />
                    <div style={{ width: '100%', marginTop: '10px' }}>
                        <canvas ref="chartStackBar" width="0" height="350px"></canvas>
                    </div>
                </div>



                <br />

            </div>
        )
    }

    returnProductName(element) {
        if (element.QuestionnaireTest) {
            return Strings.SIDE_MENU_TRANSLATION.QUESTIONÁRIO[language];
        } else {
            return "BackgroundCheck";
        }
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.selectedDomain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ABTestingReport);
