import React, { Component } from 'react';

import Strings from '../../constants/strings';
import SectionTitle from '../SectionTitle';


// Material UI Imports
import TextField from '../../components/mui/TextField';
import { MenuItem } from '@material-ui/core';
import Toggle from '../../components/mui/Toggle';
import DatePicker from 'react-datetime';
import Table from 'react-bootstrap/lib/Table'
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Button } from '@material-ui/core';
import ButtonSecondary from '../mui/ButtonSecondary';
import Dialog from 'material-ui/Dialog';
import DialogABTest from './DialogABTest';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever';
// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import API from '../../API';

import Auth from '../../Authentication';
import moment from 'moment';
import SelectField from '@material-ui/core/Select';
import colors from '../../constants/colors';
import UserLanguage from '../configurations/UserLanguage.js';
import ArrowBack from '@material-ui/icons/ArrowBack';

let language = UserLanguage.getLanguage();

const cardContainerStyle = {
    display: 'block',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxShadow: null
};

const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

const columnStyle = {
    width: '600px'
};


const toggleStyle = {
    // width: '100%',
    marginRight: '330px'
};

const backTextButton = {
    textAlign: 'left',
    marginBottom: '20px',
    fontWeight: 'bolder',
    color: '#F5423B',
    fontSize: '16px',
    cursor: 'pointer',
    maxWidth: 'fit-content',
    display: 'flex'
};
const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

class ABTesting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            menuActive: Strings.generalConfigurations.menu.GENERAL,
            parameters: new Map(),
            startDate: moment(),
            endDate: moment().add(1, 'days'),
            productBGC: false,
            productQuestionnare: true,
            generatingLoading: true,
            finishTestAB: false,
            productName: '',
            showModal: false,
            testAb: [],
            disableButton: true,
            componentUpdate: false,
            toggleDate: false,
            toggleQty: false,
            finishSearchTestAB: false,
            createTestAB: false,
            sizeOfSample: "",
            confiabilityList: [
                "80", "85", "90", "95", "99"
            ],
            confiabilityValue: "",
            marginError: "",
            tableConfiability: {
                "80": "1.28",
                "85": "1.44",
                "90": "1.65",
                "95": "1.96",
                "99": "2.58"
            }
        }

    }

    componentWillReceiveProps(newProps) {
        if (newProps.configurations.Domain != this.state.configurations.Domain) {
            this.setState({
                configurations: newProps.configurations,
                testAb: [],
                parameters: new Map(),
                finishTestAB: false,
                generatingLoading: true,
                disableButton: true,
                finishSearchTestAB: true,
                componentUpdate: true
            });
        }

    }

    handleLoadingSearchABTesting() {
        this.setState({
            generatingLoading: false,
            finishTestAB: true,
            disableButton: false
        });
    }

    handleResponseSearchAbTesting(result) {
        this.setState({
            testAb: result.testAbs,
            finishSearchTestAB: true
        });
    }

    handleFinalResponse(newState) {
        this.setState({
            generatingLoading: false,
            testAb: newState.testAb,
            finishTestAB: true,
            disableButton: false,
            finishSearchTestAB: false
        })
    }

    async handleTotalRequests() {
        let ids = []
        let result = null;
        this.state.testAb.map((element, index) => {
            if (element.UseRequestQty && !(moment(element.StartDate) > moment().utc())) {
                ids.push(element.Id)
            }
        })

        result = await API.TakeAbTestReportbyListId(ids)

        if (result != null) {
            result.map((report, indexId) => {
                this.state.testAb.map((element, indexEl) => {
                    if (element.Id === report.TestId) {
                        element["TotalRequest"] = report.TotalRequest
                    }
                })
            })
        }

        this.handleFinalResponse(this.state);
    }

    async handleSearchABTesting() {
        let result;
        var resultproductName;

        result = await API.GetABConfigs(this.state.configurations.Domain, moment().utc().format());
        result.testAbs.forEach(element => {

            if (element.BackgroundCheckTest && element.QuestionnaireTest) {
                resultproductName = "Questionário/BGC"
            } else if (element.QuestionnaireTest) {
                resultproductName = "Questionário"
            } else if (element.BackgroundCheckTest) {
                resultproductName = "BGC"
            }
            element["Products"] = resultproductName
        });
        this.handleResponseSearchAbTesting(result);
    }

    async componentWillMount() {

        this.handleSearchABTesting();

        if (this.state.finishSearchTestAB) {
            this.handleTotalRequests();
        }

    };

    handleOpenModal = (event) => {
        this.setState({ showModal: true });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }



    // -- RENDER -- //
    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "400px" }} thickness={3.5} size={50} />
        )
    }

    handleRemoveElement = async (index, element) => {
        this.setState({ generatingLoading: true });

        let result = await API.RemoveABConfigs(element);

        this.state.testAb.splice(index, 1)

        let resultReport = await API.RemoveABReport(element.Id)

        this.setState({
            testAb: this.state.testAb,
            generatingLoading: false
        })

    }

    checkStatus(status) {

        if (status == Strings.AB_TEST_STATUS.FINISHED[language] || status == Strings.AB_TEST_STATUS.WAITING[language])
            return false
        else
            return true
    }

    handlePauseOrInitiateElement = async (index, element) => {
        this.setState({ generatingLoading: true });

        let futureElement = Object.assign({}, element);
        futureElement.OnPause = !futureElement.OnPause

        let result = await API.UpdatePauseConfig(futureElement);

        element.OnPause = !element.OnPause

        this.state.testAb[index] = element

        this.setState({
            testAb: this.state.testAb,
            generatingLoading: false
        })
    }

    inRange(x, min, max) {
        return ((x - min) * (x - max) <= 0);
    }

    checkVolumeFinished(newProductQuest, newProductBGC, newGroupA, newGroupB) {
        let result = true
        this.state.testAb.forEach(element => {
            const oldGroupA = element.GroupA;
            const oldGroupB = element.GroupB;
            const oldproductQuest = element.QuestionnaireTest
            const oldproductBGC = element.BackgroundCheckTest

            if ((oldGroupA == newGroupA || oldGroupA == newGroupB) || (oldGroupB == newGroupA || oldGroupB == newGroupB)) {
                if (oldproductQuest == newProductQuest || oldproductBGC == newProductBGC){                   
                    if (!element.VolumeFinished) {
                        result = false;
                    }
                }

            }
        })
        return result;
    };

    checkGroupsDatesandProducts(groupA, groupB, initialDate, finalDate, newProductQuest, newProductBGC) {

        let initialDateFormat;
        let finalDateFormat;
        let result = true;
        initialDateFormat = new Date(initialDate);
        finalDateFormat = new Date(finalDate);
        this.state.testAb.forEach(element => {
            const oldGroupA = element.GroupA;
            const oldGroupB = element.GroupB;
            const oldproductQuest = element.QuestionnaireTest
            const oldproductBGC = element.BackgroundCheckTest

            if (((oldGroupA == groupA || oldGroupB == groupA) || (oldGroupA == groupB || oldGroupB == groupB))) {
                if (oldproductQuest == newProductQuest || oldproductBGC == newProductBGC)
                {
                    let oldStartDate = new Date(element.StartDate)
                    let oldEndDate = new Date(element.EndDate);
                    if (initialDateFormat >= oldStartDate && initialDateFormat <= oldEndDate) {
                        result = false;
                    }
                    if (finalDateFormat >= oldStartDate && finalDateFormat <= oldEndDate) {
                        result = false;
                    }
                    if (oldStartDate >= initialDateFormat && oldStartDate <= finalDateFormat) {
                        result = false;
                    }
                    if (oldEndDate >= initialDateFormat && oldEndDate <= finalDateFormat) {
                        result = false;
                    }
                }
            }
        })

        return result;

    };


    onGenerate = async () => {
        this.setState({
            generatingLoading: true,
            disableButton: true
        });
        let result = null;
        let params = this.state.parameters;

        // Check if params has at least 4 fields fild //
        if (params.size < 4) {
            alert(Strings.AB_TEST_ALERTS.FIELDS_FILL_IN_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        if (!params.has("GroupA" || !params.has("GroupB"))) {
            alert(Strings.AB_TEST_ALERTS.CHOOSE_GROUPS_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        const paramGroupA = params.get("GroupA");
        const paramGroupB = params.get("GroupB");
        const percentGroupA = params.get("RequestPercentA");
        const percentGroupB = params.get("RequestPercentB");
        if (paramGroupA == paramGroupB) {
            alert(Strings.AB_TEST_ALERTS.SAME_GROUPS_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        if (isNaN(Number(percentGroupA)) || isNaN(Number(percentGroupB))) {
            alert(Strings.AB_TEST_ALERTS.NUMERIC_FIELDS_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }

        // Get the difference between timezone and UTC in minutes, divide by 60 to get hours
        let utcOffSetStartDate = this.state.startDate.utcOffset() / 60
        let utcOffSetEndDate = this.state.endDate.utcOffset() / 60

        // Create local variables to subtract offSet
        let getStartDate = this.state.startDate.clone()
        let getEndDate = this.state.endDate.clone()

        // Add the difference between local date and UTC to get the valid comparation
        let startDateUTC = getStartDate.subtract(utcOffSetStartDate, "hours")
        let endDateUTC = getEndDate.subtract(utcOffSetEndDate, "hours")

        // Get the StartDate and EndDate dates in string format
        let startDate = startDateUTC.format();
        let endDate = endDateUTC.format();

        const productQuestionnare = this.state.productQuestionnare.toString();
        const productBGC = this.state.productBGC.toString();

        if (!this.state.productQuestionnare && !this.state.productBGC) {
            alert(Strings.AB_TEST_ALERTS.CHOOSE_PRODUCT_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        if (!this.state.toggleDate && !this.state.toggleQty) {
            alert(Strings.AB_TEST_ALERTS.CHOOSE_END_CONDITION_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        // Compare if StartDate and EndDate are before the moment
        if (startDateUTC.isBefore(moment().utc().subtract(1, "minutes")) || endDateUTC.isBefore(moment().utc().subtract(1, "minutes"))) {
            alert(Strings.AB_TEST_ALERTS.DATES_GRATER_THAN_TODAY_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        if (this.state.toggleDate) {
            if (startDateUTC > endDateUTC) {
                alert(Strings.AB_TEST_ALERTS.END_GREATER_THAN_START_ALERT[language]);
                this.handleLoadingSearchABTesting();
                return;
            }
            if (!this.checkGroupsDatesandProducts(paramGroupA, paramGroupB, startDate, endDate, productQuestionnare, productBGC)) {
                alert(Strings.AB_TEST_ALERTS.AB_TEST_ALREADY_EXISTS_ALERT[language]);
                this.handleLoadingSearchABTesting();
                return;
            }
        }
        if (!this.checkVolumeFinished(this.state.productQuestionnare, this.state.productBGC, paramGroupA, paramGroupB)) {
            alert(Strings.AB_TEST_ALERTS.AB_TEST_ALREADY_ACTIVE_ALERT[language]);
            this.handleLoadingSearchABTesting();
            return;
        }
        if (!this.state.toggleQty) {
            params.set("RequestQty", 0)
        }
        if (this.state.toggleQty && !this.state.toggleDate) {
            params.set("EndDate", "9999-12-31T23:59:59.999999Z")
        }
        // Add all params to params dictionary
        params.set("EndDate", endDate);
        params.set("StartDate", startDate);
        params.set("QuestionnaireTest", productQuestionnare);
        params.set("BackgroundCheckTest", productBGC);
        params.set("Domain", this.state.configurations.Domain);
        params.set("UseDates", this.state.toggleDate.toString());
        params.set("UseRequestQty", this.state.toggleQty.toString());
        params.set("VolumeFinished", "false")

        if (this.state.productQuestionnare) {
            params.set("BackgroundCheckTest", "false")
            params.set("QuestionnaireTest", "true")
            params.set("Product","Questionnare")
            result = await API.InsertABConfigs(params);
        }
        else if (this.state.productBGC) {
            params.set("QuestionnaireTest", "false")
            params.set("BackgroundCheckTest", "true")
            params.set("Product","BGC")
            result = await API.InsertABConfigs(params);
        }

        let resultReport = await API.GenerateAbTestReport(params, result.Id)

        this.handleSearchABTesting();

        if (this.state.finishSearchTestAB) {
            this.handleTotalRequests();
        }

        alert(Strings.AB_TEST_ALERTS.AB_TEST_CREATED_ALERT[language])

        this.setState({
            disableButton: false,
        })
    };

    // -- HANDLERS -- //
    handleChangeendDate = (field, newValue, event) => {
        const newState = this.state;
        newState.endDate = newValue;
        this.setState(newState);
    };

    handleChangestartDate = (field, newValue, event) => {
        const newState = this.state;
        newState.startDate = newValue;
        this.setState(newState);
    };

    handleSizeofSample = (event) => {
        const newState = this.state;
        if (event.target.value < 0) {
            newState.sizeOfSample = 0;
        } else {
            newState.sizeOfSample = event.target.value;
        }
        this.setState(newState);
    }

    getValueOfSample() {
        const marginError = Number(this.state.marginError) / 100
        const sampleLarge = Number(this.state.sizeOfSample)
        const confiabilityZ = Number(this.state.tableConfiability[this.state.confiabilityValue])
        const pValue = 0.5;

        const confiabilityElevated2 = Math.pow(confiabilityZ, 2);
        const ptimespminus1 = pValue * (1 - pValue);
        const marginErrorElevated2 = Math.pow(marginError, 2);

        const lineUp = (confiabilityElevated2 * ptimespminus1) / marginErrorElevated2;
        const lineDown = 1 + ((confiabilityElevated2 * ptimespminus1) / (marginErrorElevated2 * sampleLarge))

        let total = Math.round(lineUp / lineDown);

        if (isNaN(total)) {
            total = 0
        }

        return (
            <div style={{ paddingBottom: "21px" }}>
                <text style={{ fontSize: '24px', color: 'green' }}>
                    {total.toString()}
                </text>
            </div>
        )

    }

    handleMarginError = (event) => {
        const newState = this.state;
        if (event.target.value > 100) {
            newState.marginError = 100;
        } else if (event.target.value < 0) {
            newState.marginError = 0
        } else {
            newState.marginError = event.target.value;
        }
        this.setState(newState);

    }

    handleConfiabilityValue = (event) => {
        const newState = this.state;
        newState.confiabilityValue = event.target.value;
        this.setState(newState);
    }


    handleToggleBGC = (event, toggled) => {
        const target = event.target;
        const newState = this.state;
        newState.productBGC = toggled;
        newState.productQuestionnare = !toggled;
        this.setState(newState);

    };
    handleToggleQuestionnare = (event, toggled) => {
        const target = event.target;
        const newState = this.state;
        newState.productQuestionnare = toggled;
        newState.productBGC = !toggled;
        this.setState(newState);

    };

    handleToggleDates = (event, toggled) => {
        const newState = this.state;
        newState.toggleDate = toggled;
        this.setState(newState);
    }

    handleToggleQuantity = (event, toggled) => {
        const newState = this.state;
        newState.toggleQty = toggled;
        this.setState(newState);
    }

    handleSelectChangeMap = (name, name2, event, key, newValue) => {
        event.preventDefault();
        const newState = this.state;
        newState.parameters.set(name, event.target.value)
        this.setState(newState);

    };

    creationButton() {
        if (this.state) {
            const newState = this.state;
            if (newState.createTestAB) {
                this.setState({ createTestAB: false });
            } else {
                this.setState({ createTestAB: true });
            }
        }
    }

    handleSelectKeyMap = (name, name2, event) => {
        const newState = this.state;
        let newValue = event.target.value;
        if (newValue > 100)
            newValue = 100
        if (newValue < 0)
            newValue = 0
        newValue = parseInt(newValue)
        const newValueB = 100 - newValue
        newState.parameters.set(name, newValue.toString())
        newState.parameters.set(name2, newValueB.toString())
        this.setState(newState);
    };

    handleSelectQuantity = (name, event) => {
        const newState = this.state;
        let newValue = event.target.value;
        if (newValue < 0)
            newValue = 0
        newValue = parseInt(newValue)
        newState.parameters.set(name, newValue.toString())
        this.setState(newState);
    };

    handleExecutionAbTesting = (element) => {
        let result;
        if (element.OnPause) {
            result = "Pausado"
            return result;
        }
        var EndDateFormat = moment(element.EndDate).utc().format()
        var StartDateFormat = moment(element.StartDate).utc().format()
        var NowUtc = moment().utc().format()

        if (element.UseDates) {
            if (StartDateFormat < NowUtc && EndDateFormat > NowUtc) {
                result = Strings.AB_TEST_STATUS.EXEC[language]
            } else if (EndDateFormat < NowUtc) {
                result =  Strings.AB_TEST_STATUS.FINISHED[language]
                return result;
            } else {
                result = Strings.AB_TEST_STATUS.WAITING[language]
            }
        }
        if (element.UseRequestQty) {
            if (StartDateFormat > NowUtc) {
                result = Strings.AB_TEST_STATUS.WAITING[language]
            }
            if (!element.VolumeFinished) {
                result = Strings.AB_TEST_STATUS.EXEC[language]
            } else if (element.VolumeFinished) {
                result = Strings.AB_TEST_STATUS.FINISHED[language]
                return result;
            }
        }

        return result;
    }

    renderErrors() {
        if (this.state.error) {
            return (
                <div>
                    {this.state.error}
                </div>
            )
        }
    }

    check_error = (state) => {
        if (state.parameters.has("GroupA") && state.parameters.has("GroupB")) {
            const groupAValue = state.parameters.get("GroupA");
            const groupBValue = state.parameters.get("GroupB");
            if (groupAValue == groupBValue)
                return Strings.AB_TEST_ALERTS.SAME_GROUP_SELECT_ALERT[language]

        }

    };

    button_minus() {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus">
                    <line x1="5" y1="12" x2="19" y2="12">
                    </line></svg>
            </div>
        )
    }

    // -- RENDER -- //
    render() {
        if (this.state.componentUpdate) {
            this.handleSearchABTesting()
            this.setState({
                componentUpdate: false
            })
        }
        let showCreation = {
            display: (this.state.createTestAB ? "block" : "none")
        };
        let showTable = {
            display: (this.state.createTestAB ? "none" : "block")
        };
        if (this.state.finishSearchTestAB) {
            this.handleTotalRequests()
        }
        const { INFO_ABTESTING } = Strings.abTestingTooltips

        const actions = [
            <Button
                label={Strings.generalConfigurations.BACK[language]}
                primary={true}
                onClick={() => this.handleCloseModal()}
            />,
        ];
        const configurationSectionTitleStyle = {
            fontSize: 16,
            color: "#2B3D50",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
        };

        const configImportant = {
            fontSize: 16,
            color: "#F9423A",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center"
        };
        const errors = this.check_error(this.state);
        return (
            <div style={cardContainerStyle}>
                <div style={columnStyle} class="ab-test-table">
                    <div style={showCreation}>
                        <div style={backTextButton} onClick={this.creationButton.bind(this)}>
                            <ArrowBack style={{width: "20px", height: "20px", marginRight: "10px"}} /> {Strings.generalConfigurations.BACK[language]}
                        </div>
                        <div style={{ marginTop: "40px" }}>
                            <div>
                                <h3 style={configImportant}>
                                    {Strings.ABTestingLabels.WARNING[language]}
                                </h3>
                            </div>
                            <text
                                onClick={this.handleOpenModal}
                                style={configurationSectionTitleStyle}>
                                {Strings.ABTestingLabels.EXPLANATION_MODAL[language]}
                            </text>
                            <h3 style={{ marginBottom: '20px' }}>
                                {Strings.ABTestingLabels.SELECT_PRODUCT[language]}
                            </h3>
                            <Toggle
                                style={toggleStyle}
                                label={Strings.ABTestingLabels.PRODUCTQUESTIONNARE[language]}
                                toggled={this.state.productQuestionnare}
                                onToggle={this.handleToggleQuestionnare}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Toggle
                                style={toggleStyle}
                                label={Strings.ABTestingLabels.PRODUCTBGC[language]}
                                toggled={this.state.productBGC}
                                onToggle={this.handleToggleBGC}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h3>
                                {Strings.ABTestingLabels.CHOOSE_PRODUCT_TEXT[language]}
                            </h3>
                            <h4 id="grupo-a" style={{color: "#f51212"}}>{Strings.ABTestingLabels.FIRST_GROUP_NAME[language]}</h4>
                            <SelectField
                                labelId="grupo-a"
                                floatingLabelText={Strings.ABTestingLabels.FIRST_GROUP_NAME[language]}
                                value={this.state.parameters.get("GroupA")}
                                name={"GroupA"}
                                errorText={errors}
                                onChange={this.handleSelectChangeMap.bind(null, "GroupA", "GroupB")}
                            >
                                {Auth.sessionData.groupsNames.map((value) => {
                                    return (
                                        <MenuItem style={styleMenuItem} key={value} value={value}> {value.toString()}</MenuItem>
                                    )
                                })}
                            </SelectField>

                            <h4 id="grupo-b" style={{color: "#f51212"}}>{Strings.ABTestingLabels.SECOND_GROUP_NAME[language]}</h4>
                            <SelectField
                                labelId="grupo-b"
                                floatingLabelText={Strings.ABTestingLabels.SECOND_GROUP_NAME[language]}
                                value={this.state.parameters.get("GroupB")}
                                name="GroupB"
                                onChange={this.handleSelectChangeMap.bind(null, "GroupB", "GroupA")}
                            >
                                {Auth.sessionData.groupsNames.map((value) => {
                                    return (
                                        <MenuItem style={styleMenuItem} key={value} value={value}> {value.toString()}</MenuItem>
                                    )
                                })}
                            </SelectField>

                        </div>
                        <div>
                            <h3 style={{ marginBottom: '40px' }}>
                                {Strings.ABTestingLabels.CHOOSE_PERCENTUAL_TEXT[language]}
                            </h3>
                            <TextField
                                inline
                                type="number"
                                style={{ marginRight: '80%' }}
                                floatingLabelText={Strings.ABTestingLabels.PERCENT_FIRST_GROUP[language]}
                                value={this.state.parameters.get("RequestPercentA") ? this.state.parameters.get("RequestPercentA") : ""}
                                name="RequestPercentA"
                                onChange={this.handleSelectKeyMap.bind(null, "RequestPercentA", "RequestPercentB")}
                            />
                            <TextField
                                inline
                                style={{ marginRight: '80%' }}
                                type="number"
                                floatingLabelText={Strings.ABTestingLabels.PERCENT_SECOND_GROUP[language]}
                                value={this.state.parameters.get("RequestPercentB") ? this.state.parameters.get("RequestPercentB") : ""}
                                name="RequestPercentB"
                                onChange={this.handleSelectKeyMap.bind(null, "RequestPercentB", "RequestPercentA")}
                            />
                        </div>
                        <div>
                            <h3 style={{ marginBottom: '40px' }}>
                                {Strings.ABTestingLabels.START_END_TEXT[language]}
                            </h3>
                            <p style={{fontFamily:'inherit' , fontWeight:700, marginTop: '-30px', fontSize:'14px'}}> {Strings.ABTestingLabels.UTCDISCLAIMER[language]}</p>
                            <br />

                            <label htmlFor="">{Strings.ABTestingLabels.START_DATE[language]}: </label>

                            <DatePicker dateFormat="DD/MM/YYYY" timeFormat="HH:mm" value={this.state.startDate} onChange={this.handleChangestartDate.bind(null, "startDate")} />

                            <br />
                            <Toggle
                                style={toggleStyle}
                                label={Strings.ABTestingLabels.DATES[language]}
                                toggled={this.state.toggleDate}
                                onToggle={this.handleToggleDates}
                            />
                            <div style={{ display: (this.state.toggleDate ? "block" : "none") }}>
                                <label htmlFor="">{Strings.ABTestingLabels.END_DATE[language]}: </label>

                                <DatePicker dateFormat="DD/MM/YYYY" timeFormat="HH:mm" value={this.state.endDate} onChange={this.handleChangeendDate.bind(null, "endDate")} />

                                <br />

                                {this.renderErrors()}
                            </div>
                            <div>
                                <Toggle
                                    style={toggleStyle}
                                    label={Strings.ABTestingLabels.VOLUME[language]}
                                    toggled={this.state.toggleQty}
                                    onToggle={this.handleToggleQuantity}
                                />
                                <div style={{ display: (this.state.toggleQty ? "block" : "none") }}>
                                    <TextField
                                        inline
                                        style={{ marginRight: '80%' }}
                                        type="number"
                                        floatingLabelText={Strings.ABTestingLabels.QUANTITY[language]}
                                        value={this.state.parameters.get("RequestQty")}
                                        name="RequestQty"
                                        onChange={this.handleSelectQuantity.bind(null, "RequestQty")}
                                    />
                                </div>
                            </div>

                        </div>
                        <div>
                            <ButtonSecondary
                                disabled={this.state.disableButton}
                                label={Strings.AB_TEST_SEND[language]}
                                style={{textTransform: "none", margin: 0}}
                                onClick={this.onGenerate}
                            />
                        </div>
                        <br />
                        <br />
                    </div>
                    <div style={showTable}>
                        <div style={{ display: 'flex' }}>
                            <SectionTitle text={Strings.ABTestingLabels.TITLE[language]} />                             
                        </div>
                        <div>
                            <h4 style={{ color: "black", fontSize: '16px' }}>
                                {Strings.ABTestingLabels.EXPLANATION_FIRST[language]}<br />
                                {Strings.ABTestingLabels.EXPLANATION_SECOND[language]}
                            </h4>
                            <ButtonSecondary
                                variant="contained"
                                label={Strings.ABTestingLabels.NEW_TEST[language]}
                                style={{left: "1%", textTransform: "none", margin: 0}}
                                onClick={this.creationButton.bind(this)}
                            />
                        </div>
                        <div>
                            <br />
                            {this.renderLoading(this.state.generatingLoading)}
                            {this.state && this.state.finishTestAB &&
                                <Table style={{ width: 850, textAlign: 'center' }}>

                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.FIRST_GROUP_NAME[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.SECOND_GROUP_NAME[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.START_DATE[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.END_DATE[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.REQUEST_LIMIT[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.REQUISION_NUMBER[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.PRODUCT[language]}</th>
                                            <th style={{ textAlign: 'center' }}>Status</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.STOP_PLAY[language]}</th>
                                            <th style={{ textAlign: 'center' }}>{Strings.ABTestingLabels.DELETE[language]}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.testAb.map((element, index) => {
                                                const actionWord = element.OnPause ? Strings.ABTestingLabels.PLAY[language] : Strings.ABTestingLabels.STOP[language]
                                                let status = this.handleExecutionAbTesting(element)
                                                return (
                                                    <tr style={{ width: "100%" }} key={index}>
                                                        <td>{element.GroupA}</td>
                                                        <td>{element.GroupB}</td>
                                                        <td>{element.StartDate.replace('T', ' ').replace('Z', '')}</td>
                                                        <td>{element.UseDates ? element.EndDate.replace('T', ' ').replace('Z', '') : this.button_minus()}</td>
                                                        <td>{element.UseRequestQty ? element.RequestQty : this.button_minus()}</td>
                                                        <td>{element.UseRequestQty ? ((element["TotalRequest"] != undefined) ? element.TotalRequest : this.button_minus()) : this.button_minus()}</td>
                                                        <td>{element.Products}</td>
                                                        <td>{status}</td>
                                                        <td>
                                                            {this.checkStatus(status) ?
                                                                !element.OnPause ?
                                                                    <div>
                                                                        <ConfirmationDialog onConfirm={this.handlePauseOrInitiateElement.bind(null, index, element)}
                                                                            text={Strings.ABTestingLabels.ACTION_ALERT[language].replace("#",actionWord)}>                                                                            
                                                                            <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause-circle">
                                                                                <circle cx="12" cy="12" r="10">
                                                                                </circle><line x1="10" y1="15" x2="10" y2="9">
                                                                                </line><line x1="14" y1="15" x2="14" y2="9">

                                                                                </line>
                                                                            </svg>
                                                                        </ConfirmationDialog>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <ConfirmationDialog onConfirm={this.handlePauseOrInitiateElement.bind(null, index, element)}

                                                                            text={Strings.ABTestingLabels.ACTION_ALERT[language].replace("#",actionWord)}> 

                                                                            <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play-circle">
                                                                                <circle cx="12" cy="12" r="10">
                                                                                </circle>
                                                                                <polygon points="10 8 16 12 10 16 10 8">
                                                                                </polygon>
                                                                            </svg>

                                                                        </ConfirmationDialog>
                                                                    </div>
                                                                :
                                                                this.button_minus()
                                                            }
                                                        </td>
                                                        <td>
                                                            <ConfirmationDialog onConfirm={this.handleRemoveElement.bind(null, index, element)}
                                                                text={Strings.ABTestingLabels.DELETE_ALERT[language]}>
                                                                <Button style={{ minWidth: "50px", cursor: "pointer" }}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </ConfirmationDialog>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                    </tbody>
                                </Table> 
                            }
                        </div>
                        <div>
                            <Dialog
                                title={INFO_ABTESTING.header[language]}
                                actions={actions}
                                modal={true}
                                open={this.state.showModal}
                                autoScrollBodyContent={true}
                            >
                                <DialogABTest title={INFO_ABTESTING.title[language]} text={INFO_ABTESTING.text[language]} />
                                <h2 style={{ textAlign: 'center' }}>
                                    {INFO_ABTESTING.calcTitle[language]}
                            </h2>
                                <div style={{ display: 'flex', marginTop: '80px' }}>
                                    <div>
                                        <h4>
                                        {INFO_ABTESTING.popSize[language]}
                                </h4>
                                        <input
                                            type="number"
                                            style={{ height: '35px' }}
                                            value={this.state.sizeOfSample}
                                            onChange={this.handleSizeofSample.bind(this)}
                                            margin="normal"
                                        />
                                    </div>
                                    <div style={{ marginLeft: '50px' }}>
                                        <h4>
                                        {INFO_ABTESTING.confDegree[language]}
                                            (%)
                                </h4>
                                        <SelectField
                                            style={{ marginBottom: '5px', width: '200px' }}
                                            type="number"
                                            value={this.state.confiabilityValue}
                                            onChange={this.handleConfiabilityValue.bind(this)}
                                        >
                                            {this.state.confiabilityList.map((value) => {
                                                return (
                                                    <MenuItem style={styleMenuItem} key={value} value={value}> {value.toString()} </MenuItem>
                                                )
                                            })}
                                        </SelectField>
                                    </div>
                                    <div style={{ marginLeft: '60px' }}>
                                        <h4>
                                        {INFO_ABTESTING.errorMargin[language]} (%)
                                </h4>
                                        <input
                                            type="number"
                                            style={{ height: '35px' }}
                                            value={this.state.marginError}
                                            name="RequestQty"
                                            onChange={this.handleMarginError.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3 style={{ textAlign: 'center', marginTop: '50px' }}>
                                    {INFO_ABTESTING.sampleSize[language]} 
                                </h3>
                                    <div style={{ textAlign: 'center' }}>
                                        {(this.state.marginError != ""
                                            && (this.state.confiabilityValue != ""
                                                && this.state.sizeOfSample != "") ? this.getValueOfSample() :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus">
                                                <line x1="5" y1="12" x2="19" y2="12">
                                                </line>
                                            </svg>)}
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        },

        onGroupDeleted: (groupsNames) => {
            dispatch(actions.storeGroupsNames(groupsNames));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ABTesting);
