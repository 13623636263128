import React from 'react';

import Strings from '../../constants/strings';

// Material UI Imports
import TextField from '../../components/mui/TextField';
import Toggle from '../../components/mui/Toggle';

import SectionTitle from '../SectionTitle';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import ApiConstants from '../../constants/api';
import API from '../../API';

import ConfirmationDialog from '../ConfirmationDialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import validate from 'validate.js';
import LoadingDialog from "../LoadingDialog";

import UserLanguage from '../configurations/UserLanguage.js'


const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const columnStyle = {
    width: '350px'
};

const toggleStyle = {
    // width: '100%'
};

class MenuGeneralConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            menuActive: Strings.generalConfigurations.menu.GENERAL,
            visitedBGCPage: false
        }
    }


    options = {
        fullMessages: false,
    };

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleTimePickerChange = (field, newValue) => {
        const newState = this.state;
        newState.configurations[field] = newValue;
        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newState = this.state;
        newState.configurations[name] = value;

        const validationErrors = validate(newState.configurations, this.constraints);

        newState.errors = validationErrors;

        this.setState(newState);
        this.props.onSave(newState.configurations);
    }
    
    handleValidationToggle = (index, event, value) => {
        const newState = this.state;
        newState.configurations.BigIdValidationOptions.find((x) => x.Name === index).Enabled = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    };

    handleIsDefaultConfirmed = () => {
        const newState = this.state;
        newState.configurations.IsDefault = true;
        newState.confirmDefault = false;

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleToggle = (event, toggled) => {
        const target = event.target;
        const value = toggled;
        const name = target.name;

        if (name === "IsDefault") {
            if (toggled) {
                this.setState({ confirmDefault: true });
            }
            return;
        }

        const newState = this.state;
        newState.configurations[name] = value;

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleSelectChange = (name, event, key, newValue) => {
        event.preventDefault();
        const newState = this.state;
        newState.configurations[name] = newValue;
        this.setState(newState);

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.props.onSave(newState.configurations);
        }
    };

    handleScoreRulesChange = (name, event, newValue) => {
        const newState = this.state;
        newState.configurations.ScoreRules[0][name] = newValue;
        this.setState(newState);
        if (validate(newState.configurations, this.constraints) === undefined) {
            this.props.onSave(newState.configurations);
        }
    };

    handleGroupDeleted = async () => {
        this.setState({ loading: true });
        const result = await API.deleteGroup(this.state.configurations.Name, this.state.configurations.Domain);
        this.setState({ loading: false });

        if (result.status === ApiConstants.status.SUCCESS) {
            this.props.onGroupDeleted(result.groupsNames);
            this.props.onSelectGroup(result.configurations);
        }
    };

    // -- Renders -- //
    
    renderConfirmDialog(configurations) {
        let language = UserLanguage.getLanguage();
        return (
            <div>
                <h4>{Strings.generalConfigurations.CONFIRM_DEFAULT_ALERT[language].replace("#",configurations.Name)}</h4>
                <p>{Strings.generalConfigurations.CONFIRM_DEFAULT_TEXT[language]}</p>
            </div>
        )
    }



    // -- RENDER -- //
    render() {
        const configurations = this.state.configurations;
        const noaRelatedElementStyle = {
            display: (!configurations.NoAQuestionEnabled ? "none" : null)
        };

        const errors = validate(this.state.configurations, this.constraints, this.options);
        let language = UserLanguage.getLanguage();
        return (
            <div style={cardContainerStyle}>
                <div style={columnStyle}>

                <SectionTitle text={Strings.generalConfigurations.MENU_GENERAL_CONFIGURATIONS[language]}  />
                    

                    <LoadingDialog open={this.state.loading} />

                   
                    <div style={{width: '200px'}}>

                        <LoadingDialog open={this.state.loading} />

                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>

                            <TextField
                                floatingLabelText={Strings.generalConfigurations.GROUP_NAME_LABEL[language]}
                                value={configurations.Name}
                                name="Name"
                                onChange={this.handleChange}
                                errorText={errors ? errors.Name : ""}
                            />

                            <ConfirmationDialog 
                                disabled={configurations.IsDefault} 
                                onConfirm={this.handleGroupDeleted}
                                text={Strings.generalConfigurations.DELETE_GROUP_ALERT[language].replace("#",configurations.Name)}
                            >
                                <DeleteForeverIcon color={configurations.IsDefault ? "inherit" : "error"} style={{ width: 30, height: 30 }} />
                            </ConfirmationDialog>
                        </div>

                        <div style={{marginBottom: '75px'}}>
                            <ConfirmationDialog
                                open={this.state.confirmDefault}
                                disabled={configurations.IsDefault}
                                onConfirm={this.handleIsDefaultConfirmed}
                                onCancel={() => this.setState({ confirmDefault: false })}
                                text={this.renderConfirmDialog(configurations)}
                            />

                            <Toggle
                                tooltip={Strings.tooltips.DEFAULT[language]}                                
                                style={toggleStyle}
                                label={Strings.generalConfigurations.DEFAULT_LABEL[language]}
                                toggled={configurations.IsDefault}
                                name='IsDefault'
                                onToggle={this.handleToggle}
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        },

        onGroupDeleted: (groupsNames) => {
            dispatch(actions.storeGroupsNames(groupsNames));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuGeneralConfig);
