import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../../actions';

import InfoTypeList from './InfoTypeList';
import InfoTypeQuestions from './InfoTypeQuestions';


class QuestionsConfigurations extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            configuringInfoType: false,
        }
    }

    handleInfoTypeSelected = (element) => {
        this.setState({configuringInfoType: true, infoTypeSelected: element});
    };

    handleBackToList = () => {
        this.setState({configuringInfoType: false, infoTypeSelected: null});
    };

    // -- RENDER -- //
    render(){
        if(this.state.configuringInfoType){
            return (
                <InfoTypeQuestions onBackButton={this.handleBackToList} infoTypeName={this.state.infoTypeSelected.TypeName}/>
            )
        }else{
            return (
                <InfoTypeList onInfoTypeSelected={this.handleInfoTypeSelected} kind={this.props.kind}/>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(QuestionsConfigurations);
