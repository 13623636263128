// *-* mode: rjsx -*-
import React, {Component} from 'react';
import propTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import {Button} from '@material-ui/core';
import {filter} from 'lodash';

import AddIcon from 'material-ui/svg-icons/content/add';
import DoneIcon from 'material-ui/svg-icons/action/done';

const containerStyle ={
    display: "flex",
    flexDirection: "column",
    maxWidth: 80,
    marginRight: 40,
    marginLeft: 10
};

const inputContainerStyle = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",

    width: 80,
    height: 80,

    borderStyle: "dashed",
    borderWidth: 3,
    borderColor: "#AAA"
};

const fileInputStyle = {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
};

const addIconStyle = {
    width: 70,
    height: 70,
    color: "#AAA"
};

const doneIconStyle = {
    width: 70,
    height: 70,
    color: "#009D00"
};

const previewStyle = {
  maxWidth: 80
};

export default class Upload extends Component {

    static defaultProps = {
        fileTypeRegex: /.*/,
        onFileLoad: (e) => undefined,
        buttonControl: Button
    };

    static propTypes = {
        fileTypeRegex: propTypes.object,
        onFileLoad: propTypes.func,
        buttonControl: propTypes.func
    };

    exclusiveProps = [
        'fileTypeRegex',
        'onFileLoad',
        'buttonControl'
    ];

    onInputChange = (e) => {
        filter(
            e.target.files,
            (file) => file.type.match(this.props.fileTypeRegex) !== null
        )
            .forEach(
                (file) => {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({uploaded: true});
                        findDOMNode(this.refs['file-preview'])
                            .src = e.target.result;

                        this.props.onFileLoad(e, file);
                    };
                    reader.readAsDataURL(file);
                }
            );
    };

    componentDidMount() {
        findDOMNode(this.refs['file-input'])
            .addEventListener(
                'change',
                this.onInputChange,
                false
            );
    };

    componentWillUnmount() {
        findDOMNode(this.refs['file-input'])
            .removeEventListener(
                'change',
                this.onInputChange,
                false
            );
    };

    componentWillReceiveProps(newProps){
        if(!newProps.uploaded){
            findDOMNode(this.refs['file-input']).value = "";
            findDOMNode(this.refs['file-preview']).src = "";
        }
    }

    getButtonProps() {
        return Object
            .keys(this.props)
            .filter(
                (name) => this.exclusiveProps.indexOf(name) === -1
            )
            .reduce(
                (acc, name) => {
                    acc[name] = this.props[name];
                    return acc;
                },
                {}
            );
    };

    renderStatusIcon(){
        if(this.props.uploaded){
            return (<DoneIcon style={doneIconStyle} />)
        }

        return (<AddIcon style={addIconStyle} />)
    }

    render() {
        return (
            <div style={containerStyle}>
                <div style={inputContainerStyle}>
                    <div>
                        {this.renderStatusIcon()}

                        <input
                            style={fileInputStyle}
                            type="file"
                            ref="file-input"
                            multiple
                        />
                    </div>
                </div>

                <div style={{textAlign: "center"}}>
                    {this.props.label}
                </div>

                <div>
                    <img src="" alt="" style={previewStyle} ref="file-preview"/>
                </div>
            </div>
        );
    };

}