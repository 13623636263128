import React from 'react';

import Strings from '../../constants/strings';

// Material UI Imports
import TextField from '../../components/mui/TextField';

import BigIdButton from '../mui/Button';

import {CircularProgress} from '@material-ui/core';

import SectionTitle from '../SectionTitle';

import Alert from 'react-bootstrap/lib/Alert';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import API from '../../API';

import validate from 'validate.js';

import UserLanguage from '../configurations/UserLanguage.js'
import colors from '../../constants/colors';

let language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxShadow: null
};

const columnStyle = {
    width: '350px'
};

const StyleButton = {
    fontSize: "14px",
    fontFamily: "HKGrotesk",
    textTransform: "none",
    backgroundColor: colors.primaryBlueDark,
    color: colors.White,
    width: "36%"
};


class NewUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            account: {
                GroupName: "",
                UserName: ""
            }
        }
    }

    constraints = {
        GroupName: {
            presence: true,
        },

        UserName: {
            presence: true,
        },

        UserPassword: {
            presence: true,
        }
    };

    generateNewPassword = () => {
        let state = Object.assign({},this.state);
        state.account.UserPassword = Math.random().toString(36).slice(-8);
        this.setState(state);
    };

    // -- HANDLERS -- //
    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newState = this.state;
        newState.account[name] = value;

        this.setState(newState);
    };

    handleSave = async () => {
        if (validate(this.state.account, this.constraints) === undefined) {
            this.setState({loading: true, result: null});
            let result = await API.createNewAccount(this.state.account);
            this.setState({loading: false});

            if(result === null){
                this.setState({error: true})
            }else{
                this.setState({result: result});
            }
        }else{
            this.setState({errors: validate(this.state.account, this.constraints)});
            this.setState({error: true});
        }
    };

    // -- RENDER -- //
    renderLoading(should){
        if(!should){
            return null;
        }

        return (
            <CircularProgress style={{marginLeft: "20px"}} thickness={3.5} size={35}/>
        )
    }

    renderConfirmation(){
        if(this.state.result){
            return (
                <Alert bsStyle="info">
                    <div>{Strings.administration.GROUP_CREATED[language]}{this.state.result.GroupCreated ? Strings.administration.NEW_USER_RESULT.TRUE[language] : Strings.administration.NEW_USER_RESULT.FALSE[language]}</div>
                    <div>{Strings.administration.USER_CREATED[language]}{this.state.result.UserCreated ? Strings.administration.NEW_USER_RESULT.TRUE[language] : Strings.administration.NEW_USER_RESULT.FALSE[language]}</div>
                    <div>{Strings.administration.USER_CREATED_IDMEPRICE[language]}{this.state.result.BigIdParameterAdded ? Strings.administration.NEW_USER_RESULT.TRUE[language] : Strings.administration.NEW_USER_RESULT.FALSE[language]}</div>
                    <div>{Strings.administration.BIGID_USER_CREATED[language]}{this.state.result.BigIdUserCreated ? Strings.administration.NEW_USER_RESULT.TRUE[language] : Strings.administration.NEW_USER_RESULT.FALSE[language]}</div>
                    <div>{Strings.administration.CONFIG_GROUP_CREATED[language]}{this.state.result.DefaultGroupCreated ? Strings.administration.NEW_USER_RESULT.TRUE[language] : Strings.administration.NEW_USER_RESULT.FALSE[language]}</div>
                </Alert>
            )
        }

        return null;
    }

    render() {
        const errors = this.state.errors;
        
        return (
            <div style={cardContainerStyle}>
                <div style={columnStyle}>
                    <SectionTitle text={Strings.administration.CREATE_NEW_ACCOUNT_TITLE[language]}/>

                    <TextField
                        style={{marginTop: "-5px"}}
                        floatingLabelText={Strings.administration.GROUP_NAME[language]}
                        name="GroupName"
                        onChange={this.handleChange}
                        error={this.state.error}
                        errorText={errors ? errors.GroupName : ""}
                    />

                    <TextField
                        style={{marginTop: "-5px"}}
                        floatingLabelText={Strings.administration.USER_NAME[language]}
                        name="UserName"
                        onChange={this.handleChange}
                        error={this.state.error}
                        errorText={errors ? errors.UserName : ""}
                    />

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            style={{marginTop: "-5px", display: 'inline-block'}}
                            floatingLabelText={Strings.administration.USER_PASSWORD[language]}
                            name="UserPassword"
                            value={this.state.account.UserPassword}
                            onChange={this.handleChange}
                            error={this.state.error}
                            errorText={errors ? errors.UserPassword : ""}
                        />

                        <AutorenewIcon style={{ cursor: "pointer", width: 25, height: 25 }} onClick={this.generateNewPassword} />
                    </div>

                    <BigIdButton style={StyleButton} onClick={this.handleSave} label={Strings.administration.CREATE_USER[language]} />

                    {this.renderLoading(this.state.loading)}

                    <br />

                    {this.renderConfirmation()}
                </div>
            </div>
        )
    }
}

export default NewUser;
