import React from 'react';

import Strings from '../../constants/strings';

// Material UI Imports
import TextField from '../../components/mui/TextField';
import { MenuItem } from '@material-ui/core';
import Toggle from '../../components/mui/Toggle';

import Select from '../mui/Select';
import TimePicker from '../TimePicker';
import SectionTitle from '../SectionTitle';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import ApiConstants from '../../constants/api';
import API from '../../API';

import validate from 'validate.js';
import LoadingDialog from "../LoadingDialog";
import Dialog from 'material-ui/Dialog';

import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

validate.validators.minScore = function (value, options, key, attributes, globalOptions) {
    const minScore = value[0].MinScore;
    const questionsConfigurations = attributes.QuestionsConfigurations;

    if (minScore < 0) {
        value[0].MinScore = 0;
        return Strings.generalConfigurations.SCORE_WARNING[language];
    }

    if (questionsConfigurations.length === 0) {
        return undefined;
    }

    // Sort questions by positive weight
    questionsConfigurations.sort((a, b) => {
        if (a.PositiveWeight < b.PositiveWeight) {
            return -1;
        } else {
            return 1;
        }
    });

    // Get N minimum questions where N is the QuestionCout
    //const minScoreQuestions = questionsConfigurations.slice(1, attributes.QuestionCount);

    let sum = attributes.QuestionCount;
    /*for (const question of minScoreQuestions) {
        sum += question.PositiveWeight;
    }*/

    if (sum < minScore) {
        return Strings.generalConfigurations.WARNING_QUESTION_POINTS[language];
    }

    return undefined;
};

validate.validators.flexibleQuestions = function (value, options, key, attributes, globalOptions) {
    const minquestion = attributes.MinQuestionCount;
    const maxquestion = attributes.MaxQuestionCount;

    if (minquestion >= maxquestion && minquestion !== 0 && maxquestion !== 0) {
        //attributes.FlexibleQuestionsCount = false;
        //attributes.MinQuestionCount = 1;
        attributes.MaxQuestionCount = minquestion + 1;
        return "Quantidade Mínima não pode ser Maior ou Igual do que o Máximo !"

    }
    if (attributes.FlexibleQuestionsCount && minquestion === 0 && maxquestion === 0) {
        attributes.MinQuestionCount = 1;
        attributes.MaxQuestionCount = 2;
    }
}

validate.validators.EssentialQuestions = function (value, options, key, attributes, globalOptions) {
    //This basicaly counts the number of infotypes that contains essential questions
    let infoTypes = {};
    let essentialsTotal = attributes.EssentialQuestionsCount;
    for (let question of attributes.QuestionsConfigurations) {
        const questionProperty = options.questionsProperties.AllQuestionPorperties.find((x) => { return question.QuestionId === x.Id });
        if (question.Essential) {
            infoTypes[questionProperty.InfoType] = (infoTypes[questionProperty.InfoType] || 0) + 1;
        }
    }

    let essentialInfoTypes = Object.keys(infoTypes).filter((x) => infoTypes[x] > 0);
    
    if(essentialInfoTypes.length < essentialsTotal){
        return Strings.generalConfigurations.TOTAL_ESSENTIAL_QUESTIONS_ALERT[language];
    }
    
    return undefined;
}

validate.validators.questionCountForInfoTypes = function (value, options, key, attributes, globalOptions) {
    let infoTypes = {};
    const questionsConfigurations = attributes.QuestionsConfigurations;
    for (let question of questionsConfigurations) {
        const questionProperty = options.questionsProperties.AllQuestionPorperties.find((x) => { return question.QuestionId === x.Id });
        if (question.Enabled) {
            infoTypes[questionProperty.InfoType] = (infoTypes[questionProperty.InfoType] || 0) + 1;
        }
    }

    let infoTypesEnabled = Object.keys(infoTypes).filter((x) => infoTypes[x] > 0);

    if (infoTypesEnabled.length < value) {
        return "Número de perguntas é maior que o número de tipo de perguntas habilitadas.";
    }

    return undefined;
};


const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const columnStyle = {
    width: '350px'
};

const toggleStyle = {
    // width: '100%'
};

class GeneralConfigurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            menuActive: Strings.generalConfigurations.menu.GENERAL,
        }
    }

    constraints = {
        FlexibleQuestionsCount: {
            flexibleQuestions: true,
        },

        ScoreRules: {
            presence: {
                message: "Este campo é obrigatório"
            },
            minScore: true
        },

        QuestionCount: {
            presence: {
                message: "Este campo é obrigatório."
            },

            questionCountForInfoTypes: {
                questionsProperties: this.props.questionsProperties
            }
        },
        EssentialQuestionsCount:{
            presence: {
                message: "Este campo é obrigatório."
            },
            EssentialQuestions: this.props
        }

    };

    options = {
        fullMessages: false,
    };

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleTimePickerChange = (field, newValue) => {
        const newState = this.state;
        newState.configurations[field] = newValue;
        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleValidationToggle = (index, event, value) => {
        const newState = this.state;
        newState.configurations.BigIdValidationOptions.find((x) => x.Name === index).Enabled = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    };

    handleIsDefaultConfirmed = () => {
        const newState = this.state;
        newState.configurations.IsDefault = true;
        newState.confirmDefault = false;

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };
    
    handleToggle = (event, toggled) => {
        const target = event.target;
        const value = toggled;
        const name = target.name;

        if (name === "IsDefault") {
            if (toggled) {
                this.setState({ confirmDefault: true });
            }
            return;
        }

        const newState = this.state;
        newState.configurations[name] = value;

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleSelectChange = (name, event, key, newValue) => {
        event.preventDefault();
        const newState = this.state;
        newState.configurations[name] = event.target.value;
        this.setState(newState);

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.props.onSave(newState.configurations);
        }
    };

    handleEssentialToggle = (event, toggled) => {
        const newState = this.state;
        newState.configurations[event.target.name] = toggled;
        if(toggled){
            newState.configurations["EssentialQuestionsCount"] = 1;
        }else{
            newState.configurations["EssentialQuestionsCount"] = 0;
        }
        this.setState(newState);
        this.props.onSave(newState.configurations);
    };

    validateEssentialRules(essentialsTotal){
        //This basicaly counts the number of infotypes that contains essential questions
        let infoTypes = {};
        for (let question of this.props.configurations.QuestionsConfigurations) {
            const questionProperty = this.props.questionsProperties.AllQuestionPorperties.find((x) => { return question.QuestionId === x.Id });
            if (question.Essential) {
                infoTypes[questionProperty.InfoType] = (infoTypes[questionProperty.InfoType] || 0) + 1;
            }
        }

        let essentialInfoTypes = Object.keys(infoTypes).filter((x) => infoTypes[x] > 0);

        return essentialInfoTypes.length >= essentialsTotal;
    }

    handleScoreRulesChange = (name, event) => {
        const newState = this.state;
        newState.configurations.ScoreRules[0][name] = event.target.value;
        this.setState(newState);
        if (validate(newState.configurations, this.constraints) === undefined) {
            this.props.onSave(newState.configurations);
        }
    };

    handleGroupDeleted = async () => {
        this.setState({ loading: true });
        const result = await API.deleteGroup(this.state.configurations.Name);
        this.setState({ loading: false });

        if (result.status === ApiConstants.status.SUCCESS) {
            this.props.onGroupDeleted(result.groupsNames);
            this.props.onSelectGroup(result.configurations);
        }
    };

    // -- Renders -- //

    renderConfirmDialog(configurations) {
        return (
            <div>
                <h4>{Strings.generalConfigurations.CONFIRM_DEFAULT_ALERT[language].replace("#",configurations.Name)}</h4>
                <p>{Strings.generalConfigurations.CONFIRM_DEFAULT_TEXT[language]}</p>
            </div>
        )
    }


    renderTypeOfQuestionnaireView(configurations, noaRelatedElementStyle, errors) {
        if (this.state.configurations.FlexibleQuestionsCount) {
            return (
                <div>
                    <Select
                        disabled={!configurations.FlexibleQuestionsCount}
                        style={noaRelatedElementStyle}
                        labelText={Strings.generalConfigurations.FLEXIBLE_MIN_QUESTIONS[language]}
                        value={configurations.MinQuestionCount}
                        name="MinQuestionCount"
                        onChange={this.handleSelectChange.bind(null, "MinQuestionCount")}
                    >
                        <MenuItem style={styleMenuItem} value={1}>1</MenuItem>
                        <MenuItem style={styleMenuItem} value={2}>2</MenuItem>
                        <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                        <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                        <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                        <MenuItem style={styleMenuItem} value={6}>6</MenuItem>
                        <MenuItem style={styleMenuItem} value={7}>7</MenuItem>
                        <MenuItem style={styleMenuItem} value={8}>8</MenuItem>
                        <MenuItem style={styleMenuItem} value={8}>8</MenuItem>
                        <MenuItem style={styleMenuItem} value={9}>9</MenuItem>
                    </Select>
                    <Select
                        style={noaRelatedElementStyle}
                        labelText={Strings.generalConfigurations.FLEXIBLE_MAX_QUESTIONS[language]}
                        value={configurations.MaxQuestionCount}
                        name="MaxQuestionCount"
                        onChange={this.handleSelectChange.bind(null, "MaxQuestionCount")}
                        errorText={errors ? errors.FlexibleQuestionsCount : ""}
                    >
                        <MenuItem style={styleMenuItem} value={1}>1</MenuItem>
                        <MenuItem style={styleMenuItem} value={2}>2</MenuItem>
                        <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                        <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                        <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                        <MenuItem style={styleMenuItem} value={6}>6</MenuItem>
                        <MenuItem style={styleMenuItem} value={7}>7</MenuItem>
                        <MenuItem style={styleMenuItem} value={8}>8</MenuItem>
                        <MenuItem style={styleMenuItem} value={8}>8</MenuItem>
                        <MenuItem style={styleMenuItem} value={9}>9</MenuItem>
                        <MenuItem style={styleMenuItem} value={9}>10</MenuItem>

                    </Select>
                    <Select
                        labelText={Strings.generalConfigurations.NUMBER_OF_ANSWERS_LABEL[language]}
                        style={{ width: "auto" }}
                        value={configurations.MaxAnswerCount}
                        name="MaxAnswerCount"
                        onChange={this.handleSelectChange.bind(null, "MaxAnswerCount")}
                    >
                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                            return (
                                <MenuItem style={styleMenuItem} key={value} value={value} > {value.toString()} </MenuItem>
                            )
                        })}
                    </Select>
                </div>
            )
        } else {
            return (
                <div>
                    <Select
                        tooltip={Strings.tooltips.NUMBER_OF_QUESTIONS[language]}
                        labelText={Strings.generalConfigurations.NUMBER_OF_QUESTIONS_LABEL[language]}
                        style={{ width: "auto" }}
                        value={configurations.QuestionCount}
                        name="QuestionCount"
                        errorText={errors ? (errors.QuestionCount ? errors.QuestionCount[0] : "") : ""}
                        onChange={this.handleSelectChange.bind(null, "QuestionCount")}
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                            return (
                                <MenuItem style={styleMenuItem} key={value} value={value} > {value.toString()} </MenuItem>
                            )
                        })}
                    </Select>
                    <Select
                        labelText={Strings.generalConfigurations.NUMBER_OF_ANSWERS_LABEL[language]}
                        style={{ width: "auto" }}
                        value={configurations.MaxAnswerCount}
                        name="MaxAnswerCount"
                        onChange={this.handleSelectChange.bind(null, "MaxAnswerCount")}
                    >
                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => {
                            return (
                                <MenuItem style={styleMenuItem} key={value} value={value} > {value.toString()} </MenuItem>
                            )
                        })}
                    </Select>
                </div>
            )
        }

    }

    renderError() {
        if (this.state.error) {
            return (
                <Dialog
                    title={"Erro"}
                    modal={false}
                    open={true}
                    onRequestClose={() => { this.setState({ error: null }) }}>

                    <div>
                        {this.state.error}
                    </div>
                </Dialog>
            )
        }

        return null;
    }

    // -- RENDER -- //
    render() {
        const configurations = this.state.configurations;
        const noaRelatedElementStyle = {
            display: (!configurations.NoAQuestionEnabled ? "none" : null)
        };

        const errors = validate(this.state.configurations, this.constraints, this.options);

        return (
            <div style={cardContainerStyle}>
                {this.renderError()}
                <div style={columnStyle}>

                    <SectionTitle text={Strings.generalConfigurations.GENERAL_CONFIGURATIONS[language]} />

                    <LoadingDialog open={this.state.loading} />

                    <Toggle
                        style={toggleStyle}
                        label={Strings.generalConfigurations.FLEXIBLE_QUESTIONS_LABEL[language]}
                        toggled={configurations.FlexibleQuestionsCount}
                        name="FlexibleQuestionsCount"
                        onToggle={this.handleToggle}
                        tooltip={Strings.generalConfigurations.FLEXIBLE_QUESTIONS_TEXT[language]}
                        id="flex-quest-toggle"
                    />

                    {this.renderTypeOfQuestionnaireView(configurations, noaRelatedElementStyle, errors)}

                    <TextField
                        tooltip={Strings.tooltips.MIN_SCORE[language]}
                        inline
                        type="number"
                        floatingLabelText={Strings.generalConfigurations.MIN_SCORE_LABEL[language]}
                        value={configurations.ScoreRules[0].MinScore}
                        name="MinScore"
                        onChange={this.handleScoreRulesChange.bind(null, "MinScore")}
                        errorText={errors ? errors.ScoreRules : ""}
                    />

                    <TimePicker
                        floatingLabelText={Strings.generalConfigurations.TIMEOUT_LABEL[language]}
                        value={configurations.MaxAnswerTime}
                        name="MaxAnswerTime"
                        onChange={this.handleTimePickerChange}
                    />

                    <TimePicker
                        tooltip={Strings.tooltips.REUSE_TIME[language]}
                        floatingLabelText={Strings.generalConfigurations.REUSE_TIME_LABEL[language]}
                        value={configurations.ReUseTime}
                        name="ReUseTime"
                        onChange={this.handleTimePickerChange}
                    />

                </div>

                <div style={columnStyle}>

                    <LoadingDialog open={this.state.loading} />

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>

                    </div>

                    <Toggle
                        style={toggleStyle}
                        label={Strings.generalConfigurations.NOA_LABEL[language]}
                        toggled={configurations.NoAQuestionEnabled}
                        name="NoAQuestionEnabled"
                        onToggle={this.handleToggle}
                        tooltip={Strings.generalConfigurations.NOA_TEXT[language]}
                    />

                    <Select
                        disabled={!configurations.NoAQuestionEnabled}
                        style={noaRelatedElementStyle}
                        labelText={Strings.generalConfigurations.MAX_NO_INFO_QUESTION_COUNT[language]}
                        value={configurations.MaxNoInfoQuestionCount}
                        name="MaxNoInfoQuestionCount"
                        onChange={this.handleSelectChange.bind(null, "MaxNoInfoQuestionCount")}
                    >
                        <MenuItem style={styleMenuItem} value={1}>1</MenuItem>
                        <MenuItem style={styleMenuItem} value={2}>2</MenuItem>
                        <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                        <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                        <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                    </Select>

                    <SectionTitle text={Strings.generalConfigurations.newMenu.ESSENTIAL[language]} />


                    <LoadingDialog open={this.state.loading} />

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>

                    </div>
                    
                    <Toggle
                        style={toggleStyle}
                        label={Strings.generalConfigurations.ESSENTIAL_LABEL[language]}
                        toggled={configurations.EssentialQuestionsEnabled}
                        name="EssentialQuestionsEnabled"
                        onToggle={this.handleEssentialToggle}
                        tooltip={Strings.generalConfigurations.ESSENTIAL_TEXT[language]}
                    />

                    <Select
                        disabled={!configurations.EssentialQuestionsEnabled}
                        style={noaRelatedElementStyle}
                        labelText={Strings.generalConfigurations.MIN_ESSENTIAL_QUESTION_COUNT[language]}
                        value={configurations.EssentialQuestionsCount}
                        name="EssentialQuestionsCount"
                        onChange={this.handleSelectChange.bind(null, "EssentialQuestionsCount")}
                        errorText={errors ? errors.EssentialQuestionsCount ? errors.EssentialQuestionsCount[0]: "" : ""}
                    >
                        <MenuItem style={styleMenuItem} value={1}>1</MenuItem>
                        <MenuItem style={styleMenuItem} value={2}>2</MenuItem>
                        <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                        <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                        <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                    </Select>

                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        },

        onGroupDeleted: (groupsNames) => {
            dispatch(actions.storeGroupsNames(groupsNames));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConfigurations);
