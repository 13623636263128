import React from 'react';

// Material UI Imports
import CircularProgress from 'material-ui/CircularProgress';

import API from '../../API';

import Auth from '../../Authentication';

import ButtonSecondary from '../mui/ButtonSecondary';

import SvgCheckIcon from 'material-ui/svg-icons/action/check-circle';
import SvgWarningIcon from 'material-ui/svg-icons/alert/warning';


import Alert from 'react-bootstrap/lib/Alert';

import SectionTitle from '../../components/SectionTitle';
import Strings from '../../constants/strings';


import Upload from '../../components/FileUpload2';

import { Card, CardHeader, CardContent } from '@material-ui/core';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import validate from 'validate.js';

import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

validate.validators.presence.message = " é obrigatório.";

const flexRowStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
};

const inputsInterface = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const alertStyle = {
    marginTop: "20px"
};

const docInfoTitle = {
    fontSize: "15px",
    color: "rgb(0, 145, 137)",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px"
}

const imgOkTextResult = {
    fontsize: "16px",
    fontWeight: 700,
    color: "black",
    marginLeft: "5px",
    borderBottom: "4px solid green"
}

const imgFalseTextResult = {
    fontsize: "16px",
    fontWeight: 700,
    color: "black",
    marginLeft: "5px",
    borderBottom: "4px solid #d9534f"
}


class DocumentValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            configurations: this.props.configurations,
            isValidaImageSize: true,
            limitImageSizeReached: false,
            key: "CPF",
            value: '',
            generatingLoading: false,
            getStatusLoading: false,            
            imageManipulation: false,
            usedFaceMatch: false,
            usedImageManipulation: false,
            documentImage: null,
            faceImage: null
        }
    }

    // handleOpenModal() {
    //     this.setState({ showModal: true });
    // }

    // handleCloseModal() {
    //     this.setState({ showModal: false });
    // }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    onKeyChange = (field, event, index, value) => {
        let newState = this.state;
        newState[field] = value;
        this.setState(newState);
    };

    onValueChange = (field, event, value) => {
        let newState = this.state;
        newState[field] = value;
        this.setState(newState);
    };

    onGenerate = async () => {
        this.setState({ generatingLoading: true, questionsEvent: null, answersError: null });

        let result;

        let params = [];

        if (this.state.value) {
            params.push(`${this.state.key}=${this.state.value}`);
        }

        const base64Prefix = "base64,";

        if (this.state.documentImage) {
            let documentImage = this.state.documentImage;
            documentImage = documentImage.substring(documentImage.indexOf(base64Prefix) + base64Prefix.length);
            params.push(`DOC_IMG=${documentImage}`);


            if (this.state.imageManipulation) {
                this.state.usedImageManipulation = true;
            }

            result = await API.verifyID(params, this.props.selectedDomain);

            if (result.DocInfo) {
                if (result.DocInfo.CPF) {
                    this.setState({ value: result.DocInfo.CPF });
                }
            }

        } else {
            result = await API.getQuestions(params, this.props.selectedDomain);
        }

        this.setState(
            {
                questionsEvent: result,
                faceImage: null,
                documentImage: null,
                generatingLoading: false,
                bigIdEvent: null,
                answers: new Array(result.Questions.length)
            }
        );
    };


    // -- RENDER -- //
    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} thickness={3.5} size={35} />
        )
    }

    renderEventStatusInterface() {
        return (
            <div>
                {this.renderLoading(this.state.getStatusLoading)}

                {this.renderEventStatus()}
            </div>
        )
    }

    renderAlert() {
        if (this.state.questionsEvent) {
            if (this.state.questionsEvent.ResultCode !== 0 && this.state.questionsEvent.ResultCode !== 70) {
                return (
                    <Alert bsStyle="danger" style={alertStyle}>
                        <h4> Código: {this.state.questionsEvent.ResultCode}</h4>
                        <p> Mensagem: {this.state.questionsEvent.ResultMessage}</p>
                    </Alert>
                )
            } else {
                //sucessful alert when facematch works
                if (this.state.questionsEvent.ResultCode === 70 && this.state.usedFaceMatch) {
                    return (
                        <Alert bsStyle="success" style={alertStyle}>
                            <h4> Código: 80</h4>
                            <p> Mensagem: Face Picture Match</p>
                        </Alert>
                    )
                }
            }
        }
    }

    renderForensincAlert() {
        //Forensic Alerts
        if (this.state.questionsEvent) {
            let forensicObj = Object(this.state.questionsEvent.ForensicValidations)
            let forensicKeys = Object.keys(forensicObj)
            if (forensicKeys.length > 0) {
                return (
                    <div>
                        {
                            forensicKeys.map(foren => {
                                return (
                                    <Alert bsStyle="danger" style={alertStyle}>
                                        <h4> Código: {foren}</h4>
                                        <p> Mensagem: {forensicObj[foren]}</p>
                                    </Alert>
                                )
                            })
                        }
                    </div>
                )
            }
        }
    }

    renderImageSizeWarning() {
        if (!this.state.isValidaImageSize && !this.state.limitImageSizeReached) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <p>{Strings.generalConfigurations.PHOTO_SIZE_WARNING[language]}</p>
                </Alert>
            )

        } else if (!this.state.isValidaImageSize && this.state.limitImageSizeReached) {
            return (
                <Alert bsStyle="danger" style={alertStyle}>
                    <p>{Strings.generalConfigurations.MAX_PHOTO_SIZE_WARNING[language]}</p>
                </Alert>
            )

        }

    }

    onFileLoad = (stateField, e, file) => {
        const bigAcceptedImageBytes = 1000000//4000000
        const limitImageBytes = 4000000


        if (file.size > bigAcceptedImageBytes && file.size < limitImageBytes) {
            this.setState({ isValidaImageSize: false })
            return
        } else if (file.size > limitImageBytes) {
            this.setState({ isValidaImageSize: false, limitImageSizeReached: true })
            return
        }

        let newState = this.state;
        newState[stateField] = e.target.result;
        this.setState(newState);
    };


    renderOcrRequestInterface() {
        if (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) {
            return null;
        }
        return (
            <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "40%" }}>

                    <div style={flexRowStyle}>
                        <div>
                            <Upload  
                                label={Strings.generalConfigurations.OCR_PHOTO_LABEL[language]} 
                                uploaded={this.state.documentImage !== null} 
                                onFileLoad={this.onFileLoad.bind(this, "documentImage")}
                                tooltip={Strings.generalConfigurations.OCR_PHOTO_LABEL[language]}
                            />
                        </div>

                    </div>
                </div>

                <div style={{ float: "right", width: "60%" }}>
                    {this.renderGenerateButton()}
                </div>
                
            </div>
        )
    }

    renderGenerateButton() {
        let style = { margin: "10px", textTransform: "none" };
        //let labelStyle = { textTransform: "capitalize" };

        if (this.state.documentImage != null) {
            return (
                <div>
                    <ButtonSecondary
                        label={Strings.generalConfigurations.OCR_EXTRACT_BUTTON[language]}
                        style={style}
                        onClick={this.onGenerate}
                    />
                </div>
            );
        }
    }

    renderDocInfo() {
        if (this.state.questionsEvent) {
            if (this.state.questionsEvent.DocInfo) {

                let docInfo = this.state.questionsEvent.DocInfo;
                let forensicInfo = this.state.questionsEvent.OfficialInfo;
                let imgManipulationInfo = this.state.questionsEvent.ImageAnalysis;

                if (Object.keys(docInfo).length < 1) {
                    return null;
                }

                return (
                    <Card initiallyExpanded={true}>
                        <CardHeader
                            title={(
                                <div>
                                    <h4>{Strings.generalConfigurations.OCR_REPORT_TITLE[language]}</h4>
                                    <h5>TicketId - {this.state.questionsEvent.TicketId}</h5>
                                </div>

                            )}
                            actAsExpander={true}
                            showExpandableButton={true}
                        />
                        <CardContent expandable={true}>
                            {this.renderImageManipulationResult(imgManipulationInfo)}
                            {this.renderDocumentInformations(docInfo)}
                            {this.renderForensicInformations(forensicInfo)}
                        </CardContent>
                    </Card>
                )
            }
        }
    }

    renderDocumentInformations(docInfo) {
        let fields = Object.keys(docInfo);

        const allowedParams = [
            "DOCTYPE",
            "SIDE",
            "CPF",
            "NAME",
            "BIRTHDATE",
            "MOTHERNAME",
            "FATHERNAME",
            "IDENTIFICATIONNUMBER",
            "IDENTIFICATIONUF",
            "ORGEMISSION",
            "VALIDDATE",
            "PLACEOFEMISSION",
            "CNHNUMBER",
            "FIRSTQUALIFICATIONDATE"
        ];

        //Sort by allowedParams order: Ex: DOCTYPE will be the first shown parameter
        //New params will be showed in last
        const sortedFields = fields.sort(function (a, b) {
            var indexA = allowedParams.indexOf(a)
            var indexB = allowedParams.indexOf(b);
            if (indexA === -1) {
                indexA = 100;
            }
            if (indexB === -1) {
                indexB = 100;
            }
            return indexA - indexB;
        });

        return (
            <div>
                <h3 style={docInfoTitle}>{Strings.generalConfigurations.OCR_REPORT_EXTRACTION_TITLE[language]}</h3>
                {sortedFields.map(field => {
                    let value = docInfo[field];

                    return (
                        <div style={flexRowStyle}>
                            <label htmlFor="">{field}: </label>
                            <div style={{ marginLeft: '5px' }}> {value}</div>
                        </div>
                    )
                })}
                <hr></hr>
            </div>
        )
    }

    renderForensicInformations(forensicInfo) {
        //change number to % on view
        if(forensicInfo.FATHERNAMEMATCHPERCENTAGE !== undefined)
        {
            forensicInfo.FATHERNAMEMATCHPERCENTAGE = forensicInfo.FATHERNAMEMATCHPERCENTAGE * 100 + '%';
        }

        if(forensicInfo.NAMEMATCHPERCENTAGE !== undefined)
        {
            forensicInfo.NAMEMATCHPERCENTAGE = forensicInfo.NAMEMATCHPERCENTAGE * 100 + '%';
        }

        if(forensicInfo.BIRTHDATEMATCH !== undefined)
        {
            forensicInfo.BIRTHDATEMATCH = forensicInfo.BIRTHDATEMATCH * 100 + '%';
        }

        if(forensicInfo.MOTHERNAMEMATCHPERCENTAGE !== undefined)
        {
            forensicInfo.MOTHERNAMEMATCHPERCENTAGE = forensicInfo.MOTHERNAMEMATCHPERCENTAGE * 100 + '%';
        }

        return (
            <div>
                <h3 style={docInfoTitle}>{Strings.generalConfigurations.OCR_REPORT_VALIDATION_TITLE[language]}</h3>
                    {Object.keys(forensicInfo).map(field => {
                        let value = forensicInfo[field];
                        return (
                            <div style={flexRowStyle}>
                                <label htmlFor="">{field}: </label>
                                <div style={{marginLeft: '5px'}}> {value}</div>
                            </div>
                        )
                    })}
            </div>
        )
    }

    renderImageManipulationResult(imgManipulationInfo) {
        if (this.state.usedImageManipulation) {
            if (imgManipulationInfo.MANIPULATEDIMAGE.toLowerCase() === 'digitaly ok') {
                return (
                    <div>
                        <div style={flexRowStyle}>
                            <SvgCheckIcon style={{ color: "green", width: "30px", height: "30px" }}></SvgCheckIcon>
                            <p style={imgOkTextResult}>Imagem sem indícios de manipulação digital</p>
                        </div>
                        <hr></hr>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div style={flexRowStyle}>
                            <SvgWarningIcon style={{ color: "rgb(217, 83, 79)", width: "30px", height: "30px" }}></SvgWarningIcon>
                            <p style={imgFalseTextResult}>Foram detectados possíveis indícios de manipulação digital na imagem</p>
                        </div>
                        <hr></hr>
                    </div>
                )
            }
        }
    }

    render() {
        let demoTitle = (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser) ? "Questionário" : Strings.generalConfigurations.OCR_PAGE_TITLE[language];
        return (
            <div>
                <SectionTitle text={demoTitle} />

                <div style={inputsInterface}>

                    {this.renderOcrRequestInterface()}

                    {this.renderLoading(this.state.generatingLoading)}
                </div>

                <div>
                    {this.renderDocInfo()}
                </div>

                <div>
                    {this.renderAlert()}
                    {this.renderForensincAlert()}
                </div>

                <div>
                    {this.renderImageSizeWarning()}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.selectedDomain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentValidation);