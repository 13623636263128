import API from './API';

export default class Auth {
    static sessionData = {
        isAuthenticated: false,
        token: null,
        activeGroupConfiguration: null,
        groupsNames: null,
        login: null,
        password: null,
        isAdmin: null,
        isTelemarketing: null,
        isViewOnlyUser: null,
        userLanguage: null,
        userRegion: null,
        isReportUser: null,
        isQuestionResult: null,
        isOperatorUserTimer: null,
        refConfigurations: null
    };

    static AUTHENTICATION_OBJECT_KEY = "AUTH_OBJECT";

    static async authenticate(login, password) {
        let result = await API.login(login, password);

        if (result.status !== "ERROR") {
            Auth.saveSessionDataInLocalStorage(result, login, result.encryptedPassword);
        }

        return result;
    }

    static async authenticateSSO(token, sso, login) {
        let result = await API.loginSSO(token, sso, login);

        if (result.status !== "ERROR") {
            Auth.saveSessionDataInLocalStorage(result, login, result.encryptedPassword);
        }

        return result;
    }

    static logout() {
        API.logout(Auth.sessionData.token);
        localStorage.removeItem(Auth.AUTHENTICATION_OBJECT_KEY);
        Auth.sessionData.isAuthenticated = false;
        Auth.sessionData.token = null;
    }

    static isLogged() {
        if (Auth.sessionData.token === null) {
            Auth.loadSessionDataFromLocalStorage();
        }

        return Auth.sessionData.isAuthenticated;
    }

    static saveSessionDataInLocalStorage(result, login, password) {
        Auth.sessionData.login = login;
        Auth.sessionData.password = password;
        Auth.sessionData.createdAt = result.sessionCreatedAt;
        Auth.sessionData.isAuthenticated = true;
        Auth.sessionData.token = result.token;
        Auth.sessionData.timeout = result.timeout;
        Auth.sessionData.activeGroupConfiguration = result.configurations;
        Auth.sessionData.questionsProperties = result.questionsProperties;
        Auth.sessionData.groupsNames = result.groupsNames;
        Auth.sessionData.isAdmin = result.isAdmin;
        Auth.sessionData.isTelemarketing = result.isTelemarketing;  
        Auth.sessionData.isViewOnlyUser = result.isViewOnlyUser;
        Auth.sessionData.isOperatorUserTimer = result.isOperatorUserTimer;
        Auth.sessionData.isReportUser = result.isReportUser;
        Auth.sessionData.userLanguage = result.userLanguage;
        Auth.sessionData.userRegion = result.userRegion;
        Auth.sessionData.isQuestionResult = result.isQuestionResult;
        Auth.sessionData.refConfigurations = result.refConfigurations;

        localStorage.setItem("visitedBGCPage", false);

        localStorage.setItem(Auth.AUTHENTICATION_OBJECT_KEY, JSON.stringify(Auth.sessionData));
    }

    static loadSessionDataFromLocalStorage() {
        

        if (Auth.sessionData.token === null) {
            let sessionData = JSON.parse(localStorage.getItem(Auth.AUTHENTICATION_OBJECT_KEY));

            localStorage.setItem("visitedBGCPage", false);
            
            if (sessionData !== null) {
                Auth.sessionData.createdAt = sessionData.createdAt;
                Auth.sessionData.token = sessionData.token;
                Auth.sessionData.timeout = sessionData.timeout;
                Auth.sessionData.activeGroupConfiguration = sessionData.activeGroupConfiguration;
                Auth.sessionData.questionsProperties = sessionData.questionsProperties;
                Auth.sessionData.groupsNames = sessionData.groupsNames;
                Auth.sessionData.login = sessionData.login;
                Auth.sessionData.password = sessionData.password;
                Auth.sessionData.isAdmin = sessionData.isAdmin;
                Auth.sessionData.isTelemarketing = sessionData.isTelemarketing;
                Auth.sessionData.isViewOnlyUser = sessionData.isViewOnlyUser;
                Auth.sessionData.isOperatorUserTimer = sessionData.isOperatorUserTimer;
                Auth.sessionData.isReportUser = sessionData.isReportUser;
                Auth.sessionData.userLanguage = sessionData.userLanguage;
                Auth.sessionData.userRegion = sessionData.userRegion;
                Auth.sessionData.isAuthenticated = true;
                Auth.sessionData.isQuestionResult = sessionData.isQuestionResult;
                Auth.sessionData.refConfigurations = sessionData.refConfigurations;
            }
        }

        const createdAt = new Date(Auth.sessionData.createdAt);
        let diffSeconds = Math.abs(createdAt.getTime() - Date.now()) / 1000;
        if (diffSeconds > Auth.sessionData.timeout) {
            Auth.sessionData.isAuthenticated = false;
        }
    }

    static changeCurrentGroup(result) {
        if(result.status !== "ERROR"){
            Auth.sessionData.activeGroupConfiguration = result.configurations;
            Auth.sessionData.groupsNames = result.groupsNames;
            localStorage.setItem(Auth.AUTHENTICATION_OBJECT_KEY, JSON.stringify(Auth.sessionData));
        }        
    }

    static getToken() {
        Auth.loadSessionDataFromLocalStorage();
        return Auth.sessionData.token;
    }

    static getCurrentConfigurations() {
        Auth.loadSessionDataFromLocalStorage();
        return Auth.sessionData.activeGroupConfiguration;
    }

    static getQuestionsProperties() {
        Auth.loadSessionDataFromLocalStorage();
        return Auth.sessionData.questionsProperties;
    }

    static getSessionData(){
        Auth.loadSessionDataFromLocalStorage();
        return Auth.sessionData;
    }
}
