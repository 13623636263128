import SelectField from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import React from 'react';

import SelectGroupConfiguration from '../components/SelectGroupConfiguration';

import Auth from '../Authentication';
import Strings from '../constants/strings.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Material UI Imports
import API from '../API';

import { connect } from 'react-redux';
import * as actions from '../actions';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const ExpandMoreIconStyle = {
    width: "30px",
    height: "30px"
};

const styleMenuItem = {
    fontSize: "16px",
    padding: "14px"
};

class GroupsSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            modified: this.props.modified,
            displayCreateGroupForm: false,
            groups: this.props.groupsNames,
            domain: Auth.getCurrentConfigurations().Domain,
            fullGroupList: this.props.groupsNames
        }
    }


    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
            modified: newProps.modified,
        });
    }


    updateGroupConfiguration = () => {
        this.setState({
            configurations: Auth.getCurrentConfigurations(),
            menuActive: Strings.generalConfigurations.menu.GENERAL,
            displayCreateGroupForm: false,
        });
        this.props.onSelectGroup(Auth.getCurrentConfigurations());
    };

    render() {
        if (this.props.groupsNames.includes(Auth.sessionData.activeGroupConfiguration.Name)) {
            return (
                <div>
                    <SelectField
                        id="change-group-config"
                        IconComponent={() => (<ExpandMoreIcon style={ExpandMoreIconStyle} />)}
                        onChange={this.handleTabChange}
                        value={Auth.sessionData.activeGroupConfiguration.Name}
                    >
                        {this.props.groupsNames.map((value) => {
                            if (Auth.sessionData.activeGroupConfiguration.Name === value && this.state.modified) {
                                return (<MenuItem style={styleMenuItem} id={"group-config-" + value} value={value}>{value} *</MenuItem>)
                            }
                            return (<MenuItem key={"menu1"} style={styleMenuItem} id={"group-config-" + value} value={value}>{value}</MenuItem>)
                        })}
                        <MenuItem style={styleMenuItem} id="new-config-group" value={'+'}><b>{Strings.groupConfiguration.NEW_GROUP_SELECT[language]}</b></MenuItem>)
                </SelectField>

                    <SelectGroupConfiguration open={this.state.displayCreateGroupForm}
                        onCancelNewGroupConfiguration={() => { this.setState({ displayCreateGroupForm: false }) }}
                        updateGroupConfiguration={this.updateGroupConfiguration} />
                </div>
            )
        } else {
            return (
                <div>Renderizando...</div>
            )
        }

    }

    handleTabChange = async (event) => {
        var newGroup = event.target.value;
        if (newGroup === "+") {
            this.setState({ displayCreateGroupForm: true });
            return;
        }

        if (newGroup === "right") {
            this.goRight();
            return;
        }

        this.setState({ loading: true });
        let requestDomain = this.props.selectedDomain
        if(!(this.props.selectedDomain)){
            requestDomain = this.state.domain
        }
        let result = await API.selectGroup(newGroup, requestDomain);
        this.props.onSelectGroup(result.configurations);
        this.setState({ configurations: Auth.getCurrentConfigurations() });
        this.setState({ loading: false });
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        groupsNames: state ? state.groupsNames : [],
        modified: state ? state.modified : false,
        selectedDomain: state ? state.selectedDomain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSelect);