import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DialogItem from './configurations/DialogItem';
import Strings from '../constants/strings';
import UserLanguage from './configurations/UserLanguage.js';

var language = UserLanguage.getLanguage();

export default class DialogNew extends React.Component {
    constructor (props){
        super(props);
        this.state = {}
    }

    getFaceMatchCustomDialog() {
        let { DARK_IMAGE, LITTLE_IMAGE, ROTATION_IMAGE, FOCUS_CAMERA } = Strings.faceMatchTooltips;
        return(
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                autoScrollBodyContent={this.props.autoScrollBodyContent}
                aria-labelledby={this.props.dialogTitleId}
            >
                <DialogTitle id={this.props.dialogTitleId}>
                    {Strings.faceMatchTooltips.TOOLTIP_TITLE[language]}
                    <IconButton aria-label="close" onClick={this.props.onClose} style={{left: "28%"}}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogItem title={DARK_IMAGE.title[language]} text={DARK_IMAGE.text[language]} image={DARK_IMAGE.image} order={2} />
                    <DialogItem title={LITTLE_IMAGE.title[language]} text={LITTLE_IMAGE.text[language]} image={LITTLE_IMAGE.image} order={2} />
                    <DialogItem title={ROTATION_IMAGE.title[language]} text={ROTATION_IMAGE.text[language]} image={ROTATION_IMAGE.image} order={2} />
                    <DialogItem title={FOCUS_CAMERA.title[language]} text={FOCUS_CAMERA.text[language]} image={FOCUS_CAMERA.image} order={2} />
                </DialogContent>
                <DialogActions>
                    {this.props.actions}
                </DialogActions>
            </Dialog>
        )
    }

    getOCRCustomDialog() {
        let { REMOVE_PROTECTION, REMOVE_NOISE, ADJUST_FOLDING, ADJUST_ROTATION, ADJUST_CAMERA_ROTATION, ADJUST_BRIGHTNESS, ADJUST_FOCUS, ADJUST_AREA } = Strings.validatingDocumentsTooltips;
        return(
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                autoScrollBodyContent={this.props.autoScrollBodyContent}
                aria-labelledby={this.props.dialogTitleId}
            >
                <DialogTitle id={this.props.dialogTitleId}>
                    {Strings.validatingDocumentsTooltips.TOOLTIP_TITLE[language]}
                    <IconButton aria-label="close" onClick={this.props.onClose} style={{left: "13%"}}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogItem title={REMOVE_PROTECTION.title[language]} text={REMOVE_PROTECTION.text[language]} image={REMOVE_PROTECTION.image} order={2} />
                    <DialogItem title={REMOVE_NOISE.title[language]} text={REMOVE_NOISE.text[language]} image={REMOVE_NOISE.image} order={2} />
                    <DialogItem title={ADJUST_FOLDING.title[language]} text={ADJUST_FOLDING.text[language]} image={ADJUST_FOLDING.image} order={2} />
                    <DialogItem title={ADJUST_ROTATION.title[language]} text={ADJUST_ROTATION.text[language]} image={ADJUST_ROTATION.image} order={2} />
                    <DialogItem title={ADJUST_CAMERA_ROTATION.title[language]} text={ADJUST_CAMERA_ROTATION.text[language]} image={ADJUST_CAMERA_ROTATION.image} order={2} />
                    <DialogItem title={ADJUST_BRIGHTNESS.title[language]} text={ADJUST_BRIGHTNESS.text[language]} image={ADJUST_BRIGHTNESS.image} order={2} />
                    <DialogItem title={ADJUST_FOCUS.title[language]} text={ADJUST_FOCUS.text[language]} image={ADJUST_FOCUS.image} order={2} />
                    <DialogItem title={ADJUST_AREA.title[language]} text={ADJUST_AREA.text[language]} image={ADJUST_AREA.image} order={2} />
                </DialogContent>
                <DialogActions>
                    {this.props.actions}
                </DialogActions>
            </Dialog>
        )
    }

    getNonCustomDialog() {
        return(
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                autoScrollBodyContent={this.props.autoScrollBodyContent}
                aria-labelledby={this.props.dialogTitleId}
            >
                <DialogTitle id={this.props.dialogTitleId}>
                    {this.props.title}
                    <IconButton aria-label="close" onClick={this.props.onClose} style={{left: "15%"}}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {this.props.dialogContent}
                </DialogContent>
                <DialogActions>
                    {this.props.actions}
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        let origin = this.props.originCall

        if(origin === 'OCR')
            return (this.getOCRCustomDialog())
        else if (origin === 'FaceMatch')
            return (this.getFaceMatchCustomDialog())
        else
            return (this.getNonCustomDialog())
    }
}