import React from 'react';

import Toggle from '../mui/Toggle';
import SectionTitle from '../SectionTitle';
import Auth from '../../Authentication';

import Strings from '../../constants/strings';

import { connect } from 'react-redux';
import * as actions from '../../actions';
import NameMatchValidation from "../NameMatchValidation";
import AgeValidation from "../AgeValidation";
import LoadingDialog from "../LoadingDialog";

import VelocityValidation from "../VelocityValidation";

import validate from 'validate.js';
import ApiConstants from "../../constants/api";
import API from "../../API";
import IpVelocityValidation from '../IpVelocityValidation';

import UserLanguage from '../configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();


const cardContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const columnStyle = {
    width: 385
};

class Validations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: props.configurations,
            domain: Auth.getCurrentConfigurations().Domain,
        }
    }

    constraints = {
        Name: {
            presence: {
                message: "Nome é obrigatório"
            },

            length: {
                maximum: 20,
                message: "Nome deve ter menos de 20 caracteres."
            }
        }
    };

    options = {
        fullMessages: false,
    };

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleValidationToggle = (index, event, value) => {
        const newState = this.state;
        newState.configurations.BigIdValidationOptions.find((x) => x.Name === index).Enabled = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    };

    handleIpVelocityValidationToggle = (index, event, value) => {
        const validationName = "BIGID_IP_VELOCITY"
        const newState = this.state;
        const bigIdValidationOptionsItem = {
            Name: "BIGID_IP_VELOCITY",
            Enabled: value,
            Configurations: {
                QUANTITY: "30",
                TIME: "1800000"
            },
            ListsConfiguration: {}
        };

        let hasIpVelocityValidation = false;

        newState.configurations.BigIdValidationOptions.map(validationItem => {
            if (validationItem.Name === validationName) {
                hasIpVelocityValidation = true;
                validationItem.Enabled = value;
            }

            return null;
        });

        if (!hasIpVelocityValidation) {
            newState.configurations.BigIdValidationOptions.push(bigIdValidationOptionsItem)
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);

    }


    handleAgeValidationToggle = (index, event, value) => {
        const validationName = "BIGID_AGE_VALIDATION"
        const newState = this.state;

        const bigIdValidationOptionsItem = {
            Name: validationName,
            Enabled: value,
            Configurations: {
                MINIMUM_AGE: "18",
            },
            ListsConfiguration: {}
        };


        let hasAgeValidation = false;

        newState.configurations.BigIdValidationOptions.map(validationItem => {
            if (validationItem.Name === validationName) {
                hasAgeValidation = true;
                validationItem.Enabled = value;
            }
            return null;
        })

        if (hasAgeValidation) {
            newState.configurations.BigIdValidationOptions.find((x) => x.Name === validationName).Enabled = value;
        } else {
            newState.configurations.BigIdValidationOptions.push(bigIdValidationOptionsItem)
        }

        this.setState({ newState });
        this.props.onSave(newState.configurations);
    };


    handleQuestionnaireOCR = (index, event, value) => {
        const newState = this.state;
        newState.configurations.GenQuestionnaireAfterOCR = value;
        this.setState({ newState });
        this.props.onSave(newState.configurations);
    };

    handleNameMatchChange = (value) => {
        let state = this.state;
        let validation = state.configurations.BigIdValidationOptions.filter((x) => {
            return x.Name === "BIGID_NAME_MATCH"
        })[0];
        validation.Configurations.MATCH_PERCENT = value;
        this.setState({ state });
        this.props.onSave(state.configurations);
    };

    handleAgeValidationChange = (value) => {
        let state = this.state;
        let validation = state.configurations.BigIdValidationOptions.filter((x) => {
            return x.Name === "BIGID_AGE_VALIDATION"
        })[0];
        validation.Configurations.MINIMUM_AGE = value;
        this.setState({ state });
        this.props.onSave(state.configurations);
    };

    handleIPVelocityQuantityChange = (value) => {
        let state = this.state;
        let validation = state.configurations.BigIdValidationOptions.filter((x) => {
            return x.Name === "BIGID_IP_VELOCITY"
        })[0];
        validation.Configurations.QUANTITY = value;
        this.setState({ state });
        this.props.onSave(state.configurations);
    };

    handleIPVelocityTimeChange = (value) => {
        let state = this.state;
        let validation = state.configurations.BigIdValidationOptions.filter((x) => {
            return x.Name === "BIGID_IP_VELOCITY"
        })[0];
        validation.Configurations.TIME = (value * 60 * 1000).toString();
        this.setState({ state });
        this.props.onSave(state.configurations);
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const newState = this.state;
        newState.configurations[name] = value;

        const validationErrors = validate(newState.configurations, this.constraints);

        newState.errors = validationErrors;

        this.setState(newState);
        this.props.onSave(newState.configurations);
    }

    handleToggle = (event, toggled) => {
        const target = event.target;
        const value = toggled;
        const name = target.name;

        if (name === "IsDefault") {
            if (toggled) {
                this.setState({ confirmDefault: true });
            }
            return;
        }

        const newState = this.state;
        newState.configurations[name] = value;

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleIsDefaultConfirmed = () => {
        const newState = this.state;
        newState.configurations.IsDefault = true;
        newState.confirmDefault = false;

        if (validate(newState.configurations, this.constraints) === undefined) {
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    handleGroupDeleted = async () => {
        this.setState({ loading: true });
        const result = await API.deleteGroup(this.state.configurations.Name, this.state.domain);
        this.setState({ loading: false });

        if (result.status === ApiConstants.status.SUCCESS) {
            this.props.onGroupDeleted(result.groupsNames);
            this.props.onSelectGroup(result.configurations);
        }
    };

    // -- RENDER -- //
    renderConfirmDialog(configurations) {
        return (
            <div>
                <h4>{Strings.generalConfigurations.CONFIRM_DEFAULT_ALERT[language].replace("#",configurations.Name)}</h4>
                <p>{Strings.generalConfigurations.CONFIRM_DEFAULT_TEXT[language]}</p>
            </div>
        )
    }


    render() {
        const configurations = this.state.configurations;
        const errors = validate(this.state.configurations, this.constraints, this.options);

        //Initializing the special validations. They can NOT be in the database yet.
        let bigIdAgeValidation = null;
        let bigIdIpVelocityValidation = null;
        console.info(`configurations.BigIdValidationOptions: `, configurations.BigIdValidationOptions);
        return (
            <div style={cardContainerStyle}>
                <div key="left-column" style={columnStyle}>
                    <LoadingDialog open={this.state.loading} />

                    <SectionTitle text={Strings.SIDE_MENU_TRANSLATION.VALIDAÇÕES[language]}/>

                    {configurations.BigIdValidationOptions.map((validation, index) => {
                        console.log("Validation", validation);
                        if (!Strings.validations.simpleValidations[validation.Name]) {
                            return null;
                        }
                            
                        return (
                            <Toggle
                                tooltip={Strings.tooltips.validations[validation.Name][language]}
                                label={Strings.validations.PREFIX[language] + Strings.validations.simpleValidations[validation.Name][language]}
                                toggled={validation.Enabled}
                                key={validation.Name}
                                name={validation.Name}
                                onToggle={this.handleValidationToggle.bind(null, validation.Name)}
                            />
                        )
                    })}

                    {/* Show all default validations even if they are not in the database. */}
                    {/* If they are at the database, validate the Enabled property, if not, show them as Enabled: False */}
                    {configurations.BigIdValidationOptions.filter((x) => {
                        return x.Name === "BIGID_NAME_MATCH"
                    }).map((validation) => {
                        return (
                            <NameMatchValidation
                                validation={validation}
                                handleValidationToggle={this.handleValidationToggle}
                                nameMatchChange={this.handleNameMatchChange}
                            />
                        )
                    })}

                    {/* Show all default validations even if they are not in the database. */}
                    {configurations.BigIdValidationOptions.map((validation) => {
                        switch (validation.Name) {
                            case "BIGID_AGE_VALIDATION":
                                bigIdAgeValidation = validation
                                break;
                            case "BIGID_IP_VELOCITY":
                                bigIdIpVelocityValidation = validation
                                break;
                            default:
                                break;
                        }
                        return null;
                    })}


                    <AgeValidation
                        validation={bigIdAgeValidation}
                        handleValidationToggle={this.handleAgeValidationToggle}
                        minimumAgeChange={this.handleAgeValidationChange}
                    />


                    {configurations.BigIdValidationOptions.filter((x) => {
                        return x.Name === "BIGID_VELOCITY_KEY"
                    }).map((validation) => {

                        return (
                            <VelocityValidation
                                validation={validation}
                                handleValidationToggle={this.handleValidationToggle}
                            />
                        );
                    })}

                    <IpVelocityValidation
                        validation={bigIdIpVelocityValidation}
                        onQuantityChange={this.handleIPVelocityQuantityChange}
                        onTimeChange={this.handleIPVelocityTimeChange}
                        handleValidationToggle={this.handleIpVelocityValidationToggle}>
                    </IpVelocityValidation>


                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        },

        onGroupDeleted: (groupsNames) => {
            dispatch(actions.storeGroupsNames(groupsNames));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Validations);
