import React from 'react';
import ReactDOM from 'react-dom';

import Strings from '../../constants/strings';

import Alert from 'react-bootstrap/lib/Alert';

// Material UI Imports
import {CircularProgress} from '@material-ui/core';
import SectionTitle from '../SectionTitle';
import SelectField from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import Auth from '../../Authentication';

import ButtonSecondary from '../mui/ButtonSecondary';

// API
import { connect } from 'react-redux';
import * as actions from '../../actions';

import API from '../../API';

import Chart from 'chart.js';
import 'chart.piecelabel.js';

import moment from 'moment';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import DateTimePicker from 'react-datetime-picker';

import {Divider} from '@material-ui/core';

import UserLanguage from '../configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const reportContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
};

const reportFieldStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: '10px 0'
};

const reportFieldLabelStyle = {
    fontSize: "17px",
    fontWeight: "bold"
};

const reportFieldValueStyle = {
    fontSize: "17px",
    color: "#888"
};

const labelMargin = {
    marginTop: "15px"
}

const alertStyle = {
    marginTop: "20px"
};

const styleMenuItem = {
    fontSize: "16px",
    fontFamily: "HKGrotesk",
    padding: "16px"
}


class FacematchReport extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            configurations: this.props.configurations,
            endDate: new Date(),
            startDate: new Date(),
            group: Auth.getSessionData().activeGroupConfiguration.Name
        }

        this.setState({ startDate: this.state.startDate.setDate(this.state.startDate.getDate() - 30) })
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
        });
    }

    // -- HANDLERS -- //
    handleChange = (field, event) => {
        const newState = {};
        if (field == 'group') {
            newState[field] = event.target.value;
        } else {
            newState[field] = event;
        }

        this.setState(newState);
    };

    handleGenerate = async () => {
        this.setState({ loading: true, error: null });

        var momentStartDate = moment(this.state.startDate)
        var momentEndDate = moment(this.state.endDate)

        let diffInDays = momentEndDate.diff(momentStartDate, 'days');

        if (diffInDays > 60) {
            this.setState({ error: Strings.reports.MAX_PERIOD_ALERT[language], loading: false });
            return;
        }

        if (diffInDays < 0) {
            this.setState({ error: Strings.reports.DATE_ALERT[language], loading: false });
            return;
        }

        momentStartDate = momentStartDate.format('YYYY-MM-DDTHH:mm:ss');
        momentEndDate = momentEndDate.format('YYYY-MM-DDTHH:mm:ss');

        // Here the data is collected to bring up the results
        let report;
        if (this.props.repType === "detailed")
            report = await API.getReportData(momentStartDate, momentEndDate, "ALLGROUPS", this.props.selectedDomain, 'GenerateDetailedFaceMatchReport');
        else
            report = await API.getReportData(momentStartDate, momentEndDate, "ALLGROUPS", this.props.selectedDomain, 'GenerateGeneralFaceMatchReport');

        if (!report) {
            this.setState({ error: Strings.reports.NO_QUESTIONNAIRE_ALERT[language], loading: false });
            return;
        }

        if(report.length){
            let arrayHeader = ["_id", "Data", "Status", "Message", "Ip"]
            let csv = arrayHeader.join(",") + "\n";
            report.forEach(array => {
                csv += `${array._id}, ${array.Date}, ${array.Status}, ${array.Message}, ${array.Ip},\n`;
            });
            let csvData = new Blob([csv], { type: 'text/csv' });
            let csvUrl = URL.createObjectURL(csvData);
            let hiddenElement = document.createElement('a');
            hiddenElement.href = csvUrl;
            hiddenElement.target = "_blank";
            hiddenElement.download = "Facematch_detailed.csv";
            hiddenElement.click();
            this.setState({loading: false});
            return;
        }

        let data = {
            labels: [Strings.reports.APROVED[language],
            Strings.reports.DISAPROVED[language],
            Strings.reports.INVALID_REQUESTS[language], 
            Strings.reports.NOT_ENOUGH_INFO[language],
            Strings.reports.INVALID_FORMAT[language]],
            datasets: [
                {
                    label: "# de questionários",
                    data: [report.Success, 
                    report.NotSuccess,
                    report.BadInformation, 
                    report.BadRequest,
                    report.NoInfo,
                    report.InvalidFormat
                    ],

                    backgroundColor: [
                        '#6EBEA2',
                        '#F6454C',
                        '#00B2DE',
                        '#A090C0',
                        '#F8C261',
                        'rgba(255, 159, 64, 1)'
                    ],
                }
            ]
        };

        let canvas = ReactDOM.findDOMNode(this.refs.chart);

        if (this.state.chart) {
            this.state.chart.destroy();
        }

        let chart = new Chart(canvas, {
            type: 'pie',
            data: data,
            options: {
                legend: {
                    position: "bottom",
                    labels: {
                        fontSize: 10,
                        boxWidth: 20
                    }
                },

                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let allData = data.datasets[tooltipItem.datasetIndex].data;
                            let tooltipLabel = data.labels[tooltipItem.index];
                            let tooltipData = allData[tooltipItem.index];
                            let total = 0;
                            for (let i in allData) {
                                total += allData[i];
                            }
                            let tooltipPercentage = Math.round((tooltipData / total) * 100);
                            return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
                        }
                    }
                }
            }
        });
        this.setState({ loading: false, report: report, chart: chart });
    };

    // -- RENDER -- //
    renderReportField(label, value) {
        return (
            <div style={reportFieldStyle}>
                <div style={reportFieldLabelStyle}>{label}:</div>

                <div style={reportFieldValueStyle}>
                    {value}
                </div>
            </div>
        )
    }

    renderReportSubField(label, value) {
        return (
            <div style={reportFieldStyle}>
                <div>{label}:</div>

                <div>
                    {value}
                </div>
            </div>
        )
    }

    renderReport() {
        if (this.state.report) {
            let averageGenerationTime = Math.round((this.state.report.AverageGenerationTime / 1000) * 100) / 100 + " s";
            let averageAnswerTime = Math.round((this.state.report.AverageAnswerTime / 1000) * 100) / 100 + " s";

            return (
                <div style={{ marginTop: "30px" }}>
                    {this.renderReportField("Total", this.state.report.EventsCount)}
                    {this.renderReportField(Strings.reports.FACE_FOUND[language], this.state.report.Success)}
                    {this.renderReportField(Strings.reports.FACE_NOT_FOUND[language], this.state.report.NotSuccess)}                   
                    {this.renderReportField(Strings.reports.INVALID_REQUESTS[language], this.state.report.BadRequest)}
                    {this.renderReportField(Strings.reports.NOT_ENOUGH_INFO[language], this.state.report.NoInfo)}
                    {this.renderReportField(Strings.reports.INVALID_FORMAT[language], this.state.report.InvalidFormat)}

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                    {this.renderReportSubField(Strings.reports.AVERAGE_GENERATION_TIME[language], averageGenerationTime)}

                    {this.renderReportSubField(Strings.reports.AVERAGE_ANSWER_TIME[language], averageAnswerTime)}
                </div>
            )
        }
    }

    renderLoading(should) {
        if (!should) {
            return null;
        }

        return (
            <CircularProgress style={{ marginLeft: "20px" }} />
        )
    }

    renderErrors() {
        if (this.state.error) {
            return (
                <div>
                    {this.state.error}
                </div>
            )
        }
    }

    render() {
        if (this.state.chart && this.props.repType === "detailed") {
            document.getElementById("report").style.visibility = "hidden";
        }else if(document.getElementById("report")){
            document.getElementById("report").style.visibility = "visible";
        }
        return (
            <div >
                <div>
                    <SectionTitle text={this.props.repType === "detailed" ? Strings.reports.FACEMATCH_DETAILED_TITLE[language] : Strings.reports.FACEMATCH_GENERAL_TITLE[language]} />
                    
                    <label htmlFor="" style={labelMargin}>{Strings.reports.INICIAL_DATE[language]}</label>
                    <br />
                    <DateTimePicker value={this.state.startDate} format="dd/MM/yyyy HH:mm" onChange={this.handleChange.bind(null, "startDate")} />
                    <br />

                    <label htmlFor="" style={labelMargin}>{Strings.reports.FINAL_DATE[language]}</label>
                    <br />
                    <DateTimePicker value={this.state.endDate} format="dd/MM/yyyy HH:mm" onChange={this.handleChange.bind(null, "endDate")} />
                    <br />

                    {this.renderErrors()}

                    {this.renderAllDisclaimer()}
                    
                    <ButtonSecondary key="generate" type="submit" onClick={this.handleGenerate} label={Strings.reports.GENERATE_REPORT[language]} />

                    {this.renderLoading(this.state.loading)}

                    <br />


                </div>

                <div id="report" style={reportContainerStyle}>

                    <div style={{ width: '50%' }}>
                        {this.renderReport()}
                    </div>

                    <div style={{ width: '50%' }}>
                        <canvas ref="chart" width="0" height="0"></canvas>
                    </div>

                </div>
            </div>
        )
    }

    renderAllDisclaimer() {
        if(this.state.group == "ALLGROUPS" && !this.state.chart) {
            return (
                <Alert bsStyle="warning" style={alertStyle}>
                    <p>{Strings.reports.ALL_DISCLAIMERS[language]}</p>
                </Alert>
            )
        }
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        selectedDomain: state ? state.selectedDomain : null,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FacematchReport);
