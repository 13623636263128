import React from 'react';
import Toggle from './mui/Toggle';

import Strings from '../constants/strings';
import TextField from './mui/TextField';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const defaultToggleStyle = {

};

const defaultWrapperStyle = {
    marginBottom: 10
};

const textStyle = {
    float: 'right'
};

class AgeValidation extends React.Component {

    state = {
        over: false,
        validationName: "BIGID_AGE_VALIDATION",
        age: "18"
    }


    onAgeChange = (event) => {
        this.setState({ age: event.target.value })
        this.props.minimumAgeChange(event.target.value)
    }


    render() {
        const validation = this.props.validation;

        let Configurations = null;
        const { validationName, age } = this.state

        let Enabled = null

        if (!validation) {
            Enabled = false
        } else {
            Enabled = validation.Enabled
            Configurations = validation.Configurations
        }


        const toggleStyle = Object.assign({}, defaultToggleStyle, this.props.style || {});
        let wrapperStyle = Object.assign({}, defaultWrapperStyle, this.props.wrapperStyle || {});

        if (this.state.over) {
            wrapperStyle.backgroundColor = "rgba(200,200,200,0.25)";
        }

        const ageValidationTextStyle = {
            display: (Enabled ? "block" : "none"),
            marginLeft: '10%'
        };

        return (
            <div
                style={wrapperStyle}
                key={validationName}
                onMouseOver={() => { this.setState({ over: true }) }}
                onMouseLeave={() => { this.setState({ over: false }) }}>
                <Toggle
                    tooltip={Strings.tooltips.validations.BIGID_AGE_VALIDATION[language]}
                    style={toggleStyle}
                    label={Strings.validations.PREFIX[language] + Strings.validations[validationName][language]}
                    toggled={Enabled}
                    key={validationName}
                    name={validationName}
                    onToggle={this.props.handleValidationToggle.bind(null, validationName)}
                />

                <TextField
                    tooltip={Strings.tooltips.validations.BIGID_AGE_VALIDATION_MINIMUM_AGE[language]}
                    textStyle={{ textStyle }}
                    labelStyle={{ fontWeight: 300 }}
                    type="number"
                    wrapperStyle={ageValidationTextStyle}
                    floatingLabelText={Strings.validations.BIGID_AGE_VALIDATION[language]}
                    value={validation ? Configurations.MINIMUM_AGE : age}
                    name="BIGID_AGE_VALIDATION"
                    onChange={this.onAgeChange}
                />
            </div>
        )
    }
}

export default AgeValidation;
