/* src/actions/FriendsActions.js */
import * as types from './actionsTypes';

export function saveConfigurations(configurations) {
    return {
        type: types.SAVE_CONFIGURATIONS,
        configurations
    };
}

export function selectConfigurations(configurations) {
    return {
        type: types.SELECT_CONFIGURATIONS,
        configurations
    };
}

export function storeQuestionsProperties(questionsProperties) {
    return {
        type: types.STORE_QUESTIONS_PROPERTIES,
        questionsProperties
    };
}

export function storeGroupsNames(groupsNames) {
    return {
        type: types.STORE_GROUPS_NAMES,
        groupsNames
    };
}

export function storeSelectedDomain(domain) {
    return {
        type: types.STORE_SELECTED_DOMAIN,
        domain
    };
}
