import ApiConstants from './constants/api';
import Auth from './Authentication.js';

class SERPROAPI {

    static getFormattedDate(dateString) {
        let birthdate, formattedBirthDate;
        try {
            birthdate = new Date(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))

            let month = (birthdate.getMonth() + 1).toString();

            if(month.length === 1){
                month = "0" + (birthdate.getMonth() + 1).toString();
            }

            let birtday = birthdate.getDate().toString()

            if(birtday.length === 1){
                birtday = "0" + (birthdate.getDate() + 1).toString();
            }

            formattedBirthDate = birthdate.getFullYear().toString() + "-" + month + "-" + birtday
        } catch (e) {
            return '';
        }

        return formattedBirthDate;
    }

    static async validatePf(serproParams, ticketId) {
        try {
            let URL = `${ApiConstants.URL}SerproConsult/ValidatePf`;
            const { CPF, NAME, BIRTHDATE, MOTHERNAME, FATHERNAME, CNHNUMBER, FIRSTQUALIFICATIONDATE, VALIDDATE } = serproParams
            const request =
            {
                ...ApiConstants.headers.SERPROPOST,
                body: JSON.stringify({
                    "Login": Auth.getSessionData().login,
                    "Password": Auth.getSessionData().password,
                    "Group": Auth.getCurrentConfigurations().Name,
                    "Domain": Auth.getSessionData().activeGroupConfiguration.Domain,
                    "TicketId": ticketId,
                    "key": {
                        "cpf": CPF ? CPF : "",
                    },
                    "answer": {
                        "nome": NAME ? NAME :  "",
                        "data_nascimento": this.getFormattedDate(BIRTHDATE) ? this.getFormattedDate(BIRTHDATE) : "",
                        "filiacao": {
                            "nome_mae": MOTHERNAME ? MOTHERNAME : "",
                            "nome_pai": FATHERNAME ? FATHERNAME :  "",
                        },
                        "cnh": {
                            "numero_registro": CNHNUMBER ? CNHNUMBER :  "",
                            "data_primeira_habilitacao": this.getFormattedDate(FIRSTQUALIFICATIONDATE) ? this.getFormattedDate(FIRSTQUALIFICATIONDATE) : "",
                            "data_validade": this.getFormattedDate(VALIDDATE) ? this.getFormattedDate(VALIDDATE) : ""
                        }
                    }
                }),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);
            const result = await response.json();

            switch (response.status) {
                case 200:
                    return result;
                default:
                    return {
                        status: "ERROR",
                        errors: ["Problemas com a rede de acesso."],
                    };
            }

        } catch (e) {
            return {
                status: "ERROR",
                errors: ["Problemas com a rede de acesso."],
            };
        }
    }

    static async validateFacePf(serproFaceParams, ticketId) {
        try {
            let URL = `${ApiConstants.URL}SerproConsult/ValidateFacePf`;
            const { CPF, FACEIMAGE } = serproFaceParams
            const request =
            {
                ...ApiConstants.headers.SERPROPOST,
                body: JSON.stringify({
                    "Login": Auth.getSessionData().login,
                    "Password": Auth.getSessionData().password,
                    "Group": Auth.getCurrentConfigurations().Name,
                    "Domain": Auth.getSessionData().activeGroupConfiguration.Domain,
                    "TicketId": ticketId,
                    "key": {
                        "cpf": CPF ? CPF : "",
                    },
                    "answer": {
                        "biometria_face": FACEIMAGE ? FACEIMAGE :  ""
                    }
                }),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);
            const result = await response.json();

            switch (response.status) {
                case 200:
                    return result;
                default:
                    return {
                        status: "ERROR",
                        errors: ["Problemas com a rede de acesso."],
                    };
            }

        } catch (e) {
            return {
                status: "ERROR",
                errors: ["Problemas com a rede de acesso."],
            };
        }
    }

    static async validatePfMock(serproParams, ticketId) {
        try {

            let URL = ApiConstants.URL + 'SerproConsult/ValidatePf'
            // const { CPF, NAME, BIRTHDATE, MOTHERNAME, FATHERNAME, CNHNUMBER, FIRSTQUALIFICATIONDATE, VALIDDATE } = serproParams

            const request =
            {
                ...ApiConstants.headers.SERPROPOST,
                body: JSON.stringify({
                    "Login": Auth.getSessionData().login,
                    "Password": Auth.getSessionData().password,
                    "Group": Auth.getCurrentConfigurations().Name,
                    "TicketId": ticketId,
                    "Domain": Auth.getSessionData().activeGroupConfiguration.Domain,
                    "key": {
                        "cpf": "33840981026",
                    },
                    "answer": {
                        "nome": "João",
                        "data_nascimento": "2000-10-10",
                        "filiacao": {
                            "nome_mae": "Mãe do João",
                            "nome_pai": "Pai do João"
                        },
                        "cnh": {
                            "numero_registro": "0000001",
                            "data_primeira_habilitacao": "2000-10-10",
                            "data_validade": "2000-10-10"
                        }
                    }
                }),
            };

            request.headers.Authorization = Auth.getToken();

            const response = await fetch(URL, request);
            const result = await response.json();

            switch (response.status) {
                case 200:
                    return result;
                default:
                    return {
                        status: "ERROR",
                        errors: ["Problemas com a rede de acesso."],
                    };
            }

        } catch (e) {
            return {
                status: "ERROR",
                errors: ["Problemas com a rede de acesso."],
            };
        }
    }


}



export default SERPROAPI;
