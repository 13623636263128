//Página da Modal de criar um novo grupo

import React from 'react';

// Material UI elements
import ButtonSecondary from '../components/mui/ButtonSecondary';
import TextField from '../components/mui/TextField';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';

// API
import API from '../API';

// constants
import Strings from '../constants/strings';
import ApiConstants from '../constants/api';

import {MenuItem} from '@material-ui/core';

import {connect} from 'react-redux';
import * as actions from '../actions';
import Select from "./mui/Select";

import validate from 'validate.js';
import UserLanguage from './configurations/UserLanguage.js';

validate.validators.presence.message = " é obrigatório.";

let language = UserLanguage.getLanguage();

const dialogStyle = {
};

const styleMenuItem = {
    fontSize: "14px",
    padding: "12px"
}

class NewGroupConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openDialog: props.open,
            title: Strings.groupConfiguration.NEW_GROUP[language],
            groupReference: "Default",
            targetDomain: props.configurations.Domain,
            error: false
        };
    }

    constraints = {
        newGroupName: {
            presence: {
                message: "É necessário dar um nome ao novo grupo."
            },
        },
    };

    options = {
        fullMessages: false,
    };

    // -- HANDLERS -- //
    componentWillReceiveProps(nextProps) {
        this.setState({...this.state, openDialog: nextProps.open});
    }

    handleOnClose = () => {
        this.setState({openDialog: false});
    };

    handleStoreGroup = async () => {
        let errors = validate(this.state, this.constraints, this.options);
        if(errors !== undefined){
            this.setState({errors: errors, error: true});
            return;
        }

        if(this.props.groupsNames.indexOf(this.state.newGroupName) !== -1){
            this.setState({errors: {newGroupName: "Não é possível criar grupos com o mesmo nome."}});
            this.setState({error: true});
            return;
        }

        const { newGroupName, groupReference } = this.state;

        let result = await API.createNewGroup(newGroupName, groupReference, this.props.configurations.Domain);
        if (result.status === ApiConstants.status.SUCCESS) {
            console.log("SUCESSO!");
            this.props.updateGroupConfiguration(result.configurations.Name);
            this.props.onNewGroup(result.groupsNames);
            this.setState({...this.state, groups: result.groupsNames, openDialog: false, newGroupFormDisplay: false});
        }
    };

    onReferenceGroupSelected = (event,index, value) => {
        let temp = index.props
        this.setState({groupReference: temp.value});
    };

    // -- RENDER METHODS -- //
    renderNewGroupForm() {
        return (
            <div>
                <TextField
                    hintText={Strings.groupConfiguration.NEW_GROUP_HINT[language]}
                    error={this.state.error}
                    errorText={this.state.errors ? this.state.errors.newGroupName || "" : ""}
                    onChange={(e) => {
                        this.setState({...this.state, newGroupName: e.target.value});
                    }}
                />

                <Select
                    labelText={Strings.groupConfiguration.NEW_GROUP_FROM[language]}
                    selectFieldStyle={{width: 150}}
                    onChange={this.onReferenceGroupSelected}
                    value={this.state.groupReference}
                >
                    {this.props.groupsNames.map((item) => {
                        return (
                            <MenuItem style={styleMenuItem} key={item} value={item}> {item} </MenuItem>
                        )
                    })}
                </Select>
                <ButtonSecondary label={Strings.groupConfiguration.NEW_GROUP_OK[language]} onClick={this.handleStoreGroup}/>
                <ButtonSecondary label={Strings.groupConfiguration.NEW_GROUP_CANCEL[language]} onClick={this.handleOnClose}/>
            </div>
        )
    }
    

    render() {
        return (
            <div>
                <Dialog
                    id = "SelectGroupConfigurationDialog"
                    fullScreen={this.props.fullScreen}
                    open={this.state.openDialog}
                    aria-labelledby="SelectGroupConfigurationDialogTitle"
                    style={dialogStyle}               
                    onClose={() => {
                        this.setState({openDialog: false});
                        this.props.onCancelNewGroupConfiguration();
                    }}
                    >
                        <DialogTitle id="SelectGroupConfigurationDialogTitle">
                            {this.state.title}
                        </DialogTitle>
                        <DialogContent>
                            {this.renderNewGroupForm()}
                        </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onNewGroup: (groupsNames) => {
            dispatch(actions.storeGroupsNames(groupsNames));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        groupsNames: state ? state.groupsNames : [],
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupConfiguration);
