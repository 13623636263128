import React from 'react';
import Toggle from './mui/Toggle';
import ButtonSecondary from './mui/ButtonSecondary';
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Strings from '../constants/strings';
import TextField from './mui/TextField';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const defaultToggleStyle = {
    
};

const defaultWrapperStyle = {
    marginBottom: 10
};

const textStyle = {
    
};

const buttonStyle = {
    backgroundColor: '#fff',
    border: '1px solid #000',
    borderRadius: '100px',
    width: '26px'
}

const selectFieldStyle = {
    fontSize: '18px',
    color: 'black',
    marginBottom: '12px',
    position: 'relative',
    bottom: '5px'
    // width: "200px", marginLeft: "20px", marginRight: "20px"

};

const flexRowStyle = {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
};

const reactscrollablelist = {
    sizing: 'border-box',
    height: '200px',
    width: '200px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    border: '#ddd solid 1px'
};

const imgStyle = {
    width: 18,
    height: 18,
    marginLeft: 10,
    color: 'blue',
    cursor: 'pointer'
}

const configurationSectionTitleStyle = {
    fontSize: 16,
    color: "#2B3D50",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
};

class CheckBoxEnableValueCNAE extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            newValue: ""
        }
    }

    handleSelectKeyMap = (field, event) => {
        if (field != "score") {
            const newState = this.state;
            this.state.newValue = event.target.value;
            this.setState({ newState });
        } else {
            this.state.data.Score = event.target.value;
            this.setState({state: this.state})
        }
    }

    onDelete = (value) => {
        const newState = this.state

        var index = this.state.data.Values.indexOf(value);
        this.state.data.Values.splice(index, 1);

        this.setState({ newState })

    }

    onGenerate = (newValue) => {
        const newState = this.state

        newState.data.Values.push(newState.newValue)
        this.setState({ newState });
    }

    onToggled = (newValue) => {
        const newState = this.state

        newState.data.Values.push(newState.newValue)
        this.setState({ newState });
    }

    renderView(dataset) {
        if (dataset.config[dataset.name].Enabled) {
            return (<div>
                <div style={{ display: 'flex' }}>
                    <div style={{ maxWidth: '50%' }}>
                        <h5>CNAES</h5>
                        <List
                            heightOfItem={20}
                            style={{ ...reactscrollablelist }}
                            maxItemsToRender={30}
                        >
                            {
                                this.state.data.Values.map((value, index) => {
                                    return (
                                        <ListItem style={{ borderBottom: '1px solid' }}>
                                            <ListItemText primary={value} />
                                            <ListItemSecondaryAction>
                                                <IconButton 
                                                    edge="end"
                                                    aria-label="delete"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={this.onDelete.bind(value)}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </div>
                    <div style={{ maxWidth: '50%', marginLeft: '15px' }}>
                        <TextField
                            type="text"
                            value={this.state.newValue}
                            floatingLabelText={
                                <h5 style={{ fontWeight: 'normal' }}>
                                    {Strings.backgroundCheckConfigurations_PJ.CNAE_TYPE_IN[language]}
                                </h5>
                            }
                            onChange={this.handleSelectKeyMap.bind(null, "cnae")}
                        />
                        
                        <ButtonSecondary
                            style={{textTransform: "none"}}
                            label={Strings.backgroundCheckConfigurations_PJ.CNAE_ADD[language]}
                            onClick={this.onGenerate.bind(this.state.newValue)}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px', marginBottom: "30px" }}>
                    <TextField
                        type="number"
                        value={this.state.data.Score}
                        floatingLabelText={
                            <h5 style={{ fontWeight: 'normal' }}>
                                Score:
                    </h5>
                        }
                        onChange={this.handleSelectKeyMap.bind(null, "score")}
                    />
                </div>

            </div>)
        }
    }

    render() {
        const dataset = this.props.dataset;
        const toggleStyle = Object.assign({}, defaultToggleStyle, this.props.style || {});

        return (
            <div style={{ display: 'block', marginRight: '5px' }}>
                <Toggle
                    tooltip={typeof Strings.backgroundCheckConfigurations_PJ[dataset.name + '_TEXT'] === 'object' ? Strings.backgroundCheckConfigurations_PJ[dataset.name + '_TEXT'][language] : Strings.backgroundCheckConfigurations_PJ[dataset.name + '_TEXT']}
                    style={toggleStyle}
                    label={(dataset.incomplete ? ' . . . API . . . - ' + dataset.incomplete : Strings.backgroundCheckConfigurations_PJ[dataset.name][language])}
                    toggled={dataset.config[dataset.name].Enabled}
                    key={dataset.name}
                    name={dataset.name}
                    onToggle={this.props.handledataToggle.bind(null, dataset.name)}
                />
                {this.renderView(dataset)}
            </div>
        )
    }

}

export default CheckBoxEnableValueCNAE;