import React from 'react';
import Toggle from './mui/Toggle';

import Strings from '../constants/strings';
import TextField from './mui/TextField';
import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const defaultToggleStyle = {

};

const defaultWrapperStyle = {
    marginBottom: 10
};

const textStyle = {
  float: 'right'
};

class NameMatchValidation extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            over: false,
            nameMatch: ""
        }
    }

    onNameMatchChange = (event) => {
        this.setState({nameMatch: event.target.value})
        this.props.nameMatchChange(event.target.value)
    }

    render() {
        const validation = this.props.validation;

        const toggleStyle = Object.assign({},defaultToggleStyle, this.props.style || {});
        let wrapperStyle = Object.assign({},defaultWrapperStyle, this.props.wrapperStyle || {});

        if(this.state.over){
            wrapperStyle.backgroundColor = "rgba(200,200,200,0.25)";
        }

        const nameMatchTextStyle = {
            display: (validation.Enabled ? "block" : "none"),
            marginLeft: '10%'
        };

        return (
            <div
                style={wrapperStyle}
                key={validation.Name}
                onMouseOver={()=>{this.setState({over: true})}}
                onMouseLeave={()=>{this.setState({over: false})}}>
                <Toggle
                    tooltip={Strings.tooltips.validations.BIGID_NAME_MATCH[language]}
                    style={toggleStyle}
                    label={Strings.validations.PREFIX[language] + Strings.validations[validation.Name][language]}
                    toggled={validation.Enabled}
                    key={validation.Name}
                    name={validation.Name}
                    onToggle={this.props.handleValidationToggle.bind(null, validation.Name)}
                />

                <TextField
                    tooltip={Strings.tooltips.validations.BIGID_NAME_MATCH_PORCENTAGE[language]}
                    textStyle={{textStyle}}
                    labelStyle={{fontWeight: 300}}
                    type="number"
                    wrapperStyle={nameMatchTextStyle}
                    floatingLabelText={Strings.validations.BIGID_NAME_MATCH_PORCENTAGE[language]}
                    value={validation.Configurations ? validation.Configurations.MATCH_PERCENT : this.state.nameMatch}
                    name="BIGID_NAME_MATCH"
                    onChange={this.onNameMatchChange}
                />
            </div>
        )
    }
}

export default NameMatchValidation;
