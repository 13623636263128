import React from 'react';

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin:"10px"
}

const textContainer = {
    display: 'flex',
    flexDirection: 'column'
}


export default class DialogItem extends React.Component {

    render(){

        const { title, image, text, order } = this.props

        if(order === 1){
            return (
                <div style={containerStyle}>
                    <div style={textContainer}>
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                    <img src={image} alt=""></img>
                </div>
            );
        } else {
            return (
                <div style={containerStyle}>
                    <img src={image} alt=""></img>
                    <div style={textContainer}>
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                </div>
            );
        }
    }
}
