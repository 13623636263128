import React from 'react';

import Colors from '../constants/colors';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tooltip';
import strings from '../constants/strings';

import UserLanguage from '../components/configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

const configurationSectionWrapperStyle = {
    marginBottom: 30,
};

const labelWrapperStyle = {
    display: 'flex',
    alignItems: "center",
};

class SectionTitle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    componentDidMount() {
        $(document).tooltip({
            position: { my: "left+15 top-15", at: "right center", collision: "flipfit" }
        });
    }



    renderTooltipIcon() {
        if (this.props.tooltip) {
            return (
                <HelpOutlineIcon title={this.props.tooltip} style={{ width: 18, height: 18, padding: 0, marginLeft: 10 }} />
            )
        }

        return null;
    }

    renderLabel() {
        if (this.props.tooltip) {
            return (
                <span style={labelWrapperStyle}>
                    <span>
                        {this.props.text}
                    </span>

                    {this.renderTooltipIcon()}
                </span>
            )
        } else {
            return (
                <span>
                    {this.props.text}
                    {this.renderTooltipIcon()}
                </span>
            )
        }
    }

    renderHelper() {
        const imgStyle = {
            width:18,
            height:18,
            marginLeft: 10,
            cursor: "pointer"
        }

        if (this.props.helper) {
            return (
                <div>
                    <img style={imgStyle} onClick={this.props.handleOpenModal} src="assets/interrogation.png" alt="" />
                </div>
            )
        } else if (this.props.helper === "") {
            return (
                <div>
                    <h3 style={imgStyle} onClick={this.props.handleOpenModal}>{strings.ABTestingLabels.EXPLANATION_MODAL[language]}</h3> 
                </div>
            )
        }
    }

    render() {
        const configurationSectionTitleStyle = {
            fontSize: 15,
            color: Colors.primaryTitleRed,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center"
        };


        let wrapperStyle = Object.assign({}, configurationSectionWrapperStyle, this.props.wrapperStyle);

        return (
            <div style={wrapperStyle}>
                {this.props.children}
                <h3 style={configurationSectionTitleStyle}> {this.renderLabel()} {this.renderHelper()}</h3>
            </div>

        );

    }
}

export default SectionTitle;
