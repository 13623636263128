import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const progressWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

export default class NotAllowedDialog extends React.Component {
    

    render() {
        if (!this.props.open) {
            return null;
        }

        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                open={this.props.open}
                onClose={this.props.handleRequestClose}
                aria-labelledby="user-not-auth"
            >
                <DialogTitle id="user-not-auth">
                    {this.props.dialogTitle || String.APP_NAME}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    <div style={progressWrapperStyle}>
                        <h3>Usuário não autorizado a realizar a ação.</h3>
                    </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.props.actions}
                </DialogActions>
            </Dialog>
        );
    }
}
