export default {
    //URL: process.env.NODE_ENV === 'development' ? 'http://localhost:58219/api/' : 'https://bigid.bigdatacorp.com.br/SiteAPI/api/',
    //URL: process.env.NODE_ENV === 'development' ? 'https://localhost:44358/' : 'https://bigid.bigdatacorp.com.br/SiteAPI/api/',

    URL: 'https://bigid.bigdatacorp.com.br/SiteAPI/api/',
    //URL: 'http://localhost:58219/api/',
    headers: {
        GET: {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "Access-Control-Allow-Credentials": true
            },
        },

        POST: {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "Access-Control-Allow-Credentials": true
            },
        },

        SERPROPOST: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "Access-Control-Allow-Credentials": true
            },
        },

        DELETE: {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "Access-Control-Allow-Credentials": true
            },
        }
    },

    status: {
        SUCCESS: "SUCCESS",
        PROBABILITY_ERROR: "PROBABILITY_ERROR"
    }
}
