import React from 'react';

import Colors from '../constants/colors';
import Toggle from './mui/Toggle';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import ButtonSecondary from '../components/mui/ButtonSecondary';
import { MenuItem } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Select from './mui/Select';

import Strings from '../constants/strings';

import { connect } from 'react-redux';
import * as actions from '../actions';

import Auth from '../Authentication';

import validate from 'validate.js';
import ConfirmationDialog from "./ConfirmationDialog";

import UserLanguage from './configurations/UserLanguage.js'

let language = UserLanguage.getLanguage();

const questionWrapperStyle = {
    marginBottom: "5px"
};

const questionHeaderStyle = {
    backgroundColor: Colors.primaryBlue,
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "10px",
    cursor: "pointer"
};

const questionHeaderTextStyle = {
    display: "inline-block",
    color: "white",
    fontWeight: "bold"
};

const defaultEnabledToggleStyle = {
    display: "inline-block",
    marginRight: "20px"
};

const arrowDownStyle = {
    position: "absolute",
    right: "50px",
};

const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}

let questionConfigurationsStyleFlex = {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: 10,
    display: "flex",
    padding: "10px"
};

let questionConfigurationsStyleNone = {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    paddingLeft: 10,
    display: "none"
};

const questionHeaderArrowStyle = { width: 50, height: 50 };

class QuestionConfiguration extends React.Component {

    constructor(props) {
        super(props);

        // Get question from the current configuration
        let questionConfiguration = this.props.configurations.QuestionsConfigurations.find((x) => {
            return x.QuestionId === this.props.question.Id;
        });

        // If the question does not exists in the configuration
        // create one with these default values
        if (!questionConfiguration) {
            questionConfiguration = {
                QuestionId: this.props.question.Id,
                Enabled: false,
                AllowNoAAsCorrectAnswer: true,
                Frequency: 1,
                Mandatory: false,
                PositiveWeight: 1,
                NegativeWeight: 0
            };
        }

        // Create the initial state with the questionConfiguration
        this.state = {
            showConfiguration: false,
            questionConfiguration: questionConfiguration,
            confirmAlways: false
        };
    }

    // Validation constraints
    constraints = {
        PositiveWeight: {
            presence: {
                message: Strings.generalConfigurations.POSITIVE_SCORE_ALERT[language]
            },
        },
        NegativeWeight: {
            presence: {
                message: Strings.generalConfigurations.NEGATIVE_SCORE_ALERT[language]
            },
        }
    };

    options = {
        fullMessages: false,
    };

    validateNumberOfInfotypes(enabled) {
        let infoTypes = {};
        for (let question of this.props.configurations.QuestionsConfigurations) {
            const questionProperty = this.props.questionsProperties.AllQuestionPorperties.find((x) => { return question.QuestionId === x.Id });
            if (question.Enabled) {
                infoTypes[questionProperty.InfoType] = (infoTypes[questionProperty.InfoType] || 0) + 1;
            }
        }

        infoTypes[this.props.question.InfoType] = infoTypes[this.props.question.InfoType] + (enabled ? 1 : -1);

        let infoTypesEnabled = Object.keys(infoTypes).filter((x) => infoTypes[x] > 0);

        return infoTypesEnabled.length >= this.props.configurations.QuestionCount;
    }

    // When disableing essential question toggle, this function checks the total number of essentials
    validateEssentialRules(toggled){
        if(!toggled){
            let infoTypes = {};
            for (let question of this.props.configurations.QuestionsConfigurations) {
                const questionProperty = this.props.questionsProperties.AllQuestionPorperties.find((x) => { return question.QuestionId === x.Id });
                if (question.Essential) {
                    infoTypes[questionProperty.InfoType] = (infoTypes[questionProperty.InfoType] || 0) + 1;
                }
            }
    
            infoTypes[this.props.question.InfoType] = infoTypes[this.props.question.InfoType] + (toggled ? 1 : -1);
    
            let essentialInfoTypes = Object.keys(infoTypes).filter((x) => infoTypes[x] > 0);
    
            return essentialInfoTypes.length >= this.props.configurations.EssentialQuestionsCount;
        }
        else{
            return true;
        }
    }

    onEnabledToggleChange = (event, toggled) => {
        let newState = this.state;
        this.state.confirmAlways = false
        if (this.validateNumberOfInfotypes(toggled)) {
            newState.questionConfiguration.Enabled = toggled;
            this.setState(newState, this.saveQuestionConfiguration);
        } else {
            this.setState({ error: Strings.generalConfigurations.ENABLED_QUESTIONS_ALERT[language] });
        }
    };

    onEssentialToggleChange = (event, toggled) => {
        let newState = this.state;
        this.state.confirmAlways = false
        if (this.validateEssentialRules(toggled)) {
            newState.questionConfiguration.Essential = toggled;
            this.setState(newState, this.saveQuestionConfiguration);
        } else {
            this.setState({ error: Strings.generalConfigurations.ESSENTIAL_QUESTIONS_ALERT[language] });
        }
    };

    onNOAToggleChange = (event, toggled) => {
        let newState = this.state;
        newState.questionConfiguration.AllowNoAAsCorrectAnswer = toggled;
        this.setState(newState, this.saveQuestionConfiguration);
    };

    onFrequencyChange = (event, index, value) => {
        let newState = this.state;
        if (event.target.value === 3) {
            newState.confirmAlways = true
            this.setState(newState, this.saveQuestionConfiguration);
            return;
        }
        else {
            newState.confirmAlways = false
        }

        newState.questionConfiguration.Mandatory = event.target.value === 3;
        newState.questionConfiguration.Frequency = event.target.value;
        this.setState(newState, this.saveQuestionConfiguration);
    };

    onAlwaysConfirm = () => {
        let newState = this.state;
        newState.questionConfiguration.Enabled = true;
        newState.questionConfiguration.Mandatory = true;
        newState.questionConfiguration.Frequency = 3;
        newState.confirmAlways = false;
        if (this.props.disableAllOtherQuestions) {
            this.props.disableAllOtherQuestions();
        }
        this.setState(newState, this.saveQuestionConfiguration);
    };

    onWeightChange = (weight, event, value) => {
        let newState = this.state;
        if (weight === 'NegativeWeight') {
            newState.questionConfiguration[weight] = event.target.value;
        } else {
            newState.questionConfiguration[weight] = event.target.value;
        }
        this.setState(newState, this.saveQuestionConfiguration);
    };

    saveQuestionConfiguration = () => {
        let configurations = this.props.configurations;

        // If validate return errors then return
        if (validate(this.state.questionConfiguration, this.constraints, this.options)) {
            return;
        }

        // If questions configurations does not have the question
        // add it to the array
        if (!configurations.QuestionsConfigurations.find((x) => { return x.QuestionId === this.props.question.Id })) {
            configurations.QuestionsConfigurations.push(this.state.questionConfiguration);
        } else {
            // Otherwise find it and modify it
            let questionIndex = configurations.QuestionsConfigurations.findIndex((x) => x.QuestionId === this.props.question.Id);
            let question = configurations.QuestionsConfigurations.find((x) => x.QuestionId === this.props.question.Id);

            question.Enabled = this.state.questionConfiguration.Enabled;
            question.AllowNoAAsCorrectAnswer = this.state.questionConfiguration.AllowNoAAsCorrectAnswer;
            question.Mandatory = this.state.questionConfiguration.Mandatory;
            question.Frequency = this.state.questionConfiguration.Frequency;
            question.PositiveWeight = this.state.questionConfiguration.PositiveWeight;
            question.NegativeWeight = this.state.questionConfiguration.NegativeWeight;

            configurations.QuestionsConfigurations[questionIndex] = question;
        }

        this.props.onSave(configurations);
    };

    showConfiguration = () => {
        this.setState({ showConfiguration: !this.state.showConfiguration, confirmAlways: false });
    };

    renderError() {
        if (this.state.error) {
            return (
                <Dialog open={true} onClose={() => { this.setState({ error: null }) }}>
                    <DialogTitle>
                        {"Erro"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ButtonSecondary
                            label={Strings.generalConfigurations.BACK[language]}
                            onClick={() => this.handleCloseModal()}
                        />
                    </DialogActions>
                </Dialog>
            )
        }

        return null;
    }

    render() {
        let errors = validate(this.state.questionConfiguration, this.constraints, this.options);
        // If configuration is opened show arrow up, else show arrow down
        const arrow = this.state.showConfiguration ? (<KeyboardArrowUpIcon style={questionHeaderArrowStyle} />) : (<KeyboardArrowDownIcon style={questionHeaderArrowStyle} />);

        let headerCurrentStyle;

        // If the question is not enabled
        // set the background color to grey
        if (!this.state.questionConfiguration.Enabled) {
            headerCurrentStyle = Object.assign({}, questionHeaderStyle, { backgroundColor: '#777' });
        } else {
            headerCurrentStyle = Object.assign({}, questionHeaderStyle)
        }

        return (
            <div style={questionWrapperStyle}>
                {this.renderError()}

                <div style={headerCurrentStyle} onClick={this.showConfiguration}>
                    <div style={questionHeaderTextStyle}>
                        {Auth.sessionData.isAdmin ? ` ID (${this.props.question.Id}) - ` : ""}
                        {this.props.question.QuestionText[language]}
                    </div>

                    <div style={arrowDownStyle}>
                        {arrow}
                    </div>
                </div>

                <div style={this.state.showConfiguration ? questionConfigurationsStyleFlex : questionConfigurationsStyleNone} >
                    <Toggle
                        key={`Enabled #${this.props.question.QuestionId}`}
                        id={`Enabled #${this.props.question.QuestionId}`}
                        style={defaultEnabledToggleStyle}
                        label={Strings.questionConfiguration.ENABLE_QUESTION[language]}
                        toggled={this.state.questionConfiguration.Enabled}
                        onToggle={this.onEnabledToggleChange}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Toggle
                        tooltip={!this.props.question.CanUseNoAAsCorrectAnswer ? "Esta pergunta não aceita NDA." : ""}
                        key={`NDA #${this.props.question.QuestionId}`}
                        id={`NDA #${this.props.question.QuestionId}`}
                        style={defaultEnabledToggleStyle}
                        disabled={!this.props.question.CanUseNoAAsCorrectAnswer}
                        label={Strings.questionConfiguration.ALLOW_NDA[language]}
                        toggled={this.state.questionConfiguration.AllowNoAAsCorrectAnswer}
                        onToggle={this.onNOAToggleChange}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Select
                        tooltip={Strings.tooltips.QUESTION_FREQUENCY[language]}
                        labelText={Strings.questionConfiguration.FREQUENCY[language]}
                        key={`Frequency #${this.props.question.QuestionId}`}
                        id={`Frequency #${this.props.question.QuestionId}`}
                        onChange={this.onFrequencyChange}
                        value={this.state.questionConfiguration.Frequency}
                        selectFieldStyle={{ width: "150px" }}
                        labelStyle={{ width: "120px" }}
                        wrapperStyle={{ marginTop: 5 }}
                    >
                        <MenuItem style={styleMenuItem} value={3}> {Strings.selectChoices.ALWAYS[language]} </MenuItem>
                        <MenuItem style={styleMenuItem} value={2}> {Strings.selectChoices.OFTEN[language]} </MenuItem>
                        <MenuItem style={styleMenuItem} value={1}> {Strings.selectChoices.SOMETIMES[language]} </MenuItem>
                        <MenuItem style={styleMenuItem} value={0}> {Strings.selectChoices.RARELY[language]} </MenuItem>
                    </Select>

                    <Select labelText={Strings.questionConfiguration.HIT_SCORE[language]}
                        key={`Positive #${this.props.question.QuestionId}`}
                        id={`Positive #${this.props.question.QuestionId}`}
                        onChange={this.onWeightChange.bind(null, "PositiveWeight")}
                        value={this.state.questionConfiguration.PositiveWeight}
                        labelStyle={{ width: "200px" }}
                        wrapperStyle={{ width: "170px", marginLeft: 20, marginTop: 5 }}
                        selectFieldStyle={{ width: "100px" }}
                        errorText={`${errors ? errors.PositiveWeight : ""}`}
                    >
                        <MenuItem style={styleMenuItem} value={0}>0</MenuItem>
                        <MenuItem style={styleMenuItem} value={1}>1</MenuItem>
                        <MenuItem style={styleMenuItem} value={2}>2</MenuItem>
                        <MenuItem style={styleMenuItem} value={3}>3</MenuItem>
                        <MenuItem style={styleMenuItem} value={4}>4</MenuItem>
                        <MenuItem style={styleMenuItem} value={5}>5</MenuItem>
                    </Select>

                    <Select labelText={Strings.questionConfiguration.SCORE_ERROR[language]}
                        key={`Negative #${this.props.question.QuestionId}`}
                        id={`Negative #${this.props.question.QuestionId}`}
                        onChange={this.onWeightChange.bind(null, "NegativeWeight")}
                        value={this.state.questionConfiguration.NegativeWeight}
                        labelStyle={{ width: "150px" }}
                        selectFieldStyle={{ width: "70px" }}
                        wrapperStyle={{ width: "170px", marginRight: 10 }}
                        errorText={`${errors ? errors.NegativeWeight : ""}`}
                    >
                        <MenuItem style={styleMenuItem} value={0}>0</MenuItem>
                        <MenuItem style={styleMenuItem} value={-1}>-1</MenuItem>
                        <MenuItem style={styleMenuItem} value={-2}>-2</MenuItem>
                        <MenuItem style={styleMenuItem} value={-3}>-3</MenuItem>
                        <MenuItem style={styleMenuItem} value={-4}>-4</MenuItem>
                        <MenuItem style={styleMenuItem} value={-5}>-5</MenuItem>
                    </Select>

                    <Toggle
                        key={`Essential #${this.props.question.QuestionId}`}
                        id={`Essential #${this.props.question.QuestionId}`}
                        style={defaultEnabledToggleStyle}
                        label={Strings.questionConfiguration.ESSENTIAL_QUESTION[language]}
                        toggled={this.state.questionConfiguration.Essential}
                        onToggle={this.onEssentialToggleChange}
                    />

                    <ConfirmationDialog
                        onConfirm={this.onAlwaysConfirm}
                        text={Strings.questionConfiguration.CONFIRM_ALWAYS[language]} open={this.state.confirmAlways}
                    />

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        questionsProperties: state ? state.questionsProperties : {}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionConfiguration);
