import React from 'react';
//import SelectField from 'material-ui/SelectField';
import SelectField from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import TextField from 'material-ui/TextField';
import Strings from '../constants/strings';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from '../actions';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const wrapperStyle = {
    width: "330px",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start"
};

const fieldStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
};

const labelStyle = {
    marginBottom: 10,
    fontSize: "16px",
    marginLeft: '10%',
};

const selectFieldStyle = {
    width: "110px",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "10px",
    fontSize: "14px"

};

const textFieldStyle = {
    width: "50px",
    marginLeft: "10px"
};
const styleMenuItem = {
    fontSize: "16px",
    padding: "8px"
}


class VelocityValidationConfiguration extends React.Component {

    constructor(props) {
        super(props);
        //const key = "CPF";
        const key = Object.keys(props.validation.ListsConfiguration)[0] || "DOC";
        const listConfiguration = props.validation.ListsConfiguration[key];

        if (listConfiguration && listConfiguration.ValueList && listConfiguration.PeriodList) {
            this.state = {
                validation: props.validation,
                configurations: props.configurations,

                key: key,
                quantity: listConfiguration.ValueList[0],
                time: (listConfiguration.PeriodList[0]) / (60 * 1000)
            };
        } else {
            this.state = {
                validation: props.validation,
                configurations: props.configurations,

                key: key,
                quantity: "",
                time: ""
            };
        }
    }

    componentWillReceiveProps(newProps) {
        const key = Object.keys(newProps.validation.ListsConfiguration)[0] || "DOC";
        const listConfiguration = newProps.validation.ListsConfiguration[key];

        if (listConfiguration && listConfiguration.ValueList && listConfiguration.PeriodList) {
            this.setState({
                validation: newProps.validation,
                configurations: newProps.configurations,

                key: key,
                quantity: listConfiguration.ValueList[0],
                time: (listConfiguration.PeriodList[0]) / (60 * 1000)
            });
        } else {
            this.setState({
                validation: newProps.validation,
                configurations: newProps.configurations,

                key: key,
                quantity: "",
                time: ""
            })
        }
    }

    onKeyChange = (event, index, value) => {
        this.setState({ key: event.target.value }, this.stateChanged);
    };

    onTextChange = (key, event, value) => {
        let newState = this.state;
        newState[key] = value;
        this.setState(newState, this.stateChanged);
    };

    stateChanged = () => {
        let validation = this.state.validation;
        validation.ListsConfiguration = {};
        validation.ListsConfiguration[this.state.key] = {
            Enabled: true,
            ValueList: [this.state.quantity],
            PeriodList: [this.state.time * 60 * 1000]
        };

        let newState = this.state;
        let result = null;

        for (const index in this.state.configurations.BigIdValidationOptions) {
            const current = this.state.configurations.BigIdValidationOptions[index];
            if (current.Name === validation.Name) {
                result = index;
                break;
            }
        }

        if (result !== null) {
            newState.configurations.BigIdValidationOptions[result] = validation;
            this.setState(newState);
            this.props.onSave(newState.configurations);
        }
    };

    render() {
        const selectField = this.props.isIP ? (
            <div></div>
        ) : (
                <div style={fieldStyle} id="validations">
                    <div style={labelStyle}>
                        {Strings.validations.BIGID_VELOCITY_KEY.KEY[language]}
                    </div>
                    
                    <SelectField
                        style={selectFieldStyle}
                        value={this.state.key}
                        onChange={this.onKeyChange.bind()}
                    >
                        <MenuItem style={styleMenuItem} value={"CPF"} primaryText="CPF">CPF</MenuItem>
                        <MenuItem style={styleMenuItem} value={"CRM"} primaryText="CRM">CRM</MenuItem>
                    </SelectField>
                </div>

            );

        return (
            <div style={wrapperStyle}>

                <div style={fieldStyle}>
                    {selectField}
                </div>

                <div style={fieldStyle}>
                    <div style={labelStyle}>
                        {Strings.validations.BIGID_VELOCITY_KEY.QUANTITY[language]}
                    </div>

                    <TextField
                        name="quantity"
                        style={textFieldStyle}
                        type="number"
                        value={this.state.quantity}
                        onChange={this.onTextChange.bind(null, "quantity")}
                    />
                </div>

                <div style={fieldStyle}>
                    <div style={labelStyle}>
                        {Strings.validations.BIGID_VELOCITY_KEY.TIME[language]}
                    </div>

                    <TextField
                        name="time"
                        style={textFieldStyle}
                        type="number"
                        value={this.state.time}
                        onChange={this.onTextChange.bind(null, "time")}
                    />

                    <div style={{ marginBottom: 10, color: "grey" }}>
                        Min
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
    }
};


VelocityValidationConfiguration.propTypes = {
    validation: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VelocityValidationConfiguration);

