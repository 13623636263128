import React from 'react';

import Header from '../components/Header';
import LoadingDialog from '../components/LoadingDialog';
import SelectGroupConfiguration from '../components/SelectGroupConfiguration';

import Auth from '../Authentication';
import Strings from '../constants/strings';

import { Redirect } from 'react-router-dom';

// Material UI Imports
import {Card, CardContent} from '@material-ui/core';


import SideMenu from '../components/SideMenu';
import GeneralReport from '../components/reports/GeneralReport';
import QuestionnaireReport from '../components/reports/QuestionnaireReport';
import QuestionsReport from '../components/reports/QuestionsReport';
import AnswersReport from '../components/reports/AnswersReport';
import ABTestingReport from '../components/reports/ABTestingReport';
import BGCReport from '../components/reports/BGCReport';
import OCRReport from '../components/reports/OCRReport';
import FacematchReport from '../components/reports/Facematch';

// API
import { connect } from 'react-redux';
import * as actions from '../actions';
import Footer from "../components/Footer";


const cardStyle = {
    width: "100%",
    maxWidth: "900px",
    minHeight: "650px",
    paddingTop: "15px",
    position: "relative",
    marginLeft: '200px',
    boxShadow: null,
};


const containerStyle = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative"
};

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configurations: this.props.configurations,
            menuActive: Strings.reports.menu.GENERAL,
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            configurations: newProps.configurations,
            modified: newProps.modified,
        });
    }

    // -- HANDLERS -- //
    handleMenuChange = (value) => {
        this.setState({menuActive: value});
    };

    updateGroupConfiguration = () => {
        this.setState({
            configurations: Auth.getCurrentConfigurations(),
            menuActive: Strings.generalConfigurations.menu.GENERAL,
            displayCreateGroupForm: false,
        });
        this.props.onSelectGroup(Auth.getCurrentConfigurations());
    };

    // -- RENDER -- //
    renderContent() {
        switch (this.state.menuActive) {
            case Strings.reports.menu.GENERAL:
                return (<GeneralReport />);
            case Strings.reports.menu.QUESTIONNAIRE:
            case Strings.reports.menu.QUESTIONNAIRE.SUBS.GERAL:
                return (<QuestionnaireReport />);
            case Strings.reports.menu.QUESTIONNAIRE.SUBS.QUESTIONS:
                return (<QuestionsReport />);
            case Strings.reports.menu.QUESTIONNAIRE.SUBS.ANSWERS:
                return (<AnswersReport />);
            case Strings.reports.menu.QUESTIONNAIRE.SUBS.ABTESTING:
                return (<ABTestingReport repType='Quest'/>);
            case Strings.reports.menu.BACKGROUND_CHECK:
            case Strings.reports.menu.BACKGROUND_CHECK.SUBS.GERAL:
                return (<BGCReport />);
            case Strings.reports.menu.BACKGROUND_CHECK.SUBS.DETALHADO:
                return (<BGCReport repType="detailed" />);
            case Strings.reports.menu.BACKGROUND_CHECK.SUBS.ABTESTING:
                return (<ABTestingReport repType='BGC'/>);
            case Strings.reports.menu.OCR:
            case Strings.reports.menu.OCR.SUBS.GERAL:
                return (<OCRReport />);
            case Strings.reports.menu.OCR.SUBS.DETALHADO:
                return (<OCRReport repType="detailed" />);
            case Strings.reports.menu.FACEMATCH:
            case Strings.reports.menu.FACEMATCH.SUBS.GERAL:
                return (<FacematchReport />);
            case Strings.reports.menu.FACEMATCH.SUBS.DETALHADO:
                return (<FacematchReport repType="detailed" />);
            default:
                return null;
        }
    }

    render() {
        // Main report menu items in order, do not put subitems here.
        const menuItems = [
            Strings.reports.menu.GENERAL,
            Strings.reports.menu.QUESTIONNAIRE,
            Strings.reports.menu.OCR,
            Strings.reports.menu.FACEMATCH,
            Strings.reports.menu.BACKGROUND_CHECK,
        ];

        if (!Auth.sessionData.isReportUser && (Auth.sessionData.isTelemarketing || Auth.sessionData.isViewOnlyUser))
            return <Redirect to={Strings.routes.CONFIGURATIONS}/>;

        return (
            <div>
                <Header />
                <div class="content-wrapper" style={containerStyle}>
                    <SideMenu title="Relatórios" menuItems={menuItems} selected={this.state.menuActive} onSelectedChange={this.handleMenuChange}/>

                    <Card id="card" style={cardStyle} class="content">
                        <CardContent>
                            {this.renderContent()}             
                        </CardContent>
                    </Card>
                </div>
                <Footer/>

                <LoadingDialog open={this.state.loading}/>
                <SelectGroupConfiguration 
                    open={this.state.displayCreateGroupForm}
                    updateGroupConfiguration={this.updateGroupConfiguration}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSave: (configurations) => {
            dispatch(actions.saveConfigurations(configurations))
        },

        onSelectGroup: (configurations) => {
            dispatch(actions.selectConfigurations(configurations));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        configurations: state ? state.configurations : {},
        modified: state? state.modified : false,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
