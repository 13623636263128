import React from 'react';
import Toggle from './mui/Toggle';

import Strings from '../constants/strings';
import TextField from './mui/TextField';

import UserLanguage from './configurations/UserLanguage.js';

let language = UserLanguage.getLanguage();

const defaultToggleStyle = {

};

const defaultWrapperStyle = {
    marginBottom: 10
};

const textStyle = {
    float: 'right'
};

const flexContainer = {
    display: 'flex',
};

class IpVelocityValidation extends React.Component {

    state = {
        over: false,
        validationName: "BIGID_IP_VELOCITY",
        quantity: "30",
        time: "20"
    }

    onTimeChange = (event) => {
        this.setState({time: event.target.value})
        this.props.onTimeChange(event.target.value)
    }

    onQuantityChange = (event) => {
        this.setState({quantity: event.target.value})
        this.props.onQuantityChange(event.target.value)
    }

    render() {

        //Receive from props: validation, handleValidationToggle, onChangeQuantity, onChangeTime
        const { validation } = this.props

        let Configurations, Enabled, savedTime = null
        const { validationName } = this.state

        if (!validation) {
            Enabled = false
        } else {
            Enabled = validation.Enabled
            Configurations = validation.Configurations
            savedTime = ( Number(Configurations.TIME) / (60 * 1000)).toString()
        }


        const toggleStyle = Object.assign({}, defaultToggleStyle, this.props.style || {});
        let wrapperStyle = Object.assign({}, defaultWrapperStyle, this.props.wrapperStyle || {});

        if (this.state.over) {
            wrapperStyle.backgroundColor = "rgba(200,200,200,0.25)";
        }

        const ageValidationTextStyle = {
            display: (Enabled ? "block" : "none"),
            marginLeft: '10%'
        };

        return (
            <div
                key={validationName}
                onMouseOver={() => { this.setState({ over: true }) }}
                onMouseLeave={() => { this.setState({ over: false }) }}>
                <Toggle
                    tooltip={Strings.tooltips.validations.BIGID_IP_VELOCITY[language]}
                    style={toggleStyle}
                    label={Strings.validations[validationName].LABEL[language]}
                    toggled={Enabled}
                    key={validationName}
                    name={validationName}
                    onToggle={this.props.handleValidationToggle.bind(null, validationName)}
                />
                <div style={flexContainer}>
                    <TextField
                        textStyle={{ textStyle }}
                        labelStyle={{ fontWeight: 300 }}
                        type="number"
                        wrapperStyle={ageValidationTextStyle}
                        floatingLabelText={Strings.validations[validationName].QUANTITY[language]}
                        value={Configurations ? Configurations.QUANTITY : this.state.quantity}
                        name="BIGID_IP_VELOCITY_QUANTITY"
                        onChange={this.onQuantityChange}
                    />

                    <TextField
                        textStyle={{ textStyle }}
                        labelStyle={{ fontWeight: 300 }}
                        type="number"
                        wrapperStyle={ageValidationTextStyle}
                        floatingLabelText={Strings.validations[validationName].TIME[language]}
                        value={Configurations ? savedTime : this.state.time}
                        name="BIGID_IP_VELOCITY_TIME"
                        onChange={this.onTimeChange}
                    />
                </div>
            </div>
        )
    }
}

export default IpVelocityValidation;
