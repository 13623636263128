import React from 'react';
import { FormControlLabel, List, ListItem, ListItemText, ListItemSecondaryAction, Switch } from '@material-ui/core';
import { red, common } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tooltip';

const RedSwitch = withStyles({
    switchBase: {
        color: common[300],
        '&$checked': {
            color: '#0069ff'
        },
        '&$checked + $track': {
            backgroundColor: '#0069ff'
        }
    },
    checked: {},
    track: {}
})(Switch);

const defaultToggleStyle = {
    
};

const defaultLabelStyle = {
    fontWeight: 'bold'
};

const defaultWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between'
};

const labelStyleWithTooltip = {
    display: 'flex',
    alignItems: 'center'
};


class BigIdToggle extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            over: false
        }
    }

    componentDidMount(){
        $(document).tooltip({
            position: { my: "left+15 top-15", at: "right center", collision: "flipfit" }
        });
    }

    renderTooltipIcon(){
        if(this.props.tooltip){
            return (
                <HelpOutlineIcon title={this.props.tooltip} style={{width: 18, height: 18, padding: 0, marginLeft: 10}}/>
            )
        }

        return null;
    }

    render() {
        var toggleStyle = Object.assign({}, defaultToggleStyle, this.props.style || {});
        let labelStyle = Object.assign({}, defaultLabelStyle, this.props.labelStyle || {});
        let wrapperStyle = Object.assign({}, defaultWrapperStyle, this.props.wrapperStyle || {});

        if(this.state.over){
            wrapperStyle.backgroundColor = "rgba(200,200,200,0.25)";
        }

        let label;

        if(this.props.disabled){
            labelStyle = Object.assign({}, labelStyle, {textDecoration: "line-through"})
        }

        if(this.props.tooltip){
            let labelWrapperStyle = Object.assign({}, labelStyleWithTooltip, this.props.labelStyleWithTooltip);

            label = (
                <span style={labelWrapperStyle}>
                    <span>
                        {this.props.label}
                    </span>

                    {this.renderTooltipIcon()}
                </span>
            )
        } else {
            label = this.props.label;
        }

        return (
            <div
                style={wrapperStyle}                
                onMouseOver={()=>{this.setState({over: true})}}
                onMouseLeave={()=>{this.setState({over: false})}}
            >
                <FormControlLabel
                    control= {
                        <RedSwitch
                            onMouseOver={()=>{this.setState({over: true})}}
                            onMouseLeave={()=>{this.setState({over: false})}}
                            style={toggleStyle}
                            checked={this.props.toggled}
                            name={this.props.name}
                            onChange={this.props.onToggle}
                            id={this.props.id}
                            edge="end"
                        />
                    }
                    label={label}
                    labelPlacement={"start"}
                />
            </div>

        )
    }
}

export default BigIdToggle;